import { gql } from '@apollo/client';

export const GET_GUESTS = gql`
  query GetGuests(
    $skip: Int
    $take: Int
    $order: [GuestSortInput!]
    $where: GuestFilterInput
  ) {
    guests(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        name
        status
        currentWorkingPlace
        introduction
        career
        position
        experiences
        education
        image
        createdBy
        createdDate
        updatedBy
        updatedDate
        creator {
          id
          name
        }
        updater {
          id
          name
        }
      }
    }
  }
`;

export const GET_GUEST_BY_ID = gql`
  query getGuestById($id: String) {
    guest(id: $id) {
      id
      name
      status
      introduction
      currentWorkingPlace
      experience
      education
    }
  }
`;
