import { Col, Row, Tooltip, Typography } from 'antd';
import React from 'react';

const ColName = ({ primary, secondary, popover }) => {
  return (
    <Row>
      <Col span={24}>
        <Tooltip placement="topLeft" title={popover}>
          <Typography.Text
            ellipsis
            strong
            style={{
              color: 'inherit',
              marginBottom: 4,
              cursor: 'pointer',
              WebkitLineClamp: '2',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              whiteSpace: 'normal'
            }}
          >
            {primary}
          </Typography.Text>
        </Tooltip>
      </Col>

      {secondary && (
        <Col span={24}>
          <Typography.Text ellipsis style={{ color: 'gray', fontSize: 13 }}>
            {secondary}
          </Typography.Text>
        </Col>
      )}
    </Row>
  );
};

export default ColName;
