import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { _radio, _radioMedias, _radioNotes } from 'operations/queries/queryRadio';

export const EDIT_RADIO = gql`
  mutation FullyEditRadio($input: EditRadioV2Input!) {
    fullyEditRadio(input: $input) {
      ${_radio}
    }
  }
`;

export const DRAFTING_RADIO = gql`
  mutation DraftingRadio($input: DraftingRadioInput!) {
    draftingRadio(input: $input) {
      ${_radio}
    }
  }
`;

export const ADD_RADIO = gql`
  mutation AddNewRadio($addRadioInput: AddRadioInput!) {
    addRadio(input: $addRadioInput) {
      id
      updater {
        id
        name
      }
      hosts {
        id
        name
      }
      guests {
        id
        name
      }
      updatedDate
      updatedBy
      createdDate
      title
      status
      introduction
      image
      radioNumber
      broadcastTime
      creator {
        id
        name
      }
      category {
        id
        title
      }
      audioUrl
    }
  }
`;

export const REMOVE_RADIO = gql`
  mutation RemoveRadio($id: String!) {
    removeRadio(id: $id) {
      id
      title
    }
  }
`;

export const ADD_RADIO_REPLAY_SCHEDULE = gql`
  mutation AddRadioReplaySchedule($input: AddRadioRaplayScheduleInput!) {
    addRadioReplaySchedule(input: $input) {
      id
      radio {
        title
      }
    }
  }
`;

export const UPDATE_RADIO_REPLAY_SCHEDULE = gql`
  mutation AddRadioReplaySchedule($input: EditRadioReplayScheduleInput!) {
    editRadioReplaySchedule(input: $input) {
      id
      radio {
        title
      }
    }
  }
`;

export const REMOVE_RADIO_REPLAY_SCHEDULE = gql`
  mutation AddRadioReplaySchedule($id: String!) {
    removeRadioReplaySchedule(id: $id) {
      id
      radio {
        title
      }
    }
  }
`;

export const ADD_RADIO_NOTES = gql`
mutation AddRadioNote($input: AddRadioNoteInput!) {
  addRadioNote(input: $input) {
    ${_radioNotes}
  }
}
`;

export const REMOVE_RADIO_NOTES = gql`
  mutation RemoveRadioNote($removeRadioNoteId: String!) {
    removeRadioNote(id: $removeRadioNoteId) {
      id
    }
  }
`;
export const ADD_RADIO_MEDIAS = gql`
mutation AddRadioMedia($input: AddRadioMediaInput!) {
  addRadioMedia(input: $input) {
    radioId
    mediaUrl
  }
}
`;

export const REMOVE_RADIO_MEDIAS = gql`
  mutation RemoveRadioMedia($removeRadioMediaId: String!) {
    removeRadioMedia(id: $removeRadioMediaId) {
      id
    }
  }
`;

export const CHANGE_PUBLISH_DATE_RADIO = gql`
  mutation EditPublishedDateRadio($input: EditPublishedDateRadioInput!) {
    editPublishedDateRadio(input: $input) {
      id
      title
    }
  }
`;

export const EDIT_DRAFT_RADIO = gql`
  mutation EditRadioDraft($input: EditRadioDraftInput!) {
    editRadioDraft(input: $input) {
      radioId
    }
  }
`;

export const MERGE_DRAFT_RADIO = gql`
  mutation MergeChangeRadioDraft($input: MergeChangeRadioDraftInput!) {
    mergeChangeRadioDraft(input: $input) {
      title
    }
  }
`;

export const useMergeDraftRadio = () => {
  const [mutate, { data, error, loading }] = useMutation(MERGE_DRAFT_RADIO, {
    onCompleted: ({ mergeChangeRadioDraft }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Radio{' '}
            <Typography.Text strong>
              {mergeChangeRadioDraft.title}
            </Typography.Text>{' '}
            đã được cập nhật 👌
          </Typography.Text>
        )
      });
    },
    onError: f => f,
    refetchQueries: ['getRadioById']
  });
  return { mutate, data, error, loading };
};

export const useEditDraftRadio = () => {
  const [mutate, { data, error, loading }] = useMutation(EDIT_DRAFT_RADIO, {
    onCompleted: () => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>Chỉnh sửa bản nháp thành công 👌</Typography.Text>
        )
      });
    },
    onError: f => f,
    refetchQueries: ['getRadioById']
  });
  return { mutate, data, error, loading };
};

export const useChangePublishDateRadio = () => {
  const [mutate, { data, error, loading }] = useMutation(
    CHANGE_PUBLISH_DATE_RADIO,
    {
      onCompleted: ({ editPublishedDateRadio }) => {
        notification.success({
          message: <Typography.Text strong>Thành công</Typography.Text>,
          description: (
            <Typography.Text>
              Radio{' '}
              <Typography.Text strong>
                {editPublishedDateRadio.title}
              </Typography.Text>{' '}
              đã được cập nhật 👌
            </Typography.Text>
          )
        });
      },
      onError: f => f,
      refetchQueries: ['getRadioById']
    }
  );
  return { mutate, data, error, loading };
};

export const useAddRadio = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_RADIO, {
    onCompleted: ({ addRadio }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Radio <Typography.Text strong>{addRadio.title}</Typography.Text> đã
            được tạo 👌
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetRadios']
  });
  return { mutate, data, error, loading };
};

export const useEditRadio = () => {
  const [mutate, { data, error, loading }] = useMutation(EDIT_RADIO, {
    refetchQueries: ['getRadioById', 'GetRadios'],
    onCompleted: ({ fullyEditRadio }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Radio{' '}
            <Typography.Text strong>{fullyEditRadio.title}</Typography.Text> đã
            được cập nhật 👌
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useDraftingRadio = () => {
  const [mutate, { data, error, loading }] = useMutation(DRAFTING_RADIO, {
    refetchQueries: ['GetRadios'],
    onCompleted: ({ draftingRadio }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Radio{' '}
            <Typography.Text strong>{draftingRadio.title}</Typography.Text> đã
            được cập nhật 👌
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useRemoveRadio = () => {
  const [mutate, { data, error, loading }] = useMutation(REMOVE_RADIO, {
    refetchQueries: ['GetRadios'],
    onCompleted: ({ removeRadio }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Radio <Typography.Text strong>{removeRadio.title}</Typography.Text>{' '}
            đã được xoá 👌
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useAddRadioReplaySchedule = () => {
  const [mutate, { data, error }] = useMutation(ADD_RADIO_REPLAY_SCHEDULE, {
    refetchQueries: ['getRadioById'],
    onCompleted: ({ addRadioReplaySchedule }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Radio{' '}
            <Typography.Text strong>
              {addRadioReplaySchedule?.radio?.title}
            </Typography.Text>{' '}
            đã được cập nhật gòi 🦧
          </Typography.Text>
        )
      });
    }
  });

  return { mutate, data, error };
};

export const useUpdateRadioReplaySchedule = () => {
  const [mutate, { data, error }] = useMutation(UPDATE_RADIO_REPLAY_SCHEDULE, {
    refetchQueries: ['getRadioById'],
    onCompleted: ({ editRadioReplaySchedule }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Radio{' '}
            <Typography.Text strong>
              {editRadioReplaySchedule?.radio?.title}
            </Typography.Text>{' '}
            đã được cập nhật gòi 🦧
          </Typography.Text>
        )
      });
    }
  });

  return { mutate, data, error };
};

export const useRemoveRadioReplaySchedule = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_RADIO_REPLAY_SCHEDULE, {
    refetchQueries: ['getRadioById'],
    onCompleted: ({ removeRadioReplaySchedule }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Radio{' '}
            <Typography.Text strong>
              {removeRadioReplaySchedule?.radio?.title}
            </Typography.Text>{' '}
            đã được cập nhật gòi 🦧
          </Typography.Text>
        )
      });
    }
  });

  return { mutate, data, error };
};

export const useAddRadioNotes = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_RADIO_NOTES, {
    onCompleted: () => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>Thêm ghi chú thành công 🥥</Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useRemoveRadioNotes = () => {
  const [mutate, { data, error, loading }] = useMutation(REMOVE_RADIO_NOTES, {
    onCompleted: () => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>Xóa ghi chú thành công 🥥</Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useAddRadioMedias = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_RADIO_MEDIAS, {
    onCompleted: () => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>Thêm file thành công 🥥</Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useRemoveRadioMedias = () => {
  const [mutate, { data, error, loading }] = useMutation(REMOVE_RADIO_MEDIAS, {
    onCompleted: () => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>Xóa file thành công 🥥</Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};
