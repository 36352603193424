import { useQuery } from '@apollo/client';
import React from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { GET_NEWS_BY_ID } from 'operations/queries/queryNews';
import { Spin, Typography } from 'antd';
import NewInfo from './NewInfo';

const NewDetail = () => {
  const { id } = useParams();
  const isCreating = useMemo(() => !id, [id]);
  const { loading } = useQuery(GET_NEWS_BY_ID, {
    variables: { id },
    skip: isCreating
  });
  return (
    <Spin spinning={loading}>
      <Typography.Title>Chi tiết tin bài</Typography.Title>
      <NewInfo isCreating={isCreating} />
    </Spin>
  );
};

export default NewDetail;
