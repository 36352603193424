import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const EDIT_TRENDTOPIC = gql`
  mutation EditTrendTopic($input: EditTrendTopicInput!) {
    editTrendTopic(input: $input) {
      trendTopicId
      contentText
      note
       title
       sourceMedia
    }
  }
`;

export const ADD_TRENDTOPIC = gql`
  mutation AddNewTrendTopic($addTrendTopicInput: AddTrendTopicInput!) {
    addTrendTopic(input: $addTrendTopicInput) {
      trendTopicId
      contentText
      note
       title
       sourceMedia
    }
  }
`;

export const REMOVE_TRENDTOPIC = gql`
mutation RemoveTrendTopic($trendTopicId: Int!) {
  removeTrendTopic(id: $trendTopicId) {
    trendTopicId
    title
  }
}
`;

export const EDIT_STATUS_TRENDTOPIC = gql`
  mutation ChangeUseStatus($input: EditTrendTopicInput!) {
    changeUseStatus(input: $input) {
      trendTopicId
      title
    }
  }
`;

export const useAddTrendTopic = () => {
  const [mutate, { data, error }] = useMutation(ADD_TRENDTOPIC, {
    refetchQueries: ['GetTrendTopics'],
    onCompleted: ({ addTrendTopic }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Chủ đề <Typography.Text strong>{addTrendTopic.title}</Typography.Text>{' '}
            đã được tạo 👌
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useEditTrendTopic = () => {
  const [mutate, { data, error }] = useMutation(EDIT_TRENDTOPIC, {
    refetchQueries: ['GetTrendTopics'],
    onCompleted: ({ editTrendTopic }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Chủ đề <Typography.Text strong>{editTrendTopic.name}</Typography.Text>{' '}
            đã được cập nhật 👌
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useRemoveTrendTopic = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_TRENDTOPIC, {
    refetchQueries: ['GetTrendTopics'],
    onCompleted: ({ removeTrendTopic }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Chủ đề{' '}
            <Typography.Text strong>{removeTrendTopic.title}</Typography.Text> đã được
            xoá 👌
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useEditTrendTopicStatus = () => {
  const [mutate, { data, error }] = useMutation(EDIT_STATUS_TRENDTOPIC , {
    refetchQueries: ['GetTrendTopics'],
    onCompleted: ({ changeUseStatus }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Chủ đề <Typography.Text strong>{changeUseStatus.title}</Typography.Text>{' '}
            đã được cập nhật 👌
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};
