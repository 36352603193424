import { useReactiveVar } from '@apollo/client';
import { Button, Col, Form, Input, Row } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import AvatarUpload from 'components/AvatarUpload';
import React, { useEffect } from 'react';

const GuestFormContent = ({ form, onSubmit }) => {
  const visibleDrawer = useReactiveVar(visibleDrawerVar);
  const guest = visibleDrawer.record;

  useEffect(() => {
    if (guest) {
      form.setFieldsValue({
        name: guest?.name,
        introduction: guest?.introduction,
        career: guest?.career,
        position: guest?.position,
        currentWorkingPlace: guest?.currentWorkingPlace,
        education: guest?.education,
        image: guest?.image
      });
    }
    return () => form.resetFields();
  }, [guest, form]);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Row justify="center" align="middle">
        <Col>
          <Form.Item name="image" valuePropName="file">
            <AvatarUpload
              uploadType="user"
              size={128}
              fontSize={64}
              fullName={guest?.name}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="name"
        label="Tên khách mời"
        rules={[{ required: true, message: 'Nhập tên!' }]}
      >
        <Input placeholder="Nhập họ tên" />
      </Form.Item>
      <Form.Item name="introduction" label="Giới thiệu">
        <Input.TextArea placeholder="Nhập giới thiệu" />
      </Form.Item>
      <Form.Item name="career" label="Nghề nghiệp">
        <Input placeholder="Nhập nghề nghiệp" />
      </Form.Item>
      <Form.Item name="position" label="Vị trí công tác">
        <Input placeholder="Nhập vị trí công tác" />
      </Form.Item>
      <Form.Item name="currentWorkingPlace" label="Cơ quan công tác">
        <Input placeholder="Cơ quan công tác" />
      </Form.Item>

      <Form.Item name="education" label="Quá trình học tập">
        <Input.TextArea placeholder="Nhập quá trình học tập" />
      </Form.Item>

      <Form.Item style={{ display: 'none' }}>
        <Button htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  );
};

export default GuestFormContent;
