import { Select, Tag } from 'antd';
import { DEBOUNCE_VALUE } from 'appConstants';
import React, { useEffect, useState } from 'react';
import { debounce } from 'utils/lodash';
import { useAddGuest } from 'operations/mutations/guest/mutateGuest';
const GuestAutoCompleteCustom = ({
  defaultOption,
  labelKey,
  valueKey,
  dataKey,
  handleSearch,
  disableValue,
  prefixDataKey,
  extraParams,
  getLabel,
  value,
  hasGuest,
  disabled,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const val = value?.filter(x => !x.includes('isnew_')) ?? [];
  const { mutate: createGuest } = useAddGuest();

  useEffect(() => {
    if (Array.isArray(defaultOption)) {
      defaultOption && setOptions(defaultOption);
    } else {
      defaultOption && setOptions([defaultOption]);
    }
  }, [defaultOption]);

  const onSearch = async searchText => {
    if (!searchText) {
      return;
    }
    const results = await handleSearch({
      variables: {
        where: { [labelKey]: { contains: searchText }, ...extraParams }
      }
    });

    if (!results) {
      return;
    }
    if (!results.data[dataKey]?.items?.length) {
      setOptions([
        { label: searchText, value: 'isnew_' + searchText, isNew: true }
      ]);
      return;
    }
    return setOptions(
      results.data[dataKey]?.items?.map(result => ({
        label: getLabel
          ? getLabel(result)
          : (prefixDataKey ? result[prefixDataKey] + ' - ' : '') +
            result[labelKey],
        value: result[valueKey]
      }))
    );
  };

  const onCreate = async (value, label) => {
    try {
      let res = await createGuest({
        variables: {
          addGuestInput: {
            name: label
          }
        }
      });
      if (res) {
        let _options = options.map(x => {
          if (x.value == value && x.isNew) {
            x.isNew = false;
            x.value = res?.data?.addGuest?.id;
          }
          return x;
        });
        setOptions(_options);
      }
    } catch (error) {}
  };

  const debouncedOnSearch = debounce(value => onSearch(value), DEBOUNCE_VALUE);

  const renderOptions = () => {
    return options.map(({ value, label, isNew }) => (
      <Select.Option
        value={value}
        key={value}
        disabled={disableValue === value}
      >
        {label}
        {isNew && (
          <>
            {'  '}
            <Tag
              onClick={() => onCreate(value, label)}
              title="Thêm mới"
              color="#1890ff"
            >
              +
            </Tag>
          </>
        )}
      </Select.Option>
    ));
  };

  return (
    <Select
      filterOption={false}
      notFoundContent={null}
      showSearch
      onSearch={debouncedOnSearch}
      value={val}
      disabled={disabled || !hasGuest}
      {...props}
    >
      {renderOptions()}
    </Select>
  );
};

export default GuestAutoCompleteCustom;
