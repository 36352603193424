import { Card, Empty, Timeline } from 'antd';
import NewsAuditLogList from 'components/NewsAuditLogList';
import React, { useMemo } from 'react';

const NewsAuditLog = ({ newsDetail }) => {
  const logs = useMemo(
    () => newsDetail?.newsProcesses || [],
    [newsDetail?.newsProcesses]
  );

  return (
    <Card title="Lịch sử ghi nhận">
      {logs.length === 0 ? <Empty /> : <NewsAuditLogList logs={logs} />}
    </Card>
  );
};

export default NewsAuditLog;
