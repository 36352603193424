import { InMemoryCache, makeVar } from '@apollo/client';
import { isLoggedIn } from 'utils/authority';
import moment from 'moment';
import { CHANNEL_BROADCAST_KEY } from 'appConstants';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        visibleDrawer: {
          read() {
            return visibleDrawerVar();
          }
        },
        selectedRows: {
          read() {
            return selectedRowsVar();
          }
        },
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          }
        },
        currentRole: {
          read() {
            return currentRoleVar();
          }
        },
        scheduleFilter: {
          read() {
            return scheduleFilterVar();
          }
        },
        currentTimeAudio: {
          read() {
            return currentTimeAudioVar();
          }
        },
        timeLine: {
          read() {
            return timeLineVar();
          }
        },
        currentPermissions: {
          read() {
            return currentPermissionsVar();
          }
        },
        isWaitingUser: {
          read() {
            return isWaitingUserVar();
          }
        }
      }
    }
  }
});

export const visibleDrawerVar = makeVar({ visible: false, record: null });
export const selectedRowsVar = makeVar([]);
export const isLoggedInVar = makeVar(isLoggedIn());
export const currentRoleVar = makeVar(null);
export const scheduleFilterVar = makeVar({
  channel: CHANNEL_BROADCAST_KEY.FM999MHZ,
  broadcastDate: moment()
});
export const currentTimeAudioVar = makeVar(null);
export const durationTimeAudioVar = makeVar(null);
export const draftCurrentTimeAudioVar = makeVar(null);
export const timeLineVar = makeVar([]);
export const draftTimeLineVar = makeVar([]);
export const currentPermissionsVar = makeVar([]);
export const isWaitingUserVar = makeVar();
