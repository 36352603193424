import { gql } from '@apollo/client';

export const GET_HOSTS = gql`
  query GetHosts(
    $skip: Int
    $take: Int
    $order: [HostSortInput!]
    $where: HostFilterInput
  ) {
    hosts(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        name
        status
        currentWorkingPlace
        introduction
        experience
        education
        image
        createdBy
        createdDate
        updatedBy
        updatedDate
        creator {
          id
          name
        }
        updater {
          id
          name
        }
      }
    }
  }
`;

export const GET_HOST_BY_ID = gql`
  query getHostById($id: String) {
    host(id: $id) {
      id
      name
      status
      introduction
      currentWorkingPlace
      experience
      education
    }
  }
`;

export const GET_ALL_HOSTS = gql`
  query AllHosts {
    allHosts {
      id
      name
      status
      statusName
      currentWorkingPlace
      introduction
      experience
      education
      image
      createdBy
      createdDate
      updatedBy
      updatedDate

      user {
        id
        name
      }

      creator {
        id
        name
      }

      updater {
        id
        name
      }
    }
  }
`;
