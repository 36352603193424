import { RadioDetailContext } from 'pages/RadioDetail/RadioDetail';
import { useContext, useMemo, useState, useRef, useEffect } from 'react';
import {
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Tooltip,
  Typography
} from 'antd';
import Upload from 'components/Upload';
import { AUDIO_UPLOAD_SIZE_LIMIT } from 'appConstants';
import { encodeUrlFile, nameForUrlFile } from 'utils/other';
import { useCurrentUser } from 'operations/queries/queryCurrentUser';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { AudioDownloadButton } from 'components/AudioUpload/AudioUpload';
import {
  useAddRadioMedias,
  useRemoveRadioMedias
} from 'operations/mutations/radio/mutateRadio';
import { apolloClient } from 'api';
import { GET_RADIO_BY_ID } from 'operations/queries/queryRadio';

const MediaUploadDraft = () => {
  const { radio, form } = useContext(RadioDetailContext);
  const { data } = useCurrentUser();
  const { mutate: addMutate, loading: addLoading } = useAddRadioMedias();
  const { mutate: removeMutate, loading: removeLoading } =
    useRemoveRadioMedias();

  const audioRefs = useRef([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const radioMedias = useMemo(
    () => radio?.radioMedias || [],
    [radio?.radioMedias]
  );
  const onChange = async args => {
    if (args.file.status === 'done') {
      const uploadedFileName = args.file.response.Data;
      console.log(args.file.response);

      const { data } = await addMutate({
        variables: {
          input: {
            mediaUrl: uploadedFileName,
            radioId: radio?.id
          }
        }
      });

      setUploadedFiles([data.addRadioMedia, ...uploadedFiles])
      //setUploadedFiles(data)
    }
  };

  const beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < AUDIO_UPLOAD_SIZE_LIMIT;
    if (!isLt2M) {
      notification.error({
        message: 'Up file dưới ' + AUDIO_UPLOAD_SIZE_LIMIT + 'mb thôi nha 😡'
      });
    }
    return isLt2M;
  };
  const remove = async _id => {
    const { data } = await removeMutate({
      variables: {
        removeRadioMediaId: _id
      }
    });

    setUploadedFiles([...uploadedFiles.filter(
      _radioMedia => _radioMedia.id !== _id
    )])

  };

  useEffect(() => {
    setUploadedFiles(radioMedias);
  }, [radioMedias]);
  console.log(uploadedFiles);
  return (
    <>
      <Row gutter={8} align="middle">
        <Col>
          <Upload
            maxCount={10}
            showUploadList={false}
            onChange={onChange}
            beforeUpload={beforeUpload}
            accept=".wav, .mp3"
            multiple={true}
          >
            <Button icon={<UploadOutlined />}>Tải lên</Button>
          </Upload>
        </Col>

        <Col style={{ flexGrow: 1 }}>
          {uploadedFiles.map((radioMedia, index) => {
            if (radioMedia && radioMedia.mediaUrl) {
              return (
                <Row gutter={8} align="middle" key={radioMedia.id}>
                  {encodeUrlFile(radioMedia.mediaUrl) && (
                    <Col>
                      <AudioDownloadButton
                        url={encodeUrlFile(radioMedia.mediaUrl)}
                        fileName={radioMedia.mediaUrl}
                      />
                    </Col>
                  )}
                  <Col>
                    <Popconfirm
                      title="Bạn muốn xóa?"
                      onConfirm={() => remove(radioMedia.id)}
                      okText="Đồng ý"
                      cancelText="Huỷ"
                    >
                      <Tooltip title="Xóa">
                        <Button size="small" icon={<DeleteOutlined />} />
                      </Tooltip>
                    </Popconfirm>
                  </Col>
                  <Col style={{ flexGrow: 1 }}>
                    <audio
                      src={encodeUrlFile(radioMedia.mediaUrl)}
                      ref={element => (audioRefs.current[index] = element)}
                      controls
                      style={{ width: '100%' }}
                    ></audio>
                    <Typography.Text ellipsis>
                      <a
                        style={{
                          whiteSpace: 'nowrap',
                          display: 'inline-block'
                        }}
                        href={encodeUrlFile(radioMedia.mediaUrl)}
                      >
                        {nameForUrlFile(radioMedia.mediaUrl)}
                      </a>
                    </Typography.Text>
                  </Col>
                </Row>
              );
            }
          })}
        </Col>
      </Row>
    </>
  );
};
export default MediaUploadDraft;
