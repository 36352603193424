import { Button, Col, Form, notification, Row, Space, TimePicker } from 'antd';
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect } from 'react';
import {
  useEditSchedule,
  useRemoveSchedule
} from 'operations/mutations/category/mutateCategory';

import _moment from 'moment';
import { extendMoment } from 'moment-range';

import DeleteConfirmPopup from 'components/DeleteConfirmPopup';
import DayOfWeekSelect from 'components/DateOfWeekSelect';
import { checkOverlaps } from './ScheduleAddFormInline';

const format = 'HH:mm';
const moment = extendMoment(_moment);

const ScheduleFormInline = ({ category, schedule }) => {
  const { mutate: handleEditSchedule } = useEditSchedule();
  const { mutate: handleRemoveSchedule } = useRemoveSchedule();

  const [form] = Form.useForm();

  const onSave = useCallback(async () => {
    try {
      const { dayOfWeek, timeFrom } = await form.validateFields();
      const timeTo = moment(timeFrom).add('minutes', category.duration);
      const isOverlap = checkOverlaps(
        { timeFrom, timeTo, dayOfWeek },
        category?.schedules.filter(({ id }) => id !== schedule.id)
      );

      if (isOverlap) {
        return notification.error({ message: 'Khoảng thời gian trùng lặp!' });
      }

      const paramsToSend = {
        id: schedule?.id,
        categoryId: category?.id,
        dayOfWeek: dayOfWeek * 1,
        timeFrom,
        timeTo
      };

      await handleEditSchedule({ variables: { input: paramsToSend } });
    } catch (e) {}
  }, [
    category?.duration,
    category?.id,
    category?.schedules,
    form,
    handleEditSchedule,
    schedule?.id
  ]);

  const onDelete = useCallback(async () => {
    try {
      await handleRemoveSchedule({ variables: { id: schedule?.id } });
    } catch (e) {}
  }, [handleRemoveSchedule, schedule?.id]);

  useEffect(() => {
    if (schedule) {
      form.setFieldsValue({
        dayOfWeek: schedule.dayOfWeek,
        timeFrom: moment(schedule.timeFrom),
        timeTo: moment(schedule.timeTo)
      });
    }
  }, [form, schedule]);

  const onValuesChange = timeFrom => {
    form.setFieldsValue({
      timeFrom,
      timeTo: moment(timeFrom).add('minutes', category?.duration)
    });
  };

  const renderBtns = useCallback(() => {
    return (
      <Space>
        <Button
          onClick={onSave}
          type="primary"
          icon={<SaveOutlined />}
        ></Button>

        <DeleteConfirmPopup onDelete={onDelete} title="Xóa lịch phát sóng?">
          <Button type="primary" danger icon={<DeleteOutlined />}></Button>
        </DeleteConfirmPopup>
      </Space>
    );
  }, [onDelete, onSave]);

  return (
    <Form form={form}>
      <Row gutter={16}>
        <Col span={7}>
          <Form.Item
            name="dayOfWeek"
            rules={[{ required: true, message: 'Chọn ngày!' }]}
          >
            <DayOfWeekSelect />
          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item
            name="timeFrom"
            rules={[{ required: true, message: 'Chọn thời gian!' }]}
          >
            <TimePicker
              onSelect={onValuesChange}
              placeholder="Chọn thời gian"
              style={{ width: '100%' }}
              format={format}
            />
          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item
            name="timeTo"
            rules={[{ required: true, message: 'Chọn thời gian!' }]}
          >
            <TimePicker
              placeholder="Chọn thời gian"
              disabled
              style={{ width: '100%' }}
              format={format}
            />
          </Form.Item>
        </Col>

        <Col span={3}>{renderBtns()}</Col>
      </Row>
    </Form>
  );
};

export default ScheduleFormInline;
