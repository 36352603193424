import React from 'react';
import UserInfoDisplay from './components/UserInfoDisplay';
import UserDropdown from './UserDropdown';

const CurrentUserMenu = () => {
  return (
    <div className="current-user-menu">
      {true && (
        <>
          <UserInfoDisplay />
          {/* <NotificationDropdown /> */}
          <UserDropdown />
        </>
      )}
    </div>
  );
};

export default CurrentUserMenu;
