import { gql } from '@apollo/client';
export const GET_ALL_TOPICS = gql`
  query GetAllTopics($skip: Int, $take: Int) {
    topics(skip: $skip, take: $take) {
      totalCount
      items {
        id
        title
      }
    }
  }
`;

export const GET_TOPICS = gql`
  query GetTopics(
    $skip: Int
    $take: Int
    $order: [TopicSortInput!]
    $where: TopicFilterInput
  ) {
    topics(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        title
        image
        introduction
        createdBy
        updatedBy
        createdDate
        updatedDate
        isDeleted
        creator {
          id
          name
        }
        updater {
          id
          name
        }
        categoryStatistic {
          status {
            code
            count
          }
          total
        }
      }
    }
  }
`;

export const GET_TOPIC_BY_ID = gql`
  query getTopicById($id: String) {
    topic(id: $id) {
      id
      title
      image
      introduction
      status
      ordinarily
      createdBy
      updatedBy
      createdDate
      updatedDate
      seoTitle
      seoDescription
      seoKeyword
      isDeleted
      creator {
        id
        name
      }
      updater {
        id
        name
      }
    }
  }
`;
