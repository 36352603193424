import { gql } from '@apollo/client';

export const GET_NOTI_DASHBOARD = gql`
  query NotificationsDashboard {
    notificationsDashboard {
      id
      radioId
      radioName
      processType
      note
      isRead
    }

    statisticsDashboard {
      countRadio
      countCategory
      countUser
    }
  }
`;

export const GET_DASHBOARD_RADIO = gql`
  query AllRadioStatisticDashboard($status: RadioStatus!) {
    allRadioStatisticDashboard(status: $status) {
      id
      title
      channel
      status
      statusName
      broadcastTime
      timeTo
    }
  }
`;
