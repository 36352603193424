import { Button, Space, Tag, Tooltip, Typography } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { visibleDrawerVar } from 'api/cache';
import RelatedUser from 'components/RelatedUser';
import TableRecordName from 'components/TableRecordName';
import TimeLine from 'components/TimeLine';
import UserAvatar from 'components/UserAvatar';
import UserStatusButton from 'components/UserStatusButton';
import UserTypeTag from 'components/UserTypeTag/UserTypeTag';
import 'moment/locale/vi';
import { useEditUser } from 'operations/mutations/user/mutateUser';
import { useContext } from 'react';
import { UserContext } from './UserProvider';
import ChangePassword from 'components/Icons/ChangePassword';
import { CHANNEL_BROADCAST_DICTIONARY_IMG } from 'appConstants';

const Status = ({ record }) => {
  const { mutate } = useEditUser();

  return <UserStatusButton record={record} onClick={mutate} />;
};

const Channel = channels => {
  if (!channels?.length) return <></>;
  const _channels = CHANNEL_BROADCAST_DICTIONARY_IMG.filter(({ value }) =>
    channels.map(_c => _c).includes(value)
  );

  if (!!_channels.length) {
    return (
      <Space direction="vertical">
        {_channels.map(channel => (
          <Tag color={channel.color}>{channel.label}</Tag>
        ))}
      </Space>
    );
  }

  return <></>;
};

const Name = ({ record }) => {
  const { name } = record;

  return (
    <Space direction="vertical">
      <TableRecordName
        name={name}
        setDrawerVisible={() =>
          visibleDrawerVar({ visible: true, record: record })
        }
      />
    </Space>
  );
};

const Btns = ({ record }) => {
  const { setAuditLogTarget, setChangePasswordTarget } =
    useContext(UserContext);

  return (
    <Space>
      <Tooltip title={'Đặt lại mật khẩu'}>
        <Button
          onClick={() => setChangePasswordTarget(record)}
          type="default"
          size="small"
          icon={<ChangePassword />}
        />
      </Tooltip>

      <Tooltip title={'Lịch sử đăng nhập'}>
        <Button
          onClick={() => setAuditLogTarget(record.id)}
          type="default"
          size="small"
          icon={<HistoryOutlined />}
        />
      </Tooltip>
    </Space>
  );
};

export const columns = [
  {
    title: 'User',
    dataIndex: 'image',
    key: 'image',
    render: (image, record) => (
      <Space size={10}>
        <UserAvatar fullName={record.name} avatar={record?.host?.image} />

        <Space direction="vertical" align="start">
          <Name record={record} />
          <Typography.Text style={{ color: 'gray' }}>
            {record?.email}
          </Typography.Text>
          <UserTypeTag type={record.userType} />
        </Space>
      </Space>
    ),
    fixed: 'left',
    width: '300px'
  },
  {
    title: 'Kênh phát sóng',
    dataIndex: 'channels',
    render: Channel,
    align: 'left',
    width: '200px'
  },
  {
    title: 'Host',
    dataIndex: 'host',
    render: host => <Typography.Text strong>{host?.name}</Typography.Text>,
    align: 'left',
    width: '150px'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'name',
    render: (_, record) => <Status record={record} />,
    align: 'left',
    width: '200px'
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '220px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'createdDate',
    width: '180px',
    render: (_, { creator, updater }) => (
      <RelatedUser creator={creator} updater={updater} />
    )
  },
  {
    title: 'Thao tác',
    width: '100px',
    render: (_, record) => <Btns record={record} />,
    align: 'center'
  }
];
