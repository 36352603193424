import React from 'react';
import { Tag } from 'antd';
import {
  USER_TYPE_DICTIONARY,
  USER_TYPE_VALUE,
  USER_TYPE_KEY
} from 'appConstants';

const generateColor = type => {
  switch (type) {
    case USER_TYPE_VALUE[USER_TYPE_KEY.STANDARD]:
      return '';

    case USER_TYPE_VALUE[USER_TYPE_KEY.MANAGER]:
      return 'cyan';

    case USER_TYPE_VALUE[USER_TYPE_KEY.ADMIN]:
      return 'magenta';

    default:
      break;
  }
};

const UserTypeTag = ({ type, ...props }) => {
  return (
    <Tag color={generateColor(type)} {...props}>
      {USER_TYPE_DICTIONARY[type]}
    </Tag>
  );
};

export default UserTypeTag;
