import { useReactiveVar } from '@apollo/client';
import { Form } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import UpdateCreateModal from 'containers/UpdateCreateModal';
import {
  useAddGuest,
  useEditGuest
} from 'operations/mutations/guest/mutateGuest';
import React from 'react';
import { setError } from 'utils/other';
import GuestFormContent from './GuestFormContent';

const GuestForm = () => {
  const { mutate: editGuest } = useEditGuest();
  const visibleDrawer = useReactiveVar(visibleDrawerVar);

  const { mutate: createGuest } = useAddGuest();

  const [form] = Form.useForm();

  const onSave = async () => {
    try {
      const params = await form.validateFields();
      const _image = params.image;

      await editGuest({
        variables: {
          input: {
            ...params,
            id: visibleDrawer.record.id,
            image: _image?.file?.response?.Data || _image
          }
        }
      });

      visibleDrawerVar({ visible: false, record: null });
    } catch (e) {
      setError(form, e);
    }
  };

  const onCreate = async () => {
    try {
      const params = await form.validateFields();
      const _image = params.image;

      await createGuest({
        variables: {
          addGuestInput: {
            ...params,
            image: _image?.file?.response?.Data || _image
          }
        }
      });
      form.resetFields();
      visibleDrawerVar(false);
    } catch (error) {}
  };

  return (
    <UpdateCreateModal
      title={visibleDrawer.record ? 'Chỉnh sửa khách mời' : 'Tạo khách mời'}
      onOk={visibleDrawer.record ? onSave : onCreate}
      onClose={() => {
        form.resetFields();
        visibleDrawerVar(false);
      }}
      visible={visibleDrawer.visible}
      isUpdate={!!visibleDrawer.record}
      destroyOnClose
    >
      <GuestFormContent
        form={form}
        onSubmit={visibleDrawer.record ? onSave : onCreate}
      />
    </UpdateCreateModal>
  );
};

export default GuestForm;
