import React, { useEffect } from 'react';

import { Button, Col, Form, Input, Row } from 'antd';

import {
  colProps,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX
} from 'appConstants';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { visibleDrawerVar } from 'api/cache';
import FormBase from 'containers/FormBase';
import { processFormItemName } from 'utils/filter';

const GuestFilter = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    form.setFieldsValue(queryString.parse(history.location.search));
  }, [form, history.location.search]);

  return (
    <FormBase form={form}>
      <Row gutter={8}>
        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'name',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.CONTAINS
            })}
            label="Họ tên"
          >
            <Input placeholder="Nhập họ tên" />
          </Form.Item>
        </Col>

        {/* <Col {...colProps}>
            <Form.Item
              name={FILTER_PARAMS_PREFIX + 'currentWorkingPlace'}
              label="Cơ quan công tác"
            >
              <Input placeholder="Nhập cơ quan công tác" />
            </Form.Item>
          </Col> */}
      </Row>

      <Row gutter={8} justify="end">
        <Col>
          <Button htmlType="submit">Lọc</Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => visibleDrawerVar({ visible: true })}
          >
            Thêm
          </Button>
        </Col>
      </Row>
    </FormBase>
  );
};

export default GuestFilter;
