import RemoveButton from 'components/RemoveButton';

import { EditOutlined, HistoryOutlined } from '@ant-design/icons';

import 'moment/locale/vi';
import { useRemoveNews } from 'operations/mutations/news/mutateNews';

import { Button, Space, Typography } from 'antd';
import { NEWS_TYPE_DICTIONARY_INT } from 'appConstants';
import ColName from 'components/ColName';
import NewsStatusButton from 'components/NewsStatusButton';
import { NewsContext } from 'pages/News/NewsProvider';
import { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import ProcessCol from './ProcessCol';
import { useCurrentUser } from 'operations/queries/queryCurrentUser';
import RelatedUser from 'components/RelatedUser';

const Btns = ({ record }) => {
  const { } = useContext(NewsContext);

  const { mutate } = useRemoveNews();
  const { data } = useCurrentUser();
  const isAdmin = useMemo(() => data.detail?.userTypeName === 'ADMIN', []);

  return (
    <Space>
      <RemoveButton record={record} actionRemove={mutate} disabled={!isAdmin} />
    </Space>
  );
};

export const columns = [
  {
    title: 'Trạng thái',
    dataIndex: 'name',
    render: (_, record) => (
      <Space direction="vertical">
        <Typography.Text strong>
          {NEWS_TYPE_DICTIONARY_INT[record?.newType]}
        </Typography.Text>
        <NewsStatusButton record={{ status: record?.status }} />
      </Space>
    ),
    align: 'center',
    width: '160px'
  },
  {
    title: 'Chủ đề',
    key: 'name',
    dataIndex: 'name',
    render: (name, record) => (
      <ColName
        primary={
          <a
            style={{ color: 'inherit' }}
            href={record?.slug}
            target='_blank'
          >
            {record?.title}
          </a>
        }
      />
    ),
    width: '350px'
    // fixed: 'left'
  },

  // {
  //   title: 'Thời gian',
  //   dataIndex: 'timeLine',
  //   width: '280px',
  //   render: (_, { createdDate, updatedDate }) => (
  //     <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
  //   )
  // },
  {
    title: 'Lịch sử ghi nhận',
    dataIndex: 'newsProcesses',
    width: '350px',
    colSpan: 2,
    render: (newsProcesses, { createByUserName, createDay }) => {
      return (
        <ProcessCol
          title="Người tạo"
          userName={createByUserName}
          date={createDay}
        />
      );
    }
  },
  {
    title: 'Liên quan',
    dataIndex: 'newsProcesses',
    width: '350px',
    colSpan: 0,
    render: (_, { publishByUserName, publishDay }) => {
      return (
        <ProcessCol
          title="Publish"
          userName={publishByUserName}
          date={publishDay}
        />
      );
    }
  },
  {
    title: 'Liên quan',
    dataIndex: 'related',
    width: '350px',
    render: (_, { saveBankByUserName, saveBankDay }) => (
      <ProcessCol
        title="Người gửi"

        userName={saveBankByUserName}
        date={saveBankDay}

      />
    )
  }
];
