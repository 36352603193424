import { useQuery } from '@apollo/client';
import { Button, Card, Col, Divider, Form, Input, Radio, Row } from 'antd';
import {
  AUDIO_UPLOAD_SIZE_LIMIT,
  IMG_UPLOAD_SIZE_LIMIT,
  NEWS_STATUS_KEY,
  NEWS_STATUS_KEY_INT,
  NEWS_TYPE_DICTIONARY,
  SCRIPT_UPLOAD_SIZE_LIMIT
} from 'appConstants';
import DocumentUpload from 'components/DocumentUpload';
import Tiptap from 'components/Tiptap';
import { useAddNews, useEditNews } from 'operations/mutations/news/mutateNews';
import { GET_NEWS_BY_ID } from 'operations/queries/queryNews';
import NewStatusSelect from 'pages/News/components/NewStatusSelect';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AudioListUpload from './components/AudioListUpload';
import ImageListUpload from './components/ImageListUpload';
import VideoListUpload from './components/VideoListUpload';
import { BulbOutlined } from '@ant-design/icons';
import NewsAuditLog from './NewsAuditLog';

const extract = ({ audioFiles: _audioFiles, docFile: _docFile }) => {
  const docFile = _docFile?.file?.response?.Data || _docFile;

  const audioFiles =
    _audioFiles?.fileList?.map(file => file?.response?.Data) || _audioFiles;

  return { docFile, audioFiles };
};

const NewInfo = ({ isCreating }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();
  const { data } = useQuery(GET_NEWS_BY_ID, {
    variables: { id },
    skip: isCreating
  });

  const newsDetail = useMemo(() => data?.newsDetail, [data?.newsDetail]);
  const { mutate: mutateAdd, loading: addLoading } = useAddNews();
  const { mutate: mutateEdit, loading: editLoading } = useEditNews();

  useEffect(() => {
    if (!isCreating) {
      form.setFieldsValue({
        name: newsDetail?.name,
        audioFiles: newsDetail?.audioFiles,
        videoFiles: newsDetail?.videoFiles,
        imageFiles: newsDetail?.imageFiles,
        docFile: newsDetail?.docFile || '',
        status: newsDetail?.statusName,
        type: newsDetail?.typeName,
        content: newsDetail?.content
      });
    }
  }, [
    form,
    isCreating,
    newsDetail?.audioFiles,
    newsDetail?.content,
    newsDetail?.docFile,
    newsDetail?.imageFiles,
    newsDetail?.name,
    newsDetail?.statusName,
    newsDetail?.typeName,
    newsDetail?.videoFiles
  ]);

  const onSave = useCallback(
    async ({ status, forDownloadCallback }) => {
      try {
        if (forDownloadCallback && isCreating) {
          return;
        }
        let params = await form.validateFields();
        const { docFile, audioFiles } = extract(params);
        params = { ...params, docFile, audioFiles };
        if (isCreating) {
          return await mutateAdd({
            variables: { input: { ...params, ...(status ? { status } : {}) } },
            onCompleted: ({ addNews }) => {
              history.push('/chi-tiet-tin-bai/' + addNews?.id);
            }
          });
        }

        return await mutateEdit({
          variables: {
            input: { ...params, id, ...(status ? { status } : {}) }
          }
        });
      } catch (e) {}
    },
    [form, history, id, isCreating, mutateAdd, mutateEdit]
  );

  const onDownloadCallback = useCallback(async () => {
    try {
      if (
        newsDetail?.statusName !== NEWS_STATUS_KEY.APPROVED &&
        newsDetail?.statusName !== NEWS_STATUS_KEY.USED
      )
        return;
      let params = await form.validateFields();
      const { docFile, audioFiles } = extract(params);
      params = { ...params, docFile, audioFiles };

      return await mutateEdit({
        variables: {
          input: { ...params, id, status: NEWS_STATUS_KEY.USED }
        }
      });
    } catch (e) {}
  }, [form, id, mutateEdit, newsDetail?.statusName]);

  const renderSaveBtns = useCallback(() => {
    if (isCreating) {
      return (
        <>
          <Button onClick={onSave} type="primary">
            Tạo nháp
          </Button>

          <Divider type="vertical" />

          <Button
            onClick={() => onSave({ status: NEWS_STATUS_KEY.WAITING })}
            type="primary"
          >
            Gửi duyệt
          </Button>
        </>
      );
    }

    if (newsDetail?.statusName === NEWS_STATUS_KEY.DRAFT) {
      return (
        <>
          <Button onClick={onSave} type="primary">
            Lưu nháp
          </Button>

          <Divider type="vertical" />

          <Button
            onClick={() => onSave({ status: NEWS_STATUS_KEY.WAITING })}
            type="primary"
          >
            Gửi duyệt
          </Button>
        </>
      );
    }

    if (newsDetail?.statusName === NEWS_STATUS_KEY.WAITING) {
      return (
        <>
          <Button onClick={onSave} type="primary">
            Lưu nháp
          </Button>

          <Divider type="vertical" />

          <Button
            onClick={() => onSave({ status: NEWS_STATUS_KEY.APPROVED })}
            type="primary"
          >
            Duyệt
          </Button>
        </>
      );
    }

    return <></>;
  }, [isCreating, newsDetail?.statusName, onSave]);

  const disableDownload = useMemo(() => {
    return (
      newsDetail?.statusName === NEWS_STATUS_KEY.APPROVED ||
      newsDetail?.statusName === NEWS_STATUS_KEY.USED
    );
  }, [newsDetail?.statusName]);

  return (
    <>
      <Card title="Thông tin" style={{ marginBottom: 8 }}>
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="type"
                    label="Loại"
                    rules={[{ required: true, message: 'Chọn loại!' }]}
                  >
                    <Radio.Group>
                      {Object.entries(NEWS_TYPE_DICTIONARY).map(
                        ([key, value]) => (
                          <Radio value={key} key={key}>
                            {value}
                          </Radio>
                        )
                      )}
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="name"
                    label="Tiêu đề"
                    rules={[{ required: true, message: 'Nhập Tiêu đề!' }]}
                  >
                    <Input placeholder="Nhập Tiêu đề" />
                  </Form.Item>
                </Col>
                <Col span={24} lg={{ span: 24 }}>
                  <Form.Item
                    valuePropName="file"
                    name="docFile"
                    label={`File doc (.doc, .docx. Dung lượng tối đa ${SCRIPT_UPLOAD_SIZE_LIMIT} MB )`}
                    // rules={[{ required: true, message: 'Chọn file doc!' }]}
                  >
                    <DocumentUpload
                      message="Đã cập nhật file doc"
                      size={64}
                      disabled={disableDownload}
                      downloadBtn
                      onDownloadCallback={onDownloadCallback}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} lg={{ span: 24 }}>
                  <Form.Item
                    name="audioFiles"
                    valuePropName="files"
                    label={`File âm thanh (Định dạng .mp3, .wav. Dung lượng tối đa ${AUDIO_UPLOAD_SIZE_LIMIT} MB)`}
                    // rules={[{ required: true, message: 'Chọn file âm thanh!' }]}
                  >
                    <AudioListUpload
                      disabled={disableDownload}
                      form={form}
                      downloadBtn
                      onDownloadCallback={onDownloadCallback}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                {!isCreating && (
                  <Col span={24}>
                    <Form.Item name="status" label="Trạng thái">
                      <NewStatusSelect
                        disabled
                        // disabled={newsDetail?.status === NEWS_STATUS_KEY_INT.USED}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item
                    name="imageFiles"
                    valuePropName="files"
                    label={`File ảnh (Định dạng .png, .jpeg, .jpg Dung lượng tối đa ${IMG_UPLOAD_SIZE_LIMIT} MB)`}
                    // rules={[{ required: true, message: 'Chọn file ảnh!' }]}
                  >
                    <ImageListUpload
                      form={form}
                      disabled={disableDownload}
                      downloadBtn
                      onDownloadCallback={onDownloadCallback}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="videoFiles"
                    valuePropName="files"
                    label={`File video (Định dạng .avi, .mp4, Dung lượng tối đa ${AUDIO_UPLOAD_SIZE_LIMIT} MB)`}
                    // rules={[{ required: true, message: 'Chọn file video!' }]}
                  >
                    <VideoListUpload
                      disabled={disableDownload}
                      form={form}
                      downloadBtn
                      onDownloadCallback={onDownloadCallback}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Form.Item label="Nội dung" name="content">
            <Tiptap />
          </Form.Item>
          <Row justify="space-between">
            <Col>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.google.com/document/d/11r3rDeEsHllpSIT4TChQlepB9qQ58X3keX7pYgx02cU/edit"
              >
                <BulbOutlined /> Hướng dẫn sử dụng
              </a>
            </Col>
            <Col>{renderSaveBtns()}</Col>
          </Row>
        </Form>
      </Card>

      {/* <NewsAuditLog newsDetail={newsDetail} /> */}
    </>
  );
};

export default NewInfo;
