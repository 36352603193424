import { Col, DatePicker, Form, Input, InputNumber, Select } from 'antd';
import { DATE_FORMAT, RADIO_TYPE_DICTIONARY, TIME_FORMAT } from 'appConstants';
import moment from 'moment';
import React, { useMemo } from 'react';

const OtherFields = ({ radio }) => {
  const listTimeSchedule = useMemo(() => {
    if (radio) {
      const { schedules } = radio.category;
      const list = schedules.map(schedule => {
        return moment(schedule.timeFrom).format('HH:mm');
      });
      return [...new Set(list)];
    }
    return [];
  }, [radio]);

  const dayOfWeekAvailable = useMemo(() => {
    if (radio?.category) {
      const { schedules } = radio?.category;
      const listDayOfWeek = [
        ...new Set(schedules.map(schedule => schedule.dayOfWeek))
      ];
      return listDayOfWeek;
    }
    return [];
  }, [radio?.category]);

  const disabledDate = current => {
    return (
      (current && current < moment().endOf('day')) ||
      !dayOfWeekAvailable.includes(moment(current).day())
    );
  };

  return (
    <>
      <Col span={8}>
        <Form.Item
          name="duration"
          label="Thời lượng (phút)"
          rules={[{ required: true, message: 'Nhập thời lượng!' }]}
        >
          <InputNumber disabled={true} min={1} style={{ width: '100%' }} />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item label="Loại radio">
          <Input disabled value={RADIO_TYPE_DICTIONARY[radio.isLive]} />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          name="broadcastTime"
          label="Ngày phát sóng"
          rules={[{ required: true, message: 'Chọn ngày phát sóng!' }]}
        >
          <DatePicker
            disabled={true}
            format={DATE_FORMAT}
            style={{ width: '100%' }}
            disabledDate={disabledDate}
          />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          name="broadcastTimeFrom"
          label="Giờ phát sóng"
          rules={[{ required: true, message: 'Chọn giờ phát sóng!' }]}
        >
          <Select
            disabled={true}
            style={{ width: '100%' }}
            options={
              !!listTimeSchedule.length
                ? listTimeSchedule.map(item => ({ value: item }))
                : []
            }
          />
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item name="timeTo" label="Thời gian kết thúc">
          <DatePicker
            disabled
            format={DATE_FORMAT + ' ' + TIME_FORMAT}
            showTime={{ format: 'HH:mm' }}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default OtherFields;
