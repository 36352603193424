import { useLazyQuery } from '@apollo/client';
import AutoComplete from 'components/AutoComplete';
import { GET_USERS } from 'operations/queries/queryUser';
import React, { useMemo } from 'react';

const UserAutoComplete = ({ user, ...props }) => {
  const [searchUser] = useLazyQuery(GET_USERS);
  const defaultOption = useMemo(
    () =>
      user
        ? Array.isArray(user)
          ? user.map(({ id, name }) => ({ value: id, label: name }))
          : { value: user.id, label: user.name }
        : null,
    [user]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      handleSearch={searchUser}
      labelKey="name"
      valueKey="id"
      dataKey="users"
      placeholder="Tìm người tạo"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default UserAutoComplete;
