import { Col, Row, Typography } from 'antd';
import AvatarUpload from 'components/AvatarUpload';
import { useUpdateProfile } from 'operations/mutations/user/mutateUser';
import { useCurrentUser } from 'operations/queries/queryCurrentUser';
import React from 'react';

const CurrentUserAvatar = () => {
  const { mutate: updateProfile } = useUpdateProfile();
  const { data, loading } = useCurrentUser();
  if (!data) return null;
  const { name, host } = data?.detail;
  const image = host?.image;

  const onSave = async image => {
    try {
      await updateProfile({ variables: { input: { name, image } } });
    } catch (e) {}
  };

  return (
    <Row gutter={16} align="middle" style={{ marginBottom: 16 }}>
      <Col>
        <AvatarUpload
          uploadType="user"
          size={128}
          fontSize={64}
          loading={loading}
          fullName={name}
          avatar={image}
          onDone={onSave}
        />
      </Col>

      <Col>
        <Typography.Title>{name}</Typography.Title>
      </Col>
    </Row>
  );
};

export default CurrentUserAvatar;
