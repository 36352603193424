import { useQuery } from '@apollo/client';
import { Button, Space } from 'antd';

import { ORDER_BY_DICTIONARY, PAGE_SIZE, TABLE_PROPS } from 'appConstants';
import ListCard from 'components/ListCard';
import SortSelect from 'components/SortSelect';
import Table from 'components/Table';

import { GET_CATEGORIES } from 'operations/queries/queryCategory';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { processQueryFilerParams } from 'utils/filter';
import { isObjEmpty } from 'utils/lodash';
import { columns } from '../columns';
import ExportCategory from './ExportCategory';
const CategoryList = () => {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );
  const filterQuery = useMemo(() => {
    let additonalQueries = {};
    const processedSearchParams = processQueryFilerParams(searchParams);

    if (!processedSearchParams) return null;

    if (processedSearchParams?.onAirRange) {
      const { lte: to, gte: from } = processedSearchParams.onAirRange;

      additonalQueries.or = [
        {
          onAirFrom: {
            lte: from
          },
          onAirTo: {
            gte: from
          }
        },
        {
          onAirFrom: {
            gte: from
          },
          onAirTo: {
            lte: to
          }
        },
        {
          onAirTo: {
            gte: to
          },
          onAirFrom: {
            lte: to
          }
        }
      ];
      delete processedSearchParams.onAirRange;
    }

    const finalQuery = { ...processedSearchParams, ...additonalQueries };
    return isObjEmpty(finalQuery) ? null : finalQuery;
  }, [searchParams]);

  const {
    loading: isCategoriesLoading,
    error: categoriesError,
    data: categoriesConnect
  } = useQuery(GET_CATEGORIES, {
    variables: {
      take: PAGE_SIZE,
      skip: PAGE_SIZE * page - PAGE_SIZE,
      order: [ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    }
  });

  const categories = useMemo(
    () =>
      categoriesConnect?.categories?.items?.map(item => ({
        ...item,
        key: item.id
      })),
    [categoriesConnect?.categories?.items]
  );
  const total = useMemo(
    () => categoriesConnect?.categories?.totalCount,
    [categoriesConnect?.categories?.totalCount]
  );

  const onPaginate = page => {
    history.push({
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  const onRedirectSchedule = () => {
    const channel = filterQuery?.channelId?.eq;
    history.push({
      pathname: '/lich-phat-song',
      search: channel && '?channel=' + channel
    });
  };

  if (categoriesError) return <div>Oops, đã có vấn đề xảy ra 😅😅😅</div>;
  // if (!categoriesConnect) return <div>None</div>;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          <ExportCategory filterQuery={filterQuery} />

          <Button onClick={onRedirectSchedule}>Bảng</Button>

          <SortSelect value={sort} onChange={setOrderBy} />
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        scroll={{ x: 1600 }}
        columns={columns}
        dataSource={categories}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page * 1,
          quantity: categories?.length
        }}
        loading={isCategoriesLoading}
      />
    </ListCard>
  );
};

export default CategoryList;
