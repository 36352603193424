import { Button, Modal } from 'antd';
import { useMergeDraftRadio } from 'operations/mutations/radio/mutateRadio';
import { RadioDetailContext } from 'pages/RadioDetail/RadioDetail';
import React, { useContext, useState } from 'react';
import MergeModalContent from './MergeModalContent';

const MergeModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { radio } = useContext(RadioDetailContext);
  const { mutate, loading } = useMergeDraftRadio();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    try {
      await mutate({
        variables: {
          input: {
            radioId: radio.id
          }
        }
      });
    } catch (e) {
      throw e;
    }
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="primary" danger onClick={showModal} block>
        Duyệt thay đổi
      </Button>

      <Modal
        width={'95%'}
        confirmLoading={loading}
        className="merge-modal"
        style={{ top: 20 }}
        title="Duyệt thay đổi"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Duyệt"
        cancelText="Hủy"
      >
        <MergeModalContent />
      </Modal>
    </>
  );
};

export default MergeModal;
