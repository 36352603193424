import { Space, Typography } from 'antd';
import 'moment/locale/vi';
import React from 'react';

const RelatedUser = ({ creator, updater, approver }) => {
  return (
    <Space direction="vertical">
      <Typography.Text>
        <Typography.Text strong>Khởi tạo: </Typography.Text>
        {creator?.name}
      </Typography.Text>

      <Typography.Text>
        <Typography.Text strong>Cập nhật: </Typography.Text>
        {updater?.name}
      </Typography.Text>

      {approver && (
        <Typography.Text>
          <Typography.Text strong>Duyệt: </Typography.Text>
          {approver?.name}
        </Typography.Text>
      )}
    </Space>
  );
};

export default RelatedUser;
