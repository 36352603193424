import { Select } from 'antd';
import {
  NEWS_STATUS_DICTIONARY,
  NEWS_STATUS_DICTIONARY_INT
} from 'appConstants';
import React from 'react';

const NewStatusSelect = ({ intValue, ...props }) => {
  return (
    <Select
      options={Object.entries(
        intValue ? NEWS_STATUS_DICTIONARY_INT : NEWS_STATUS_DICTIONARY
      ).map(([key, value]) => ({
        value: key,
        label: value
      }))}
      style={{ width: '100%' }}
      placeholder="Chọn trạng thái"
      allowClear
      {...props}
    />
  );
};

export default NewStatusSelect;
