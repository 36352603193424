import { Badge, Button, Drawer, List } from 'antd';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { DashboardContext } from '../Dashboard';
import { AlertOutlined } from '@ant-design/icons';

const RadioNotification = () => {
  const { notificationsDashboard } = useContext(DashboardContext);

  const [show, setShow] = useState(false);

  return (
    <>
      <Badge
        className="db-notification-button-wrapper"
        size="small"
        count={notificationsDashboard.length}
      >
        <Button
          className="db-notification-button "
          onClick={() => setShow(prev => !prev)}
          icon={<AlertOutlined />}
          type="primary"
        >
          Thông báo số radio
        </Button>
      </Badge>

      <Drawer
        onClose={() => setShow(false)}
        visible={show}
        title="Thông báo số radio"
        width="600px"
      >
        <List
          bordered
          dataSource={notificationsDashboard}
          renderItem={item => (
            <List.Item key={item.id}>
              <List.Item.Meta
                title={
                  <Link to={`/chi-tiet-radio/${item.radioId}`}>
                    {item.radioName}
                  </Link>
                }
                description={item.note}
              />
            </List.Item>
          )}
        />
      </Drawer>
    </>
  );
};

export default RadioNotification;
