import { Badge, Tabs, Typography } from 'antd';
import { GET_NEWSLEAT } from 'operations/queries/queryNews';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { OUTSIDE_VOH_API } from 'appConstants';
import NewsPodcast from './NewsPodcast';
import NewsWeb from './Web/NewsWeb';
import { useQuery } from '@apollo/client';

const News = () => {
  const [newsWeb, setNewsWeb] = useState(0); 

  const currentDate = new Date();
  const formattedDate = `${String(currentDate.getDate()).padStart(2, '0')}-${String(
    currentDate.getMonth() + 1
  ).padStart(2, '0')}-${currentDate.getFullYear()}`;

  const { data, refetch } = useQuery(GET_NEWSLEAT);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const fetchWebNewsData = async () => {
      const body = {
        searchByDate: 1,
        startTime: formattedDate,
        endTime: formattedDate,
        page: 0,
        orderBy: 2,
      };
      try {
        const response = await axios.post(`${OUTSIDE_VOH_API}/api/Others/GetNewsSaveBanks`, body);
        setNewsWeb(response.data?.totalCount ?? 0);
      } catch (error) {
        console.error("Error fetching Web news data", error);
      }
    };

    fetchWebNewsData();
  }, [formattedDate]);

  return (
    <>
      <Typography.Title>Ngân hàng tin bài</Typography.Title>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane
          tab={
            <div>
              <span>Podcast</span>
              <span style={{ color: '#d51542' }}> ({data?.countNewsLeat || 0} tin bài mới)</span>
            </div>
          }
          key="1"
        >
          <NewsPodcast />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <div>
              <span>Web</span>
              <span style={{ color: '#d51542' }}> ({newsWeb} tin bài mới)</span>
            </div>
          }
          key="2"
        >
          <NewsWeb />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default News;
