import { useQuery } from '@apollo/client';
import { Space } from 'antd';
import { ORDER_BY_DICTIONARY, PAGE_SIZE, TABLE_PROPS } from 'appConstants';
import ListCard from 'components/ListCard';

import SortSelect from 'components/SortSelect';

import Table from 'components/Table';

import { GET_HOSTS } from 'operations/queries/queryHost';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { processQueryFilerParams } from 'utils/filter';
import { columns } from '../columns';

import ExportHosts from './ExportHosts';

const HostList = () => {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );
  const filterQuery = useMemo(
    () => processQueryFilerParams(searchParams),
    [searchParams]
  );

  const {
    loading: isHostsLoading,
    error: hostsError,
    data: hostsConnect
  } = useQuery(GET_HOSTS, {
    variables: {
      take: PAGE_SIZE,
      skip: PAGE_SIZE * page - PAGE_SIZE,
      order: [ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    }
  });

  const onPaginate = page => {
    history.push({
      pathname: '/danh-sach-host',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/danh-sach-host',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  if (hostsError) return <div>Oops, đã có vấn đề xảy ra 😅😅😅</div>;
  // if (!hostsConnect) return <div>None</div>;
  const hosts = hostsConnect?.hosts?.items?.map(item => ({
    ...item,
    key: item.id
  }));
  const total = hostsConnect?.hosts?.totalCount;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          <ExportHosts filterQuery={filterQuery} />
          <SortSelect value={sort} onChange={setOrderBy} />
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        columns={columns}
        dataSource={hosts}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page * 1,
          quantity: hosts?.length
        }}
        loading={isHostsLoading}
      />
    </ListCard>
  );
};

export default HostList;
