import { useLazyQuery } from '@apollo/client';
import AutoComplete from 'components/AutoComplete';
import { GET_CATEGORIES } from 'operations/queries/queryCategory';
import React, { useMemo } from 'react';

const CategoryAutoComplete = ({ category, ...props }) => {
  const [searchCategory] = useLazyQuery(GET_CATEGORIES);
  const defaultOption = useMemo(
    () =>
      category
        ? Array.isArray(category)
          ? category.map(({ id, title }) => ({ value: id, label: title }))
          : { value: category.id, label: category.title }
        : null,
    [category]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      handleSearch={searchCategory}
      labelKey="title"
      valueKey="id"
      dataKey="categories"
      prefixDataKey="channel"
      placeholder="Tìm chương trình"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default CategoryAutoComplete;
