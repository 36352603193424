import { useLazyQuery } from '@apollo/client';
import { Form } from 'antd';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GET_USER_BY_ID } from 'operations/queries/queryUser';
import UserAutoComplete from 'pages/News/components/NewsFilter/UserAutoComplete';

const UserAutoCompleteFilter = () => {
  const [searchUser, { loading }] = useLazyQuery(GET_USER_BY_ID);
  const [user, setUser] = useState([]);
  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const createdByFilterName = 'createdBy';

  const value = useMemo(() => {
    return searchParams[createdByFilterName];
  }, [createdByFilterName, searchParams]);

  const getUser = useCallback(async () => {
    try {
      const {
        data: { user }
      } = await searchUser({
        variables: {
          id: value
        }
      });
      setUser(user);
    } catch (e) {
      throw e;
    }
  }, [searchUser, value]);

  useEffect(() => {
    if (searchParams[createdByFilterName]) getUser();
  }, [getUser, createdByFilterName, searchParams]);

  return (
    <>
      <Form.Item name={createdByFilterName} label="Tạo bởi">
        <UserAutoComplete
          allowClear
          user={user}
          loading={loading}
          labelKey2={'email'}
          prefixDataKey2={'email'}
        />
      </Form.Item>
    </>
  );
};

export default UserAutoCompleteFilter;
