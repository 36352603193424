import React from 'react';
import { Select } from 'antd';
import { AUTO_PUBLISH_CATE_DICTIONARY } from 'appConstants';

const AutoPublishCateSelect = ({ value, ...props }) => {
  return (
    <Select
      style={{ fontWeight: 'normal' }}
      placeholder="Chọn"
      value={value}
      allowClear
      {...props}
    >
      {Object.keys(AUTO_PUBLISH_CATE_DICTIONARY).map((key, index) => (
        <Select.Option key={key} value={key}>
          {AUTO_PUBLISH_CATE_DICTIONARY[key]}
        </Select.Option>
      ))}
    </Select>
  );
};

export default AutoPublishCateSelect;
