import { useQuery } from '@apollo/client';
import { Space } from 'antd';
import { ORDER_BY_DICTIONARY, PAGE_SIZE, TABLE_PROPS } from 'appConstants';
import ListCard from 'components/ListCard';
import SortSelect from 'components/SortSelect';
import Table from 'components/Table';
import moment from 'moment';
import { GET_RADIOS } from 'operations/queries/queryRadio';
import queryString from 'query-string';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { processQueryFilerParams } from 'utils/filter';
import { isObjEmpty } from 'utils/lodash';
import { columns } from '../columns';
import ExportRadios from './ExportRadios';

const RadioList = () => {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );

  const filterQuery = useMemo(() => {
    const processedSearchParams = processQueryFilerParams(searchParams);
    let additonalQueries = {
      radioHosts: {
        some: {
          or: []
        }
      }
    };

    if (!processedSearchParams) return null;
    if (processedSearchParams?.mcRadioHosts) {
      additonalQueries.radioHosts.some.or.push({
        type: { eq: 1 },
        hostId: { or: processedSearchParams?.mcRadioHosts }
      });
    }

    if (processedSearchParams?.editorRadioHosts) {
      additonalQueries.radioHosts.some.or.push({
        type: { eq: 2 },
        hostId: { or: processedSearchParams?.editorRadioHosts }
      });
    }
    if (processedSearchParams?.channelId) {
      additonalQueries.category = {
        channelId: processedSearchParams.channelId
      };

      delete processedSearchParams.channelId;
    }

    if (
      !processedSearchParams?.mcRadioHosts &&
      !processedSearchParams?.editorRadioHosts
    ) {
      delete additonalQueries.radioHosts;
    }

    delete processedSearchParams.editorRadioHosts;
    delete processedSearchParams.mcRadioHosts;

    /*
    if (processedSearchParams?.radioGuests) {
      additonalQueries.radioGuests.some.or.push({
        guestId: { or: processedSearchParams?.radioGuests }
      });
      delete additonalQueries.radioGuests.any;
      delete processedSearchParams.radioGuests;
    } else if (processedSearchParams?.typeRadioGuests) {
      delete additonalQueries.radioGuests.some;
      if (processedSearchParams?.typeRadioGuests.eq === 'EXIST') {
        additonalQueries.radioGuests.any = true;
      } else if (processedSearchParams?.typeRadioGuests.eq === 'EMPTY') {
        additonalQueries.radioGuests.any = false;
      } else {
        delete additonalQueries.radioGuests;
      }
      delete processedSearchParams.typeRadioGuests;
    } else {
      delete additonalQueries.radioGuests;
    }*/
    if (processedSearchParams?.typeRadioGuests) {
      if (processedSearchParams?.typeRadioGuests.eq === 'EXIST') {
        additonalQueries.isHaveGuest={}
        additonalQueries.isHaveGuest.eq = 2
      }
      if (processedSearchParams?.typeRadioGuests.eq === 'EMPTY') {
        additonalQueries.isHaveGuest={}
        additonalQueries.isHaveGuest.eq = 1
      }
    }
    delete processedSearchParams.typeRadioGuests;
    const finalQuery = { ...processedSearchParams, ...additonalQueries };
    return isObjEmpty(finalQuery) ? null : finalQuery;
  }, [searchParams]);

  const {
    data: radiosConnect,
    loading: radioLoading,
    error: radiosError
  } = useQuery(GET_RADIOS, {
    variables: {
      take: PAGE_SIZE,
      skip: PAGE_SIZE * page - PAGE_SIZE,
      order: [ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    }
  });

  const onPaginate = page => {
    history.push({
      pathname: '/danh-sach-radio',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/danh-sach-radio',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  if (radioLoading) return <div>Loading...</div>;
  if (radiosError) return <div>Oops, đã có vấn đề xảy ra 😅😅😅</div>;

  const radios = radiosConnect.radios.items;
  const total = radiosConnect.radios.totalCount;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          <ExportRadios filterQuery={filterQuery} />
          <SortSelect value={sort} onChange={setOrderBy} />
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        columns={columns}
        dataSource={radios.map(item => ({
          ...item,
          key: item.id
        }))}
        rowClassName={({ publicTime }) => {
          const isComingSoon = moment(publicTime).isAfter(moment());
          return isComingSoon && 'coming-soon';
        }}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page * 1,
          quantity: radios?.length
        }}
      />
    </ListCard>
  );
};

export default RadioList;
