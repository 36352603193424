import React from 'react';
import NewsAuditLogDrawer from './components/NewsAuditLogDrawer';
import NewsFilter from './components/NewsFilter';
import NewsForm from './components/NewsForm';
import NewsList from './components/NewsList';
import NewsProvider from './NewsProvider';

const NewsPodcast = () => {
  return (
    <NewsProvider>
          <div className="spacing-container">


        <NewsFilter />

        <NewsList />

        <NewsForm />

        <NewsAuditLogDrawer />
        </div>
  
    </NewsProvider>
  );
};

export default NewsPodcast;
