import React from 'react';
import CategoryHosts from './CategoryHosts';
import CategoryInfo from './CategoryInfo';
import CategorySchedules from './CategorySchedules';

const CategoryDetailLeft = ({ isCreating }) => {
  return (
    <>
      <CategoryInfo isCreating={isCreating} />

      {!isCreating && (
        <>
          <CategorySchedules />
          <CategoryHosts />
        </>
      )}
    </>
  );
};

export default CategoryDetailLeft;
