import { EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { Button, Space, Tag, Typography } from 'antd';
import { CHANNEL_BROADCAST_DICTIONARY_IMG, RADIO_TYPE_KEY } from 'appConstants';
import ColName from 'components/ColName';
import LiveTag from 'components/LiveTag';
import RadioStatusTag from 'components/RadioStatusTag';

import RelatedUser from 'components/RelatedUser';
import RemoveButton from 'components/RemoveButton';

import TimeLine from 'components/TimeLine';
import UserAvatar from 'components/UserAvatar';

import moment from 'moment';
import 'moment/locale/vi';
import { useRemoveRadio } from 'operations/mutations/radio/mutateRadio';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { RadioContext } from './RadioProvider';

const Btns = ({ record }) => {
  const { mutate } = useRemoveRadio();
  const { setAuditLogTarget } = useContext(RadioContext);

  return (
    <Space>
      <Button
        onClick={() => setAuditLogTarget(record.id)}
        type="default"
        size="small"
        icon={<HistoryOutlined />}
      />
      <RemoveButton record={record} actionRemove={mutate} titleKey="title" />
    </Space>
  );
};

export const Channel = ({ channelId, style }) => {
  const channel = CHANNEL_BROADCAST_DICTIONARY_IMG.find(
    ({ value }) => value === channelId
  );

  if (channel) {
    return (
      <Tag style={style} color={channel.color}>
        {channel.label}
      </Tag>
    );
  }
  return <></>;
};

export const RadioImage = (image, record) => {
  const isLive = record.isLive === RADIO_TYPE_KEY.ON_LINE;
  return (
    <div style={{ position: 'relative', width: 'fit-content' }}>
      <UserAvatar
        fullName={record.title}
        avatar={image}
        shape="square"
        style={
          isLive
            ? { border: '#ff4d4f 1px solid', width: '114px' }
            : { width: '114px' }
        }
      />

      {isLive && (
        <LiveTag
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      )}
    </div>
  );
};

export const columns = [
  {
    dataIndex: 'image',
    key: 'image',
    render: RadioImage,
    fixed: 'left',
    width: '150px',
    align: 'center'
  },
  {
    title: 'Chủ đề',
    dataIndex: 'title',
    key: 'image',
    render: (_, record) => (
      <Space direction="vertical">
        <ColName
          primary={
            <Link
              style={{ color: 'inherit' }}
              to={`/chi-tiet-radio/${record?.id}`}
            >
              {record?.title} {<EditOutlined />}
            </Link>
          }
          secondary={record?.category?.title}
        />
        <Channel channelId={record?.category?.channel} />
      </Space>
    ),
    fixed: 'left'
    // width: '250px'
  },
  // {
  //   title: 'Kênh phát sóng',
  //   key: 'channel',
  //   render: (_, record) => <Channel channelId={record?.category?.channel} />,
  //   align: 'left',
  //   width: '150px'
  // },
  {
    title: 'Trạng thái',
    key: 'status',
    render: (_, record) => <RadioStatusTag status={record?.statusName} />,
    align: 'left',
    width: '160px'
  },
  {
    title: 'Phát sóng',
    dataIndex: 'broadcastTime',
    render: broadcastTime => (
      <Space direction="vertical">
        <Typography.Text>
          {broadcastTime && moment(broadcastTime).format('DD/MM/YYYY HH:mm')}
        </Typography.Text>

        <Typography.Text>
          {broadcastTime && moment(broadcastTime).fromNow()}
        </Typography.Text>
      </Space>
    ),
    align: 'left',
    width: '220px'
  },

  {
    title: 'Host',
    dataIndex: 'hosts',
    render: hosts => (
      <Space direction="vertical">
        {hosts?.map((host, index) => (
          <Typography.Text key={index}>{host?.name}</Typography.Text>
        ))}
      </Space>
    ),
    align: 'left',
    width: '200px'
  },

  // {
  //   title: 'Khách mời',
  //   dataIndex: 'guests',
  //   render: guests => (
  //     <Space direction="vertical">
  //       {guests?.map((guest, index) => (
  //         <Typography.Text key={index}>{guest?.name}</Typography.Text>
  //       ))}
  //     </Space>
  //   ),
  //   align: 'left',
  //   width: '200px'
  // },
  {
    title: 'Liên quan',
    dataIndex: 'related',
    width: '180px',
    render: (_, { creator, updater, approver }) => (
      <RelatedUser creator={creator} updater={updater} approver={approver} />
    )
  },

  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '220px',
    render: (_, { createdDate, updatedDate, publicTime }) => (
      <TimeLine
        createdDate={createdDate}
        updatedDate={updatedDate}
        publicTime={publicTime}
      />
    )
  },

  {
    title: 'Thao tác',
    width: '100px',
    render: (_, record) => <Btns record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
