import { useQuery } from '@apollo/client';

import { GET_CATEGORY_BY_ID } from 'operations/queries/queryCategory';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import CategoryCard from 'components/CategoryCard/CategoryCard';
import ChildCategories from './ChildCategories';

const CategoryDetailRight = () => {
  const { id } = useParams();

  const { data } = useQuery(GET_CATEGORY_BY_ID, {
    variables: { id }
  });

  const category = useMemo(() => data?.category, [data?.category]);

  return (
    <>
      <CategoryCard category={category} />

      <ChildCategories categories={category?.childCategories || []} />
    </>
  );
};

export default CategoryDetailRight;
