import 'moment/locale/vi';
import { Button, Space, Typography } from 'antd';
import ColName from 'components/ColName';
import { UserOutlined} from '@ant-design/icons';

export const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    render: (stt, record) => (
      <Space direction="vertical" >
        <Typography.Text strong>
          {stt}
        </Typography.Text>
      </Space>
    ),
    align: 'center',
    width: '40px'
  },
  {
    title: 'Tiêu đề',
    key: 'title',
    dataIndex: 'title',
    render: (title, record)=> {
      let profiles= record?.profileList?.map(x=>x?.profileName)
      return(
        <ColName
          primary={
            <a
              style={{ color: 'inherit' }}
              href={record?.slug?.startsWith("http") ? record?.slug : "https://voh.com.vn" + record?.slug}
              target='_blank'
            >
              {record?.title}
            </a>
          }
          secondary={
            <Space direction="horizontal" >
              <Typography.Text strong>
                <UserOutlined title='Tác giả' />
              </Typography.Text>
              <Typography.Text >
                {profiles.join(', ')}
              </Typography.Text>
            </Space>
          }
        />
      )
    } ,
    // fixed: 'left'
  },

  // {
  //   title: 'View count',
  //   dataIndex: 'viewCount',
  //   width: '160px',
  //   align: 'right',
  //   render: (viewCount, _) => (
  //     <Typography.Text >
  //       {viewCount}
  //     </Typography.Text>

  //   )
  // },
];
