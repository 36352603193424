import { Alert, Form, Input } from 'antd';

import React from 'react';

const ChangeUserPasswordContent = ({ changePasswordTarget, form }) => {
  return (
    <>
      <Form layout="vertical" form={form}>
        <Form.Item name="password" label="Mật khẩu mới">
          <Input.Password placeholder="Nhập mật khẩu mới" />
        </Form.Item>
      </Form>

      <Alert
        message="Không nhập mật khẩu sẽ tự động đặt lại mật khẩu mặc định 💆‍♂️"
        type="info"
        showIcon
        closable
      />
    </>
  );
};

export default ChangeUserPasswordContent;
