import { useLazyQuery } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import { DATE_FORMAT, USER_STATUS_DICTIONARY } from 'appConstants';
import moment from 'moment';
import { GET_ALL_USERS } from 'operations/queries/queryUser';

import React, { useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

import { DownloadOutlined } from '@ant-design/icons';

const ExportUser = ({ filterQuery }) => {
  const exportRef = useRef();

  const [loading, setLoading] = useState(false);

  const [query, { data }] = useLazyQuery(GET_ALL_USERS, {
    variables: {
      where: filterQuery
    }
  });

  const users = useMemo(() => data?.allUsers, [data?.allUsers]);

  const headers = [
    { label: 'Tên', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Trạng thái', key: 'statusName' },
    { label: 'Host liên kết', key: 'host' },
    { label: 'Người khởi tạo', key: 'creator' },
    { label: 'Ngày khởi tạo', key: 'createdDate' },
    { label: 'Người cập nhật', key: 'updater' },
    { label: 'Ngày cập nhật', key: 'updateDate' }
  ];

  const radiosCSV = useMemo(() => {
    if (users) {
      const csvData = users?.map(user => ({
        name: user?.name,
        email: user?.email,
        statusName: USER_STATUS_DICTIONARY[user?.status],
        host: user?.host?.name,
        creator: user?.creator?.name,
        updater: user?.updater?.name,
        createdDate: moment(user?.createdDate).format(DATE_FORMAT),
        updateDate: moment(user?.updateDate).format(DATE_FORMAT)
      }));

      return csvData;
    }
    return [];
  }, [users]);

  const onExportRadio = async () => {
    setLoading(true);
    try {
      const data = await query();
      if (data) {
        exportRef.current.link.click();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Export user dựa trên bộ lọc">
        <Button
          onClick={onExportRadio}
          loading={loading}
          icon={<DownloadOutlined />}
        >
          Export
        </Button>
      </Tooltip>

      <CSVLink
        data={radiosCSV}
        headers={headers}
        filename="danh-sach-user-export.csv"
        ref={exportRef}
      />
    </>
  );
};

export default ExportUser;
