import { useEffect } from 'react';

import { Button, Col, Form, Input, Row } from 'antd';

import {
  colProps,
  FILTER_PARAMS_DATA_TYPE_SUFFIX,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX
} from 'appConstants';
import queryString from 'query-string';
import { Link, useHistory } from 'react-router-dom';

import FormBase from 'containers/FormBase';
import { processFormItemName } from 'utils/filter';
import NewStatusSelect from '../NewStatusSelect';
import NewsTypeSelect from '../NewsTypeSelect';
import UserAutoCompleteFilter from './UserAutoCompleteFilter';

const NewsFilter = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    form.setFieldsValue(queryString.parse(history.location.search));
  }, [form, history.location.search]);

  return (
    <FormBase form={form}>
      <Row gutter={8}>
        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'name',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.CONTAINS
            })}
            label="Tiêu đề"
          >
            <Input placeholder="Nhập Tiêu đề" />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'status',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
              dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.INT
            })}
            label="Trạng thái"
          >
            <NewStatusSelect intValue={true} />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'type',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
              dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.INT
            })}
            label="Loại"
          >
            <NewsTypeSelect intValue={true} />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <UserAutoCompleteFilter form={form} />
        </Col>
      </Row>

      <Row gutter={8} justify="end">
        <Col>
          <Button htmlType="submit">Lọc</Button>
        </Col>

        <Col>
          <Link to={'/chi-tiet-tin-bai'}>
            <Button type="primary">Thêm</Button>
          </Link>
        </Col>
      </Row>
    </FormBase>
  );
};

export default NewsFilter;
