import React from 'react';
import { Select } from 'antd';
import { RADIO_STATUS_TYPE_DICTIONARY_INT_SHORT } from 'appConstants';

const RadioStatusSelect = ({
  filter = () => true,
  value,
  disableSelected,
  integerValue,
  ...props
}) => {
  return (
    <Select
      style={{ width: 200, fontWeight: 'normal' }}
      placeholder="Chọn trạng thái"
      value={value}
      allowClear
      {...props}
    >
      {Object.keys(RADIO_STATUS_TYPE_DICTIONARY_INT_SHORT)
        .map(i => i * 1)
        .filter(filter)
        .map((key, index) => (
          <Select.Option
            key={key}
            value={integerValue ? index + 1 + '' : key}
            disabled={disableSelected ? value === key : false}
          >
            {RADIO_STATUS_TYPE_DICTIONARY_INT_SHORT[key]}
          </Select.Option>
        ))}
    </Select>
  );
};

export default RadioStatusSelect;
