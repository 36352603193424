import { Layout } from 'antd';
import CurrentUser from 'containers/CurrentUserMenu';
import GeneralNotify from '../../components/GeneralNotify'
import React from 'react';

const { Header } = Layout;

const NaviHeader = () => {
  // const toggleMenu = useLayout(state => state.toggleMenu);
  return (
    <Header className="navi-header">
      {/* <MenuUnfoldOutlined onClick={toggleMenu} className="icon-toggle" /> */}
      <GeneralNotify />

      <CurrentUser />
    </Header>
  );
};

export default NaviHeader;
