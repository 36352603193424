import LiveIcon from 'components/Icons/Live';
import React from 'react';

const LiveTag = ({ style = {}, withoutText }) => {
  return (
    <span
      style={{
        backgroundColor: '#ff4d4f',
        borderRadius: ' 2px',
        padding: '0 2px',
        fontSize: '12px',
        alignContent: 'center',
        display: 'flex',
        color: '#fafafa',
        userSelect: 'none',
        ...style
      }}
    >
      <LiveIcon
        style={{ fontSize: '20px', marginRight: withoutText ? '0px' : '4px' }}
      />
      {!!!withoutText && 'LIVE'}
    </span>
  );
};

export default LiveTag;
