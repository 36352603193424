import { Drawer } from 'antd';
import React, { useCallback, useContext } from 'react';
import { RadioContext } from '../RadioProvider';
import RadioAuditLogContent from './RadioAuditLogContent';

const RadioAuditLog = () => {
  const { setAuditLogTarget, auditLogTarget } = useContext(RadioContext);

  const closeDrawer = useCallback(() => {
    setAuditLogTarget();
  }, [setAuditLogTarget]);

  return (
    <Drawer
      visible={!!auditLogTarget}
      placement="right"
      width={600}
      onClose={closeDrawer}
      title="Lịch sử chỉnh sửa"
      getContainer={false}
      destroyOnClose
    >
      <RadioAuditLogContent auditLogTarget={auditLogTarget} />
    </Drawer>
  );
};

export default RadioAuditLog;
