import { useReactiveVar } from '@apollo/client';
import {
  Button,
  Form,
  Popconfirm,
  Row,
  Table,
  Tooltip,
  Space,
  Typography
} from 'antd';
import { draftCurrentTimeAudioVar, draftTimeLineVar } from 'api/cache';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import {
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { EditableCell } from '../TimeLine';

const DraftTimeLine = ({ disabled, required }) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const currentTimeAudio = useReactiveVar(draftCurrentTimeAudioVar);
  const timeLine = useReactiveVar(draftTimeLineVar);

  const isEditing = record => record.key === editingKey;

  const edit = record => {
    form.setFieldsValue({
      ...record
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const remove = _key => {
    draftTimeLineVar(timeLine.filter(({ key }) => key !== _key));
  };

  const save = async key => {
    try {
      const row = await form.validateFields();
      const newData = [...timeLine];
      const index = newData.findIndex(item => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        draftTimeLineVar(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        draftTimeLineVar(newData);
        setEditingKey('');
      }
    } catch (errInfo) {}
  };

  const columns = [
    {
      width: 150,
      title: 'Thời gian',
      dataIndex: 'time',
      editable: true,
      render: time => {
        return (
          <Typography.Text>{moment(time).format('HH:mm:ss')}</Typography.Text>
        );
      }
    },
    {
      title: 'Nội dung',
      dataIndex: 'content',
      editable: true
    },

    {
      width: 150,
      align: 'center',
      title: 'Thao tác',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Space>
              <Tooltip title="Lưu">
                <Button
                  size="small"
                  icon={<CheckOutlined />}
                  onClick={() => save(record.key)}
                />
              </Tooltip>

              <Popconfirm
                title="Bạn muốn huỷ bỏ?"
                onConfirm={cancel}
                okText="Đồng ý"
                cancelText="Huỷ"
              >
                <Tooltip title="Hủy">
                  <Button size="small" icon={<CloseOutlined />} />
                </Tooltip>
              </Popconfirm>
            </Space>
          </span>
        ) : (
          <Space>
            <Tooltip title="Chỉnh sửa">
              <Button
                size="small"
                icon={<EditOutlined />}
                disabled={editingKey !== ''}
                onClick={() => edit(record)}
              />
            </Tooltip>

            <Popconfirm
              title="Bạn muốn xóa?"
              onConfirm={() => remove(record?.key)}
              okText="Đồng ý"
              cancelText="Huỷ"
            >
              <Tooltip title="Xóa">
                <Button
                  size="small"
                  icon={<DeleteOutlined />}
                  disabled={editingKey !== ''}
                />
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      }
    }
  ];
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'time' ? 'time' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  const handleAddContent = () => {
    draftTimeLineVar([
      ...timeLine,
      {
        key: timeLine.length,
        content: `Nội dung ${timeLine.length}`,
        time: moment('00:00:00', 'HH:mm:ss')
      }
    ]);

    form.setFieldsValue({
      key: timeLine.length,
      content: `Nội dung ${timeLine.length}`,
      time: moment('00:00:00', 'HH:mm:ss')
    });
    setEditingKey(timeLine.length);
  };

  useEffect(() => {
    if (currentTimeAudio) {
      draftTimeLineVar([
        ...timeLine,
        {
          key: timeLine.length,
          content: `Nội dung ${timeLine.length}`,
          time: moment(currentTimeAudio, 'HH:mm:ss')
        }
      ]);

      form.setFieldsValue({
        key: timeLine.length,
        content: `Nội dung ${timeLine.length}`,
        time: moment(currentTimeAudio, 'HH:mm:ss')
      });
      setEditingKey(timeLine.length);
    }

    return () => {
      draftCurrentTimeAudioVar(null);
    };
  }, [currentTimeAudio, timeLine, form]);

  return (
    <>
      <Form form={form} component={false}>
        <Row align="middle" justify="space-between">
          <Typography.Text className={cn({ 'fake-required-label': required })}>
            Thời gian - chủ đề module
          </Typography.Text>
          <Button disabled={disabled} type="link" onClick={handleAddContent}>
            Tạo mới nội dung
          </Button>
        </Row>
        <Table
          components={{
            body: {
              cell: props => <EditableCell form={form} {...props} />
            }
          }}
          bordered
          dataSource={timeLine}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
        />
      </Form>

      <div style={{ marginBottom: 16 }} />
    </>
  );
};

export default DraftTimeLine;
