import { useQuery } from '@apollo/client';
import { Space } from 'antd';
import { ORDER_BY_DICTIONARY, PAGE_SIZE, TABLE_PROPS } from 'appConstants';
import ListCard from 'components/ListCard';

import SortSelect from 'components/SortSelect';
import { isObjEmpty } from 'utils/lodash';

import Table from 'components/Table';

import { GET_TRENDTOPICS } from 'operations/queries/queryTrendTopic';
import queryString from 'query-string';
import React, { useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { processQueryFilerParams } from 'utils/filter';
import { columns } from '../columns';

const TrendTopicList = () => {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => parseInt(searchParams.page || 1, 10), [searchParams.page]);
  const sort = useMemo(() => searchParams.sort || 'EARLIEST', [searchParams.sort]);
  const filterQuery = useMemo(() => {
    if (!searchParams.filter__createdDate__btw__date) delete searchParams.filter__createdDate__btw__date;
    if (searchParams.filter__note__eq__string === "0") {
      searchParams.filter__note__eq__string = "";
    }
    if (searchParams.filter__note__eq__string === "1") {
      searchParams.filter__note__neq__string = "";
      delete searchParams.filter__note__eq__string;
    }
    let query = processQueryFilerParams(searchParams);
    let additonalQueries = {
      trendTopicPlatforms: {
        some: {}
      }
    };
    if (query?.platFormId) {
      let a = query?.platFormId?.map(x => Number(x.eq));
      additonalQueries.trendTopicPlatforms.some = { usePlatformId: { in: a } };
    } else {
      delete additonalQueries.trendTopicPlatforms;
    }
    delete query?.platFormId;

    const finalQuery = { ...query, ...additonalQueries };
    return isObjEmpty(finalQuery) ? null : finalQuery;
  }, [searchParams]);

  const { loading: isTrendTopicsLoading, error: trendTopicsError, data: trendTopicsConnect, refetch } = useQuery(GET_TRENDTOPICS, {
    variables: {
      take: PAGE_SIZE,
      skip: PAGE_SIZE * page - PAGE_SIZE,
      order: [ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    },
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    refetch({
      take: PAGE_SIZE,
      skip: PAGE_SIZE * page - PAGE_SIZE,
      order: [ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    });
  }, [page, sort, filterQuery, refetch]);

  const onPaginate = page => {
    history.push({
      pathname: '/chu-de-trend',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/chu-de-trend',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  if (trendTopicsError) return <div>Oops, đã có vấn đề xảy ra 😅😅😅</div>;

  const trendTopics = trendTopicsConnect?.trendTopics?.items?.map(item => ({
    ...item,
    key: item.id
  }));
  const total = trendTopicsConnect?.trendTopics?.totalCount;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          <SortSelect value={sort} onChange={setOrderBy} />
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        columns={columns}
        dataSource={trendTopics}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page * 1,
          quantity: trendTopics?.length
        }}
        loading={isTrendTopicsLoading}
      />
    </ListCard>
  );
};

export default TrendTopicList;
