import { Drawer } from 'antd';
import NewsAuditLogList from 'components/NewsAuditLogList';
import React, { useCallback, useContext } from 'react';
import { NewsContext } from '../NewsProvider';

const NewsAuditLogDrawer = () => {
  const { setAuditLogTarget, auditLogTarget } = useContext(NewsContext);

  const closeDrawer = useCallback(() => {
    setAuditLogTarget();
  }, [setAuditLogTarget]);

  return (
    <Drawer
      visible={!!auditLogTarget}
      placement="right"
      width={600}
      onClose={closeDrawer}
      title="Lịch sử ghi nhận"
      getContainer={false}
      destroyOnClose
    >
      <NewsAuditLogList logs={auditLogTarget} />
    </Drawer>
  );
};

export default NewsAuditLogDrawer;
