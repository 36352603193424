import { Card, Typography } from 'antd';
import Microphone from 'components/Icons/Microphone';
import React, { useMemo } from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import { CHANNEL_BROADCAST_DICTIONARY } from 'appConstants';
import defaultImg from 'assets/images/default.jpg';
import { Link } from 'react-router-dom';
import { encodeUrlFile } from 'utils/other';

const renderHosts = (hosts, _type) => {
  return hosts
    .filter(({ cateHosts }) => {
      return (cateHosts || []).some(({ type }) => type === _type);
    })
    .map(({ name }, index, arr) => {
      if (index !== arr.length - 1) return name + ', ';
      return name;
    });
};

const CategoryCard = ({ category, titleAsLink }) => {
  const hosts = useMemo(() => {
    return category?.hosts.filter(
      (v, i, a) => a.findIndex(v2 => v2.id === v.id) === i
    );
  }, [category?.hosts]);

  return category ? (
    <Card
      cover={
        <img
          style={{
            maxHeight: 600,
            objectFit: 'cover',
            margin: '0 auto'
          }}
          alt="example"
          src={category?.image ? encodeUrlFile(category?.image) : defaultImg}
        />
      }
    >
      <Card.Meta
        title={
          titleAsLink ? (
            <Link to={`/chi-tiet-chuong-trinh/${category?.id}`}>
              <Typography.Text>{category?.title}</Typography.Text>
            </Link>
          ) : (
            <Typography.Text>{category?.title}</Typography.Text>
          )
        }
        description={`${category?.topic?.title} ${
          category.channel !== 'NONE'
            ? ' - ' + CHANNEL_BROADCAST_DICTIONARY[category.channel]
            : ''
        }`}
      />

      <div style={{ marginBottom: 12 }} />

      <Typography.Text>
        <Microphone style={{ fontSize: '1rem', marginRight: '0.25rem' }} />
        {renderHosts(hosts, 1)}
      </Typography.Text>

      <br />

      <Typography.Text>
        <FileTextOutlined
          style={{ fontSize: '1rem', marginRight: '0.25rem' }}
        />
        {renderHosts(hosts, 2)}
      </Typography.Text>
    </Card>
  ) : (
    <></>
  );
};

export default CategoryCard;
