import { Space, Typography } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import {
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX
} from 'appConstants';
import RelatedUser from 'components/RelatedUser';
import RemoveButton from 'components/RemoveButton';
import TableRecordName from 'components/TableRecordName';
import TimeLine from 'components/TimeLine';
import UserAvatar from 'components/UserAvatar';

import 'moment/locale/vi';
import { useRemoveTopic } from 'operations/mutations/topic/mutateTopic';
import { Link } from 'react-router-dom';
import { processFormItemName } from 'utils/filter';
import { BulbOutlined } from '@ant-design/icons';
const RemoveTopic = ({ record }) => {
  const { mutate } = useRemoveTopic();
  return (
    <RemoveButton record={record} actionRemove={mutate} titleKey="title" />
  );
};

const Name = ({ record }) => {
  const { title } = record;

  return (
    <Space direction="vertical">
      <TableRecordName
        name={title}
        setDrawerVisible={() =>
          visibleDrawerVar({ visible: true, record: record })
        }
      />
    </Space>
  );
};

export const columns = [
  {
    title: 'Nhóm chương trình',
    dataIndex: 'image',
    key: 'image',
    render: (image, record) => (
      <Space size={10}>
        <UserAvatar fullName={record.title} avatar={image} />
        <Space direction="vertical" align="start">
          <Name record={record} />
        </Space>
      </Space>
    ),
    fixed: 'left',
    width: '300px'
  },

  {
    title: 'Chương trình',
    key: 'category',
    render: (_, record) => (
      <Typography.Text>
        <Link
          to={
            '/danh-sach-chuong-trinh/?' +
            processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'topicId',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ
            }) +
            '=' +
            record?.id
          }
        >
          <BulbOutlined /> {record?.categoryStatistic?.total} chương trình
        </Link>
      </Typography.Text>
    ),
    width: '200px'
  },
  {
    title: 'Giới thiệu',
    dataIndex: 'introduction',
    align: 'left'
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '220px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'createdDate',
    width: '180px',
    render: (_, { creator, updater }) => (
      <RelatedUser creator={creator} updater={updater} />
    )
  },
  {
    title: 'Thao tác',
    width: '100px',
    render: (_, record) => <RemoveTopic record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
