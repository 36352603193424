import { Button, Col, Form, notification, Row, TimePicker } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useAddSchedule } from 'operations/mutations/category/mutateCategory';

import _moment from 'moment';
import { extendMoment } from 'moment-range';

import DayOfWeekSelect from 'components/DateOfWeekSelect';

const format = 'HH:mm';
const moment = extendMoment(_moment);

export const checkOverlaps = (currSchedule, list) => {
  const range = moment.range(currSchedule.timeFrom, currSchedule.timeTo);

  return list.some(schedule => {
    const _range = moment.range(schedule.timeFrom, schedule.timeTo);

    return (
      schedule.dayOfWeek === currSchedule.dayOfWeek && range.overlaps(_range)
    );
  });
};

const ScheduleAddFormInline = ({ category, schedules = [] }) => {
  const { mutate: handleAddSchedule } = useAddSchedule();

  const [form] = Form.useForm();
  const lastSchedule = useMemo(() => {
    if (schedules.length === 0) {
      return null;
    }
    return schedules.slice(-1).pop();
  }, [schedules]);

  const onAdd = useCallback(async () => {
    try {
      const { dayOfWeek, timeFrom } = await form.validateFields();
      const timeTo = moment(timeFrom).add('minutes', category.duration);

      const isOverlap = checkOverlaps(
        { timeFrom, timeTo, dayOfWeek },
        category?.schedules
      );

      if (isOverlap) {
        return notification.error({ message: 'Khoảng thời gian trùng lặp!' });
      }

      const paramsToSend = {
        categoryId: category.id,
        dayOfWeek: dayOfWeek * 1,
        timeFrom,
        timeTo
      };
      await handleAddSchedule({ variables: { input: paramsToSend } });
    } catch (e) {}
  }, [
    category?.duration,
    category?.id,
    category?.schedules,
    form,
    handleAddSchedule
  ]);

  useEffect(() => {
    form.resetFields();

    if (lastSchedule) {
      form.setFieldsValue({
        timeFrom: moment(lastSchedule.timeFrom),
        timeTo: moment(lastSchedule.timeTo)
      });
    }
  }, [
    form,
    lastSchedule,
    lastSchedule?.timeFrom,
    lastSchedule?.timeTo,
    schedules
  ]);

  const onValuesChange = timeFrom => {
    form.setFieldsValue({
      timeFrom,
      timeTo: moment(timeFrom).add('minutes', category?.duration)
    });
  };

  const renderBtns = useCallback(() => {
    return (
      <Button
        onClick={onAdd}
        type="primary"
        icon={<PlusCircleOutlined />}
      ></Button>
    );
  }, [onAdd]);

  return (
    <Form form={form}>
      <Row gutter={16}>
        <Col span={7}>
          <Form.Item
            name="dayOfWeek"
            rules={[{ required: true, message: 'Chọn ngày!' }]}
          >
            <DayOfWeekSelect />
          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item
            shouldUpdate
            name="timeFrom"
            rules={[{ required: true, message: 'Chọn thời gian!' }]}
          >
            <TimePicker
              onSelect={onValuesChange}
              placeholder="Chọn thời gian"
              style={{ width: '100%' }}
              format={format}
            />
          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item
            shouldUpdate
            name="timeTo"
            rules={[{ required: true, message: 'Chọn thời gian!' }]}
          >
            <TimePicker
              placeholder="Chọn thời gian"
              disabled
              style={{ width: '100%' }}
              format={format}
            />
          </Form.Item>
        </Col>

        <Col span={3}>{renderBtns()}</Col>
      </Row>
    </Form>
  );
};

export default ScheduleAddFormInline;
