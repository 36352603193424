import { Typography } from 'antd';

import React from 'react';
import TopicList from './components/TopicList';
import TopicFilter from './TopicFilter';
import TopicForm from './TopicForm';

const Topic = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Quản lý nhóm chương trình</Typography.Title>

      <TopicFilter />

      <TopicList />

      <TopicForm />
    </div>
  );
};

export default Topic;
