import { useLazyQuery } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import {
  CATEGORY_STATUS_DICTIONARY,
  CATEGORY_TYPE_DICTIONARY,
  CHANNEL_BROADCAST_DICTIONARY,
  DATE_FORMAT,
  DATE_OF_WEEK_DICTIONARY,
  TIME_FORMAT
} from 'appConstants';
import moment from 'moment';
import { GET_ALL_CATEGORIES } from 'operations/queries/queryCategory';

import React, { useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

import { DownloadOutlined } from '@ant-design/icons';
import { formatStringForExport } from 'utils/other';

const mapSchedules = schedules => {
  return schedules
    .map(
      ({ dayOfWeek, timeFrom, timeTo }) =>
        `${DATE_OF_WEEK_DICTIONARY[dayOfWeek]} từ ${moment(timeFrom).format(
          TIME_FORMAT
        )} đến ${moment(timeTo).format(TIME_FORMAT)}; `
    )
    .join('');
};

const renderHosts = (hosts, _type) => {
  return hosts
    .filter(({ cateHosts }) => {
      return (cateHosts || []).some(({ type }) => type === _type);
    })
    .map(({ name }) => {
      return name + '; ';
    })
    .join('');
};

const ExportCategory = ({ filterQuery }) => {
  const exportRef = useRef();

  const [loading, setLoading] = useState(false);

  const [query, { data }] = useLazyQuery(GET_ALL_CATEGORIES, {
    variables: {
      where: filterQuery
    }
  });

  const categories = useMemo(() => data?.allCategories, [data?.allCategories]);

  const headers = [
    { label: 'Tên chương trình', key: 'title' },
    { label: 'Nhóm', key: 'topic' },
    { label: 'Loại chương trình', key: 'typeName' },
    { label: 'Kênh', key: 'channel' },
    { label: 'Thời lượng', key: 'duration' },
    { label: 'Phát sóng từ', key: 'onAirFrom' },
    { label: 'Phát sóng đến', key: 'onAirTo' },
    { label: 'Giới thiệu', key: 'introduction' },
    { label: 'Trạng thái', key: 'statusName' },
    { label: 'Lịch phát sóng mỗi tuần', key: 'schedules' },
    { label: 'MC', key: 'mc' },
    { label: 'Biên tập viên', key: 'editor' },
    { label: 'Người khởi tạo', key: 'creator' },
    { label: 'Tổng số radio', key: 'radioStatistics' },
    { label: 'DS email nhận', key: 'emails' }
  ];

  const radiosCSV = useMemo(() => {
    if (categories) {
      const csvData = categories?.map(category => ({
        title: formatStringForExport(category?.title),
        topic: formatStringForExport(category?.topic?.title),
        channel: CHANNEL_BROADCAST_DICTIONARY[category?.channel],
        duration: category?.duration,
        typeName: CATEGORY_TYPE_DICTIONARY[category?.typeName],
        radioStatistics: category?.radioStatistics?.total || 0,
        onAirFrom: moment(category?.onAirFrom).format(DATE_FORMAT),
        onAirTo: moment(category?.onAirTo).format(DATE_FORMAT),
        introduction: formatStringForExport(category?.introduction),
        statusName: CATEGORY_STATUS_DICTIONARY[category?.statusName],
        creator: category?.creator?.name,
        mc: renderHosts(category?.hosts, 1),
        emails: category?.emails
          ?.map(email => {
            return email + '; ';
          })
          .join(''),
        editor: renderHosts(category?.hosts, 2),
        schedules: mapSchedules(category?.schedules || [])
      }));

      return csvData;
    }
    return [];
  }, [categories]);

  const onExportRadio = async () => {
    setLoading(true);
    try {
      const data = await query();
      if (data) {
        exportRef.current.link.click();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Export chương trình dựa trên bộ lọc">
        <Button
          onClick={onExportRadio}
          loading={loading}
          icon={<DownloadOutlined />}
        >
          Export
        </Button>
      </Tooltip>

      <CSVLink
        data={radiosCSV}
        headers={headers}
        filename="danh-sach-chuong-trinh-export.csv"
        ref={exportRef}
      />
    </>
  );
};

export default ExportCategory;
