import { Tag } from 'antd';
import {
  RADIO_STATUS_TYPE_COLOR,
  RADIO_STATUS_TYPE_DICTIONARY_SHORT
} from 'appConstants';
import React from 'react';

const generateColor = status => {
  return RADIO_STATUS_TYPE_COLOR[status];
};

const RadioStatusTag = ({ status, ...props }) => {
  return (
    <Tag color={generateColor(status)} {...props}>
      {RADIO_STATUS_TYPE_DICTIONARY_SHORT[status]}
    </Tag>
  );
};

export default RadioStatusTag;
