import { Dropdown, Menu, Row, Space, Tag } from 'antd';
import { RADIO_TYPE_DICTIONARY, RADIO_TYPE_KEY } from 'appConstants';
import React, { useContext, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useAddRadio } from 'operations/mutations/radio/mutateRadio';

import LiveTag from 'components/LiveTag';
import QuickViewInfo from './QuickViewInfo';
import { RadioQuickViewContext } from './RadioQuickView';
import { visibleDrawerVar } from 'api/cache';

const WithoutRadioContent = () => {
  const { record } = useContext(RadioQuickViewContext);
  const [isOnline, setIsOnline] = useState(RADIO_TYPE_KEY.OFF_LINE);
  const { mutate: addRadio } = useAddRadio();

  const menu = (
    <Menu
      onClick={({ key }) => {
        setIsOnline(key);
      }}
      selectedKeys={[isOnline]}
      items={[
        {
          label: RADIO_TYPE_DICTIONARY.OFF_LINE,
          key: RADIO_TYPE_KEY.OFF_LINE
        },
        {
          label: RADIO_TYPE_DICTIONARY.ON_LINE,
          key: RADIO_TYPE_KEY.ON_LINE
        }
      ]}
    />
  );

  const onAddRadio = async () => {
    try {
      const { start, id } = record;
      const params = {
        categoryId: id,
        broadcastTime: moment(start),
        radioType: isOnline
      };

      const res = await addRadio({
        variables: {
          addRadioInput: {
            ...params
          }
        },
        refetchQueries: ['GetAllRadios']
      });
      visibleDrawerVar(false);
      window.open('/chi-tiet-radio/' + res.data.addRadio.id, '_blank');
    } catch {}
  };

  return (
    <>
      <QuickViewInfo
        categoryTitle={record?.title}
        categoryId={record?.id}
        topicTitle={record?.topic?.title}
      />

      <Row justify="end">
        <Space>
          <Tag color="orange">Chưa có thông tin radio</Tag>
          <Dropdown.Button
            icon={<DownOutlined />}
            overlay={menu}
            onClick={onAddRadio}
            type="primary"
            style={{ width: '220px' }}
            buttonsRender={([leftButton, rightButton]) => [
              React.cloneElement(leftButton, {
                style: { width: '100%' }
              }),
              React.cloneElement(rightButton)
            ]}
          >
            {`Tạo radio ${RADIO_TYPE_DICTIONARY[isOnline]?.toLowerCase()}`}
          </Dropdown.Button>

          {isOnline === RADIO_TYPE_KEY.ON_LINE && <LiveTag />}
        </Space>
      </Row>
    </>
  );
};

export default WithoutRadioContent;
