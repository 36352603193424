import { Typography } from 'antd';
import UserAvatar from 'components/UserAvatar';
import { useCurrentUser } from 'operations/queries/queryCurrentUser';
import React from 'react';
import { Link } from 'react-router-dom';

const UserInfoDisplay = () => {
  const { data, loading, error } = useCurrentUser();
  if (loading) return <Typography.Text>loading</Typography.Text>;
  if (error) return null;
  const { name, host } = data.detail;
  const image = host?.image;

  return (
    <Link to="/ca-nhan">
      <div className="user-info-display">
        <UserAvatar fullName={name} avatar={image} size={32} />

        <Typography.Text strong className="user-info-display__name">
          {/*name{fullName.split(' ').pop()} */}
          {name}
        </Typography.Text>
      </div>
    </Link>
  );
};

export default UserInfoDisplay;
