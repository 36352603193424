import { ClockCircleOutlined } from '@ant-design/icons';
import { Avatar, Col, Row, Space, Typography } from 'antd';
import { RADIO_TYPE_KEY } from 'appConstants';
import LiveTag from 'components/LiveTag';
import ReplayTag from 'components/ReplayTag/ReplayTag';
import UserAvatar from 'components/UserAvatar';
import React from 'react';

const EventContent = eventInfo => {
  const { title, extendedProps } = eventInfo.event;
  const displayTitlte =
    extendedProps?.__typename === 'Category'
      ? title
      : extendedProps?.category?.title;
  const hosts = extendedProps?.hosts;

  const isRecordLive = extendedProps?.isLive === RADIO_TYPE_KEY.ON_LINE;

  const renderTag = ({ isRecordLive, typeName }) => {
    if (typeName === 'RadioReplaySchedule') {
      return <ReplayTag withoutText={true} />;
    }
    return isRecordLive ? (
      <Col>
        <LiveTag withoutText={true} />
      </Col>
    ) : (
      <></>
    );
  };

  return (
    <Row style={{ width: '100%' }}>
      <Col span={24} style={{ overflow: 'hidden' }}>
        <Typography.Text
          strong
          style={{ width: '100%' }}
          ellipsis={{ tooltip: title }}
        >
          {displayTitlte}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Space>
          <ClockCircleOutlined />
          <i>{eventInfo.timeText}</i>
        </Space>
      </Col>
      <Col span={24}>
        <Row align="middle" justify="space-between">
          <Col>
            <Avatar.Group>
              {hosts.map(host => (
                <UserAvatar
                  key={host?.id}
                  size={24}
                  fullName={host?.name}
                  avatar={host?.image}
                />
              ))}
            </Avatar.Group>
          </Col>

          {renderTag({ isRecordLive, typeName: extendedProps?.__typename })}
        </Row>
      </Col>
    </Row>
  );
};
export default EventContent;
