import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query GetUsers(
    $skip: Int
    $take: Int
    $order: [UserSortInput!]
    $where: UserFilterInput
  ) {
    users(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        name
        email
        status
        statusName
        roleId
        userType
        createdBy
        createdDate
        updatedBy
        updatedDate
        userTypeName
        channels
        creator {
          id
          name
        }
        updater {
          id
          name
        }
        host {
          id
          name
          currentWorkingPlace
          image
        }
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    allUsers {
      id
      name
      email
      status
      statusName
      roleId
      userType
      createdBy
      createdDate
      updatedBy
      updatedDate
      userTypeName
      channels
      creator {
        id
        name
      }
      updater {
        id
        name
      }
      host {
        id
        name
        currentWorkingPlace
        image
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($id: String!) {
    user(id: $id) {
      id
      name
      email
      status
      roleId
      createdBy
      createdDate
      updatedBy
      updatedDate
      userTypeName
      creator {
        id
        name
      }
      updater {
        id
        name
      }
    }
  }
`;
