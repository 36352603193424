import { useReactiveVar } from '@apollo/client';
import { Button, Col, Form, Input, Select } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import PlatFormAutoCompleteFilter from '../TrendTopicFilter/PlatFormAutoCompleteFilter';
import TrendTopicUpload from '../components/TrendTopicUpload';
import { SCRIPT_UPLOAD_SIZE_LIMIT, AUDIO_UPLOAD_SIZE_LIMIT ,TRENDTOPIC_STATUS} from 'appConstants';
import React, { useEffect } from 'react';

const TrendTopicFormContent = ({ form, onSubmit, isCreate }) => {
  const visibleDrawer = useReactiveVar(visibleDrawerVar);
  const trendTopic = visibleDrawer.record;

  useEffect(() => {
    if (trendTopic) {
      form.setFieldsValue({
        title: trendTopic?.title,
        contentText: trendTopic?.contentText,
        sourceUrl: trendTopic?.sourceUrl,
        platFormId: trendTopic?.usePlatforms.map(x => x.usePlatformId) ?? [],
        note: trendTopic?.note,
        usedStatus:trendTopic?.usedStatus,
        sourceMedia: trendTopic?.sourceMedia
      });
    }
    return () => form.resetFields();
  }, [trendTopic, form]);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="title"
        label="Tên chủ đề"
        rules={[{ required: true, message: 'Nhập tên!' }]}
      >
        <Input placeholder="Nhập chủ đề" />
      </Form.Item>
      <Form.Item name="contentText" label="Nội dung">
        <Input.TextArea placeholder="Nhập nội dung" />
      </Form.Item>
      <Form.Item name="note" label="Ghi chú">
        <Input.TextArea placeholder="Nhập ghi chú" />
      </Form.Item>
      <Form.Item name="sourceUrl" label="Nguồn">
        <Input placeholder="Nhập nguồn" />
      </Form.Item>
      {
        !isCreate
        && <>
          <PlatFormAutoCompleteFilter
            name="platFormId" />
          <Form.Item name="usedStatus" label="Trạng thái">
          <Select
              options={Object.entries(
                TRENDTOPIC_STATUS
              ).map(([key, value]) => ({
                value:Number(key) ,
                label: value,
              }))}
              style={{ width: '100%' }}
              placeholder="Chọn trạng thái"
              allowClear
            />
          </Form.Item>
        </>
      }
      <Form.Item
        name="sourceMedia"
        label={`File(.doc, .docx. Dung lượng tối đa ${SCRIPT_UPLOAD_SIZE_LIMIT} MB, .avi, .mp4 Dung lượng tối đa ${AUDIO_UPLOAD_SIZE_LIMIT} MB )`}
        valuePropName="file"
      >
        <TrendTopicUpload
          size={64}
          form={form}
          disabled={trendTopic?.useStatus == 2}
        />
      </Form.Item>
      <Form.Item style={{ display: 'none' }}>
        <Button htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  );
};

export default TrendTopicFormContent;
