import React, { useCallback, useEffect, useMemo } from 'react';
import queryString from 'query-string';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';

import {
  colProps,
  FILTER_PARAMS_PREFIX,
  CHANNEL_BROADCAST_DICTIONARY_IMG,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_DATA_TYPE_SUFFIX
} from 'appConstants';
import TopicSelectFilter from './TopicSelectFilter';
import { Link, useHistory } from 'react-router-dom';

import { processFormItemName } from 'utils/filter';
import FormBase from 'containers/FormBase';
import CategoryStatusSelect from 'components/CategoryStatusSelect';
import CategoryTypeSelect from 'components/CategoryTypeSelect';
import moment from 'moment';
import { cleanseObj } from 'utils/other';

const imgStyles = {
  height: 20,
  width: 20
};

const CategoryFilter = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const onAirTimeFormName = useMemo(
    () =>
      processFormItemName({
        prefix: FILTER_PARAMS_PREFIX,
        name: 'onAirRange',
        operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.BTW,
        dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.DATE
      }),
    []
  );

  useEffect(() => {
    const initValue = queryString.parse(history.location.search);

    const broadcastTimes = initValue[onAirTimeFormName];

    /* RISK */
    // chưa check thứ tự,
    // mặc định khi filter đưa lên params là đứng trước start,
    // sau là end nha

    form.setFieldsValue({
      ...initValue,
      ...(broadcastTimes
        ? {
            [onAirTimeFormName]: broadcastTimes.map(broadcastTime =>
              moment(broadcastTime)
            )
          }
        : {})
    });
  }, [onAirTimeFormName, form, history.location.search]);

  const onFilter = useCallback(() => {
    const params = cleanseObj(form.getFieldsValue());

    history.push({
      search: queryString.stringify({
        ...params
      })
    });
  }, [form, history]);

  return (
    <FormBase form={form} onFinish={onFilter}>
      <Row gutter={8}>
        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'title',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.CONTAINS
            })}
            label="Tên chương trình"
          >
            <Input placeholder="Nhập tên chương trình" />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'channelId',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ
            })}
            label="Kênh phát sóng"
          >
            <Select allowClear placeholder="Chọn kênh phát sóng">
              {CHANNEL_BROADCAST_DICTIONARY_IMG.map(({ value, label, img }) => (
                <Select.Option value={value} key={value}>
                  <img style={imgStyles} src={img} alt={label} /> {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'status',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
              dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.INT
            })}
            label="Trạng thái"
          >
            <CategoryStatusSelect style={{ width: '100%' }} intValue />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <TopicSelectFilter />
        </Col>

        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'type',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
              dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.INT
            })}
            label="Loại chương trình"
          >
            <CategoryTypeSelect integerValue={true} />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item name={onAirTimeFormName} label="Khoản thời gian phát sóng">
            <DatePicker.RangePicker
              showTime={false}
              style={{ width: '100%' }}
              allowClear
              format="DD/MM/YYYY"
              placeholder={['Chọn ngày bắt đầu', 'Chọn ngày kết thúc']}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="end" gutter={8}>
        <Col>
          <Button htmlType="submit">Lọc</Button>
        </Col>
        <Col>
          <Link to={'/chi-tiet-chuong-trinh'}>
            <Button type="primary">Thêm</Button>
          </Link>
        </Col>
      </Row>
    </FormBase>
  );
};

export default CategoryFilter;
