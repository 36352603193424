import { Col, Collapse, Row, Table, Typography } from 'antd';
import React from 'react';
import useDashboard from '../useDashboard';
import { columns } from './columns';

const CollapseHeader = ({ title, number }) => (
  <Row justify="space-between" style={{ flexGrow: 1 }}>
    <Typography.Title level={5} style={{ margin: 0 }}>
      {title}
    </Typography.Title>
    <Typography.Text>{number} số radio</Typography.Text>
  </Row>
);

const RadioTable = ({ title, list }) => (
  <Collapse {...collapseProps}>
    <Collapse.Panel
      key={1}
      header={<CollapseHeader title={title} number={list.length} />}
    >
      <Table {...tableProps} dataSource={list} />
    </Collapse.Panel>
  </Collapse>
);

const collapseProps = {
  style: { background: '#fff' },
  bordered: false,
  defaultActiveKey: ['1']
};

const tableProps = {
  bordered: true,
  pagination: false,
  columns,
  scroll: { x: 750 }
};

const Radios = () => {
  const {
    data: {
      waitingRadioDashboard,
      draftRadioDashboard,
      readyRadioDashboard,
      approvedRadioDashboard
    }
  } = useDashboard();
  console.log(useDashboard());
  return (
    <Row gutter={8}>
      <Col
        span={24}
        style={{
          marginBottom: 8
        }}
      >
        <RadioTable title="Radio chờ duyệt" list={waitingRadioDashboard} />
      </Col>

      <Col
        span={24}
        style={{
          marginBottom: 8
        }}
      >
        <RadioTable title="Radio nháp" list={draftRadioDashboard} />
      </Col>

      <Col span={12}>
        <RadioTable title="Radio đã được duyệt" list={approvedRadioDashboard} />
      </Col>

      <Col span={12}>
        <RadioTable title="Radio sẵn sàng" list={readyRadioDashboard} />
      </Col>
    </Row>
  );
};

export default Radios;
