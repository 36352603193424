import { useLazyQuery } from '@apollo/client';
import { Form } from 'antd';
import {
  FILTER_PARAMS_DATA_TYPE_SUFFIX,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX
} from 'appConstants';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { processFormItemName } from 'utils/filter';
import { useLocation } from 'react-router-dom';
import { GET_USER_BY_ID } from 'operations/queries/queryUser';
import UserAutoComplete from 'pages/News/components/NewsFilter/UserAutoComplete';

const UserAutoCompleteFilter = () => {
  const [searchUser, { loading }] = useLazyQuery(GET_USER_BY_ID);
  const [user, setUser] = useState([]);
  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const createdByFilterName = useMemo(() => {
    return processFormItemName({
      prefix: FILTER_PARAMS_PREFIX,
      name: 'createdBy',
      operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
      dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.STRING
    });
  }, []);

  const value = useMemo(() => {
    return searchParams[createdByFilterName];
  }, [createdByFilterName, searchParams]);

  const getUser = useCallback(async () => {
    try {
      const {
        data: { user }
      } = await searchUser({
        variables: {
          id: value
        }
      });
      setUser(user);
    } catch (e) {
      throw e;
    }
  }, [searchUser, value]);

  useEffect(() => {
    if (searchParams[createdByFilterName]) getUser();
  }, [getUser, createdByFilterName, searchParams]);

  return (
    <>
      <Form.Item name={createdByFilterName} label="Tạo bởi">
        <UserAutoComplete allowClear user={user} loading={loading} />
      </Form.Item>
    </>
  );
};

export default UserAutoCompleteFilter;
