import { Col, Input, Row, Spin } from 'antd';
import { apolloClient } from 'api';
import UserAvatar from 'components/UserAvatar';

import { useAddRadioNotes } from 'operations/mutations/radio/mutateRadio';
import { useCurrentUser } from 'operations/queries/queryCurrentUser';
import { GET_RADIO_BY_ID } from 'operations/queries/queryRadio';
import React, { useCallback, useMemo, useState } from 'react';

const RadioNotesForm = ({ radio }) => {
  const [value, setValue] = useState('');
  const { mutate, loading } = useAddRadioNotes();
  const { data } = useCurrentUser();
  const currentUser = useMemo(() => data?.detail, [data?.detail]);

  const onPressEnter = useCallback(async () => {
    try {
      if (!value) return;

      const { data } = await mutate({
        variables: {
          input: {
            note: value,
            radioId: radio?.id
          }
        }
      });

      const variables = {
        id: radio.id
      };

      apolloClient.writeQuery({
        query: GET_RADIO_BY_ID,
        variables,
        data: {
          radio: {
            ...radio,
            radioNotes: [...radio.radioNotes, data.addRadioNote]
          }
        }
      });

      setValue('');
    } catch (e) {}
  }, [mutate, radio, value]);

  return (
    <Spin spinning={loading}>
      <Row wrap={false} style={{ marginBottom: 8 }} gutter={[8, 8]}>
        <Col>
          <UserAvatar
            size={32}
            fullName={currentUser?.name}
            avatar={currentUser?.image}
          />
        </Col>

        <Col style={{ flexGrow: 1, flexBasis: 0 }}>
          <Input
            value={value}
            onChange={e => setValue(e.target.value)}
            placeholder="Nhập ghi chú và enter để gửi..."
            onPressEnter={onPressEnter}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default RadioNotesForm;
