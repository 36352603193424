import { Card, Form } from 'antd';

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

const FormBase = ({ form, onFinish, children }) => {
  const history = useHistory();

  const onFilter = useCallback(() => {
    const params = form.getFieldsValue();

    history.push({
      search: queryString.stringify(params)
    });
  }, [form, history]);

  return (
    <Card style={{ marginBottom: 8 }}>
      <Form onFinish={onFinish || onFilter} form={form} layout="vertical">
        {children}
      </Form>
    </Card>
  );
};

export default FormBase;
