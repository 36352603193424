import { Col, Row, Typography } from 'antd';
import { DATE_FORMAT, TIME_FORMAT } from 'appConstants';
import UserAvatar from 'components/UserAvatar';
import moment from 'moment';

import React, { useMemo, useState } from 'react';
import NoteMenu from './NoteMenu';

const Item = ({ radio, radioNote }) => {
  const { note, creator, createdDate, id } = radioNote;
  const [showBtn, setShowBtn] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setShowBtn(true);
      }}
      onMouseLeave={() => setShowBtn(false)}
    >
      <Row wrap={false} style={{ marginBottom: 8 }} gutter={[8, 8]}>
        <Col>
          <UserAvatar
            size={32}
            fullName={creator?.name}
            avatar={creator?.image}
          />
        </Col>

        <Col style={{ flexGrow: 1, flexBasis: 0 }}>
          <div
            style={{
              backgroundColor: '#d9d9d9',
              padding: '8px 12px',
              borderRadius: 4
            }}
          >
            <Row gutter={[4, 4]}>
              <Col span={24} style={{ lineHeight: '1.2' }}>
                <Typography.Text strong>{creator.name}</Typography.Text>

                <Typography.Text style={{ color: '#595959', fontSize: '13px' }}>
                  {' · '}
                  {moment(createdDate).format(DATE_FORMAT + ' ' + TIME_FORMAT)}
                </Typography.Text>
              </Col>

              <Col span={24}>
                <Typography.Text>{note}</Typography.Text>
              </Col>
            </Row>
          </div>
        </Col>

        <Col style={{ margin: 'auto 0', opacity: showBtn ? 1 : 0 }}>
          <NoteMenu id={id} radioNote={radioNote} radio={radio} />
        </Col>
      </Row>
    </div>
  );
};

const NotesContent = ({ radio }) => {
  const radioNotes = useMemo(
    () => radio?.radioNotes || [],
    [radio?.radioNotes]
  );

  return (
    <div style={{ marginBottom: 8 }}>
      {radioNotes.map(radioNote => (
        <Item key={radioNote.id} radioNote={radioNote} radio={radio} />
      ))}
    </div>
  );
};

export default NotesContent;
