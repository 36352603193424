import { Col, Divider, Row, Space, Typography } from 'antd';
import { RADIO_TYPE_KEY } from 'appConstants';
import LiveTag from 'components/LiveTag';
import ReplayTag from 'components/ReplayTag';
import React, { useContext, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { EditOutlined } from '@ant-design/icons';

import QuickViewInfo from './QuickViewInfo';
import { RadioQuickViewContext } from './RadioQuickView';

import WithRadioContentBtns from './WithRadioContentBtns';

const WithRadioContent = () => {
  const { record } = useContext(RadioQuickViewContext);

  const isRecordLive = useMemo(
    () => record?.isLive === RADIO_TYPE_KEY.ON_LINE,
    [record?.isLive]
  );

  const isReplay = useMemo(
    () => record?.__typename === 'RadioReplaySchedule',
    [record?.__typename]
  );

  const isRadio = useMemo(
    () => record?.__typename === 'Radio',
    [record?.__typename]
  );

  const renderTag = useCallback(({ isReplay }) => {
    if (isReplay) {
      return <ReplayTag />;
    }
    return <LiveTag />;
  }, []);

  const renderApproverCreator = useCallback(
    record => {
      if (isReplay || isRadio) {
        return (
          <Row style={{ marginBottom: 8 }}>
            <Col span={24}>
              Người tạo:
              <Typography.Text strong> {record?.creator?.name}</Typography.Text>
            </Col>
            <Col span={24}>
              Người duyệt:
              <Typography.Text strong>
                {' '}
                {record?.approver?.name}
              </Typography.Text>
            </Col>
          </Row>
        );
      }

      return <></>;
    },
    [isRadio, isReplay]
  );

  return (
    <>
      <Col span={24}>
        <Row align="middle">
          {(isRecordLive || isReplay) && (
            <>
              <Col>{renderTag({ isReplay })}</Col>

              <Col>
                <Divider type="vertical" style={{ margin: '0 8px' }} />
              </Col>
            </>
          )}
          <Col>
            <Typography.Text strong style={{ fontSize: 24 }}>
              <Link
                target={'_blank'}
                to={`chi-tiet-radio/${record?.radioId || record?.id}`}
              >
                {record?.title} - {record?.radioNumber}{' '}
                <EditOutlined style={{ fontSize: '1rem' }} />
              </Link>
            </Typography.Text>
          </Col>
        </Row>
      </Col>
      <QuickViewInfo
        categoryTitle={record?.category?.title}
        categoryId={record?.category?.id}
        topicTitle={record?.category?.topic?.title}
      />

      {renderApproverCreator(record)}

      <Col span={24}>
        <Typography.Text strong>Nội dung chính</Typography.Text>
      </Col>
      <Col span={24}>
        <Space direction="vertical">
          {record?.summary ? (
            record?.summary.map(timeline => (
              <Space>
                <Typography.Text>{timeline.time} -</Typography.Text>
                <Typography.Text strong>{timeline.content}</Typography.Text>
              </Space>
            ))
          ) : (
            <Typography.Text style={{ color: 'gray' }}>
              Chưa có thông tin chính
            </Typography.Text>
          )}
        </Space>
      </Col>

      <WithRadioContentBtns
        record={record}
        isRadio={isRadio}
        isReplay={isReplay}
      />
    </>
  );
};

export default WithRadioContent;
