import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const EDIT_CATEGORY = gql`
  mutation EditCategory($input: EditCategoryInput!) {
    editCategory(input: $input) {
      id
      title
      image
      introduction
      topic {
        id
        title
      }
      hosts {
        id
        name
      }
      topicId
      createdBy
      createdDate
      updatedBy
      updatedDate
    }
  }
`;

export const ADD_CATEGORY = gql`
  mutation AddNewCategory($input: AddCategoryInput!) {
    addCategory(input: $input) {
      id
      title
      image
      introduction
      topic {
        id
        title
      }
      hosts {
        id
        name
      }
      topicId

      createdBy
      createdDate
      updatedBy
      updatedDate
    }
  }
`;

export const REMOVE_CATEGORY = gql`
  mutation RemoveCategory($id: String!) {
    removeCategory(id: $id) {
      id
      title
    }
  }
`;

export const BULK_ACTION_CATEGORIES = gql`
  mutation BulkActionCategories($input: [BulkActionCategoryInput]) {
    bulkActionCategories(input: $input) {
      id
      title
      status
      isDeleted
    }
  }
`;

export const ADD_SCHEDULE = gql`
  mutation AddSchedule($input: AddScheduleInput!) {
    addSchedule(input: $input) {
      categoryId
      createdBy
      updatedBy
      updatedDate
      createdDate
      dayOfWeek
      id
      isDeleted
      status
      statusName
      timeFrom
      timeTo
    }
  }
`;

export const EDIT_SCHEDULE = gql`
  mutation EditSchedule($input: EditScheduleInput!) {
    editSchedule(input: $input) {
      categoryId
      createdBy
      updatedBy
      updatedDate
      createdDate
      dayOfWeek
      id
      isDeleted
      status
      statusName
      timeFrom
      timeTo
    }
  }
`;

export const REMOVE_SCHEDULE = gql`
  mutation EditSchedule($id: String!) {
    removeSchedule(id: $id) {
      id
    }
  }
`;

export const ATTACH_HOST_CATEGORY = gql`
  mutation AttachHostIntoCategory(
    $hostIds: [AttachTypeCateHostModelInput!]!
    $categoryId: String!
  ) {
    attachHostIntoCategory(hostIds: $hostIds, categoryId: $categoryId) {
      id
      category {
        title
      }
    }
  }
`;

export const useAddCategory = () => {
  const [mutate, { data, error }] = useMutation(ADD_CATEGORY, {
    onCompleted: ({ addCategory }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo chương trình{' '}
            <Typography.Text strong>{addCategory.title}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useEditCategory = () => {
  const [mutate, { data, error }] = useMutation(EDIT_CATEGORY, {
    onCompleted: ({ editCategory }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa chương trình{' '}
            <Typography.Text strong>{editCategory.title}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['getCategoryById', 'GetCategories']
  });
  return { mutate, data, error };
};

export const useAttachHostIntoCategory = () => {
  const [mutate, { data, error }] = useMutation(ATTACH_HOST_CATEGORY, {
    onCompleted: ({ attachHostIntoCategory }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã thêm host vào chương trình{' '}
            <Typography.Text strong>
              {attachHostIntoCategory?.category?.title}
            </Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['getCategoryById']
  });
  return { mutate, data, error };
};

export const useRemoveCategory = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_CATEGORY, {
    refetchQueries: ['GetCategories'],
    onCompleted: ({ removeCategory }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá chương trình{' '}
            <Typography.Text strong>{removeCategory.title}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useBulkCategories = actionField => {
  const [mutate, { data, error }] = useMutation(BULK_ACTION_CATEGORIES, {
    refetchQueries: ['GetCategories'],
    onCompleted: ({ bulkActionCategories }) => {
      getBulkMessage('chương trình', bulkActionCategories, actionField);
    }
  });

  return { mutate, data, error };
};

export const useAddSchedule = () => {
  const [mutate, { data, error }] = useMutation(ADD_SCHEDULE, {
    onCompleted: () => {
      notification.success({
        message: (
          <Typography.Text>Đã tạo thành công lịch phát sóng.</Typography.Text>
        )
      });
    },
    refetchQueries: ['getCategoryById']
  });
  return { mutate, data, error };
};

export const useEditSchedule = () => {
  const [mutate, { data, error }] = useMutation(EDIT_SCHEDULE, {
    onCompleted: () => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa thành công lịch phát sóng.
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['getCategoryById']
  });
  return { mutate, data, error };
};

export const useRemoveSchedule = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_SCHEDULE, {
    onCompleted: () => {
      notification.success({
        message: (
          <Typography.Text>Đã xóa thành công lịch phát sóng.</Typography.Text>
        )
      });
    },
    refetchQueries: ['getCategoryById']
  });
  return { mutate, data, error };
};
