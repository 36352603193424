import { createContext, useState } from 'react';

export const NewsContext = createContext();

const NewsProvider = props => {
  const [auditLogTarget, setAuditLogTarget] = useState();

  return (
    <NewsContext.Provider value={{ auditLogTarget, setAuditLogTarget }}>
      {props.children}
    </NewsContext.Provider>
  );
};

export default NewsProvider;
