import { Button, Space } from 'antd';
import {
  STATUS_ACTION_TIMEOUT,
  STATUS_VALUE,
  STATUS_VALUE_STRING
} from 'appConstants';
import Upgrade from 'components/Icons/Upgrade';
import StatusTag from 'components/StatusTag';
import React, { useCallback, useRef, useState } from 'react';

const StatusButton = ({ record, onClick = f => f }) => {
  const { status } = record;
  const [actionVisible, setActionVisible] = useState(false);
  const timerRef = useRef();

  const buttonStyle = useCallback(
    (downStatus = false) => ({
      style: {
        padding: '0 7px',
        height: 'auto'
      },
      icon: downStatus ? <Upgrade rotate={180} /> : <Upgrade />,
      type: 'primary',
      danger: downStatus
    }),
    []
  );

  const onMouseEnter = () => {
    timerRef.current = setTimeout(() => {
      setActionVisible(true);
    }, STATUS_ACTION_TIMEOUT);
  };

  const onMouseLeave = () => {
    setActionVisible(false);
    clearTimeout(timerRef.current);
  };

  const renderButtonAction = useCallback(() => {
    switch (status) {
      case STATUS_VALUE.DRAFT:
        return (
          <Button
            {...buttonStyle()}
            onClick={() => onClick(STATUS_VALUE_STRING.APPROVED)}
          >
            Duyệt
          </Button>
        );
      case STATUS_VALUE.APPROVED:
        return (
          <Space>
            <Button
              {...buttonStyle(true)}
              onClick={() => onClick(STATUS_VALUE_STRING.DRAFT)}
            >
              Bỏ duyệt
            </Button>

            <Button
              {...buttonStyle()}
              onClick={() => onClick(STATUS_VALUE_STRING.PUBLISHED)}
            >
              Phát hành
            </Button>
          </Space>
        );
      case STATUS_VALUE.PUBLISHED:
        return (
          <Button
            {...buttonStyle(true)}
            onClick={() => onClick(STATUS_VALUE_STRING.APPROVED)}
          >
            Bỏ phát hành
          </Button>
        );

      default:
        break;
    }
  }, [status, buttonStyle, onClick]);

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ width: 'fit-content' }}
    >
      {actionVisible ? (
        renderButtonAction()
      ) : (
        <StatusTag status={status} style={{ cursor: 'pointer' }} />
      )}
    </div>
  );
};

export default StatusButton;
