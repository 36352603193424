import RemoveButton from 'components/RemoveButton';

import { EditOutlined, HistoryOutlined } from '@ant-design/icons';

import 'moment/locale/vi';
import { useRemoveNews } from 'operations/mutations/news/mutateNews';

import { Button, Space, Typography } from 'antd';
import { NEWS_TYPE_DICTIONARY_INT } from 'appConstants';
import ColName from 'components/ColName';
import NewsStatusButton from 'components/NewsStatusButton';
import { NewsContext } from 'pages/News/NewsProvider';
import { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import ProcessCol from './ProcessCol';
import { useCurrentUser } from 'operations/queries/queryCurrentUser';
import RelatedUser from 'components/RelatedUser';

const Btns = ({ record }) => {
  const { setAuditLogTarget } = useContext(NewsContext);

  const { mutate } = useRemoveNews();
  const { data } = useCurrentUser();
  const isAdmin = useMemo(() => data.detail?.userTypeName === 'ADMIN', []);

  return (
    <Space>
      <Button
        onClick={() => setAuditLogTarget(record.newsProcesses)}
        type="default"
        size="small"
        icon={<HistoryOutlined />}
      />
      <RemoveButton record={record} actionRemove={mutate} disabled={!isAdmin} />
    </Space>
  );
};

export const columns = [
  {
    title: 'Trạng thái',
    dataIndex: 'name',
    render: (_, record) => (
      <Space direction="vertical">
        <Typography.Text strong>
          {NEWS_TYPE_DICTIONARY_INT[record?.type]}
        </Typography.Text>
        <NewsStatusButton record={record} />
      </Space>
    ),
    align: 'center',
    width: '160px'
  },
  {
    title: 'Chủ đề',
    key: 'name',
    dataIndex: 'name',
    render: (name, record) => (
      <ColName
        primary={
          <Link
            style={{ color: 'inherit' }}
            to={`/chi-tiet-tin-bai/${record?.id}`}
          >
            {name} {<EditOutlined />}
          </Link>
        }
        // secondary={`${NEWS_TYPE_DICTIONARY_INT[record?.type]}`}
      />
    ),
    width: '300px'
    // fixed: 'left'
  },

  // {
  //   title: 'Thời gian',
  //   dataIndex: 'timeLine',
  //   width: '280px',
  //   render: (_, { createdDate, updatedDate }) => (
  //     <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
  //   )
  // },
  {
    title: 'Lịch sử ghi nhận',
    dataIndex: 'newsProcesses',
    width: '350px',
    colSpan: 2,
    render: newsProcesses => {
      const _process = (newsProcesses || []).slice(0, 3);
      const sendProcess = _process.find(_p => _p.processType === 0);
      const approvedProcess = _process.find(_p => _p.processType === 1);
      return (
        <Space direction="vertical">
          <ProcessCol
            title="Gửi duyệt"
            userName={sendProcess?.createdName}
            date={sendProcess?.createdDate}
          />
          <ProcessCol
            title="Duyệt"
            userName={approvedProcess?.createdName}
            date={approvedProcess?.createdDate}
          />
        </Space>
      );
    }
  },
  {
    title: 'Liên quan',
    dataIndex: 'newsProcesses',
    width: '350px',
    colSpan: 0,
    render: (_, { newsProcesses, updatedDate, updater }) => {
      const _process = (newsProcesses || []).slice(0, 3);
      const usedProcess = _process.find(_p => _p.processType === 2);
      return (
        <Space direction="vertical">
          <ProcessCol
            title="Cập nhật cuối"
            userName={updater?.name}
            date={updatedDate}
          />
          <ProcessCol
            title="Khai thác"
            userName={usedProcess?.createdName}
            date={usedProcess?.createdDate}
          />
        </Space>
      );
    }
  },
  {
    title: 'Liên quan',
    dataIndex: 'related',
    width: '180px',
    render: (_, { creator, updater }) => (
      <RelatedUser creator={creator} updater={updater} />
    )
  },
  {
    title: 'Thao tác',
    width: '100px',

    render: (_, record) => <Btns record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
