import { useQuery } from '@apollo/client';
import { Card, Col, Row, Space, Typography } from 'antd';
import RadioStatusTag from 'components/RadioStatusTag';

import { GET_RADIO_BY_ID } from 'operations/queries/queryRadio';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const RadioStatus = () => {
  const { id } = useParams();
  const { data } = useQuery(GET_RADIO_BY_ID, {
    variables: { id },
    skip: !id
  });

  const radio = useMemo(() => data?.radio || {}, [data?.radio]);

  return (
    <Card title="Thông tin khác">
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Space>
            <Typography.Text>Trạng thái:</Typography.Text>
            <RadioStatusTag status={radio?.statusName} />
          </Space>
        </Col>

        <Col span={24}>
          <Typography.Text>Tạo bởi:</Typography.Text>
          <Typography.Text strong> {radio?.creator?.name}</Typography.Text>
        </Col>

        {radio?.updater?.name && (
          <Col span={24}>
            <Typography.Text>Cập nhật lần cuối bởi:</Typography.Text>
            <Typography.Text strong> {radio?.updater?.name}</Typography.Text>
          </Col>
        )}

        {radio?.approver?.name && (
          <Col span={24}>
            <Typography.Text>Duyệt bởi:</Typography.Text>
            <Typography.Text strong> {radio?.approver?.name}</Typography.Text>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default RadioStatus;
