import { useReactiveVar } from '@apollo/client';
import { Form } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import UpdateCreateModal from 'containers/UpdateCreateModal';
import { useAddUser, useEditUser } from 'operations/mutations/user/mutateUser';
import React, { useCallback } from 'react';
import { setError } from 'utils/other';
import UserFormContent from './UserFormContent';

const UserForm = () => {
  const { mutate: editUser } = useEditUser();
  const visibleDrawer = useReactiveVar(visibleDrawerVar);

  const [form] = Form.useForm();

  const { mutate: createUser } = useAddUser();

  const onSave = useCallback(async () => {
    try {
      const params = await form.validateFields();
      delete params.email;

      const _image = params.image;
      const image = _image?.file?.response?.Data || _image;

      await editUser({
        variables: {
          input: {
            ...params,
            id: visibleDrawer.record.id,
            image
          }
        }
      });

      visibleDrawerVar({ visible: false, record: null });
    } catch (e) {
      // throw e;
    }
  }, [form, editUser, visibleDrawer?.record?.id]);

  const onCreate = async () => {
    try {
      const params = await form.validateFields();

      const _image = params.image;
      const image = _image?.file?.response?.Data || _image;

      await createUser({
        variables: {
          addUserInput: {
            ...params,
            image
          }
        }
      });
      form.resetFields();
      visibleDrawerVar(false);
    } catch (e) {
      setError(form, e);
    }
  };

  return (
    <UpdateCreateModal
      title={visibleDrawer.record ? 'Chỉnh sửa biên tập' : 'Tạo biên tập'}
      onOk={visibleDrawer.record ? onSave : onCreate}
      onClose={() => {
        form.resetFields();
        visibleDrawerVar(false);
      }}
      visible={visibleDrawer.visible}
      isUpdate={!!visibleDrawer.record}
      destroyOnClose
    >
      <UserFormContent
        form={form}
        onSubmit={visibleDrawer.record ? onSave : onCreate}
      />
    </UpdateCreateModal>
  );
};

export default UserForm;
