import React, { useState } from 'react';

import { Form, Input, Button, Typography } from 'antd';

import OTPTimer from './OTPTimer';

const getQueryVariable = key => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');

  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');

    if (pair[0] === key) {
      return pair[1];
    }
  }
  return false;
};

const PasswordRecovery = () => {
  const [started, setStarted] = useState(false);

  const onFinish = values => {
    //
    setStarted(true);
  };

  const onFinishFailed = errorInfo => {
    //
  };

  return (
    <div className="unauthorized-container">
      {!!getQueryVariable('token') ? (
        <>
          <Typography.Title style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            Đặt lại mật khẩu
          </Typography.Title>
          <div className="unauthorized-container__form-wrapper">
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
            >
              <Form.Item
                label="Mật khẩu mới"
                name="password"
                rules={[{ required: true, message: 'Nhập mật khẩu mới!' }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="Nhập lại mật khẩu mới"
                name="password"
                rules={[{ required: true, message: 'Nhập mật khẩu mới!' }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button
                  style={{ width: '100%' }}
                  type="primary"
                  htmlType="submit"
                >
                  Đặt lại mật khẩu
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      ) : (
        <>
          <Typography.Title style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            Lấy lại mật khẩu
          </Typography.Title>
          <div className="unauthorized-container__form-wrapper">
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
            >
              <Form.Item
                label="Tên đăng nhập"
                name="username"
                rules={[{ required: true, message: 'Nhập tên đăng nhập!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <Button
                  style={{ width: '100%' }}
                  type="primary"
                  htmlType="submit"
                >
                  Lấy OTP
                </Button>
              </Form.Item>
            </Form>

            <OTPTimer started={started} />
          </div>
        </>
      )}
    </div>
  );
};

export default PasswordRecovery;
