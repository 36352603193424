import { useQuery } from '@apollo/client';
import { Space, Select } from 'antd';
import { ORDER_BY_DICTIONARY, PAGE_SIZE, TABLE_PROPS, OUTSIDE_VOH_API } from 'appConstants';
import ListCard from 'components/ListCard';
import axios from 'axios';

import SortSelect from 'components/SortSelect';

import Table from 'components/Table';

import { GET_NEWS } from 'operations/queries/queryNews';
import queryString from 'query-string';
import { useCallback, useMemo, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { processQueryFilerParams } from 'utils/filter';
import { columns } from './columns';
import { NewsWebContext } from '../../NewsWebProvider';

const getData = async (body) => {
  try {
    if (!body) {
      body = {
        "newsTypeId": 0,
        "keywords": "",
        "searchByDate": 1,
        "startTime": "11-10-1999",
        "endTime": "11-10-2999",
        "orderBy": 0,
        "sortOrder": 0,
        "page": 0
      }
    }
    const response = await axios.post(`${OUTSIDE_VOH_API}/api/Others/GetNewsSaveBanks`, body);
    return response.data
  } catch (error) {
    // Xử lý lỗi nếu có
    console.error('Error:', error);
  }
  return {}
}

const NewsWebList = () => {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const { paramFilter, setParamFilter } = useContext(NewsWebContext);
  const [loading, setLoading] = useState(false)
  const [news, setNews] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)



  useEffect(async () => {
    setLoading(true)
    let body = {
      "newsTypeId": 0,
      "keywords": "",
      "searchByDate": 1,
      "orderBy": 0,
      "sortOrder": 0,
      "page": 0
    }
    if (paramFilter) {
      body = paramFilter
    }
    body.orderBy = 2
    try {
      const response = await axios.post(`${OUTSIDE_VOH_API}/api/Others/GetNewsSaveBanks`, body);
      let _list = response.data.data?.map((x, index) => ({ ...x, key: index })) ?? []
      setNews(_list)
      setTotal(response.data?.totalCount ?? 0)
      setPage(response.data?.currentPage ?? 1)
    } catch {

    }

    setLoading(false)
  }, [paramFilter])



  const onPaginate =
    page => {
      setParamFilter(_p => ({ ..._p, page }))
    }

  const setOrderBy = useCallback(
    sort => {
      history.push({
        search: queryString.stringify({ ...searchParams, page: 1, sort })
      });
    },
    [history, searchParams]
  );

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space >
          <Select
            defaultValue={0}
            style={{ width: 200, fontWeight: 'normal' }}
            options={[{ value: 2, label: "Cũ nhất" }, { value: 0, label: "Mới nhất" }]}
            onChange={x => (setParamFilter(_p => ({ ..._p, sortOrder: x })))}
          />
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        scroll={{ x: 1200 }}
        columns={columns}
        dataSource={news}
        pagination={{
          total,
          pageSize: 30,
          onChange: onPaginate,
          current: page * 1,
          quantity: news?.length
        }}
        loading={loading}
        bordered
      />
    </ListCard>
  );
};

export default NewsWebList;
