import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
  query GetCategories(
    $skip: Int
    $take: Int
    $order: [CategorySortInput!]
    $where: CategoryFilterInput
  ) {
    categories(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        title
        status
        statusName
        type
        typeName
        onAirTo
        onAirFrom
        image
        introduction
        channelId
        channel
        duration
        topic {
          id
          title
        }
        hosts {
          id
          name
          cateHosts {
            type
          }
        }
        creator {
          id
          name
        }
        updater {
          id
          name
        }
        topicId
        createdBy
        createdDate
        updatedBy
        updatedDate
        schedules {
          id
          dayOfWeek
          timeFrom
          timeTo
        }
        radioStatistics {
          status {
            count
            code
          }
          total
        }
      }
    }
  }
`;

export const GET_CATEGORY_BY_ID = gql`
  query getCategoryById($id: String!) {
    category(id: $id) {
      isAutoPublish
      isAutoPublishName
      id
      title
      status
      statusName
      type
      typeName
      image
      introduction
      duration
      onAirFrom
      onAirTo
      emails
      rootCategory {
        id
        title
        channel
        duration
      }
      rootCategoryId
      childCategories {
        id
        title
        channel
        duration
      }
      topic {
        id
        title
      }
      hosts {
        id
        name
        cateHosts {
          type
        }
      }
      cateHosts {
        id
        type
      }
      creator {
        id
        name
      }
      updater {
        id
        name
      }
      topicId
      channelId
      channel
      createdBy
      createdDate
      updatedBy
      updatedDate
      radioStatistics {
        total
      }
      schedules {
        categoryId
        createdBy
        updatedBy
        updatedDate
        createdDate
        dayOfWeek
        id
        isDeleted
        status
        statusName
        timeFrom
        timeTo
      }
      emailView
      phoneNumber
      facebook
      instagram
      tiktok
      youtube
      twiter
      spotify
    }
  }
`;

export const GET_ALL_CATEGORIES_CALENDAR = gql`
  query GetAllCategories(
    $order: [CategorySortInput!]
    $where: CategoryFilterInput
  ) {
    allCategories(order: $order, where: $where) {
      id
      title
      hosts {
        id
        name
        image
      }
      onAirFrom
      onAirTo
      schedules {
        id
        dayOfWeek
        timeFrom
        timeTo
      }
    }
  }
`;

export const GET_ALL_CATEGORIES = gql`
  query GetAllCategories(
    $order: [CategorySortInput!]
    $where: CategoryFilterInput
  ) {
    allCategories(order: $order, where: $where) {
      id
      duration
      title
      status
      statusName
      type
      typeName
      onAirFrom
      onAirTo
      image
      introduction
      channelId
      channel
      emails
      radioStatistics {
        total
      }
      topic {
        id
        title
      }
      hosts {
        id
        name
        image
        cateHosts {
          type
        }
      }
      creator {
        id
        name
      }
      updater {
        id
        name
      }
      topicId
      createdBy
      createdDate
      updatedBy
      updatedDate
      schedules {
        id
        dayOfWeek
        timeFrom
        timeTo
      }
    }
  }
`;
