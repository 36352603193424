import { createContext, useState } from 'react';

export const SpotlightBankContext = createContext();

const SpotlightBankProvider = props => {
  const [paramFilter, setParamFilter] = useState();

  return (
    <SpotlightBankContext.Provider value={{ paramFilter, setParamFilter }}>
      {props.children}
    </SpotlightBankContext.Provider>
  );
};

export default SpotlightBankProvider;
