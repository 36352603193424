import { Button, Card, DatePicker, Form } from 'antd';
import { DATE_FORMAT, TIME_FORMAT } from 'appConstants';
import moment from 'moment';
import { useChangePublishDateRadio } from 'operations/mutations/radio/mutateRadio';
import React, { useContext, useEffect } from 'react';
import { RadioDetailContext } from '../RadioDetail';

const RadioPublishDate = () => {
  const { radio } = useContext(RadioDetailContext);
  const { mutate, loading } = useChangePublishDateRadio();
  const [miniForm] = Form.useForm();

  const onClick = async () => {
    const { publishedDate } = await miniForm.validateFields();
    // const publishedDate = moment(_publishedDate);

    // publishedDate.hours(0);
    // publishedDate.minutes(0);
    // publishedDate.seconds(0);
    // publishedDate.milliseconds(0);

    return mutate({
      variables: {
        input: {
          id: radio.id,
          publishedDate
        }
      }
    });
  };

  //   const disabledDate = current => {
  //     return moment(current).isBefore(radio.timeTo, 'day');
  //   };

  //   const disabledTime = date => {
  //     return moment(date).isSame(radio.timeTo, 'day');
  //   };

  useEffect(() => {
    miniForm.setFieldsValue({
      publishedDate: radio.publishedDate ? moment(radio.publishedDate) : null
    });
  }, [miniForm, radio.publishedDate]);

  return (
    <Card title="Ngày công khai">
      <Form form={miniForm}>
        <Form.Item name="publishedDate">
          <DatePicker
            showTime
            // disabledDate={disabledDate}
            // disabledTime={disabledTime}
            format={DATE_FORMAT + ' ' + TIME_FORMAT}
            style={{ width: '100%' }}
            placeholder="Chọn ngày công khai"
          />
        </Form.Item>
      </Form>

      <div style={{ display: 'flex' }}>
        <Button
          loading={loading}
          onClick={onClick}
          type="primary"
          style={{ marginLeft: 'auto' }}
        >
          Lưu
        </Button>
      </div>
    </Card>
  );
};

export default RadioPublishDate;
