import {
  ApartmentOutlined,
  BulbOutlined,
  CloudServerOutlined,
  HomeOutlined,
  TeamOutlined,
  ProfileOutlined,
  UnorderedListOutlined,
  RiseOutlined,
  CustomerServiceOutlined
} from '@ant-design/icons';
import yn from 'yn';
import { useReactiveVar } from '@apollo/client';
import { Layout, Menu } from 'antd';
import { currentPermissionsVar, isWaitingUserVar } from 'api/cache';
import {
  CHANNEL_BROADCAST_KEY,
  FILTER_PARAMS_DATA_TYPE_SUFFIX,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX,
  USER_STATUS_KEY,
  USER_STATUS_VALUE
} from 'appConstants';
import logo from 'assets/images/voh-radio.png';
import GuestIcon from 'components/Icons/GuestIcon';
import Microphone from 'components/Icons/Microphone';
import Podcast from 'components/Icons/Podcast';
import moment from 'moment';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { processFormItemName } from 'utils/filter';
import { get } from 'utils/lodash';
import { getCookie } from 'utils/authority';

const { Sider } = Layout;

const iconStyle = { fontSize: '20px' };

const showDB = yn(process.env.REACT_APP_SHOW_DASHBOARD);

const menuItems = [
  showDB
    ? {
        to: '/',
        code: '/',
        name: 'Bảng điều khiển',
        icon: <HomeOutlined style={iconStyle} />
      }
    : {},
  {
    to: '/lich-phat-song',
    name: 'Lịch phát sóng',
    icon: <UnorderedListOutlined style={iconStyle} />,
    code: 'broadcastschedule',
    params:
      '?' +
      queryString.stringify({
        [processFormItemName({
          name: 'channel'
        })]:
          getCookie('BROADCAST_SCHEDULE_CHANNEL_DEFAULT') ||
          CHANNEL_BROADCAST_KEY.FM999MHZ
      }) +
      '&' +
      queryString.stringify({
        endDate: moment().endOf('isoWeek').format('YYYY-MM-DD')
      }) +
      '&' +
      queryString.stringify({
        startDate: moment().startOf('isoWeek').format('YYYY-MM-DD')
      }) +
      '&' +
      queryString.stringify({
        name: 'timeGridWeek'
      }) +
      '&' +
      queryString.stringify({
        typeView: 'timeGridWeek'
      })
  },
  {
    to: '/danh-sach-radio',
    name: 'Radio',
    icon: <Podcast style={iconStyle} />,
    code: 'radio',
    params:
      '?' +
      queryString.stringify({
        [processFormItemName({
          prefix: FILTER_PARAMS_PREFIX,
          name: 'broadcastTime',
          operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.BTW,
          dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.DATE
        })]: moment()
      }) +
      '&' +
      queryString.stringify({
        [processFormItemName({
          prefix: FILTER_PARAMS_PREFIX,
          name: 'broadcastTime',
          operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.BTW,
          dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.DATE
        })]: moment().add(14, 'days')
      })
  },
  {
    to: '/danh-sach-nhom-chuong-trinh',
    name: 'Nhóm chương trình',
    icon: <ApartmentOutlined style={iconStyle} />,
    code: 'topic'
  },
  {
    to: '/danh-sach-chuong-trinh',
    name: 'Chương trình',
    icon: <BulbOutlined style={iconStyle} />,
    code: 'category'
  },
  {
    to: '/danh-sach-host',
    name: 'Host',
    icon: <Microphone style={iconStyle} />,
    code: 'host'
  },
  {
    to: '/danh-sach-khach-moi',
    name: 'Khách mời',
    icon: <GuestIcon style={iconStyle} />,
    code: 'guest'
  },
  {
    to: '/danh-sach-bien-tap-vien',
    name: 'User',
    icon: <TeamOutlined style={iconStyle} />,
    code: 'user',
    params:
      '?' +
      queryString.stringify({
        [processFormItemName({
          prefix: FILTER_PARAMS_PREFIX,
          name: 'status',
          operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
          dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.INT
        })]: USER_STATUS_VALUE[USER_STATUS_KEY.ACTIVATED]
      })
  },
  {
    to: '/chu-de-trend',
    name: 'Chủ đề Trend',
    icon: <RiseOutlined style={iconStyle} />,
    code: 'trend'
  },
  {
    to: '/danh-sach-bai-tin',
    name: 'Ngân hàng tin bài',
    icon: <ProfileOutlined style={iconStyle} />,
    code: 'news'
  },
  {
    to: '/danh-sach-spotlight',
    name: 'Danh sách Spotlight',
    icon: <CustomerServiceOutlined style={iconStyle} />,
    code: 'spotlight'
  }
];

const Sidebar = () => {
  const location = useLocation();
  const selectedKey = get(location.pathname.split('/'), [1], '');
  const currentPermissions = useReactiveVar(currentPermissionsVar);

  const isWaitingUser = useReactiveVar(isWaitingUserVar);
  const menusByPermission = useMemo(
    () =>
      menuItems.filter(
        menu =>
          currentPermissions?.includes(menu?.code) ||
          menu?.code === '/' ||
          menu?.code === 'news' ||
          menu?.code === 'trend' ||
          menu?.code === 'spotlight'
      ),
    [currentPermissions]
  );

  const currentMenus = useMemo(
    () =>
      menusByPermission.map(({ icon, name, to, params }) => {
        return {
          label: <Link to={to + (params || '')}>{name}</Link>,
          key: to.slice(1),
          icon
        };
      }),
    [menusByPermission]
  );

  return (
    <Sider trigger={null} collapsible>
      <div className="navi-logo">
        <Link to="/">
          <img alt="logo" src={logo} className="navi-logo-img" />
        </Link>
      </div>
      {!isWaitingUser && (
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedKey]}
          items={currentMenus}
        >
          <Menu.Item icon={<CloudServerOutlined style={iconStyle} />}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={process.env.REACT_APP_GOOGLE_DRIVE}
            >
              Tư liệu
            </a>
          </Menu.Item>
        </Menu>
      )}

      <Menu selectedKeys={[]} theme="dark" mode="inline">
        <Menu.Item icon={<CloudServerOutlined style={iconStyle} />}>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={process.env.REACT_APP_GOOGLE_DRIVE}
          >
            Tư liệu
          </a>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
