import { Card } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const ChildCategories = ({ categories = [] }) => {
  return categories.length ? (
    <Card title="Các chương trình con" style={{ marginTop: 8 }}>
      {categories.map(({ id, title, channel, duration }) => (
        <p key={id} style={{ marginBottom: '0.5rem' }}>
          <Link to={`/chi-tiet-chuong-trinh/${id}`}>
            {channel} - {title} - {duration} phút
          </Link>
        </p>
      ))}
    </Card>
  ) : (
    <></>
  );
};

export default ChildCategories;
