import { gql } from '@apollo/client';

export const _radioNotes = `
    id
    radioId
    note
    createdBy
    updatedBy
    createdDate
    updatedDate
    creator {
      id
      name
      image
    }
    isDeleted
  `;

export const _radio = `
  id
  isLive
  statusName
  updater {
    id
    name
  }

  approver {
    id
    name
  }
  publishedDate
  radioType
  createdBy
  createdDate
  updatedDate
  updatedBy
  title
  status
  introduction
  duration
  radioNumber
  scenarioFile
  audioUrl
  image
  isHaveGuest
  nextStatus {
    value
    label
  }
  prevStatus {
    value
    label
  }
  creator {
    id
    name
  }
  category {
    id
    title
    image
    type
    typeName
    duration
    channelId
    channel
    onAirTo
    topic {
      id
      title
    }
    hosts {
      id
      name
      image
      cateHosts {
        type
      }
    }
    schedules {
      categoryId
      createdBy
      updatedBy
      updatedDate
      createdDate
      dayOfWeek
      id
      isDeleted
      status
      statusName
      timeFrom
      timeTo
    }
  }
  broadcastTime
  timeTo
  summary {
    content
    time
  }

  radioReplaySchedules {
    id
    timeFrom
    timeTo
    radioId
  }
  guests {
    id
    name
  }
  hosts {
    id
    name
    image
    radioHosts {
      id
      type
      host {
        id
        name
      }
    }
  }
  radioNotes {
    ${_radioNotes}
  }

  radioMedias {
    id
    mediaUrl
  }
  
`;

export const GET_RADIOS = gql`
  query GetRadios(
    $skip: Int
    $take: Int
    $order: [RadioSortInput!]
    $where: RadioFilterInput
  ) {
    radios(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        ${_radio}
      }
    }
  }
`;

export const GET_RADIO_BY_ID = gql`
  query getRadioById($id: String!) {
    radio(id: $id) {
      ${_radio}
      radioDraft {
        audioUrl
        image
        introduction
        radioId
        radioNumber
        title
        summary {
          content
          time
        }
      }
    }
  }
`;

// Get all radios for broadcast schedule
export const GET_ALL_RADIOS_CALENDAR = gql`
  query GetAllRadios($order: [RadioSortInput!], $where: RadioFilterInput) {
    allRadios(order: $order, where: $where) {
      id
      title
      timeTo
      broadcastTime
      duration
      statusName
      isLive
      category {
        title
      }
      hosts {
        id
        name
        image
      }
    }
  }
`;

export const GET_ALL_RADIOS = gql`
  query GetAllRadios($order: [RadioSortInput!], $where: RadioFilterInput) {
    allRadios(order: $order, where: $where) {
      ${_radio}
    }
  }
`;

export const GET_ALL_RADIO_REPLAY_SCHEDULES_CALENDAR = gql`
  query AllRadioReplaySchedules($where: RadioReplayScheduleFilterInput) {
    allRadioReplaySchedules(where: $where) {
      id
      timeFrom
      timeTo
      radioId
      radio {
        id
        title
        timeTo
        broadcastTime
        duration
        statusName
        isLive
        category {
          title
        }
        hosts {
          id
          name
          image
        }
      }
    }
  }
`;

export const GET_ALL_RADIO_REPLAY_SCHEDULE = gql`
  query AllRadioReplaySchedules($where: RadioReplayScheduleFilterInput) {
    allRadioReplaySchedules(where: $where) {
      id
      timeFrom
      timeTo
      radioId
      radio {
        ${_radio}
      }
    }
  }
`;

export const GET_RADIO_REPLAY_SCHEDULE_BY_ID = gql`
query RadioReplaySchedule($id: String!) {
  radioReplaySchedule(id: $id) {
    id
    timeFrom
    timeTo
    radioId
    radio {
      ${_radio}
    }
  }
}
`;

export const GET_ALL_RADIO_REPLAY_SCHEDULE_SUGGESTIONS = gql`
  query RadioReplayScheduleSuggestions($id: String!) {
    radioReplayScheduleSuggestions(id: $id) {
      id
      timeFrom
      timeTo
      status
      radioId
      createdBy
      updatedBy
      createdDate
      updatedDate
      radio {
        id
      }
      statusName
      isDeleted
    }
  }
`;
//sangdq recheck 05062023
