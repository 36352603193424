import React, { useCallback, useEffect } from 'react';

import { Button, Col, Form, Input, Row } from 'antd';

import {
  colProps,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX
} from 'appConstants';
import { processFormItemName } from 'utils/filter';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { visibleDrawerVar } from 'api/cache';
import FormBase from 'containers/FormBase';

const TopicFilter = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    form.setFieldsValue(queryString.parse(history.location.search));
  }, [form, history.location.search]);

  return (
    <FormBase form={form}>
      <Row gutter={8}>
        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'title',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.CONTAINS
            })}
            label="Nhóm chương trình"
          >
            <Input placeholder="Nhập tên nhóm chương trình" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8} justify="end">
        <Col>
          <Button htmlType="submit">Lọc</Button>
        </Col>
        <Col>
          <Button
            onClick={() => visibleDrawerVar({ visible: true })}
            type="primary"
          >
            Thêm
          </Button>
        </Col>
      </Row>
    </FormBase>
  );
};

export default TopicFilter;
