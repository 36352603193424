import { useQuery } from '@apollo/client';
import { Form, Select } from 'antd';
import { GET_ALL_TOPICS } from 'operations/queries/queryTopic';
import React from 'react';

const TopicSelect = ({ props }) => {
  const { error, data: topicsConnect } = useQuery(GET_ALL_TOPICS, {
    variables: {
      skip: 0,
      take: 50
    }
  });

  if (error) return <div>Oops, đã có vấn đề xảy ra 😅😅😅</div>;
  if (!topicsConnect) return <div>None</div>;
  const allTopics = topicsConnect.topics.items.map(({ id, title }) => ({
    value: id,
    label: title
  }));
  return (
    <Form.Item
      name="topicId"
      label="Nhóm chương trình"
      rules={[{ required: true, message: 'Chọn nhóm chương trình!' }]}
    >
      <Select
        options={allTopics}
        placeholder="Chọn nhóm chương trình"
        style={{ width: '100%' }}
        allowClear
        showSearch
        optionFilterProp="label"
        {...props}
      />
    </Form.Item>
  );
};

export default TopicSelect;
