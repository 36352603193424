import { gql, useQuery } from '@apollo/client';
import { currentPermissionsVar, isWaitingUserVar } from 'api/cache';
import { SCREEN_PERMISSIONS, USER_STATUS_KEY } from 'appConstants';
import { validateEmail } from 'utils/other';

export const GET_CURRENT_USER = gql`
  query UserDetail {
    detail {
      id
      name
      email
      status
      statusName
      userType
      userTypeName
      image
      host {
        id
        name
        image
        dateOfBirth
        introduction
        education
        experience
        currentWorkingPlace
      }
    }
  }
`;

export const GET_LOGGIN_LOG = gql`
  query LoginAttemptByUserId($id: String!) {
    loginAttemptByUserId(id: $id) {
      id
      userId
      ip
      userAgent
      success
      createdDate
      user {
        id
        name
        email
        status
      }
    }
  }
`;

export const useCurrentUser = () => {
  const { client, data, error, loading } = useQuery(GET_CURRENT_USER, {
    onCompleted: ({ detail: { userType, email, statusName } }) => {
      const isOldUser =
        !validateEmail(email) && statusName === USER_STATUS_KEY.WAITING;
      isWaitingUserVar(isOldUser);
      const currentPermissions = SCREEN_PERMISSIONS[userType];
      currentPermissionsVar(currentPermissions);
    }
  });
  return { client, data, error, loading };
};
