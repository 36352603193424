import { useReactiveVar } from '@apollo/client';
import { Button, Form, Modal } from 'antd';
import { draftTimeLineVar } from 'api/cache';
import moment from 'moment';
import { useEditDraftRadio } from 'operations/mutations/radio/mutateRadio';
import { RadioDetailContext } from 'pages/RadioDetail/RadioDetail';
import React, { useContext, useState } from 'react';
import EditDraftRadioModalContent from './EditDraftRadioModalContent';

const EditDraftRadioModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { radio } = useContext(RadioDetailContext);
  const { mutate, loading } = useEditDraftRadio();
  const [form] = Form.useForm();
  const timeLine = useReactiveVar(draftTimeLineVar);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    try {
      const { title, radioNumber, introduction, image, audioUrl } =
        await form.validateFields();

      await mutate({
        variables: {
          input: {
            title,
            radioNumber,
            introduction,
            image: image?.file?.response?.Data || image,
            audioUrl: audioUrl?.file?.response?.Data || audioUrl,

            radioId: radio.id,
            summary: timeLine?.map(item => ({
              time: moment(item.time).format('HH:mm:ss'),
              content: item.content
            }))
          }
        }
      });
    } catch (e) {
      throw e;
    }

    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button onClick={showModal} danger block>
        Chỉnh sửa thay đổi
      </Button>

      <Modal
        destroyOnClose
        confirmLoading={loading}
        width={800}
        centered
        className="merge-modal"
        title="Chỉnh sửa thay đổi"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Lưu"
        cancelText="Hủy"
      >
        <EditDraftRadioModalContent form={form} />
      </Modal>
    </>
  );
};

export default EditDraftRadioModal;
