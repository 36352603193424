import { Select } from 'antd';
import { DATE_OF_WEEK_DICTIONARY } from 'appConstants';
import React from 'react';

const DayOfWeekSelect = props => {
  return (
    <Select
      options={Object.entries(DATE_OF_WEEK_DICTIONARY).map(([key, value]) => ({
        value: key * 1,
        label: value
      }))}
      style={{ width: '100%' }}
      placeholder="Chọn ngày trong tuần"
      allowClear
      {...props}
    />
  );
};

export default DayOfWeekSelect;
