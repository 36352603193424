import cookies from 'js-cookie';
import jwt from 'jwt-decode';

const ACCESS_TOKEN_KEY = 'token';
const REFRESH_TOKEN_KEY = 'refresh_token';

/**
 *
 * @param {string}   key         The key passed to get value.
 * @param {string}   value       Value passed to cookie.
 * @param {object} option        Include path, expire time etc.
 *
 * @return {type} null.
 */
export const setCookie = (key, value, option = {}) =>
  cookies.set(key, value, option);

/**
 *
 * @param {string}   key         The key passed to get value.
 * @param {object} option        When you need to share to sub domain
 *                               use property { domain: 'example.com' }.
 *
 * @return {type} null.
 */
export const getCookie = (key, option = {}) => cookies.get(key, option);

export const getAccessToken = (option = {}) =>
  getCookie(ACCESS_TOKEN_KEY, option);

export const getRefreshToken = (option = {}) =>
  getCookie(REFRESH_TOKEN_KEY, option);

export const setAccessToken = (value = '', option) =>
  setCookie(ACCESS_TOKEN_KEY, value, option);

export const setRefreshToken = (value = '', option) =>
  setCookie(REFRESH_TOKEN_KEY, value, option);

const removeCookie = (key, option) => cookies.remove(key, option);
export const removeAccessToken = (option = {}) =>
  removeCookie(ACCESS_TOKEN_KEY, option);

export const removeRefreshToken = (option = {}) =>
  removeCookie(REFRESH_TOKEN_KEY, option);

const jwtDecode = token => jwt(token);

// const getTokenExpirationDate = encodedToken => {
//   const token = jwtDecode(encodedToken);
//   if (!token || !token.exp) {
//     return null;
//   }
//   const date = new Date(0);
//   date.setUTCSeconds(token.exp);
//   return date;
// };

// const isTokenExpired = token => {
//   if (token) {
//     const expirationDate = getTokenExpirationDate(token);
//     return expirationDate < new Date();
//   }
//   return true;
// };

export const isLoggedIn = () => {
  const idToken = getAccessToken();
  return !!idToken;
};

export const getUserInfoByToken = () => {
  if (isLoggedIn()) {
    const decode = jwtDecode(getAccessToken());
    return decode.data;
  }
  return null;
};
