import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { _news } from 'operations/queries/queryNews';

export const EDIT_NEWS = gql`
  mutation EditNews($input: EditNewsInput!) {
    editNews(input: $input) {
      ${_news}
      newsProcesses {
        processType
        id
        newsId
        newState
        oldState
        createdBy
        createdDate
        note
      }
    }
  }
`;

export const ADD_NEWS = gql`
  mutation AddNews($input: AddNewsInput!) {
    addNews(input: $input) {
      ${_news}
    }
  }
`;

export const REMOVE_NEWS = gql`
  mutation RemoveNews($id: String!) {
    removeNews(id: $id) {
      id
      name
    }
  }
`;

export const useAddNews = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_NEWS, {
    refetchQueries: ['NewsList', 'countNewsLeat'],

    onCompleted: ({ addNews }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Tin bài <Typography.Text strong>{addNews.name}</Typography.Text> đã
            được tạo 👌
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useEditNews = () => {
  const [mutate, { data, error, loading }] = useMutation(EDIT_NEWS, {
    refetchQueries: ['NewsList'],
    onCompleted: ({ editNews }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Tin bài <Typography.Text strong>{editNews.name}</Typography.Text> đã
            được cập nhật 👌
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useRemoveNews = (refetchQueries) => {
  const [mutate, { data, error }] = useMutation(REMOVE_NEWS, {
    refetchQueries: ['NewsList', 'countNewsLeat'], 
    onCompleted: ({ removeNews }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Tin bài <Typography.Text strong>{removeNews.name}</Typography.Text>{' '}
            đã được xoá 👌
          </Typography.Text>
        ),
      });
      if (refetchQueries) {
        refetchQueries(); 
      }
    },
  });

  return { mutate, data, error };
};
