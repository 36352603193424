import { Col, Form, Input, Row, Table, Typography } from 'antd';
import UserAvatar from 'components/UserAvatar';
import { RadioDetailContext } from 'pages/RadioDetail/RadioDetail';
import React, { useContext, useMemo } from 'react';
import { encodeUrlFile } from 'utils/other';

const columns = [
  {
    width: 150,
    title: 'Thời gian',
    dataIndex: 'time',
    editable: true,
    render: time => {
      return <Typography.Text>{time}</Typography.Text>;
    }
  },
  {
    title: 'Nội dung',
    dataIndex: 'content'
  }
];
const MergeModalContent = () => {
  const { radio } = useContext(RadioDetailContext);
  const audio = useMemo(
    () => encodeUrlFile(radio?.audioUrl),
    [radio?.audioUrl]
  );

  const draftAudio = useMemo(
    () => encodeUrlFile(radio?.radioDraft?.audioUrl),
    [radio?.radioDraft?.audioUrl]
  );
  return (
    <Form layout="vertical">
      <Row gutter={32}>
        <Col span={12}>
          <Typography.Title>Bản gốc</Typography.Title>
        </Col>

        <Col span={12}>
          <Typography.Title>Thay đổi chờ duyệt</Typography.Title>
        </Col>

        <Col span={12} className="merge-column">
          <Form.Item label="Số radio">
            <Input readOnly value={radio?.radioNumber}></Input>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Số radio">
            <Input readOnly value={radio?.radioDraft?.radioNumber}></Input>
          </Form.Item>
        </Col>

        <Col span={12} className="merge-column">
          <Form.Item label="Tiêu đề">
            <Input readOnly value={radio?.title}></Input>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Tiêu đề">
            <Input readOnly value={radio?.radioDraft?.title}></Input>
          </Form.Item>
        </Col>

        <Col span={12} className="merge-column">
          <Form.Item label="Giới thiệu ngắn (Sapo)">
            <Input.TextArea
              readOnly
              value={radio?.introduction}
            ></Input.TextArea>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Giới thiệu ngắn (Sapo)">
            <Input.TextArea
              readOnly
              value={radio?.radioDraft?.introduction}
            ></Input.TextArea>
          </Form.Item>
        </Col>

        <Col span={12} className="merge-column">
          <Form.Item label="Poster">
            <UserAvatar
              fullName={radio?.title}
              avatar={radio?.image}
              size={128}
              style={{ width: (128 * 16) / 9 }}
              shape={'square'}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Poster">
            <UserAvatar
              fullName={radio?.radioDraft?.title}
              avatar={radio?.radioDraft?.image}
              size={128}
              style={{ width: (128 * 16) / 9 }}
              shape={'square'}
            />
          </Form.Item>
        </Col>

        <Col span={12} className="merge-column">
          <Form.Item label="Audio">
            <audio src={audio} controls style={{ width: '100%' }}></audio>

            <Typography.Text ellipsis>
              <a href={audio}>{audio?.split('/')?.pop() || audio}</a>
            </Typography.Text>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Audio">
            <audio src={draftAudio} controls style={{ width: '100%' }}></audio>

            <Typography.Text ellipsis>
              <a href={draftAudio}>
                {draftAudio?.split('/')?.pop() || draftAudio}
              </a>
            </Typography.Text>
          </Form.Item>
        </Col>

        <Col span={12} className="merge-column">
          <Form.Item label="Thời gian - chủ đề module">
            <Table
              bordered
              dataSource={radio.summary}
              columns={columns}
              pagination={false}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Thời gian - chủ đề module">
            <Table
              bordered
              dataSource={radio?.radioDraft.summary}
              columns={columns}
              pagination={false}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default MergeModalContent;
