import { useReactiveVar } from '@apollo/client';
import { Form } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import {
  useAddTrendTopic,
  useEditTrendTopic
} from 'operations/mutations/TrendTopic/mutateTrendTopic';
import React from 'react';
import { setError } from 'utils/other';
import TrendTopicFormContent from './TrendTopicFormContent';
import { GET_TRENDTOPIC_BY_ID } from 'operations/queries/queryTrendTopic';
import { Spin} from 'antd';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
const TrendTopicDetail = () => {
  const  {id}  = useParams();
  const isCreating = useMemo(() => !id);
  const { loading } = useQuery(GET_TRENDTOPIC_BY_ID, {
    variables: { 
      id:parseInt(id)
    },
    skip: isCreating
  });
  return (
    <Spin spinning={loading}>
      <TrendTopicFormContent isCreating={isCreating} />
    </Spin>
  );
};

export default TrendTopicDetail;
