import { useLazyQuery } from '@apollo/client';
import { Form } from 'antd';
import {
  FILTER_PARAMS_DATA_TYPE_SUFFIX,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX
} from 'appConstants';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { processFormItemName } from 'utils/filter';
import HostAutoComplete from '../components/HostAutoComplete';
import { GET_HOSTS } from 'operations/queries/queryHost';
import { useLocation } from 'react-router-dom';

const MCAutoCompleteFilter = () => {
  const [searchHost, { loading }] = useLazyQuery(GET_HOSTS);
  const [hosts, setHosts] = useState([]);
  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const mcFilterName = useMemo(() => {
    return processFormItemName({
      prefix: FILTER_PARAMS_PREFIX,
      name: 'mcRadioHosts',
      operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
      dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.ARR
    });
  }, []);

  const value = useMemo(() => {
    if (Array.isArray(searchParams[mcFilterName])) {
      return searchParams[mcFilterName];
    }

    return [searchParams[mcFilterName]];
  }, [mcFilterName, searchParams]);

  const getHosts = useCallback(async () => {
    try {
      const {
        data: { hosts }
      } = await searchHost({
        variables: {
          where: {
            id: {
              or: value.map(eq => ({ eq }))
            }
          }
        }
      });

      setHosts(hosts?.items);
    } catch (e) {
      throw e;
    }
  }, [searchHost, value]);

  useEffect(() => {
    if (searchParams[mcFilterName]) getHosts();
  }, [getHosts, mcFilterName, searchParams]);

  return (
    <>
      <Form.Item name={mcFilterName} label="MC">
        <HostAutoComplete
          allowClear
          mode="multiple"
          host={hosts}
          loading={loading}
        />
      </Form.Item>
    </>
  );
};

export default MCAutoCompleteFilter;
