import { gql } from '@apollo/client';

export const GET_AUDIT_LOGS = gql`
  query {
    auditLogs {
      items {
        createdBy
        createdDate
        id
        resource
        changedField
        resourceId
      }
    }
  }
`;

export const GET_AUDIT_LOG_BY_ID = gql`
  query ($resourceId: String!) {
    auditLogsByResourceId(resourceId: $resourceId) {
      items {
        id
        resource
        resourceId
        changedField
        createdBy
        createdDate
        creator {
          name
        }
      }
    }
  }
`;
