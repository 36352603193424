import { Button, Col, DatePicker, Form, Input, Radio, Row } from 'antd';
import {
  colProps,
  FILTER_PARAMS_DATA_TYPE_SUFFIX,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX,
  RADIO_GUETS_TYPE_DICTIONARY_INT
} from 'appConstants';
import CategoryAutoCompleteFilter from 'components/CategoryAutoCompleteFilter';
import ChannelSelect from 'components/ChannelSelect';

import FormBase from 'containers/FormBase';
import moment from 'moment';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { processFormItemName } from 'utils/filter';
import { cleanseObj } from 'utils/other';
import EditorAutoCompleteFilter from './EditorAutoCompleteFilter';
import MCAutoCompleteFilter from './MCAutoCompleteFilter';
import RadioStatusSelect from './RadioStatusSelect';
import GuestAutoCompleteFilter from './GuestAutoCompleteFilter';
import UserAutoCompleteFilter from './UserAutoCompleteFilter';

const RadioFilter = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const broadcastTimeFormName = useMemo(
    () =>
      processFormItemName({
        prefix: FILTER_PARAMS_PREFIX,
        name: 'broadcastTime',
        operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.BTW,
        dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.DATE
      }),
    []
  );
  const statusFormName = useMemo(
    () =>
      processFormItemName({
        prefix: FILTER_PARAMS_PREFIX,
        name: 'status',
        operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
        dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.INT
      }),
    []
  );

  const onFilter = useCallback(() => {
    const params = cleanseObj(form.getFieldsValue());

    history.push({
      search: queryString.stringify({
        ...params
      })
    });
  }, [form, history]);

  useEffect(() => {
    const initValue = queryString.parse(history.location.search);

    const broadcastTimes = initValue[broadcastTimeFormName];
    const status = initValue[statusFormName];
    /* RISK */
    // chưa check thứ tự,
    // mặc định khi filter đưa lên params là đứng trước start,
    // sau là end nha
    form.setFieldsValue({
      ...initValue,
      ...(broadcastTimes
        ? {
            [broadcastTimeFormName]: broadcastTimes.map(broadcastTime =>
              moment(broadcastTime)
            )
          }
        : {}),
      ...(status ? { [statusFormName]: status * 1 } : {})
    });
  }, [broadcastTimeFormName, form, history.location.search, statusFormName]);

  return (
    <FormBase form={form} onFinish={onFilter}>
      <Row gutter={8}>
        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'title',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.CONTAINS
            })}
            label="Tên radio"
          >
            <Input placeholder="Nhập tên radio" />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <CategoryAutoCompleteFilter form={form} />
        </Col>

        <Col {...colProps}>
          <Form.Item name={broadcastTimeFormName} label="Ngày phát sóng">
            <DatePicker.RangePicker
              showTime={false}
              style={{ width: '100%' }}
              allowClear
              format="DD/MM/YYYY"
              placeholder={['Chọn ngày bắt đầu', 'Chọn ngày kết thúc']}
            />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <MCAutoCompleteFilter form={form} />
        </Col>

        <Col {...colProps}>
          <EditorAutoCompleteFilter form={form} />
        </Col>

        <Col {...colProps}>
          <Form.Item name={statusFormName} label="Trạng thái">
            <RadioStatusSelect style={{ style: '100%' }} />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'channelId',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ
            })}
            label="Kênh"
          >
            <ChannelSelect style={{ style: '100%' }} />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <UserAutoCompleteFilter form={form} />
        </Col>
        <Col {...colProps}>
          <GuestAutoCompleteFilter form={form} />
        </Col>
      </Row>

      <Row gutter={8} justify="end">
        <Col>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'typeRadioGuests',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
              dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.STRING
            })}
          >
            <Radio.Group defaultValue="ALL">
              {Object.entries(RADIO_GUETS_TYPE_DICTIONARY_INT).map(
                ([key, value]) => (
                  <Radio value={key} key={key}>
                    {value}
                  </Radio>
                )
              )}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col>
          <Button htmlType="submit">Lọc</Button>
        </Col>
      </Row>
    </FormBase>
  );
};

export default RadioFilter;
