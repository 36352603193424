import {
  DeleteOutlined,
  UploadOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { Button, notification, Typography } from 'antd';
import { AUDIO_UPLOAD_SIZE_LIMIT } from 'appConstants';
import Upload from 'components/Upload';
import React, { useCallback, useEffect, useState } from 'react';
import { downloadResource, encodeUrlFile } from 'utils/other';

export const renderLink = (fileUrl, disableLink) => {
  const Typo = disableLink ? Typography.Text : Typography.Link;
  return (
    <Typo
      {...(disableLink ? {} : { href: encodeUrlFile(fileUrl) })}
      ellipsis
      style={{ width: '100%', paddingLeft: 8 }}
      target="_blank"
      // download={true}
    >
      {fileUrl?.split('/')?.pop() || fileUrl}
    </Typo>
  );
};

const VideoListUpload = ({
  size,
  onDone = f => f,
  disabled,
  disableLink,
  downloadBtn,
  onDownloadCallback = f => f,
  ...props
}) => {
  const [fileUrls, setFileUrls] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.files?.length) {
      setFileUrls(props.files);
    }
  }, [onDone, props.files]);

  const onChange = args => {
    if (args.file.status === 'done') {
      setFileUrls(prev => {
        const dayLaLuc = [...prev, args.file.response.Data];
        props.onChange(dayLaLuc);
        return dayLaLuc;
      });
    }
  };

  const beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < AUDIO_UPLOAD_SIZE_LIMIT;
    if (!isLt2M) {
      notification.error({
        message: 'Up file dưới ' + AUDIO_UPLOAD_SIZE_LIMIT + 'mb thôi nha 😡'
      });
    }
    return isLt2M;
  };

  const onRemove = useCallback(
    item => {
      setFileUrls(prevLinks => {
        const dayLaLuc = prevLinks.filter(prevLinks => prevLinks !== item);
        props.onChange(dayLaLuc);

        return dayLaLuc;
      });
    },
    [props]
  );

  const onClick = useCallback(async () => {
    setLoading(true);
    await Promise.all(
      fileUrls.map(fileUrl =>
        downloadResource(encodeUrlFile(fileUrl), fileUrl?.split('/')?.pop())
      )
    );
    onDownloadCallback();
    setLoading(false);
  }, [fileUrls, onDownloadCallback]);

  return (
    <>
      <Upload
        accept=".avi, .mp4"
        showUploadList={false}
        onChange={onChange}
        beforeUpload={beforeUpload}
        multiple
      >
        <Button disabled={disabled} type="default" icon={<UploadOutlined />}>
          Tải lên
        </Button>
      </Upload>

      <div style={{ marginTop: 8 }}>
        {fileUrls.map(fileUrl => (
          <div key={fileUrl} style={{ marginBottom: 8 }}>
            <span style={{ display: 'flex' }}>
              <Button
                size="small"
                onClick={() => onRemove(fileUrl)}
                danger
                icon={<DeleteOutlined style={{ cursor: 'pointer' }} />}
              ></Button>

              {renderLink(fileUrl, disableLink)}
            </span>
          </div>
        ))}

        {downloadBtn && fileUrls.length !== 0 && (
          <div>
            <Button
              icon={<DownloadOutlined />}
              disabled={fileUrls.length === 0}
              onClick={onClick}
              loading={loading}
              type="primary"
            >
              Tải xuống
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default VideoListUpload;
