import { useLazyQuery } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import {
  CHANNEL_BROADCAST_DICTIONARY,
  DATE_FORMAT,
  RADIO_STATUS_TYPE_DICTIONARY_SHORT,
  TIME_FORMAT
} from 'appConstants';
import moment from 'moment';
import { GET_ALL_RADIOS } from 'operations/queries/queryRadio';

import React, { useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

import { DownloadOutlined } from '@ant-design/icons';
import { formatStringForExport } from 'utils/other';

const renderHosts = (hosts, _type) => {
  return hosts
    .filter(({ radioHosts }) => {
      return (radioHosts || []).some(({ type }) => type === _type);
    })
    .map(({ name }, index, arr) => {
      if (index !== arr.length - 1) return name + '; ';
      return name;
    })
    .join('');
};

const ExportRadios = ({ filterQuery }) => {
  const exportRef = useRef();

  const [loading, setLoading] = useState(false);

  const [fetchExportRadios, { data }] = useLazyQuery(GET_ALL_RADIOS, {
    variables: {
      where: filterQuery
    }
  });

  const radios = useMemo(() => data?.allRadios, [data?.allRadios]);

  const headers = [
    { label: 'Kênh phát sóng', key: 'channelId' },
    { label: 'Hình ảnh', key: 'image' },
    { label: 'Chương trình', key: 'category' },
    { label: 'Chủ đề', key: 'title' },
    { label: 'Giới thiệu ngắn', key: 'introduction' },
    { label: 'Trạng thái', key: 'statusName' },
    { label: 'Thời gian phát sóng', key: 'broadcastTime' },
    { label: 'Người khởi tạo', key: 'creator' },
    { label: 'MC', key: 'mc' },
    { label: 'Biên tập viên', key: 'editor' },
    { label: 'Khách mời', key: 'guests' },
    { label: 'Người duyệt', key: 'approver' },
    { label: 'Kịch bản', key: 'scenarioFile' },
    { label: 'Audio', key: 'audioUrl' },
    { label: 'Thời gian - chủ đề module', key: 'summary' },
    { label: 'Lịch phát lại', key: 'radioReplaySchedules' }
  ];

  const radiosCSV = useMemo(() => {
    if (radios) {
      const csvData = radios?.map(radio => ({
        channelId: CHANNEL_BROADCAST_DICTIONARY[radio?.category?.channel],
        category: formatStringForExport(radio?.category?.title),
        title: formatStringForExport(radio?.title),
        image: radio?.audioUrl
          ? process.env.REACT_APP_CLOUD_URL + radio?.image
          : '',
        introduction: formatStringForExport(radio?.introduction),
        statusName: RADIO_STATUS_TYPE_DICTIONARY_SHORT[radio?.statusName],
        broadcastTime:
          radio?.broadcastTime &&
          moment(radio?.broadcastTime).format('DD/MM/YYYY HH:mm'),
        creator: radio?.creator?.name,
        mc: renderHosts(radio?.hosts, 1),
        editor: renderHosts(radio?.hosts, 2),
        guests: radio?.guests
          ?.map(
            (guest, index, arr) =>
              guest?.name + (index === arr.length - 1 ? '' : '; ')
          )
          .join(''),
        approver: radio?.approver?.name,
        scenarioFile: radio?.scenarioFile
          ? process.env.REACT_APP_CLOUD_URL + radio?.scenarioFile
          : '',
        audioUrl: radio?.audioUrl
          ? process.env.REACT_APP_CLOUD_URL + radio?.audioUrl
          : '',
        summary: (radio?.summary || [])
          .map(
            (item, index, arr) =>
              `${item.time} ${item.content}` +
              (index === arr.length - 1 ? '' : '; ')
          )
          .join(''),
        radioReplaySchedules: (radio?.radioReplaySchedules || [])
          .map(
            (item, index, arr) =>
              `${moment(item.timeFrom).format(
                DATE_FORMAT + ' ' + TIME_FORMAT
              )} ${moment(item.timeTo).format(
                DATE_FORMAT + ' ' + TIME_FORMAT
              )}` + (index === arr.length - 1 ? '' : '; ')
          )
          .join('')
      }));

      return csvData;
    }
    return [];
  }, [radios]);

  const onExportRadio = async () => {
    setLoading(true);
    try {
      const data = await fetchExportRadios();
      if (data) {
        exportRef.current.link.click();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Export radio dựa trên bộ lọc">
        <Button
          onClick={onExportRadio}
          loading={loading}
          icon={<DownloadOutlined />}
        >
          Export
        </Button>
      </Tooltip>

      <CSVLink
        data={radiosCSV}
        headers={headers}
        filename="danh-sach-radio-export.csv"
        ref={exportRef}
      />
    </>
  );
};

export default ExportRadios;
