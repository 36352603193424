import CategoryCard from 'components/CategoryCard/CategoryCard';
import React, { useContext, useMemo } from 'react';
import RadioHosts from './components/RadioHosts';
import RadioStatus from './components/RadioStatus';
import { RadioDetailContext } from './RadioDetail';
import RadioNotes from './components/RadioNotes';
import { Col, Row } from 'antd';
import RadioPublishDate from './components/RadioPublishDate';

const RadioDetailRight = () => {
  const { radio } = useContext(RadioDetailContext);
  const categoryDisplay = useMemo(() => radio?.category, [radio?.category]);

  return (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <CategoryCard titleAsLink={true} category={categoryDisplay} />
      </Col>

      <Col span={24}>
        <RadioPublishDate />
      </Col>

      <Col span={24}>
        <RadioHosts />
      </Col>

      <Col span={24}>
        <RadioStatus />
      </Col>

      <Col span={24}>
        <RadioNotes />
      </Col>
    </Row>
  );
};

export default RadioDetailRight;
