import { useReactiveVar } from '@apollo/client';
import { Button, Form, Input, Row } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import AvatarUpload from 'components/AvatarUpload';
import React, { useEffect } from 'react';

const TopicFormContent = ({ form, onSubmit }) => {
  const visibleDrawer = useReactiveVar(visibleDrawerVar);
  const topic = visibleDrawer.record;

  useEffect(() => {
    if (topic) {
      form.setFieldsValue({
        title: topic?.title,
        introduction: topic?.introduction,
        image: topic?.image
      });
    }
    return () => form.resetFields();
  }, [topic, form]);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Row justify="center" align="middle" style={{ marginBottom: 16 }}>
        <Form.Item name="image" valuePropName="file">
          <AvatarUpload size={128} fontSize={64} fullName={topic?.title} />
        </Form.Item>
      </Row>

      <Form.Item
        name="title"
        label="Tên nhóm chương trình"
        rules={[{ required: true, message: 'Nhập tên!' }]}
      >
        <Input placeholder="Nhập họ tên" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: 'Nhập giới thiệu!' }]}
        name="introduction"
        label="Giới thiệu"
      >
        <Input.TextArea placeholder="Nhập giới thiệu" />
      </Form.Item>

      <Form.Item style={{ display: 'none' }}>
        <Button htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  );
};

export default TopicFormContent;
