import { useLazyQuery } from '@apollo/client';
import AutoComplete from 'components/AutoComplete';
import { GET_GUESTS } from 'operations/queries/queryGuest';
import React, { useMemo } from 'react';

const GuestAutoComplete = ({ guest, ...props }) => {
  const [searchGuest] = useLazyQuery(GET_GUESTS);
  const defaultOption = useMemo(
    () =>
      guest
        ? Array.isArray(guest)
          ? guest
              .filter(guest => !!guest)
              .map(({ id, name }) => ({ value: id, label: name }))
          : { value: guest.id, label: guest.name }
        : null,
    [guest]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      handleSearch={searchGuest}
      labelKey="name"
      valueKey="id"
      dataKey="guests"
      placeholder="Tìm khách mời"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default GuestAutoComplete;
