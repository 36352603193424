import { useQuery } from '@apollo/client';
import { Space } from 'antd';
import { ORDER_BY_DICTIONARY, PAGE_SIZE, TABLE_PROPS } from 'appConstants';
import ListCard from 'components/ListCard';
import SortSelect from 'components/SortSelect';
import Table from 'components/Table';
import { GET_NEWS } from 'operations/queries/queryNews';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { processQueryFilerParams } from 'utils/filter';
import { columns } from './columns';

const NewsList = () => {
  const { search } = useLocation();
  const history = useHistory();

  // Parse search params from URL
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(() => searchParams.sort || 'EARLIEST', [searchParams.sort]);
  const filterQuery = useMemo(() => processQueryFilerParams(searchParams), [searchParams]);

  const { loading, error, data, refetch } = useQuery(GET_NEWS, {
    variables: {
      take: PAGE_SIZE,
      skip: PAGE_SIZE * page - PAGE_SIZE,
      order: [ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    }
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const onPaginate = useCallback(
    page => {
      history.push({
        search: queryString.stringify({ ...searchParams, page })
      });
    },
    [history, searchParams]
  );

  const setOrderBy = useCallback(
    sort => {
      history.push({
        search: queryString.stringify({ ...searchParams, page: 1, sort })
      });
    },
    [history, searchParams]
  );

  const news = useMemo(
    () => data?.newsList?.items?.map(item => ({ ...item, key: item.id })),
    [data?.newsList?.items]
  );

  const total = useMemo(() => data?.newsList?.totalCount, [data?.newsList?.totalCount]);

  if (error) return <div>Oops, đã có vấn đề xảy ra 😅😅😅</div>;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          <SortSelect value={sort} onChange={setOrderBy} />
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        scroll={{ x: 1200 }}
        columns={columns}
        dataSource={news}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: Number(page),
          quantity: news?.length
        }}
        loading={loading}
        bordered
      />
    </ListCard>
  );
};

export default NewsList;
