import { Menu, Typography } from 'antd';
import UserAvatar from 'components/UserAvatar';
import { useCurrentUser } from 'operations/queries/queryCurrentUser';
import React from 'react';
import { Link } from 'react-router-dom';

const UserInfoMenuItem = ({ closeDropdown }) => {
  const { data, loading } = useCurrentUser();
  if (loading) return <Typography.Text>loading</Typography.Text>;
  const { name, image } = data.detail;

  return (
    <Menu.Item className="user-info-menu-item" key="info">
      <Link to="/ca-nhan" onClick={closeDropdown}>
        <div className="user-info-menu-item__inner">
          <UserAvatar fullName={name} avatar={image} size={42} />
          <div className="user-info-menu-item__text">
            <Typography.Text strong>{name}</Typography.Text>
            <Typography.Text>Xem trang cá nhân</Typography.Text>
          </div>
        </div>
      </Link>
    </Menu.Item>
  );
};

export default UserInfoMenuItem;
