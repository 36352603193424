import { createContext, useState } from 'react';

export const UserContext = createContext();

const UserProvider = props => {
  const [auditLogTarget, setAuditLogTarget] = useState();
  const [changePasswordTarget, setChangePasswordTarget] = useState();

  return (
    <UserContext.Provider
      value={{
        auditLogTarget,
        setAuditLogTarget,
        changePasswordTarget,
        setChangePasswordTarget
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
