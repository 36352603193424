import { Switch } from 'antd';
import { CATEGORY_STATUS_KEY } from 'appConstants';

import React from 'react';

const CategoryStatusButton = ({ record, onClick = f => f }) => {
  return (
    <Switch
      checked={record?.statusName === CATEGORY_STATUS_KEY.ACTIVE}
      onChange={onClick}
      checkedChildren="Hoạt động"
      unCheckedChildren="Chưa hoạt động"
      defaultChecked
      style={{ width: 130 }}
    />
  );
};

export default CategoryStatusButton;
