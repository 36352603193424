import { useQuery } from '@apollo/client';
import { Card, Col, Row, Typography } from 'antd';
import moment from 'moment';
import { GET_RADIO_BY_ID } from 'operations/queries/queryRadio';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import RadioReplayFormInline from './RadioReplayFormInline';
import ReplaySchduleSuggestions from './ReplaySchduleSuggestions';

const RadioReplaySchedules = () => {
  const { id } = useParams();
  const { data } = useQuery(GET_RADIO_BY_ID, {
    variables: { id },
    skip: !id
  });

  const radio = useMemo(() => data?.radio || {}, [data?.radio]);

  const schedules = useMemo(() => {
    let _schedules = [...(radio?.radioReplaySchedules || [])];

    _schedules.sort((a, b) => {
      if (moment(a.timeFrom).isBefore(b.timeFrom)) {
        return -1;
      }

      if (moment(b.timeFrom).isBefore(a.timeFrom)) {
        return 1;
      }

      return 0;
    });

    return _schedules;
  }, [radio?.radioReplaySchedules]);

  const renderDummyHeader = useCallback(() => {
    return (
      <Row gutter={16}>
        <Col span={7}>
          <Typography.Paragraph strong>Ngày phát lại</Typography.Paragraph>
        </Col>

        <Col span={7}>
          <Typography.Paragraph strong>Giờ phát sóng</Typography.Paragraph>
        </Col>

        <Col span={7}>
          <Typography.Paragraph strong>Giờ kết thúc</Typography.Paragraph>
        </Col>

        <Col span={3}></Col>
      </Row>
    );
  }, []);

  return (
    <Card title="Lịch phát lại" style={{ marginTop: 8 }}>
      <ReplaySchduleSuggestions radio={radio} />
      {renderDummyHeader()}

      {schedules.map(schedule => (
        <RadioReplayFormInline
          key={schedule?.id}
          schedule={schedule}
          radio={radio}
        />
      ))}

      <RadioReplayFormInline radio={radio} />
    </Card>
  );
};

export default RadioReplaySchedules;
