import { Button, Col, DatePicker, Form, Input, Row, Select, Space } from 'antd';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';
import React, { useCallback, useEffect } from 'react';
import {
  PlusCircleOutlined,
  SaveOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from 'appConstants';
import {
  useAddRadioReplaySchedule,
  useRemoveRadioReplaySchedule,
  useUpdateRadioReplaySchedule
} from 'operations/mutations/radio/mutateRadio';
import { useMemo } from 'react';

export const applyTimeToDate = ({
  scheduleDate,
  hour = 0,
  minute = 0,
  second = 0
}) => {
  return moment(scheduleDate)
    .clone()
    .set('hour', hour)
    .set('minute', minute)
    .set('second', second);
};

export const processTimeParams = ({ scheduleDate, startTime, duration }) => {
  const _startTime = moment(startTime, TIME_FORMAT);

  const timeFrom = applyTimeToDate({
    scheduleDate,
    hour: _startTime.get('hour'),
    minute: _startTime.get('minute')
  });

  const timeTo = timeFrom.clone().add(duration, 'minutes');

  return { timeFrom, timeTo };
};

const RadioReplayFormInline = ({
  suggestion,
  radio,
  schedule,
  onDoneAdd = f => f
}) => {
  const [form] = Form.useForm();
  const { mutate: handleAddRadioReplaySchedule } = useAddRadioReplaySchedule();
  const { mutate: handleUpdateRadioReplaySchedule } =
    useUpdateRadioReplaySchedule();
  const { mutate: handleRemoveRadioReplaySchedule } =
    useRemoveRadioReplaySchedule();

  const timeOptions = useMemo(() => {
    if (radio?.category) {
      const { schedules } = radio?.category;
      const listTime = [
        ...new Set(
          schedules.map(schedule => {
            return moment(schedule.timeFrom).format('HH:mm');
          })
        )
      ];
      const options = listTime.map(time => ({ value: time }));
      return options;
    }
    return [];
  }, [radio?.category]);

  const dayOfWeekAvailable = useMemo(() => {
    if (radio?.category) {
      const { schedules } = radio?.category;
      const listDayOfWeek = [
        ...new Set(schedules.map(schedule => schedule.dayOfWeek))
      ];
      return listDayOfWeek;
    }
    return [];
  }, [radio?.category]);

  const disabledDate = useCallback(
    (current, radioDate) => {
      return (
        moment(radioDate).isAfter(current, 'day') ||
        !dayOfWeekAvailable.includes(moment(current).day()) ||
        moment(radio?.category?.onAirTo).isBefore(current, 'day')
      );
    },
    [dayOfWeekAvailable, radio?.category?.onAirTo]
  );

  const onAdd = useCallback(async () => {
    try {
      const { scheduleDate, startTime } = await form.validateFields();
      const params = processTimeParams({
        scheduleDate,
        startTime,
        duration: radio?.duration || 0
      });

      await handleAddRadioReplaySchedule({
        variables: {
          input: {
            ...params,
            timeTo: moment(params.timeTo).set({
              seconds: 0,
              milliseconds: 0
            }),
            timeFrom: moment(params.timeFrom).set({
              seconds: 0,
              milliseconds: 0
            }),
            radioId: radio.id
          }
        }
      });
      if (suggestion) {
        form.setFieldsValue({
          scheduleDate: moment(schedule.timeFrom),
          startTime: moment(schedule.timeFrom).format(TIME_FORMAT),
          endTime: moment(schedule.timeTo).format(TIME_FORMAT)
        });

        onDoneAdd();
      } else {
        form.resetFields();
      }
    } catch (e) {}
  }, [
    form,
    handleAddRadioReplaySchedule,
    onDoneAdd,
    radio?.duration,
    radio.id,
    schedule?.timeFrom,
    schedule?.timeTo,
    suggestion
  ]);

  const onSave = useCallback(async () => {
    try {
      const { scheduleDate, startTime, endTime } = await form.validateFields();
      const params = processTimeParams({
        scheduleDate,
        startTime,
        endTime,
        duration: radio?.duration || 0
      });

      await handleUpdateRadioReplaySchedule({
        variables: {
          input: {
            ...params,
            timeTo: moment(params.timeTo).set({
              seconds: 0,
              milliseconds: 0
            }),
            timeFrom: moment(params.timeFrom).set({
              seconds: 0,
              milliseconds: 0
            }),
            radioId: radio.id,
            id: schedule?.id
          }
        }
      });
    } catch (e) {}
  }, [
    form,
    handleUpdateRadioReplaySchedule,
    radio?.duration,
    radio.id,
    schedule?.id
  ]);

  const onDelete = useCallback(async () => {
    try {
      handleRemoveRadioReplaySchedule({ variables: { id: schedule?.id } });
    } catch (e) {}
  }, [handleRemoveRadioReplaySchedule, schedule?.id]);

  const renderBtns = useCallback(() => {
    if (!schedule || suggestion) {
      return (
        <Button
          onClick={onAdd}
          type="primary"
          icon={<PlusCircleOutlined />}
        ></Button>
      );
    }

    return (
      <Space>
        <Button
          onClick={onSave}
          type="primary"
          icon={<SaveOutlined />}
        ></Button>

        <DeleteConfirmPopup onDelete={onDelete} title="Xóa lịch phát lại?">
          <Button type="primary" danger icon={<DeleteOutlined />}></Button>
        </DeleteConfirmPopup>
      </Space>
    );
  }, [onAdd, onDelete, onSave, schedule, suggestion]);

  const onTimeFromSelect = useCallback(
    v => {
      const startTime = moment(v, TIME_FORMAT);
      const endTime = startTime
        .add(radio?.duration, 'minutes')
        .format(TIME_FORMAT);

      form.setFieldsValue({ endTime });
    },
    [form, radio?.duration]
  );

  useEffect(() => {
    if (schedule) {
      form.setFieldsValue({
        scheduleDate: moment(schedule.timeFrom),
        startTime: moment(schedule.timeFrom).format(TIME_FORMAT),
        endTime: moment(schedule.timeTo).format(TIME_FORMAT)
      });
    }
  }, [form, schedule]);

  const validator = ({
    startTime,
    scheduleDate,
    broadcastTime,
    reset = f => f
  }) => {
    if (startTime && moment(scheduleDate).isSame(broadcastTime, 'dates')) {
      if (
        broadcastTime.isSameOrAfter(
          broadcastTime.clone().set({
            hours: startTime.split(':')[0],
            minutes: startTime.split(':')[1]
          }),
          'minutes'
        )
      ) {
        return Promise.reject(
          'Lịch phát lại nằm trước hoặc trùng lịch phát chính!'
        );
      }
    }

    reset();
    return Promise.resolve();
  };

  return (
    <Form form={form}>
      <Row gutter={16}>
        <Col span={7}>
          <Form.Item
            name="scheduleDate"
            rules={[
              { required: true, message: 'Chọn ngày!' },
              ({ getFieldValue, setFields }) => ({
                validator: (_, value) => {
                  const broadcastTime = moment(radio.broadcastTime);
                  const startTime = getFieldValue('startTime');
                  const scheduleDate = value;

                  return validator({
                    startTime,
                    broadcastTime,
                    scheduleDate,
                    setFields,
                    reset: () => setFields([{ name: 'startTime', errors: [] }])
                  });
                }
              })
            ]}
          >
            <DatePicker
              format={DATE_FORMAT}
              disabledDate={c => disabledDate(c, radio.broadcastTime)}
              placeholder="Chọn ngày"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item
            name="startTime"
            rules={[
              { required: true, message: 'Chọn thời gian!' },
              ({ getFieldValue, setFields }) => ({
                validator: (_, value) => {
                  const scheduleDate = getFieldValue('scheduleDate');
                  const broadcastTime = moment(radio.broadcastTime);
                  const startTime = value;

                  return validator({
                    startTime,
                    broadcastTime,
                    scheduleDate,
                    setFields,
                    reset: () =>
                      setFields([{ name: 'scheduleDate', errors: [] }])
                  });
                }
              })
            ]}
          >
            <Select
              onSelect={onTimeFromSelect}
              style={{ width: '100%' }}
              placeholder="Chọn giờ"
              options={timeOptions}
            />
          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item name="endTime">
            <Input disabled style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={3}>{renderBtns()}</Col>
      </Row>
    </Form>
  );
};

export default RadioReplayFormInline;
