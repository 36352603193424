import { useQuery } from '@apollo/client';
import { RADIO_STATUS_TYPE_KEY } from 'appConstants';
import {
  GET_DASHBOARD_RADIO,
  GET_NOTI_DASHBOARD
} from 'operations/queries/queryDashboard';
import { useMemo } from 'react';

const useDashboard = () => {
  const { data, loading } = useQuery(GET_NOTI_DASHBOARD);

  const { data: draftRadioData, loading: draftRadioLoading } = useQuery(
    GET_DASHBOARD_RADIO,
    {
      variables: {
        status: RADIO_STATUS_TYPE_KEY.DRAFT
      }
    }
  );
  const { data: waitingRadioData, loading: waitingRadioLoading } = useQuery(
    GET_DASHBOARD_RADIO,
    {
      variables: {
        status: RADIO_STATUS_TYPE_KEY.WAITING
      }
    }
  );
  const { data: approvedRadioData, loading: approvedRadioLoading } = useQuery(
    GET_DASHBOARD_RADIO,
    {
      variables: {
        status: RADIO_STATUS_TYPE_KEY.APPROVED
      }
    }
  );
  const { data: readyRadioData, loading: readyRadioLoading } = useQuery(
    GET_DASHBOARD_RADIO,
    {
      variables: {
        status: RADIO_STATUS_TYPE_KEY.READY
      }
    }
  );

  const _data = useMemo(() => {
    if (
      data?.notificationsDashboard &&
      data?.statisticsDashboard &&
      draftRadioData?.allRadioStatisticDashboard &&
      waitingRadioData?.allRadioStatisticDashboard &&
      approvedRadioData?.allRadioStatisticDashboard &&
      readyRadioData?.allRadioStatisticDashboard
    )
      return {
        notificationsDashboard: data?.notificationsDashboard,
        statisticsDashboard: data?.statisticsDashboard,
        draftRadioDashboard: draftRadioData?.allRadioStatisticDashboard,
        waitingRadioDashboard: waitingRadioData?.allRadioStatisticDashboard,
        approvedRadioDashboard: approvedRadioData?.allRadioStatisticDashboard,
        readyRadioDashboard: readyRadioData?.allRadioStatisticDashboard
      };

    return {
      notificationsDashboard: [],
      statisticsDashboard: [],
      draftRadioDashboard: [],
      waitingRadioDashboard: [],
      approvedRadioDashboard: [],
      readyRadioDashboard: []
    };
  }, [
    approvedRadioData?.allRadioStatisticDashboard,
    data?.notificationsDashboard,
    data?.statisticsDashboard,
    draftRadioData?.allRadioStatisticDashboard,
    readyRadioData?.allRadioStatisticDashboard,
    waitingRadioData?.allRadioStatisticDashboard
  ]);

  const _loading = useMemo(() => {
    if (
      loading ||
      draftRadioLoading ||
      waitingRadioLoading ||
      approvedRadioLoading ||
      readyRadioLoading
    ) {
      return true;
    }

    return false;
  }, [
    approvedRadioLoading,
    draftRadioLoading,
    loading,
    readyRadioLoading,
    waitingRadioLoading
  ]);

  return {
    loading: _loading,
    data: _data
  };
};

export default useDashboard;
