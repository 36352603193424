import { Button } from 'antd';
import { STATUS_ACTION_TIMEOUT, USER_STATUS_KEY } from 'appConstants';
import Upgrade from 'components/Icons/Upgrade';
import UserStatusTag from 'components/UserStatusTag';
import React, { useCallback, useRef, useState } from 'react';

const UserStatusButton = ({ record, onClick }) => {
  const { statusName, status, id, name, userTypeName } = record;
  const [actionVisible, setActionVisible] = useState(false);
  const timerRef = useRef();

  const buttonStyle = useCallback(
    (downStatus = false) => ({
      style: {
        padding: '0 7px',
        height: 'auto'
      },
      icon: downStatus ? <Upgrade rotate={180} /> : <Upgrade />,
      type: 'primary',
      danger: downStatus
    }),
    []
  );

  const onMouseEnter = () => {
    timerRef.current = setTimeout(() => {
      setActionVisible(true);
    }, STATUS_ACTION_TIMEOUT);
  };

  const onMouseLeave = () => {
    setActionVisible(false);
    clearTimeout(timerRef.current);
  };

  const renderButtonAction = useCallback(() => {
    switch (statusName) {
      case USER_STATUS_KEY.WAITING:
        return (
          <Button
            {...buttonStyle()}
            onClick={() =>
              onClick({
                variables: {
                  input: {
                    id,
                    name,
                    userType: userTypeName,
                    channels: [],
                    status: USER_STATUS_KEY.ACTIVATED
                  }
                }
              })
            }
          >
            Kích hoạt
          </Button>
        );
      case USER_STATUS_KEY.BANNED:
        return (
          <Button
            {...buttonStyle()}
            onClick={() =>
              onClick({
                variables: {
                  input: {
                    id,
                    name,
                    userType: userTypeName,
                    channels: [],
                    status: USER_STATUS_KEY.ACTIVATED
                  }
                }
              })
            }
          >
            Mở khoá
          </Button>
        );
      case USER_STATUS_KEY.ACTIVATED:
        return (
          <Button
            {...buttonStyle(true)}
            onClick={() =>
              onClick({
                variables: {
                  input: {
                    id,
                    name,
                    userType: userTypeName,
                    channels: [],
                    status: USER_STATUS_KEY.BANNED
                  }
                }
              })
            }
          >
            Khoá
          </Button>
        );

      default:
        break;
    }
  }, [statusName, buttonStyle, onClick, id, name, userTypeName]);

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ width: 'fit-content' }}
    >
      {actionVisible ? (
        renderButtonAction()
      ) : (
        <UserStatusTag status={status} style={{ cursor: 'pointer' }} />
      )}
    </div>
  );
};

export default UserStatusButton;
