import { LogoutOutlined } from '@ant-design/icons';
import { Menu, Typography } from 'antd';
import { useLogout } from 'operations/mutations/auth/mutateAuth';
import { useCurrentUser } from 'operations/queries/queryCurrentUser';
import React from 'react';
import { getAccessToken } from 'utils/authority';
import UserInfoMenuItem from './UserInfoMenuItem';

const UserDropdownContent = ({ closeDropdown }) => {
  const { mutate: logout, loading: loggingOut } = useLogout();
  const { client, loading, error } = useCurrentUser();
  const token = getAccessToken();
  if (loading) return <Typography.Text>loading</Typography.Text>;
  if (error) return null;
  return (
    <Menu selectable={false}>
      <UserInfoMenuItem closeDropdown={closeDropdown} />
      <Menu.Item
        disabled={loggingOut}
        key="logout"
        icon={<LogoutOutlined />}
        onClick={() =>
          logout({ variables: { input: { token } } }).then(() =>
            client.resetStore()
          )
        }
      >
        Đăng xuất
      </Menu.Item>
    </Menu>
  );
};

export default UserDropdownContent;
