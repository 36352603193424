import { gql } from '@apollo/client';

export const _news = `
  id
  name
  createdBy
  createdDate
  updatedBy
  updatedDate
  status
  docFile
  audioFiles
  videoFiles
  imageFiles
  statusName
  type
  typeName
  content
  creator {
    id
    name
  }
  updater {
    id
    name
  }
  approver {
    id
    name
  }
  isDeleted
  approvedBy
`;

export const GET_NEWS = gql`
  query NewsList(
    $skip: Int
    $where: NewsFilterInput
    $take: Int
    $order: [NewsSortInput!]
  ) {
    newsList(skip: $skip, where: $where, take: $take, order: $order) {
      items {
        ${_news}
        newsProcesses {
          processType
          id
          newsId
          newState
          oldState
          createdBy
          createdDate
          note
          createdName
        }
      }

      totalCount
    }
  }
`;
export const GET_NEWSLEAT = gql`
  query countNewsLeat{
    countNewsLeat
  }
`;


export const GET_NEWS_BY_ID = gql`
  query Query($id: String!) {
    newsDetail(id: $id) {
      ${_news}
      newsProcesses {
        processType
        id
        newsId
        newState
        oldState
        createdBy
        createdDate
        note
      }
    }
  }
`;
