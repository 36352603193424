import { Col, Row, Typography } from 'antd';

import React, { useContext } from 'react';

import { BulbOutlined, TeamOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import Podcast from 'components/Icons/Podcast';
import { DashboardContext } from '../Dashboard';

const _ = {
  countCategory: {
    name: 'Chương trình',
    icon: BulbOutlined,
    cn: 'category-count'
  },
  countRadio: { name: 'Số radio', icon: Podcast, cn: 'radio-count' },
  countUser: { name: 'Người dùng', icon: TeamOutlined, cn: 'user-count' }
};

const Statistic = () => {
  const { statisticsDashboard } = useContext(DashboardContext);

  return (
    <Row gutter={8}>
      {Object.entries(statisticsDashboard).map(([key, value], index) => {
        const name = _[key]?.name;
        const Icon = _[key]?.icon;
        const cn = _[key]?.cn;

        return name ? (
          <Col span={8} key={index}>
            <div
              className={classNames('dashboard-statistic', cn)}
              style={{
                borderRadius: 4,
                padding: '8px 16px'
              }}
            >
              <Row>
                <div style={{ flexGrow: 1, display: 'flex' }}>
                  <Icon
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: 4,
                      fontSize: '32px',
                      width: 32,
                      height: 32,
                      padding: 16,
                      boxSizing: 'content-box',
                      margin: 'auto 0'
                    }}
                  />
                </div>

                <div>
                  <Typography.Text strong>{name}</Typography.Text>

                  <Typography.Title
                    ellipsis
                    style={{ marginTop: 8, marginBottom: 0 }}
                    level={2}
                  >
                    {value}
                  </Typography.Title>
                </div>
              </Row>
            </div>
          </Col>
        ) : (
          <></>
        );
      })}
    </Row>
  );
};

export default Statistic;
