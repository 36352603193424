import { Space, Tag, Typography } from 'antd';
import ColName from 'components/ColName';
import TimeLine from 'components/TimeLine';
import UserAvatar from 'components/UserAvatar';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';

export const RadioImage = (image, record) => {
  return (
    <div style={{ position: 'relative', width: 'fit-content' }}>
      <UserAvatar
        fullName={record.title}
        avatar={image}
        shape="square"
        style={{ width: '114px' }}
      />
    </div>
  );
};

export const Channel = ({ channelId, channelTitle, channelColor, style }) => {
  return (
    <Tag style={style} color={channelColor}>
      {channelTitle}
    </Tag>
  );
};
export const columns = [
  {
    dataIndex: 'image',
    key: 'image',
    render: RadioImage,
    fixed: 'left',
    width: '150px',
    align: 'center'
  },
  {
    title: 'Spotlight',
    dataIndex: 'content',
    key: 'content',
    render: (_, record) => (
      <div>
        <ColName
          primary={
            <Link
              style={{ color: 'inherit' }}
              to={`/chi-tiet-radio/${
                record?.radioId
              }?t=${record?.time.replaceAll(':', '_')}`}
              target="_blank"
            >
              {record?.content} {<EditOutlined />}
            </Link>
          }
          secondary={
            <Tag
              style={{
                color: '#cf1322',
                background: '#fff1f0',
                borderColor: '#ffa39e'
              }}
              title={'Nghe trên trang'}
            >
              <a href={record?.radioSlug} target="_blank" rel="noreferrer">
                {record?.time}
              </a>
            </Tag>
          }
        />
        <Channel
          channelId={record?.channelId}
          channelColor={record?.channelColor}
          channelTitle={record?.channelTitle}
        />
      </div>
    ),
    width: '350px'
  },
  {
    title: 'Radio chi tiết',
    dataIndex: 'title',
    key: 'title',
    render: (_, record) => (
      <Space direction="vertical">
        <Typography.Text>{record?.title}</Typography.Text>
      </Space>
    ),
    width: '200px'
  },
  {
    title: 'Chương trình',
    dataIndex: 'category',
    key: 'category',
    render: (_, record) => (
      <Space direction="vertical">
        <Typography.Text>{record?.categoryTitle}</Typography.Text>
      </Space>
    ),
    width: '200px'
  },
  {
    title: 'Nhóm chương trình',
    dataIndex: 'topic',
    key: 'topic',
    render: (_, record) => (
      <Space direction="vertical">
        <Typography.Text>{record?.topicTitle}</Typography.Text>
      </Space>
    ),
    width: '150px'
  },
  {
    title: 'Host',
    dataIndex: 'hosts',
    render: (_, record) => (
      <Space direction="vertical">
        {record?.hostList?.map((host, index) => (
          <Typography.Text key={index}>{host?.name}</Typography.Text>
        ))}
      </Space>
    ),
    align: 'left',
    width: '200px'
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '220px',
    render: (_, { radioCreatedDate, radioUpdatedDate, publishedDate }) => (
      <TimeLine
        createdDate={radioCreatedDate}
        updatedDate={radioUpdatedDate}
        publicTime={publishedDate}
      />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'related',
    width: '180px',
    render: (
      _,
      {
        radioCreatedByUserName,
        radioUpdatedByUserName,
        radioPublishedByUserName
      }
    ) => (
      <Space direction="vertical">
        <Typography.Text>
          <Typography.Text strong>Khởi tạo: </Typography.Text>
          {radioCreatedByUserName}
        </Typography.Text>

        <Typography.Text>
          <Typography.Text strong>Cập nhật: </Typography.Text>
          {radioUpdatedByUserName}
        </Typography.Text>

        {radioPublishedByUserName && (
          <Typography.Text>
            <Typography.Text strong>Xuất bản: </Typography.Text>
            {radioPublishedByUserName}
          </Typography.Text>
        )}
      </Space>
    )
  }
];
