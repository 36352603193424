import { createContext, useState } from 'react';

export const NewsWebContext = createContext();

const NewsWebProvider = props => {
  const [paramFilter, setParamFilter] = useState();

  return (
    <NewsWebContext.Provider value={{ paramFilter, setParamFilter }}>
      {props.children}
    </NewsWebContext.Provider>
  );
};

export default NewsWebProvider;
