import { Button, Space, Tag } from 'antd';
import {
  STATUS_ACTION_TIMEOUT,
  STATUS_VALUE,
  TRENDTOPIC_STATUS_COLOR,
  TRENDTOPIC_STATUS
} from 'appConstants';
import Upgrade from 'components/Icons/Upgrade';
import React, { useCallback, useRef, useState } from 'react';

const StatusButton = ({ record, onClick = f => f }) => {
  const { usedStatus } = record;

  return (
    <div
      style={{ width: 'fit-content' }}
    >
        <Tag style={{ cursor: 'pointer' }} color={TRENDTOPIC_STATUS_COLOR[usedStatus]}>
          {TRENDTOPIC_STATUS[usedStatus]}
        </Tag>
    </div>
  );
};

export default StatusButton;
