import { useReactiveVar } from '@apollo/client';
import { Form, Input, Radio } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import {
  AUDIO_UPLOAD_SIZE_LIMIT,
  NEWS_TYPE_DICTIONARY,
  SCRIPT_UPLOAD_SIZE_LIMIT
} from 'appConstants';
import DocumentUpload from 'components/DocumentUpload';
import Tiptap from 'components/Tiptap';
import React, { useEffect, useMemo } from 'react';
import AudioListUpload from '../AudioListUpload';
import NewStatusSelect from '../NewStatusSelect';

const NewsFormContent = ({ form, isCreating }) => {
  const visibleDrawer = useReactiveVar(visibleDrawerVar);
  const newsDetail = useMemo(
    () => visibleDrawer.record,
    [visibleDrawer.record]
  );

  useEffect(() => {
    if (!isCreating) {
      form.setFieldsValue({
        name: newsDetail?.name,
        audioFiles: newsDetail?.audioFiles,
        docFile: newsDetail?.docFile,
        status: newsDetail?.statusName,
        type: newsDetail?.typeName
      });
    }
  }, [
    form,
    isCreating,
    newsDetail?.audioFiles,
    newsDetail?.docFile,
    newsDetail?.name,
    newsDetail?.statusName,
    newsDetail?.typeName
  ]);
  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="type"
        label="Loại"
        rules={[{ required: true, message: 'Chọn loại!' }]}
      >
        <Radio.Group>
          {Object.entries(NEWS_TYPE_DICTIONARY).map(([key, value]) => (
            <Radio value={key} key={key}>
              {value}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="name"
        label="Tiêu đề"
        rules={[{ required: true, message: 'Nhập Tiêu đề!' }]}
      >
        <Input placeholder="Nhập Tiêu đề" />
      </Form.Item>

      {!isCreating && (
        <Form.Item name="status" label="Trạng thái">
          <NewStatusSelect />
        </Form.Item>
      )}
      <Form.Item
        name="audioFiles"
        valuePropName="files"
        label={`File âm thanh (Định dang .mp3, .wav. Dung lượng tối đa ${AUDIO_UPLOAD_SIZE_LIMIT} MB)`}
        rules={[{ required: true, message: 'Chọn file âm thanh!' }]}
      >
        <AudioListUpload form={form} />
      </Form.Item>

      <Form.Item
        valuePropName="file"
        name="docFile"
        label={`File doc (.doc, .docx. Dung lượng tối đa ${SCRIPT_UPLOAD_SIZE_LIMIT} MB )`}
        rules={[{ required: true, message: 'Chọn file doc!' }]}
      >
        <DocumentUpload message="Đã cập nhật file doc" size={64} />
      </Form.Item>
      <Tiptap />
    </Form>
  );
};

export default NewsFormContent;
