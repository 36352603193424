import { useLazyQuery } from '@apollo/client';
import AutoComplete from 'components/AutoComplete';
import { GET_CATEGORIES } from 'operations/queries/queryCategory';
import { useMemo } from 'react';

const CategoryAutoComplete = ({ category, ...props }) => {
  const [searchCategory] = useLazyQuery(GET_CATEGORIES);
  const defaultOption = useMemo(
    () =>
      category
        ? Array.isArray(category)
          ? category.map(({ id, title, channel, duration }) => ({
              value: id,
              label: `${channel} - ${title} - ${duration} phút`
            }))
          : {
              value: category.id,
              label: `${category?.channel} - ${category?.title} - ${category?.duration} phút`
            }
        : null,
    [category]
  );

  const generateCategoryLabel = category => {
    const { channel, title, duration } = category;
    return `${channel} - ${title} - ${duration} phút`;
  };

  return (
    <AutoComplete
      defaultOption={defaultOption}
      handleSearch={searchCategory}
      labelKey="title"
      valueKey="id"
      dataKey="categories"
      placeholder="Tìm chương trình"
      style={{ width: '100%' }}
      getLabel={generateCategoryLabel}
      {...props}
    />
  );
};

export default CategoryAutoComplete;
