import React, { useEffect, useState } from 'react';
import { OUTSIDE_VOH_API } from 'appConstants';
import axios from 'axios';
import './style.css';

const GeneralNotify = props => {
  const [notifyContent, setNotifyContent] = useState();

  useEffect(() => {
    setInterval(() => {
      fetchTags();
    }, 30000);
  }, []);

  const fetchTags = async () => {
    try {
      console.log('process.env', process.env);
      const response = await axios.get(
        `${OUTSIDE_VOH_API}/api/GeneralNotification/GetAvailableNotify`
      );

      const results = response.data;

      if (!results || !results.messages) {
        return;
      }

      if (results.messages.length > 0) {
        setNotifyContent(results.messages[0]);
      }
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <>
      {notifyContent && notifyContent != '0' && (
        <div className="box">
          <div className="container">
            <div className="header">Thông báo hệ thống</div>
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: notifyContent
              }}
            ></div>
          </div>
        </div>
      )}
    </>
  );
};

export default GeneralNotify;
