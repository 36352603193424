import { TABLE_PROPS, OUTSIDE_VOH_API } from 'appConstants';
import { Divider, Typography } from 'antd';
import axios from 'axios';
import Table from 'components/Table';

import { useEffect, useState } from 'react';
import { columns } from './columns';



const TopTrendKeyWord = () => {
    const [loading, setLoading] = useState(false)
    const [keyWords, setKeyWords] = useState([])

    useEffect(async () => {
        fetchData();
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchData();
        }, 600000);

        return () => clearInterval(interval);
    }, [])

    const fetchData = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`${OUTSIDE_VOH_API}/api/Others/GetTopTrendKeyWord`);
            setKeyWords(response.data)
            setLoading(false)
        } catch (error) {
            // Xử lý lỗi nếu có
            console.error('Error:', error);
            setLoading(false)
        }
    }

    return (
        <>
            <Divider orientation="left" plain >
                <Typography.Title level={4} >
                    Top 10 KeyWord Trending
                </Typography.Title>
            </Divider>
            <Table
                {...TABLE_PROPS}
                scroll={{ x: 600 }}
                columns={columns}
                dataSource={keyWords}
                loading={loading}
                bordered
            />
        </>
    );
};

export default TopTrendKeyWord;
