import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

const Unauthorized = () => {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Xin lỗi, Bạn không được quyền truy cập trang này."
      extra={
        <Button type="primary">
          <Link to="/">Trang chủ</Link>
        </Button>
      }
    />
  );
};

export default Unauthorized;
