import { useReactiveVar } from '@apollo/client';
import { Form } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import UpdateCreateModal from 'containers/UpdateCreateModal';
import {
  useAddTopic,
  useEditTopic
} from 'operations/mutations/topic/mutateTopic';
import React from 'react';
import { setError } from 'utils/other';
import TopicFormContent from './TopicFormContent';

const TopicForm = () => {
  const { mutate: editTopic } = useEditTopic();
  const visibleDrawer = useReactiveVar(visibleDrawerVar);

  const { mutate: createTopic } = useAddTopic();

  const [form] = Form.useForm();

  const onSave = async () => {
    try {
      const params = await form.validateFields();

      const _image = params.image;
      const image = _image?.file?.response?.Data || _image;

      await editTopic({
        variables: {
          input: {
            ...params,
            id: visibleDrawer.record.id,
            image
          }
        }
      });

      visibleDrawerVar({ visible: false, record: null });
    } catch (e) {
      setError(form, e);
    }
  };

  const onCreate = async () => {
    try {
      const params = await form.validateFields();

      const _image = params.image;
      const image = _image?.file?.response?.Data || _image;
      await createTopic({
        variables: {
          input: { ...params, image }
        }
      });
      form.resetFields();
      visibleDrawerVar(false);
    } catch (error) {}
  };

  return (
    <UpdateCreateModal
      title={
        visibleDrawer.record
          ? 'Chỉnh sửa nhóm chương trình'
          : 'Tạo nhóm chương trình'
      }
      onOk={visibleDrawer.record ? onSave : onCreate}
      onClose={() => {
        form.resetFields();
        visibleDrawerVar(false);
      }}
      visible={visibleDrawer.visible}
      isUpdate={!!visibleDrawer.record}
      destroyOnClose
    >
      <TopicFormContent
        form={form}
        onSubmit={visibleDrawer.record ? onSave : onCreate}
      />
    </UpdateCreateModal>
  );
};

export default TopicForm;
