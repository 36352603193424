import { Select, Tag } from 'antd';
import { DEBOUNCE_VALUE, OUTSIDE_VOH_API } from 'appConstants';
import React, { useEffect, useState } from 'react';
import { debounce } from 'utils/lodash';
import axios from 'axios';

const TagsAutoCompleteCustom = ({
  defaultOption,
  labelKey,
  valueKey,
  dataKey,
  prefixDataKey,
  extraParams,
  getLabel,
  value,
  disabled,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const val =
    value?.filter(
      x =>
        typeof x === 'number' ||
        (typeof x === 'string' && !x.includes('isnew_'))
    ) ?? [];

  useEffect(() => {
    if (Array.isArray(defaultOption)) {
      defaultOption && setOptions(defaultOption);
    } else {
      defaultOption && setOptions([defaultOption]);
    }
  }, [defaultOption]);

  const onSearch = async searchText => {
    if (!searchText) {
      return;
    }
    try {
      const response = await axios.get(
        `${OUTSIDE_VOH_API}/api/Others/TagsSearch?q=${searchText}`
      );
      const results = response.data;

      if (!results || !results.data) {
        return;
      }
      if (results.data.length === 0) {
        setOptions([
          { label: searchText, value: 'isnew_' + searchText, isNew: true }
        ]);
        return;
      }
      const searchTextNormalized = searchText.trim().toLowerCase();
      const isTextExist = results.data.find(
        result => result.text.trim().toLowerCase() === searchTextNormalized
      );
      let arrOptions = results.data.map(result => ({
        label: result.text,
        value: result.id
      }));
      if (!isTextExist) {
        arrOptions = [
          { label: searchText, value: 'isnew_' + searchText, isNew: true },
          ...arrOptions
        ];
      }

      setOptions(arrOptions);
    } catch (error) {
      console.error('Error searching tags:', error);
    }
  };

  const onCreate = async label => {
    try {
      const response = await axios.post(
        `${OUTSIDE_VOH_API}/api/Others/TagAutoCompleteCreate`,
        {
          text: label,
          tagTypeId: 5 // Thay đổi tagTypeId tùy theo loại tag
        }
      );
      const newTag = response.data;

      let _options = options.map(x => {
        if (x.label === label && x.isNew) {
          x.isNew = false;
          x.value = newTag.data;
        }
        return x;
      });
      setOptions(_options);
    } catch (error) {
      console.error('Error adding new tag:', error);
    }
  };

  const debouncedOnSearch = debounce(value => onSearch(value), DEBOUNCE_VALUE);

  const renderOptions = () => {
    return options.map(({ value, label, isNew }) => (
      <Select.Option value={value} key={value}>
        {label}
        {isNew && (
          <>
            {'  '}
            <Tag
              onClick={() => onCreate(label)}
              title="Thêm mới"
              color="#1890ff"
            >
              +
            </Tag>
          </>
        )}
      </Select.Option>
    ));
  };

  return (
    <Select
      filterOption={false}
      notFoundContent={null}
      showSearch
      onSearch={debouncedOnSearch}
      value={val}
      {...props}
    >
      {renderOptions()}
    </Select>
  );
};

export default TagsAutoCompleteCustom;
