import { Form, Input } from 'antd';
import queryString from 'query-string';
import CategoryAutoComplete from 'pages/Radio/RadioFilter/CategoryAutoComplete';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const CategoryAutoCompleteFilter = ({
  onAutoCompleteSelect = f => f,
  onAutoCompleteClear = f => f,
  form
}) => {
  const { search } = useLocation();
  const categoryIdFilterName = 'categoryId';

  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const category = useMemo(() => {
    if (!searchParams[categoryIdFilterName] || !searchParams.categoryName)
      return null;

    return {
      id: searchParams[categoryIdFilterName],
      title: searchParams.categoryName
    };
  }, [categoryIdFilterName, searchParams]);

  const onSelect = (value, record) => {
    form.setFieldsValue({ categoryId: record.value || record.children });
    onAutoCompleteSelect(value, record);
  };

  const onClear = () => {
    form.resetFields(['categoryId']);
    onAutoCompleteClear();
  };

  return (
    <>
      <Form.Item name={categoryIdFilterName} label="Chương trình">
        <CategoryAutoComplete
          allowClear
          category={category}
          onSelect={onSelect}
          onClear={onClear}
          prefixDataKey2={'duration'}
          prefixDataUnit2={' phút'}
        />
      </Form.Item>

      <Form.Item name="categoryName" style={{ display: 'none' }}>
        <Input readOnly />
      </Form.Item>
    </>
  );
};

export default CategoryAutoCompleteFilter;
