import { Typography } from 'antd';
import React from 'react';

import GuestList from './components/GuestList';
import GuestFilter from './GuestFilter';
import GuestForm from './GuestForm';

const Guest = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Quản lý khách mời</Typography.Title>

      <GuestFilter />

      <GuestList />

      <GuestForm />
    </div>
  );
};

export default Guest;
