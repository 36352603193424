import { Tabs, Card, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import GuestList from './components/TrendTopicList';
import GuestFilter from './TrendTopicFilter';
import TrendTopicForm from './TrendTopicForm';
import TrendTopicProvider from './TrendTopicProvider';
import TrendTopicAuditLog from './TrendTopicAuditLog/TrendTopicAuditLog';
import Top100News from './Top100News/Top100News';
import Top100Radios from './Top100Radios/Top100Radios';
import TopTrendKeyWord from './TopTrendKeyWord/TopTrendKeyWord';

const TrendTopic = () => {
  const history = useHistory();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState('1');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabKey = searchParams.get('tab');
    if (tabKey) {
      setCurrentTab(tabKey + '');
    }
  }, [location.search]);

  const handleTabChange = key => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('tab', key);
    history.push({ search: searchParams.toString() });
  };

  return (
    <Tabs activeKey={currentTab} size="large" onChange={handleTabChange}>
      <Tabs.TabPane tab="Dashboard" key="1">
        <Row>
          <Col span={12}>
            <Card>
              <Row align="center">
                <Col span={23}>
                  <Top100News />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Row align="center">
                <Col span={23}>
                  <Top100Radios />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Danh sách chủ đề Trend" key="2">
        <TrendTopicProvider>
          <div className="spacing-container">
            <GuestFilter />
            <GuestList />
            <TrendTopicForm />
            <TrendTopicAuditLog />
          </div>
        </TrendTopicProvider>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Danh sách từ khóa đang trending" key="3">
        <TopTrendKeyWord />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default TrendTopic;
