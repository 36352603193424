// import Role from 'pages/Role';
import BroadcastSchedule from 'pages/BroadcastSchedule';
import Category from 'pages/Category';
import CategoryDetail from 'pages/CategoryDetail';
import CurrentUserDetail from 'pages/CurrentUserDetail';
import Dashboard from 'pages/Dashboard';
import Guest from 'pages/Guest';
import Host from 'pages/Host';
import Login from 'pages/Login';
import NewDetail from 'pages/NewDetail';
import News from 'pages/News';

import NotFound from 'pages/NotFound';
import PasswordRecovery from 'pages/PasswordRecovery';
// import Permission from 'pages/Permission';
import Radio from 'pages/Radio';
import RadioDetail from 'pages/RadioDetail';
import Topic from 'pages/Topic';
import Unauthorized from 'pages/Unauthorized';
import User from 'pages/User';
import TrendTopic from 'pages/TrendTopic';
import Spotlights from 'pages/Spotlights';

import { Redirect } from 'react-router';

import yn from 'yn';
import TrendTopicDetail from 'pages/TrendTopicDetail';

const showDB = yn(process.env.REACT_APP_SHOW_DASHBOARD);

export const publicRoutes = [
  {
    path: '/dang-nhap',
    component: Login
  },
  {
    path: '/quen-mat-khau',
    component: PasswordRecovery
  }
];

export const privateRoutes = [
  {
    path: '/',
    // component: Dashboard,
    exact: true,
    component: showDB ? Dashboard : () => <Redirect to={'/lich-phat-song'} />
  },
  {
    path: '/chu-de-trend',
    component: TrendTopic,
    keyCode: 'radio'
  },
  {
    path: '/chi-tiet-chu-de-trend/:id?',
    component: TrendTopicDetail,
    keyCode: 'radiodetail'
  },
 
  {
    path: '/danh-sach-spotlight',
    component: Spotlights,
    keyCode: 'radio'
  },
  {
    path: '/lich-phat-song',
    component: BroadcastSchedule,
    keyCode: 'broadcastschedule'
  },
  {
    path: '/chi-tiet-radio/:id',
    component: RadioDetail,
    keyCode: 'radiodetail'
  },
  {
    path: '/danh-sach-nhom-chuong-trinh',
    component: Topic,
    keyCode: 'topic'
  },
  {
    path: '/danh-sach-chuong-trinh',
    component: Category,
    keyCode: 'category'
  },
  {
    path: '/chi-tiet-chuong-trinh/:id?',
    component: CategoryDetail,
    keyCode: 'categoryDetail'
  },
  {
    path: '/danh-sach-radio',
    component: Radio,
    keyCode: 'radio'
  },
  {
    path: '/danh-sach-bien-tap-vien',
    component: User,
    keyCode: 'user'
  },
  {
    path: '/danh-sach-host',
    component: Host,
    keyCode: 'host'
  },
  {
    path: '/danh-sach-khach-moi',
    component: Guest,
    keyCode: 'guest'
  },
  {
    path: '/ca-nhan',
    exact: true,
    component: () => <Redirect to="/ca-nhan/thong-tin-ca-nhan" />
  },
  {
    path: '/ca-nhan/:id',
    component: CurrentUserDetail
  },
  {
    path: '/danh-sach-bai-tin',
    component: News,
    keyCode: 'news'
  },
  {
    path: '/chi-tiet-tin-bai/:id?',
    component: NewDetail,
    keyCode: 'newDetail'
  },

  {
    path: '/404',
    component: NotFound
  },
  {
    path: '/unauthorized',
    component: Unauthorized
  }
];
