import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { SpotlightBankContext } from 'pages/Spotlights/SpotlightBankProvider';
import ListCard from 'components/ListCard';
import Table from 'components/Table';
import { TABLE_PROPS, OUTSIDE_VOH_API } from 'appConstants';
import { columns } from 'pages/Spotlights/components/SpotlightBankList/columns';
import ExportSpotlights from 'pages/Spotlights/components/ExportSpotlights';
import { Space } from 'antd';

const SpotlightBankList = () => {
  const { paramFilter, setParamFilter } = useContext(SpotlightBankContext);
  const [loading, setLoading] = useState(false);
  const [spotlights, setSpotlights] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [bodyData, setBodyData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let body = {
        categoryId: '',
        createdBy: '',
        sortOrderType: 1,
        keywords: '',
        searchByDate: 1,
        startTime: '',
        endTime: '',
        page: 0
      };
      if (paramFilter) {
        body = paramFilter;
      }
      setBodyData(body);
      try {
        const response = await axios.post(
          `${OUTSIDE_VOH_API}/api/Others/GetSpotlights`,
          body
        );
        let _list =
          response.data.data?.map((x, index) => ({ ...x, key: index })) ?? [];

        setSpotlights(_list);
        setTotal(response.data?.totalCount ?? 0);
        setPage(response.data?.currentPage ?? 1);
      } catch {}

      setLoading(false);
    }
    fetchData();
  }, [paramFilter]);

  const onPaginate = page => {
    setParamFilter(_p => ({ ..._p, page }));
  };

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          <ExportSpotlights bodyFilter={bodyData} />
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        scroll={{ x: 1200 }}
        columns={columns}
        dataSource={spotlights}
        pagination={{
          total,
          pageSize: 30,
          onChange: onPaginate,
          current: page * 1,
          quantity: spotlights?.length
        }}
        loading={loading}
        bordered
      />
    </ListCard>
  );
};

export default SpotlightBankList;
