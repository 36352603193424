import { TABLE_PROPS, OUTSIDE_VOH_API } from 'appConstants';
import {  Divider, Button, Typography } from 'antd';
import axios from 'axios';
import Table from 'components/Table';

import {  useEffect, useState } from 'react';
import { columns } from './columns';



const Top100NewsList = () => {
    const [loading, setLoading] = useState(false)
    const [news, setNews] = useState([])
    const [page, setPage] = useState(1)

    useEffect(async () => {
        getData(1)
    }, [])

    const getData = async (page) => {
        try {
            setLoading(true)
            let body = {
                page
            }
            const response = await axios.post(`${OUTSIDE_VOH_API}/api/Others/GetNewsTop100`, body);
            let _list = response.data.data?.map((x, index) => ({ ...x, key: `${page}-${index}`, stt: (page - 1) * 20 + index + 1, page })) ?? []
            _list = [...news.filter(x => x.page < page) ?? [], ..._list]
            setNews(_list)
            setPage(response.data?.currentPage ?? 1)
            setLoading(false)
        } catch (error) {
            // Xử lý lỗi nếu có
            console.error('Error:', error);
            setLoading(false)
        }
    }

    return (
        <>
            <Divider orientation="left" plain >
                <Typography.Title level={4} >
                    Top 100 News
                </Typography.Title>
            </Divider>
            <Table
                {...TABLE_PROPS}
                scroll={{ x: 600 }}
                columns={columns}
                dataSource={news}
                loading={loading}
                bordered
            />
            <Divider orientation="right" plain >
                {page > 1 && <Button type="primary" loading={loading} style={{ marginRight: 5 }} onClick={() => getData(page - 1)} >
                    Ẩn bớt
                </Button>}
                {page < 5 && <Button type="primary" loading={loading} onClick={() => getData(page + 1)} >
                    Xem thêm
                </Button>}
            </Divider>
        </>
    );
};

export default Top100NewsList;
