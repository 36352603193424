import React from 'react';
import { UndoOutlined } from '@ant-design/icons';

const ReplayTag = ({ style = {}, withoutText }) => {
  return (
    <span
      style={{
        backgroundColor: '#08979c',
        borderRadius: ' 2px',
        padding: '2px 4px',
        fontSize: '12px',
        alignContent: 'center',
        display: 'flex',
        color: '#fafafa',
        userSelect: 'none',
        ...style
      }}
    >
      <UndoOutlined
        style={{ fontSize: '16px', marginRight: withoutText ? '0px' : '4px' }}
      />
      {!!!withoutText && 'PHÁT LẠI'}
    </span>
  );
};

export default ReplayTag;
