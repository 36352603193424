import { useQuery } from '@apollo/client';
import { Card, Col, Divider, Row, Typography } from 'antd';
import moment from 'moment';
import { GET_CATEGORY_BY_ID } from 'operations/queries/queryCategory';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ScheduleFormInline from './ScheduleFormInline';
import ScheduleAddFormInline from './ScheduleAddFormInline';

const minutesToSeconds = (min, sec) => min * 60 + sec;

const CategorySchedules = () => {
  const { id } = useParams();
  const { data } = useQuery(GET_CATEGORY_BY_ID, {
    variables: { id }
  });

  const category = useMemo(() => data?.category, [data?.category]);
  const schedules = useMemo(() => {
    let _schedules = [...(category?.schedules || [])];

    _schedules.sort((a, b) => {
      if (a.dayOfWeek < b.dayOfWeek) {
        return -1;
      }

      if (a.dayOfWeek > b.dayOfWeek) {
        return 1;
      }

      const aMins = minutesToSeconds(
        moment(a.timeFrom).get('hours'),
        moment(a.timeFrom).get('minutes')
      );

      const bMins = minutesToSeconds(
        moment(b.timeFrom).get('hours'),
        moment(b.timeFrom).get('minutes')
      );

      if (aMins < bMins) {
        return -1;
      }

      if (aMins > bMins) {
        return 1;
      }

      return 0;
    });

    return _schedules;
  }, [category?.schedules]);

  const renderDummyHeader = useCallback(() => {
    return (
      <Row gutter={16}>
        <Col span={7}>
          <Typography.Paragraph strong>Thứ phát sóng</Typography.Paragraph>
        </Col>

        <Col span={7}>
          <Typography.Paragraph strong>Giờ phát sóng</Typography.Paragraph>
        </Col>

        <Col span={7}>
          <Typography.Paragraph strong>Giờ kết thúc</Typography.Paragraph>
        </Col>

        <Col span={3}></Col>
      </Row>
    );
  }, []);

  return (
    <Card
      style={{ marginBottom: 8 }}
      title={
        <>
          Lịch phát sóng mỗi tuần <Divider type="vertical" />
          <Typography.Text style={{ fontSize: '14px' }} type="secondary">
            {category?.duration} phút
          </Typography.Text>
        </>
      }
    >
      {renderDummyHeader()}

      <div
        style={{
          maxHeight: 56 * 3 + 56 * 0.5,
          overflowY: 'auto',
          overflowX: 'hidden',
          marginBottom: 8
        }}
      >
        {schedules.map(schedule => (
          <ScheduleFormInline
            key={schedule.id}
            schedule={schedule}
            category={category}
          />
        ))}
      </div>

      <ScheduleAddFormInline category={category} schedules={schedules} />
    </Card>
  );
};

export default CategorySchedules;
