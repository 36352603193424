import { Typography } from 'antd';
// import { getPermissionKeys } from 'pages/Role/utils';
import MetaCard from 'components/MetaCard';
// import { ROLE_LABEL } from 'appConstants';
// import Checkbox from 'components/Checkbox';
import React from 'react';

// const render = value => <Checkbox checked={value} />;

// const columns = [
//   {
//     title: 'Tên quyền',
//     dataIndex: 'title',
//     width: 250,
//     render: text => <Typography.Text strong>{text}</Typography.Text>
//   },
//   {
//     title: 'Mô tả',
//     dataIndex: 'description',
//     render: text => <Typography.Text>{text}</Typography.Text>,
//     ellipsis: true
//   },
//   {
//     title: 'Đọc',
//     dataIndex: 'isRead',
//     width: 170,
//     render
//   },
//   {
//     title: 'Tạo',
//     dataIndex: 'isCreate',
//     width: 170,
//     render
//   },
//   {
//     title: 'Chỉnh sửa',
//     dataIndex: 'isUpdate',
//     width: 170,
//     render
//   },
//   {
//     title: 'Xóa',
//     dataIndex: 'isDelete',
//     width: 170,
//     render
//   }
// ];

const RoleInfo = () => {
  // const generateContent = () =>
  //   getPermissionKeys(currentUserRole, true).map(key => (
  //     <Col key={key} span={6}>
  //       <Row>
  //         <Col span={12}>
  //           <Typography.Paragraph>{ROLE_LABEL[key]}:</Typography.Paragraph>
  //         </Col>
  //         <Col span={12}>
  //           {<Checkbox disabled checked={currentUserRole[key]} />}
  //         </Col>
  //       </Row>
  //     </Col>
  //   ));

  return (
    <>
      <Typography.Text strong>
        {/* Tên vai trò: {currentUser?.role?.roleName} */}
      </Typography.Text>

      <MetaCard title="Quyền của vai trò" style={{ marginTop: '24px' }}>
        {/* <Table columns={columns} dataSource={currentUser?.permissions || []} /> */}
      </MetaCard>
    </>
  );
};

export default RoleInfo;
