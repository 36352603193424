import { Button, notification } from 'antd';
import Upload from 'components/Upload';
import UserAvatar from 'components/UserAvatar';
import React, { useEffect, useState } from 'react';
import { CameraOutlined } from '@ant-design/icons';
import { IMG_UPLOAD_SIZE_LIMIT, IMG_UPLOAD_WIDTH_LIMIT } from 'appConstants';
import { encodeUrlFile } from 'utils/other';

const AvatarUpload = ({
  defaultAvatar,
  fullName,
  avatar,
  size,
  fontSize,
  loading,
  showName,
  onDone = f => f,
  checkSquare,
  avatarShape,
  uploadParams,
  ratio = 1,
  typeName,
  ...props
}) => {
  const [value, setValue] = useState(null);

  const onChange = args => {
    if (props.onChange) {
      props.onChange(args);
    }

    if (args.file.status === 'done') {
      setValue(args.file.response.Data);
      onDone(args.file.response.Data);
    }
  };

  useEffect(() => {
    if (props.file) {
      if (typeof props.file === 'string') {
        //NOTE: form item default value
        return setValue(props.file);
      } else {
        //NOTE: form item after upload
        if (props.file.status === 'done') {
          return setValue(props.file.response.Data);
        }
      }
    } else {
      //NOTE: normal default value
      return setValue(avatar);
    }
  }, [props.file, avatar]);

  const beforeUpload = file => {
    return new Promise((resolve, reject) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        notification.error({
          message: 'Poster chỉ được upload ( .jpeg, ,jpg, .png.) thôi nha 😡'
        });

        reject(false);
      }

      const isLt2M = file.size / 1024 / 1024 < IMG_UPLOAD_SIZE_LIMIT;
      if (!isLt2M) {
        notification.error({
          message: 'Up hình dưới ' + IMG_UPLOAD_SIZE_LIMIT + 'mb thôi nha 😡'
        });

        reject(false);
      }
      if (checkSquare) {
        var reader = new FileReader();
        // Read the contents of Image File.
        reader.readAsDataURL(file);
        reader.onload = e => {
          var image = new Image();

          image.src = e.target.result;

          image.onload = function () {
            // const height = this.height;
            // const width = this.width;
            // if (width / height === 16 / 9) {
            //   resolve(true);
            // } else {
            //   console.log(width, height);

            //   notification.error({
            //     message:
            //       'Hình ảnh không hợp lệ 😡 vui lòng sử dụng hình ảnh tỉ lệ 16:9.'
            //   });

            //   reject(false);
            // }
            // const height = this.height;
            // const width = this.width;
            // if (
            //   width > IMG_UPLOAD_WIDTH_LIMIT ||
            //   height > IMG_UPLOAD_WIDTH_LIMIT
            // ) {
            //   notification.error({
            //     message: `Hình ảnh không hợp lệ 😡 vui lòng sử dụng hình ảnh chiều dài và chiều rộng không quá ${IMG_UPLOAD_WIDTH_LIMIT}px.`
            //   });
            //   reject(false);
            // }
            resolve(true);
          };
        };
      } else {
        resolve(true);
      }
    });
  };

  return (
    <>
      <div
        className="avatar-wrapper"
        style={{ width: size * ratio, height: size }}
      >
        <UserAvatar
          fullName={fullName}
          defaultAvatar={defaultAvatar}
          avatar={value}
          size={size}
          style={{ fontSize, width: size * ratio }}
          loading={loading}
          shape={avatarShape}
          typeName={typeName}
        />

        <Upload
          accept="image/*"
          maxCount={1}
          showUploadList={false}
          onChange={onChange}
          beforeUpload={beforeUpload}
          uploadParams={uploadParams}
        >
          <span className="avatar-btn">
            <Button type="default" shape="circle" icon={<CameraOutlined />} />
          </span>
        </Upload>
      </div>

      {showName && value && (
        <a
          style={{
            whiteSpace: 'nowrap',
            display: 'inline-block',
            marginTop: 16
          }}
          href={encodeUrlFile(value)}
        >
          {value?.split('/')?.pop() || value}
        </a>
      )}
    </>
  );
};

export default AvatarUpload;
