import React from 'react';
import { Tag } from 'antd';
import { CATEGORY_TYPE_DICTIONARY } from 'appConstants';

// const generateColor = type => {
//   switch (type) {
//     case USER_TYPE_VALUE[USER_TYPE_KEY.STANDARD]:
//       return '';

//     case USER_TYPE_VALUE[USER_TYPE_KEY.MANAGER]:
//       return 'cyan';

//     case USER_TYPE_VALUE[USER_TYPE_KEY.ADMIN]:
//       return 'magenta';

//     default:
//       break;
//   }
// };

const CategoryTag = ({ type, ...props }) => {
  return (
    <Tag
      // color={generateColor(type)}
      {...props}
    >
      {CATEGORY_TYPE_DICTIONARY[type]}
    </Tag>
  );
};

export default CategoryTag;
