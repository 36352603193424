import { Form, Input } from 'antd';
import queryString from 'query-string';
import {
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX
} from 'appConstants';
import CategoryAutoComplete from 'pages/Radio/RadioFilter/CategoryAutoComplete';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { processFormItemName } from 'utils/filter';

const CategoryAutoCompleteFilter = ({
  onAutoCompleteSelect = f => f,
  onAutoCompleteClear = f => f,
  form
}) => {
  const { search } = useLocation();
  const categoryIdFilterName = useMemo(() => {
    return processFormItemName({
      prefix: FILTER_PARAMS_PREFIX,
      name: 'categoryId',
      operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ
    });
  }, []);

  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const category = useMemo(() => {
    if (!searchParams[categoryIdFilterName] || !searchParams.categoryName)
      return null;

    return {
      id: searchParams[categoryIdFilterName],
      title: searchParams.categoryName
    };
  }, [categoryIdFilterName, searchParams]);

  const onSelect = (value, record) => {
    form.setFieldsValue({ categoryName: record.label || record.children });
    onAutoCompleteSelect(value, record);
  };

  const onClear = () => {
    form.resetFields(['categoryName']);
    onAutoCompleteClear();
  };

  return (
    <>
      <Form.Item name={categoryIdFilterName} label="Chương trình">
        <CategoryAutoComplete
          allowClear
          category={category}
          onSelect={onSelect}
          onClear={onClear}
        />
      </Form.Item>

      <Form.Item name="categoryName" style={{ display: 'none' }}>
        <Input readOnly />
      </Form.Item>
    </>
  );
};

export default CategoryAutoCompleteFilter;
