import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, notification, Typography } from 'antd';
import { AUDIO_UPLOAD_SIZE_LIMIT } from 'appConstants';
import Upload from 'components/Upload';
import React, { useCallback, useEffect, useState } from 'react';
import { encodeUrlFile } from 'utils/other';

const AudioListUpload = ({ size, onDone = f => f, disabled, ...props }) => {
  const [fileUrls, setFileUrls] = useState([]);

  useEffect(() => {
    if (props.files?.length) {
      setFileUrls(props.files);
    }
  }, [onDone, props.files]);

  const onChange = args => {
    if (args.file.status === 'done') {
      setFileUrls(prev => {
        const dayLaLuc = [...prev, args.file.response.Data];
        props.onChange(dayLaLuc);
        return dayLaLuc;
      });
    }
  };

  const beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < AUDIO_UPLOAD_SIZE_LIMIT;
    if (!isLt2M) {
      notification.error({
        message: 'Up file dưới ' + AUDIO_UPLOAD_SIZE_LIMIT + 'mb thôi nha 😡'
      });
    }
    return isLt2M;
  };

  const onRemove = useCallback(
    item => {
      setFileUrls(prevLinks => {
        const dayLaLuc = prevLinks.filter(prevLinks => prevLinks !== item);
        props.onChange(dayLaLuc);

        return dayLaLuc;
      });
    },
    [props]
  );

  return (
    <>
      <Upload
        accept=".wav, .mp3"
        showUploadList={false}
        onChange={onChange}
        beforeUpload={beforeUpload}
        multiple
      >
        <Button disabled={disabled} type="default" icon={<UploadOutlined />}>
          Tải lên
        </Button>
      </Upload>

      <div style={{ marginTop: 8 }}>
        {fileUrls.map(fileUrl => (
          <div key={fileUrl} style={{ marginBottom: 8 }}>
            <span style={{ display: 'flex' }}>
              <Typography.Link
                href={encodeUrlFile(fileUrl)}
                ellipsis
                style={{ width: '100%', paddingRight: 8 }}
                target="_blank"
              >
                {fileUrl?.split('/')?.pop() || fileUrl}
              </Typography.Link>

              <Button
                size="small"
                onClick={() => onRemove(fileUrl)}
                danger
                icon={<DeleteOutlined style={{ cursor: 'pointer' }} />}
              ></Button>
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default AudioListUpload;
