import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router';

const PublicRoute = ({ children, isLoggedIn, ...props }) => {
  return (
    <Route {...props}>
      {!isLoggedIn ? children : <Redirect exact to={'/404'} />}
    </Route>
  );
};

PublicRoute.propTypes = { children: PropTypes.any, isLoggedIn: PropTypes.any };

export default PublicRoute;
