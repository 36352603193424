import { useLazyQuery } from '@apollo/client';
import AutoComplete from 'components/AutoComplete';
import { GET_HOSTS } from 'operations/queries/queryHost';
import React, { useMemo } from 'react';

const HostAutoComplete = ({ host, ...props }) => {
  const [searchHost] = useLazyQuery(GET_HOSTS);
  const defaultOption = useMemo(
    () =>
      host
        ? Array.isArray(host)
          ? host
              .filter(host => !!host)
              .map(({ id, name }) => ({ value: id, label: name }))
          : { value: host.id, label: host.name }
        : null,
    [host]
  );

  return (
    <AutoComplete
      defaultOption={defaultOption}
      handleSearch={searchHost}
      labelKey="name"
      valueKey="id"
      dataKey="hosts"
      placeholder="Tìm host"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default HostAutoComplete;
