import { useEffect, useContext } from 'react';

import { Button, Col, Form, Input, Row, DatePicker, Select } from 'antd';

import {
  colProps,
} from 'appConstants';
import { Link, useHistory } from 'react-router-dom';

import FormBase from 'containers/FormBase';
import { NewsWebContext } from '../../NewsWebProvider';
import moment from 'moment';

const NewsWebFilter = () => {
  const [form] = Form.useForm();
  const { paramFilter, setParamFilter } = useContext(NewsWebContext);

  useEffect(() => {
    form.setFieldsValue({ searchByDate: 1 });
  }, []);

  const onFinish = () => {
    var dataForm = form.getFieldValue()
    let dateRange = ["",""]
    if (dataForm.dateRange && dataForm.dateRange.length > 1) {
      dateRange = dataForm.dateRange.map(x => moment(x).format('DD-MM-YYYY'))
    }
    let body = {
      ...paramFilter,
      "newsTypeId": dataForm.newsTypeId,
      "keywords": dataForm.keywords,
      "searchByDate": dataForm.searchByDate,
      'startTime':dateRange[0],
      'endTime':dateRange[1],
      'page':0,
    }
    debugger
    setParamFilter(body)
  }

  return (
    <FormBase form={form} onFinish={onFinish}>
      <Row gutter={50}>
        <Col {...colProps}>
          <Form.Item
            name='keywords'
            label="Tiêu đề"
          >
            <Input placeholder="Nhập Tiêu đề" />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            name='newsTypeId'
            label="Loại"
          >
            <Select
              options={[{ value: 1, label: "Tin tức" }, { value: 4, label: "Kiến thức" }, { value: 5, label: "Tư vấn" }]}
              style={{ width: '100%' }}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col {...colProps}>

        </Col>
        <Col {...colProps}>
          <Form.Item
            name='searchByDate'
            label="Theo Ngày"
          >
            <Select
              options={[{ value: 1, label: "Ngày tạo" }, { value: 2, label: "Ngày publish" }]}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item name='dateRange' label="Khoảng thời gian">
            <DatePicker.RangePicker
              showTime={false}
              style={{ width: '100%' }}
              allowClear
              format="DD/MM/YYYY"
              placeholder={['Chọn ngày bắt đầu', 'Chọn ngày kết thúc']}
            />
          </Form.Item>
        </Col>

      </Row>

      <Row gutter={8} justify="end">
        <Col>
          <Button htmlType="submit">Lọc</Button>
        </Col>
      </Row>
    </FormBase>
  );
};

export default NewsWebFilter;
