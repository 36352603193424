import { useQuery } from '@apollo/client';
import { Col, List, Row, Spin, Table, Typography } from 'antd';
import {
  DATE_FORMAT,
  TRENDTOPIC_STATUS,
  TIME_FORMAT
} from 'appConstants';
import moment from 'moment';
import { GET_AUDIT_LOG_BY_ID } from 'operations/queries/queryAuditLog';
import React, { useCallback, useMemo } from 'react';
import { get } from 'utils/lodash';

const TrendTopicAuditLogContent = ({ auditLogTarget }) => {
  const { data, loading, error } = useQuery(GET_AUDIT_LOG_BY_ID, {
    skip: !auditLogTarget,
    variables: {
      resourceId: auditLogTarget
    }
  });

  const auditLogs = useMemo(
    () => get(data, 'auditLogsByResourceId.items', []),
    [data]
  );

  const renderItem = useCallback(item => {
    const parseJsonSafely = str => {
      try {
        return JSON.parse(str);
      } catch (e) {
        return [];
      }
    };

    const renderValue = (name, value, resource) => {
      if (!value) return 'null';

      switch (name) {
        case 'UsedStatus':
          //NOTE: radio only
          if (resource === 'TrendTopic')
            return TRENDTOPIC_STATUS[value];

          return value;

        case 'Summary':
          return (
            <Table
              pagination={false}
              dataSource={parseJsonSafely(value)}
              columns={[
                {
                  title: 'Thời gian',
                  dataIndex: 'Time'
                },
                {
                  title: 'Nội dung',
                  dataIndex: 'Content'
                }
              ]}
            />
          );

        case 'BroadcastTime':
        case 'TimeTo':
          const sampleDate = moment(value);
          return sampleDate.format(DATE_FORMAT + ' ' + TIME_FORMAT);
        default:
          return value;
      }
    };

    const renderTitle = (name) => {
      switch (name) {
        case 'UsedStatus':
          return "Trạng thái";
        case 'Title':
          return "Tiêu đề";
        case 'SourceUrl':
          return "Nguồn";
        case 'Note':
          return 'Ghi chú';
        case 'ContentText':
          return 'Nội dung';
        default:
          return name;
      }
    };

    return (
      <div style={{ marginLeft: '4px' }}>
        {JSON.parse(item.changedField).map((v, i) => {
          return (
            v.PropertyName === "UseByUserId"
              ? <div key={i}></div>
              : <div key={i} style={{ marginLeft: '4px', marginBottom: '4px' }}>
                  <Typography.Text strong>{renderTitle(v.PropertyName)}</Typography.Text> <br />
                  Giá trị cũ:{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: renderValue(v.PropertyName, v.OldValue, item.resource)
                    }}
                  />
                  <br />
                  Giá trị mới:{" "}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: renderValue(v.PropertyName, v.NewValue, item.resource)
                    }}
                  />
                </div>
          );
        })}
      </div>
    );
  }, []);

  if (error) {
    return <div>Something went wrong!</div>;
  }

  return (
    <Spin spinning={loading}>
      <List
        itemLayout="vertical"
        dataSource={auditLogs}
        renderItem={item => (
          <List.Item key={item.id}>
            <Row align="middle" gutter={4} style={{ marginBottom: '4px' }}>
              <Col>
                <Typography.Text style={{ fontSize: '1.1rem' }} strong>
                  {item.resource}
                </Typography.Text>
              </Col>
              <Col>•</Col>
              <Col>
                <Typography.Text
                  style={{ color: 'rgba(0, 0, 0, 0.45)', lineHeight: '14px' }}
                >
                  {`${moment(item.createdDate).format(
                    TIME_FORMAT + ' ' + DATE_FORMAT
                  )} - ${moment(item.createdDate).fromNow()} - ${item?.creator?.name}`}
                </Typography.Text>
              </Col>
              <Col></Col>
            </Row>
            {renderItem(item)}
          </List.Item>
        )}
      />
    </Spin>
  );
};

export default TrendTopicAuditLogContent;
