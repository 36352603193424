import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { Col, Drawer, Image, Row, Typography } from 'antd';

import { visibleDrawerVar } from 'api/cache';
import { CHANNEL_BROADCAST_DICTIONARY } from 'appConstants';
import defaultImg from 'assets/images/default.jpg';
import moment from 'moment';
import { GET_CATEGORY_BY_ID } from 'operations/queries/queryCategory';
import {
  GET_RADIO_BY_ID,
  GET_RADIO_REPLAY_SCHEDULE_BY_ID
} from 'operations/queries/queryRadio';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useState } from 'react';

import { formatDate } from 'utils/date';
import { encodeUrlFile } from 'utils/other';
import WithoutRadioContent from './WithoutRadioContent';
import WithRadioContent from './WithRadioContent';

export const RadioQuickViewContext = React.createContext();

export const renderHosts = (record, hosts, _type) => {
  if (!hosts) return null;
  return record?.__typename === 'Category'
    ? hosts
        .filter(({ cateHosts }) => {
          return (cateHosts || []).some(({ type }) => type === _type);
        })
        .map(({ name }, index, arr) => {
          if (index !== arr.length - 1) return name + ', ';
          return name;
        })
    : hosts
        .filter(({ radioHosts }) => {
          return (radioHosts || []).some(({ type }) => type === _type);
        })
        .map(({ name }, index, arr) => {
          if (index !== arr.length - 1) return name + ', ';
          return name;
        });
};

const RadioQuickView = () => {
  const visibleDrawer = useReactiveVar(visibleDrawerVar);
  const [fetchRadio] = useLazyQuery(GET_RADIO_BY_ID);
  const [fetchCate] = useLazyQuery(GET_CATEGORY_BY_ID);
  const [fetchReplay] = useLazyQuery(GET_RADIO_REPLAY_SCHEDULE_BY_ID);

  const [record, setRecord] = useState({});

  const _record = visibleDrawer?.record;

  const hosts = useMemo(() => {
    return record?.hosts?.filter(
      (v, i, a) => a.findIndex(v2 => v2.id === v.id) === i
    );
  }, [record?.hosts]);

  const isNewRadio = useMemo(() => record?.__typename === 'Category', [record]);

  useEffect(() => {
    return () => {
      visibleDrawerVar(false);
    };
  }, []);

  const fetch = useCallback(async () => {
    let res;

    if (_record) {
      if (_record.__typename === 'Radio') {
        const { data } = await fetchRadio({
          variables: {
            id: _record.id
          }
        });

        res = data.radio;
      } else if (_record.__typename === 'Category') {
        const { data } = await fetchCate({
          variables: {
            id: _record.id
          }
        });

        res = { ...data.category, ...(_record?.forCategory || {}) };
      } else if (_record.__typename === 'RadioReplaySchedule') {
        const { data } = await fetchReplay({
          variables: {
            id: _record.id
          }
        });

        const replaySchedule = data?.radioReplaySchedule || {};

        res = {
          ...(replaySchedule?.radio || {}),
          id: replaySchedule.id,
          radioId: replaySchedule.radioId,
          broadcastTime: moment(replaySchedule.timeFrom)
            .set({
              seconds: 0,
              milliseconds: 0
            })
            .toISOString(true),
          timeTo: moment(replaySchedule.timeTo)
            .set({
              seconds: 0,
              milliseconds: 0
            })
            .toISOString(true),
          __typename: replaySchedule.__typename
        };
      }
    }

    setRecord(res);
  }, [_record, fetchCate, fetchRadio, fetchReplay]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Drawer
      placement="right"
      width={600}
      visible={visibleDrawer.visible}
      onClose={() => {
        visibleDrawerVar(false);
      }}
      destroyOnClose
      closable={false}
      title={
        <Row justify="space-between">
          <Typography.Text strong>
            {CHANNEL_BROADCAST_DICTIONARY[record?.category?.channel] ||
              CHANNEL_BROADCAST_DICTIONARY[record?.channel]}
          </Typography.Text>
          <Typography.Text strong>
            {formatDate(record?.broadcastTime)}
          </Typography.Text>
        </Row>
      }
    >
      <RadioQuickViewContext.Provider
        value={{
          hosts,
          record
        }}
      >
        <Row>
          <Col span={24} style={{ marginBottom: 16 }}>
            <Image
              width="100%"
              height={300}
              src={record?.image ? encodeUrlFile(record?.image) : defaultImg}
              style={{ objectFit: 'cover' }}
            />
          </Col>

          {isNewRadio ? <WithoutRadioContent /> : <WithRadioContent />}
        </Row>
      </RadioQuickViewContext.Provider>
    </Drawer>
  );
};

export default RadioQuickView;
