import { createContext, useState } from 'react';

export const RadioContext = createContext();

const RadioProvider = props => {
  const [auditLogTarget, setAuditLogTarget] = useState();

  return (
    <RadioContext.Provider value={{ auditLogTarget, setAuditLogTarget }}>
      {props.children}
    </RadioContext.Provider>
  );
};

export default RadioProvider;
