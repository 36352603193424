import { useReactiveVar } from '@apollo/client';
import { Form } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import UpdateCreateModal from 'containers/UpdateCreateModal';
import {
  useAddTrendTopic,
  useEditTrendTopic
} from 'operations/mutations/TrendTopic/mutateTrendTopic';
import React from 'react';
import { setError } from 'utils/other';
import TrendTopicFormContent from './TrendTopicFormContent';

const TrendTopicForm = () => {
  const { mutate: editTrendTopic } = useEditTrendTopic();
  const visibleDrawer = useReactiveVar(visibleDrawerVar);

  const { mutate: createTrendTopic } = useAddTrendTopic();

  const [form] = Form.useForm();

  const onSave = async () => {
    try {
      const params = await form.validateFields();

      await editTrendTopic({
        variables: {
          input: {
            ...params,
            trendTopicId: visibleDrawer.record.trendTopicId,
          }
        }
      });

      visibleDrawerVar({ visible: false, record: null });
    } catch (e) {
      setError(form, e);
    }
  };

  const onCreate = async () => {
    try {
      const params = await form.validateFields();
      const _image = params.image;

      await createTrendTopic({
        variables: {
          addTrendTopicInput: {
            ...params,
            image: _image?.file?.response?.Data || _image
          }
        }
      });
      form.resetFields();
      visibleDrawerVar(false);
    } catch (error) { }
  };
console.log(visibleDrawer?.record)
  return (
    <UpdateCreateModal
      title={visibleDrawer.record ? 'Chỉnh sửa chủ đề' : 'Tạo chủ đề'}
      onOk={visibleDrawer.record ? onSave : onCreate}
      onClose={() => {
        form.resetFields();
        visibleDrawerVar(false);
      }}
      visible={visibleDrawer.visible}
      isUpdate={!!visibleDrawer.record}
      disableOnOk={visibleDrawer?.record?.usedStatus == 2}
      destroyOnClose
    >
      <TrendTopicFormContent
        form={form}
        onSubmit={visibleDrawer.record ? onSave : onCreate}
        isCreate={visibleDrawer.record ? false : true}
      />
    </UpdateCreateModal>
  );
};

export default TrendTopicForm;
