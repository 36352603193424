import { Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import React from 'react';

const TableRecordName = ({ name, setDrawerVisible, setRecordToEdit, icon }) => {
  const onClick = () => {
    setDrawerVisible();
    // setRecordToEdit();
  };
  return (
    <Typography.Text strong onClick={onClick} style={{ cursor: 'pointer' }}>
      {name} {icon || <EditOutlined />}
    </Typography.Text>
  );
};

export default TableRecordName;
