import { createContext, useState } from 'react';

export const TrendTopicContext = createContext();

const TrendTopicProvider = props => {
  const [auditLogTarget, setAuditLogTarget] = useState();

  return (
    <TrendTopicContext.Provider value={{ auditLogTarget, setAuditLogTarget }}>
      {props.children}
    </TrendTopicContext.Provider>
  );
};

export default TrendTopicProvider;
