import { Button, Tooltip } from 'antd';
import { OUTSIDE_VOH_API } from 'appConstants';

import React, { useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

import { DownloadOutlined } from '@ant-design/icons';
import { formatStringForExport } from 'utils/other';
import axios from 'axios';

const renderHosts = hosts => {
  return hosts
    .map(({ name }, index, arr) => {
      if (index !== arr.length - 1) return name + '; ';
      return name;
    })
    .join('');
};

const ExportSpotlights = ({ bodyFilter }) => {
  const exportRef = useRef();
  const [spotlights, setSpotlights] = useState([]);

  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      const response = await axios.post(
        `${OUTSIDE_VOH_API}/api/Others/GetSpotlights`,
        { ...bodyFilter, pageSize: 2000 }
      );
      let _list =
        response.data.data?.map((x, index) => ({ ...x, key: index })) ?? [];

      setSpotlights(_list);
    } catch {}

    setLoading(false);
  };

  const headers = [
    { label: 'Spotlight', key: 'content' },
    { label: 'Time', key: 'time' },
    { label: 'Radio chi tiết', key: 'title' },
    { label: 'Chương trình', key: 'categoryTitle' },
    { label: 'Nhóm chương trình', key: 'topicTitle' },
    { label: 'Kênh', key: 'channelTitle' },
    { label: 'Host', key: 'hostList' },
    { label: 'Khởi tạo', key: 'radioCreatedByUserName' },
    { label: 'Thời gian khởi tạo', key: 'radioCreatedDate' },
    { label: 'Cập nhật', key: 'radioUpdatedByUserName' },
    { label: 'Thời gian cập nhật', key: 'radioUpdatedDate' },
    { label: 'Xuất bản', key: 'radioPublishedByUserName' },
    { label: 'Thời gian xuất bản', key: 'publishedDate' }
  ];

  const radioSpotlightsCSV = useMemo(() => {
    if (spotlights) {
      const csvData = spotlights?.map(item => ({
        content: formatStringForExport(item?.content),
        time: formatStringForExport(item?.time),
        title: formatStringForExport(item?.title),
        categoryTitle: formatStringForExport(item?.categoryTitle),
        topicTitle: formatStringForExport(item?.topicTitle),
        channelTitle: formatStringForExport(item?.channelTitle),
        hostList: renderHosts(item?.hostList),
        radioCreatedByUserName: formatStringForExport(
          item?.radioCreatedByUserName
        ),
        radioCreatedDate: item?.radioCreatedDate,
        radioUpdatedByUserName: item?.radioUpdatedByUserName
          ? formatStringForExport(item?.radioCreatedByUserName)
          : '',
        radioUpdatedDate: item?.radioCreatedDate,
        radioPublishedByUserName: item?.radioPublishedByUserName
          ? formatStringForExport(item?.radioPublishedByUserName)
          : '',
        publishedDate: item?.publishedDate
      }));

      return csvData;
    }
    return [];
  }, [spotlights]);

  const onExportRadio = async () => {
    setLoading(true);
    try {
      await getData();

      exportRef.current.link.click();

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Export spotlight dựa trên bộ lọc">
        <Button
          onClick={onExportRadio}
          loading={loading}
          icon={<DownloadOutlined />}
        >
          Export
        </Button>
      </Tooltip>

      <CSVLink
        data={radioSpotlightsCSV}
        headers={headers}
        filename="danh-sach-radio-spotlight-export.csv"
        ref={exportRef}
      />
    </>
  );
};

export default ExportSpotlights;
