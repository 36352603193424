import { useLazyQuery } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import { DATE_FORMAT, STATUS_DICTIONARY } from 'appConstants';
import moment from 'moment';
import { GET_ALL_HOSTS } from 'operations/queries/queryHost';

import React, { useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

import { DownloadOutlined } from '@ant-design/icons';
import { formatStringForExport } from 'utils/other';

const ExportHosts = ({ filterQuery }) => {
  const exportRef = useRef();

  const [loading, setLoading] = useState(false);

  const [query, { data }] = useLazyQuery(GET_ALL_HOSTS, {
    variables: {
      where: filterQuery
    }
  });

  const hosts = useMemo(() => data?.allHosts, [data?.allHosts]);

  const headers = [
    { label: 'Host', key: 'name' },
    { label: 'Trạng thái', key: 'statusName' },
    { label: 'User liên kết', key: 'user' },
    { label: 'Người khởi tạo', key: 'creator' },
    { label: 'Ngày khởi tạo', key: 'createdDate' },
    { label: 'Người cập nhật', key: 'updater' },
    { label: 'Ngày cập nhật', key: 'updateDate' },
    { label: 'Giới thiệu', key: 'introduction' },
    { label: 'Cơ quan công tác', key: 'currentWorkingPlace' },
    { label: 'Quá trình công tác', key: 'experience' },
    { label: 'Quá trình học tập', key: 'education' }
  ];

  const radiosCSV = useMemo(() => {
    if (hosts) {
      const csvData = hosts?.map(host => ({
        name: host?.name,
        statusName: STATUS_DICTIONARY[host?.status],
        user: host?.user?.name,
        creator: host?.creator?.name,
        updater: host?.updater?.name,
        createdDate: moment(host?.createdDate).format(DATE_FORMAT),
        updateDate: moment(host?.updateDate).format(DATE_FORMAT),
        introduction: formatStringForExport(host?.introduction),
        currentWorkingPlace: formatStringForExport(host?.currentWorkingPlace),
        experience: formatStringForExport(host?.experience),
        education: formatStringForExport(host?.education)
      }));

      return csvData;
    }
    return [];
  }, [hosts]);

  const onExportRadio = async () => {
    setLoading(true);
    try {
      const data = await query();
      if (data) {
        exportRef.current.link.click();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Export host dựa trên bộ lọc">
        <Button
          onClick={onExportRadio}
          loading={loading}
          icon={<DownloadOutlined />}
        >
          Export
        </Button>
      </Tooltip>

      <CSVLink
        data={radiosCSV}
        headers={headers}
        filename="danh-sach-host-export.csv"
        ref={exportRef}
      />
    </>
  );
};

export default ExportHosts;
