import { Typography } from 'antd';
import React from 'react';

const MetaCard = ({ title, children, ...props }) => {
  return (
    <div className="meta-card" {...props}>
      <Typography.Text strong className="meta-card-title">
        {title}
      </Typography.Text>

      <div className="meta-card-content">{children}</div>
    </div>
  );
};

export default MetaCard;
