import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Popover, Space, Tag, Typography } from 'antd';
import {
  RADIO_STATUS_TYPE_COLOR,
  RADIO_STATUS_TYPE_DICTIONARY,
  RADIO_STATUS_TYPE_KEY
} from 'appConstants';
import FullScheduleCalendar from 'containers/FullScheduleCalendar';
import React from 'react';
import RadioQuickView from './components/RadioQuickView';

const BroadcastSchedule = () => {
  const content = (
    <div>
      {Object.keys(RADIO_STATUS_TYPE_KEY).map(key => (
        <div key={key}>
          <Tag
            style={{
              color: 'transparent',
              userSelect: 'none',
              margin: '0 2px 8px 0'
            }}
            color={RADIO_STATUS_TYPE_COLOR[key]}
          >
            aaaaaaaaa
          </Tag>
          :{' '}
          <Typography.Text
            style={{
              userSelect: 'none',
              cursor: 'pointer'
            }}
          >
            {RADIO_STATUS_TYPE_DICTIONARY[key]}
          </Typography.Text>
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <Space align="center">
        <Typography.Title>Lịch phát sóng</Typography.Title>

        <Divider
          style={{
            marginBottom: 10,
            display: 'inline-block'
          }}
          type="vertical"
        />

        <Popover
          placement="right"
          title="Trạng thái radio"
          content={content}
          trigger="hover"
        >
          <Button
            size="small"
            style={{
              marginBottom: 10,
              display: 'inline-block'
            }}
            icon={<QuestionCircleOutlined />}
          >
            Chú thích màu 🌈
          </Button>
        </Popover>
      </Space>

      <FullScheduleCalendar />
      <RadioQuickView />
    </div>
  );
};

export default BroadcastSchedule;
