import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, notification, Row, Spin, Tag } from 'antd';
import { DEFAULT_ERROR_MESSAGE } from 'appConstants';
import MetaCard from 'components/MetaCard';
import { useUpdateWaitingUser } from 'operations/mutations/user/mutateUser';
import React from 'react';

const UpdateWaitingUser = () => {
  const [form] = Form.useForm();
  const { mutate: updateWaitingProfile, loading } = useUpdateWaitingUser();
  const onSave = async () => {
    try {
      const formParams = await form.validateFields();
      const { email, password, retypePassword } = formParams;

      if (password !== retypePassword) {
        throw new Error('Nhập lại mật khẩu mới không trùng khớp!');
      }

      const params = {
        email,
        password
      };

      await updateWaitingProfile({ variables: { input: params } });
    } catch (e) {
      notification.error({
        message: e?.message || DEFAULT_ERROR_MESSAGE
      });
    }
  };

  return (
    <MetaCard title="Thông tin cá nhân">
      <Spin spinning={loading}>
        <Form layout="vertical" form={form}>
          <Row gutter={32}>
            <Col span={8}>
              <Form.Item
                label="Email:"
                name="email"
                rules={[
                  { required: true, type: 'email', message: 'Nhập email!' }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Mật khẩu mới"
                name="password"
                rules={[{ required: true, message: 'Nhập mật khẩu mới!' }]}
              >
                <Input.Password autoComplete="new-password" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Nhập lại mật khẩu mới"
                name="retypePassword"
                rules={[{ required: true, message: 'Nhập lại mật khẩu mới!' }]}
              >
                <Input.Password autoComplete="new-password" />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end" align="middle">
            <Tag color="warning" icon={<InfoCircleOutlined />}>
              Vui lòng cập nhật thông tin để sử các tính năng khác
            </Tag>

            <Button onClick={onSave} type="primary" loading={loading}>
              Lưu
            </Button>
          </Row>
        </Form>
      </Spin>
    </MetaCard>
  );
};

export default UpdateWaitingUser;
