import { Typography } from 'antd';

import React from 'react';

import SpotlightBankProvider from 'pages/Spotlights/SpotlightBankProvider';
import SpotlightBankFilter from 'pages/Spotlights/components/SpotlightBankFilter';
import SpotlightBankList from 'pages/Spotlights/components/SpotlightBankList';

const SpotlightBank = () => {
  return (
    <SpotlightBankProvider>
      <div className="spacing-container">
        <Typography.Title>Danh sách Spotlight</Typography.Title>

        <SpotlightBankFilter />

        <SpotlightBankList />
      </div>
    </SpotlightBankProvider>
  );
};

export default SpotlightBank;
