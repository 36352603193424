import { useQuery } from '@apollo/client';
import { Col, List, Row, Spin, Table, Typography } from 'antd';
import {
  DATE_FORMAT,
  RADIO_STATUS_TYPE_DICTIONARY_INT_SHORT,
  TIME_FORMAT
} from 'appConstants';
import moment from 'moment';
import { GET_AUDIT_LOG_BY_ID } from 'operations/queries/queryAuditLog';
import React, { useCallback, useMemo } from 'react';
import { get } from 'utils/lodash';
import { encodeUrlFile } from 'utils/other';

const RadioAuditLogContent = ({ auditLogTarget }) => {
  const { data, loading, error } = useQuery(GET_AUDIT_LOG_BY_ID, {
    skip: !auditLogTarget,
    variables: {
      resourceId: auditLogTarget
    }
  });

  const auditLogs = useMemo(
    () => get(data, 'auditLogsByResourceId.items', []),
    [data]
  );

  const renderItem = useCallback(item => {
    const a = b => {
      try {
        return JSON.parse(b);
      } catch (e) {
        return [];
      }
    };
    const renderValue = (name, value, resource) => {
      if (!value) return 'null';

      switch (name) {
        case 'Status':
          //NOTE: radio only
          if (resource === 'Radio')
            return RADIO_STATUS_TYPE_DICTIONARY_INT_SHORT[value];

          return value;

        case 'Summary':
          return (
            <Table
              pagination={false}
              dataSource={a(value)}
              columns={[
                {
                  title: 'Thời gian',
                  dataIndex: 'Time'
                },
                {
                  title: 'Nội dung',
                  dataIndex: 'Content'
                }
              ]}
            />
          );

        case 'BroadcastTime':
        case 'TimeTo':
          const sampleDate = moment(value);
          return sampleDate.format(DATE_FORMAT + ' ' + TIME_FORMAT);

        case 'ScenarioFile':
        case 'Image':
        case 'AudioUrl':
          const url = encodeUrlFile(value);
          return (
            <a href={url} target="_blank" rel="noreferrer">
              {url}
            </a>
          );

        default:
          return value;
      }
    };

    return (
      <div style={{ marginLeft: '4px' }}>
        {JSON.parse(item.changedField).map((v, i) => (
          <div key={i} style={{ marginLeft: '4px', marginBottom: '4px' }}>
            <Typography.Text strong>{v.PropertyName}</Typography.Text> <br />
            Giá trị cũ: {renderValue(v.PropertyName, v.OldValue, item.resource)}
            <br />
            Giá trị mới:{' '}
            {renderValue(v.PropertyName, v.NewValue, item.resource)}
          </div>
        ))}
      </div>
    );
  }, []);

  if (error) {
    return <div>Something wrong!</div>;
  }

  return (
    <Spin spinning={loading}>
      <List
        itemLayout="vertical"
        dataSource={auditLogs}
        renderItem={item => (
          <List.Item key={item.id}>
            <Row align="middle" gutter={4} style={{ marginBottom: '4px' }}>
              <Col>
                <Typography.Text style={{ fontSize: '1.1rem' }} strong>
                  {item.resource}
                </Typography.Text>
              </Col>

              <Col>•</Col>

              <Col>
                <Typography.Text
                  style={{ color: 'rgba(0, 0, 0, 0.45)', lineHeight: '14px' }}
                >
                  {`${moment(item.createdDate).format(
                    TIME_FORMAT + ' ' + DATE_FORMAT
                  )} -
                    ${moment(item.createdDate).fromNow()} - ${
                    item?.creator?.name
                  }
                    `}
                </Typography.Text>
              </Col>

              <Col></Col>
            </Row>

            {renderItem(item)}
          </List.Item>
        )}
      />
    </Spin>
  );
};

export default RadioAuditLogContent;
