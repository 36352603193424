import { useQuery } from '@apollo/client';
import { Form, Select } from 'antd';
import {
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX
} from 'appConstants';
import { GET_ALL_TOPICS } from 'operations/queries/queryTopic';
import React from 'react';
import { processFormItemName } from 'utils/filter';

const TopicSelectFilter = ({ props }) => {
  const { error, data: topicsConnect } = useQuery(GET_ALL_TOPICS, {
    variables: {
      skip: 0,
      take: 50
    }
  });

  if (error) return <div>Oops, đã có vấn đề xảy ra 😅😅😅</div>;
  if (!topicsConnect) return <div>None</div>;
  const allTopics = topicsConnect.topics.items.map(({ id, title }) => ({
    value: id,
    label: title
  }));
  return (
    <Form.Item
      name={processFormItemName({
        prefix: FILTER_PARAMS_PREFIX,
        name: 'topicId',
        operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ
      })}
      label="Nhóm chương trình"
    >
      <Select
        options={allTopics}
        placeholder="Chọn nhóm chương trình"
        style={{ width: '100%' }}
        allowClear
        showSearch
        optionFilterProp="label"
        {...props}
      />
    </Form.Item>
  );
};

export default TopicSelectFilter;
