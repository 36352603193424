import { Typography } from 'antd';

import React from 'react';
import HostList from './components/HostList';
import HostFilter from './HostFilter';
import HostForm from './HostForm';

const Host = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Quản lý Host</Typography.Title>

      <HostFilter />

      <HostList />

      <HostForm />
    </div>
  );
};

export default Host;
