import { Radio } from 'antd';
import {
  CHANNEL_BROADCAST_DICTIONARY,
  CHANNEL_BROADCAST_KEY
} from 'appConstants';
import queryString from 'query-string';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getCookie, setCookie } from 'utils/authority';

const options = Object.entries(CHANNEL_BROADCAST_DICTIONARY).map(
  ([value, label]) => ({ value, label })
);

const ChannelBox = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const channel = useMemo(
    () =>
      searchParams?.channel ||
      (getCookie('BROADCAST_SCHEDULE_CHANNEL_DEFAULT')
        ? getCookie('BROADCAST_SCHEDULE_CHANNEL_DEFAULT')
        : CHANNEL_BROADCAST_KEY.FM999MHZ),
    [searchParams]
  );

  const onChange = e => {
    setCookie('BROADCAST_SCHEDULE_CHANNEL_DEFAULT', e.target.value);

    history.push({
      pathname: '/lich-phat-song',
      search: queryString.stringify({
        ...searchParams,
        channel: e.target.value
      })
    });
  };

  return (
    <Radio.Group
      style={{ marginBottom: 24 }}
      value={channel}
      options={options}
      onChange={onChange}
      optionType="button"
      size="large"
      className="navi-radio-group box-radios"
    />
  );
};

export default ChannelBox;
