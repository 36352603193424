import { Col, Space, Typography } from 'antd';
import Microphone from 'components/Icons/Microphone';
import React, { useContext } from 'react';
import { CalendarOutlined, FileTextOutlined } from '@ant-design/icons';
import moment from 'moment';
import { RadioQuickViewContext, renderHosts } from './RadioQuickView';
import { Link } from 'react-router-dom';

const QuickViewInfo = ({ categoryTitle, categoryId, topicTitle }) => {
  const { record, hosts } = useContext(RadioQuickViewContext);

  return (
    <>
      <Col span={24}>
        Chương trình:{' '}
        <Link to={`/chi-tiet-chuong-trinh/${categoryId}`}>
          <Typography.Text style={{ cursor: 'pointer' }} strong>
            {categoryTitle}
          </Typography.Text>
        </Link>
      </Col>

      <Col span={24} style={{ marginBottom: 16 }}>
        <Typography.Text>
          Nhóm chương trình:{' '}
          <Typography.Text strong>{topicTitle}</Typography.Text>
        </Typography.Text>
      </Col>

      <Col span={24}>
        <Typography.Text>
          <Microphone style={{ fontSize: '1rem', marginRight: '0.25rem' }} />
          {renderHosts(record, hosts, 1)}
        </Typography.Text>
      </Col>

      <Col span={24}>
        <Typography.Text>
          <FileTextOutlined
            style={{ fontSize: '1rem', marginRight: '0.25rem' }}
          />
          {renderHosts(record, hosts, 2)}
        </Typography.Text>
      </Col>

      <Col span={24} style={{ marginBottom: 16 }}>
        <Space>
          <CalendarOutlined />
          <Typography.Text>
            {record?.broadcastTime
              ? moment(record?.broadcastTime).format('DD-MM-YYYY HH:mm')
              : moment(record?.start).format('DD-MM-YYYY HH:mm')}
          </Typography.Text>
        </Space>
      </Col>
    </>
  );
};

export default QuickViewInfo;
