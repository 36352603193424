import { Select } from 'antd';
import {
  CATEGORY_STATUS_DICTIONARY,
  CATEGORY_STATUS_INT_DICTIONARY,
  CATEGORY_STATUS_KEY,
  CATEGORY_STATUS_KEY_INT
} from 'appConstants';
import React from 'react';

const CategoryStatusSelect = ({ disabled, intValue, ...props }) => {
  return (
    <Select
      options={Object.entries(
        intValue ? CATEGORY_STATUS_INT_DICTIONARY : CATEGORY_STATUS_DICTIONARY
      ).map(([key, value]) => ({
        value: key,
        label: value,
        disabled:
          disabled !== CATEGORY_STATUS_KEY.DRAFT &&
          (key === CATEGORY_STATUS_KEY.DRAFT ||
            key === CATEGORY_STATUS_KEY_INT.DRAFT + '')
      }))}
      style={{ width: '100%' }}
      placeholder="Chọn trạng thái"
      allowClear
      {...props}
    />
  );
};

export default CategoryStatusSelect;
