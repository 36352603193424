import { Button, Form, Input, Card, Col } from 'antd';
import Tiptap from 'components/Tiptap';
import TrendTopicUpload from './components/TrendTopicUpload';
import { useCallback, useEffect, useMemo } from 'react';
import { GET_TRENDTOPIC_BY_ID } from 'operations/queries/queryTrendTopic';
import {
  SCRIPT_UPLOAD_SIZE_LIMIT,
  AUDIO_UPLOAD_SIZE_LIMIT
} from 'appConstants';
import { useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import {
  useAddTrendTopic,
  useEditTrendTopic
} from 'operations/mutations/TrendTopic/mutateTrendTopic';
import { Link } from 'react-router-dom';

const extract = ({ sourceMedia: _sourceMedia }) => {
  const sourceMedia = _sourceMedia?.file?.response?.Data || _sourceMedia;
  return { sourceMedia };
};

const TrendTopicFormContent = ({ isCreating }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { data, loading, error, refetch } = useQuery(GET_TRENDTOPIC_BY_ID, {
    variables: { id: parseInt(id) },
    skip: isCreating,
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if (!isCreating && id) {
      refetch({
        id: parseInt(id)
      });
    }
  }, [id, isCreating, refetch]);

  const history = useHistory();
  const trendTopic = useMemo(() => data?.trendTopic, [data]);

  const { mutate: mutateAdd, loading: addLoading } = useAddTrendTopic();
  const { mutate: mutateEdit, loading: editLoading } = useEditTrendTopic();

  useEffect(() => {
    if (!isCreating && trendTopic) {
      form.setFieldsValue({
        title: trendTopic.title,
        contentText: trendTopic.contentText,
        sourceUrl: trendTopic.sourceUrl,
        note: trendTopic.note,
        usedStatus: trendTopic.usedStatus,
        sourceMedia: trendTopic.sourceMedia
      });
    }
  }, [form, isCreating, trendTopic]);

  const handleGoBack = () => {
    history.goBack();
  };

  const onSave = useCallback(async () => {
    try {
      let params = await form.validateFields();
      const { sourceMedia } = extract(params);
      params = { ...params, sourceMedia };

      if (isCreating) {
        await mutateAdd({
          variables: { addTrendTopicInput: { ...params } },
          onCompleted: ({ addTrendTopic }) => {
            history.push(
              '/chi-tiet-chu-de-trend/' + parseInt(addTrendTopic.trendTopicId)
            );
          }
        });
      } else {
        await mutateEdit({
          variables: {
            input: { ...params, trendTopicId: parseInt(id) }
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [form, history, id, isCreating, mutateAdd, mutateEdit]);

  const onDownloadCallback = useCallback(async () => {
    try {
      let params = await form.validateFields();
      const { sourceMedia } = extract(params);
      params = { ...params, sourceMedia };
    } catch (e) {
      console.error(e);
    }
  }, [form]);

  const renderSaveBtns = useCallback(() => {
    return (
      <Button
        onClick={onSave}
        type="primary"
        loading={addLoading || editLoading}
      >
        {isCreating ? 'Tạo mới' : 'Cập nhật'}
      </Button>
    );
  }, [onSave, isCreating, addLoading, editLoading]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Card
      title={isCreating ? 'Tạo chủ đề' : 'Chỉnh sửa chủ đề'}
      style={{ marginBottom: 8 }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="title"
          label="Tên chủ đề"
          rules={[{ required: true, message: 'Nhập tên!' }]}
        >
          <Input placeholder="Nhập chủ đề" />
        </Form.Item>
        <Form.Item name="contentText" label="Nội dung">
          <Tiptap size={300} />
        </Form.Item>
        <Form.Item name="note" label="Ghi chú">
          <Input.TextArea placeholder="Nhập ghi chú" />
        </Form.Item>
        <Form.Item
          name="sourceMedia"
          label={`File(.doc, .docx. Dung lượng tối đa ${SCRIPT_UPLOAD_SIZE_LIMIT} MB, .avi, .mp4 Dung lượng tối đa ${AUDIO_UPLOAD_SIZE_LIMIT} MB )`}
          valuePropName="file"
        >
          <TrendTopicUpload
            size={64}
            form={form}
            fileUrl={trendTopic?.sourceMedia}
            disabled={trendTopic?.useStatus === 2}
            onDownloadCallback={onDownloadCallback}
          />
        </Form.Item>
        <Form.Item>
          <Col>{renderSaveBtns()}</Col>
        </Form.Item>
        <Form.Item>
          <Col>
            <Button onClick={handleGoBack} type="button">
              Quay lại
            </Button>
            <Link to="/chu-de-trend?tab=2"> Về trang danh sách</Link>
          </Col>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default TrendTopicFormContent;
