import { Space, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import 'moment/locale/vi';
import { DATE_FORMAT, TIME_FORMAT } from 'appConstants';

const TimeLine = ({ createdDate, updatedDate, publicTime }) => {
  return (
    <Space direction="vertical">
      <Typography.Text>
        <Typography.Text strong> Khởi tạo: </Typography.Text>
        {createdDate &&
          moment(createdDate).format(DATE_FORMAT + ' ' + TIME_FORMAT)}
      </Typography.Text>
      <Typography.Text>
        <Typography.Text strong>Cập nhật: </Typography.Text>
        {updatedDate &&
          moment(updatedDate).format(DATE_FORMAT + ' ' + TIME_FORMAT)}
      </Typography.Text>
      {publicTime && (
        <Typography.Text>
          <Typography.Text strong>Phát hành: </Typography.Text>
          {publicTime &&
            moment(publicTime).format(DATE_FORMAT + ' ' + TIME_FORMAT)}
        </Typography.Text>
      )}

      {/* <Typography.Text>{moment(createdDate).fromNow()}</Typography.Text> */}
    </Space>
  );
};

export default TimeLine;
