import { Button, Divider, Space, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { visibleDrawerVar } from 'api/cache';

import { DeleteOutlined } from '@ant-design/icons';

import { encodeUrlFile, generateFileName } from 'utils/other';

import { useRemoveRadio } from 'operations/mutations/radio/mutateRadio';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';
import { useCurrentUser } from 'operations/queries/queryCurrentUser';
import { USER_TYPE_VALUE } from 'appConstants';
import { AudioDownloadButton } from 'components/AudioUpload/AudioUpload';

const WithRadioContentBtns = ({ record, isRadio, isReplay }) => {
  const { mutate } = useRemoveRadio();
  const { data } = useCurrentUser();

  const isValidUser = useMemo(
    () =>
      data?.detail?.userType === USER_TYPE_VALUE.ADMIN ||
      data?.detail?.userType === USER_TYPE_VALUE.MANAGER ||
      record?.createdBy === data?.detail?.id,
    [data?.detail?.userType, data?.detail?.id, record?.createdBy]
  );

  const renderDownloadAudioBtn = useCallback(
    record => {
      return (
        record?.audioUrl &&
        (isRadio || isReplay) && (
          <AudioDownloadButton
            url={encodeUrlFile(record?.audioUrl)}
            fileName={generateFileName(record)}
          />
        )
      );
    },
    [isRadio, isReplay]
  );

  const renderDeleteBtn = useCallback(
    record => {
      return (
        isRadio &&
        isValidUser && (
          <DeleteConfirmPopup
            onDelete={() =>
              mutate({
                variables: {
                  id: record.id
                },
                refetchQueries: ['GetAllRadios'],
                onCompleted: () => {
                  return visibleDrawerVar(false);
                }
              })
            }
            title={
              <Typography.Text>
                Bạn muốn xoá số radio{' '}
                <Typography.Text strong>{record?.title}</Typography.Text> ?
              </Typography.Text>
            }
          >
            <Button danger icon={<DeleteOutlined />}>
              Xóa số radio
            </Button>
          </DeleteConfirmPopup>
        )
      );
    },
    [isRadio, isValidUser, mutate]
  );

  return (
    <>
      {((isRadio && isValidUser) || record?.audioUrl) && <Divider />}

      <Space>
        {renderDownloadAudioBtn(record)}
        {renderDeleteBtn(record)}
      </Space>
    </>
  );
};

export default WithRadioContentBtns;
