import { Drawer } from 'antd';
import React, { useCallback, useContext } from 'react';
import { TrendTopicContext } from '../TrendTopicProvider';
import TrendTopicAuditLogContent from './TrendTopicAuditLogContent';

const TrendTopicAuditLog = () => {
  const { setAuditLogTarget, auditLogTarget } = useContext(TrendTopicContext);

  const closeDrawer = useCallback(() => {
    setAuditLogTarget();
  }, [setAuditLogTarget]);

  return (
    <Drawer
      visible={!!auditLogTarget}
      placement="right"
      width={600}
      onClose={closeDrawer}
      title="Lịch sử chỉnh sửa"
      getContainer={false}
      destroyOnClose
    >
      <TrendTopicAuditLogContent auditLogTarget={auditLogTarget} />
    </Drawer>
  );
};

export default TrendTopicAuditLog;
