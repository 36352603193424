import { useQuery } from '@apollo/client';
import { Col, Row, Spin, Typography } from 'antd';
import { GET_CATEGORY_BY_ID } from 'operations/queries/queryCategory';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import CategoryDetailLeft from './CategoryDetailLeft';
import CategoryDetailRight from './CategoryDetailRight';

const CategoryDetail = () => {
  const { id } = useParams();

  const isCreating = useMemo(() => !id, [id]);

  const { loading } = useQuery(GET_CATEGORY_BY_ID, {
    variables: { id },
    skip: isCreating
  });

  const renderCategory = useCallback(() => {
    return loading ? (
      <></>
    ) : (
      <Row gutter={8} style={{ flexWrap: 'nowrap' }}>
        <Col
          style={{
            flexGrow: 1,
            ...(isCreating
              ? {}
              : {
                  flexBasis: 'calc(100% - 350px)',
                  maxWidth: 'calc(100% - 350px)'
                })
          }}
        >
          <CategoryDetailLeft isCreating={isCreating} />
        </Col>

        {!isCreating && (
          <Col style={{ flexBasis: 350, minWidth: 350 }}>
            <CategoryDetailRight />
          </Col>
        )}
      </Row>
    );
  }, [isCreating, loading]);

  return (
    <Spin spinning={loading}>
      <Typography.Title>Chi tiết chương trình</Typography.Title>
      {renderCategory()}
    </Spin>
  );
};

export default CategoryDetail;
