import React from 'react';
import { Select } from 'antd';
import {
  CATEGORY_TYPE_DICTIONARY,
  CATEGORY_TYPE_INT_DICTIONARY
} from 'appConstants';

const CategoryTypeSelect = ({
  value,

  integerValue,
  ...props
}) => {
  return (
    <Select
      style={{ width: '100%', fontWeight: 'normal' }}
      placeholder="Chọn loại chương trình"
      value={value}
      allowClear
      {...props}
    >
      {Object.keys(
        integerValue ? CATEGORY_TYPE_INT_DICTIONARY : CATEGORY_TYPE_DICTIONARY
      ).map((key, index) => (
        <Select.Option key={key} value={integerValue ? index + 1 + '' : key}>
          {integerValue
            ? CATEGORY_TYPE_INT_DICTIONARY[key]
            : CATEGORY_TYPE_DICTIONARY[key]}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CategoryTypeSelect;
