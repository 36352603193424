import { useReactiveVar } from '@apollo/client';
import { Card, Col, Divider, Form, Input, InputNumber, Row } from 'antd';
import { timeLineVar } from 'api/cache';
import {
  AUDIO_UPLOAD_SIZE_LIMIT,
  CATEGORY_TYPE_KEY,
  IMG_UPLOAD_SIZE_LIMIT,
  RADIO_STATUS_TYPE_KEY,
  SCRIPT_UPLOAD_SIZE_LIMIT
} from 'appConstants';
import { currentTimeAudioVar, durationTimeAudioVar } from 'api/cache';

import AudioUpload from 'components/AudioUpload';
import AvatarUpload from 'components/AvatarUpload';
import DocumentUpload from 'components/DocumentUpload';
import { useCurrentUser } from 'operations/queries/queryCurrentUser';
import React, { useContext, useMemo } from 'react';

import { encodeUrlFile, generateFileName } from 'utils/other';
import OtherFields from './components/OtherFields';
import RadioReplaySchedules from './components/RadioReplaySchedules';
import RadioSaveButton from './components/RadioSaveButton';
import TimeLine from './components/TimeLine';
import { RadioDetailContext } from './RadioDetail';
import EditDraftRadioModal from './components/EditDraftRadioModal';
import MergeModal from './components/MergeModal';
import MediaUploadDraft from 'components/MediaUpload/MediaUploadDraft';

const RadioDetailLeft = ({ radio }) => {
  const { form } = useContext(RadioDetailContext);
  const { data } = useCurrentUser();
  const currentUser = useMemo(() => data?.detail, [data?.detail]);
  const isBasicCategory = useMemo(
    () => radio?.category?.typeName === CATEGORY_TYPE_KEY.BASIC,
    [radio?.category?.typeName]
  );
  const hasDraft = useMemo(() => radio?.radioDraft, [radio?.radioDraft]);

  const timeLine = useReactiveVar(timeLineVar);

  const isDraftOrWaiting = useMemo(
    () =>
      radio.statusName === RADIO_STATUS_TYPE_KEY.DRAFT ||
      radio.statusName === RADIO_STATUS_TYPE_KEY.WAITING,
    [radio.statusName]
  );

  const isValidOwner = useMemo(
    () => currentUser?.id === radio?.creator?.id,
    [currentUser?.id, radio?.creator?.id]
  );

  const isValidUser = useMemo(
    () => currentUser?.userType === 2 || currentUser?.userType === 1,
    [currentUser?.userType]
  );

  const shouldDisableAudio = useMemo(
    () => isDraftOrWaiting || !(isValidUser || isValidOwner),
    [isDraftOrWaiting, isValidOwner, isValidUser]
  );
  const handleInputChange = e => {
    const { id, value } = e.target;
    const normalizedValue = value.replace(/[\u{1D400}-\u{1D7FF}]/gu, '');
    form.setFieldsValue({ [id]: normalizedValue });
  };
  return (
    <>
      <Card title="Nội dung phát sóng">
        <Form form={form} layout="vertical">
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                name="radioNumber"
                label="Số radio"
                rules={[{ required: true, message: 'Nhập số radio!' }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <OtherFields radio={radio} />
          </Row>

          <Divider></Divider>

          <Form.Item
            name="title"
            label="Tiêu đề (tối đa 22 từ)"
            rules={[
              { required: true, message: 'Nhập Tiêu đề!' },
              () => ({
                validator(_, value) {
                  if (
                    value.trim().split(' ').length > 22 ||
                    value.trim().length > 100
                  ) {
                    return Promise.reject(
                      'Tiêu đề không quá 22 từ và không quá 100 ký tự!'
                    );
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <Input
              value={form.getFieldValue('title')}
              placeholder={radio.title}
              onChange={handleInputChange}
            />
          </Form.Item>

          <Form.Item
            rules={[
              { required: true, message: 'Nhập đi mà!' },
              () => ({
                validator(_, value) {
                  if (value.trim().split(' ').length > 49) {
                    return Promise.reject('Giới thiệu không quá 49 từ!');
                  }
                  return Promise.resolve();
                }
              })
            ]}
            name="introduction"
            label="Giới thiệu ngắn (Sapo)"
          >
            <Input.TextArea
              value={form.getFieldValue('introduction')}
              placeholder={radio.introduction}
              onChange={handleInputChange}
            />
          </Form.Item>

          <Form.Item
            name="scenarioFile"
            label={`File kịch bản (.doc, .docx. Dung lượng tối đa ${SCRIPT_UPLOAD_SIZE_LIMIT} MB )`}
            valuePropName="file"
            rules={[{ required: true, message: 'Tải file kịch bản!' }]}
          >
            <DocumentUpload
              size={64}
              disabled={
                radio?.statusName === RADIO_STATUS_TYPE_KEY.APPROVED ||
                radio?.statusName === RADIO_STATUS_TYPE_KEY.READY ||
                !(isValidUser || isValidOwner)
              }
            />
          </Form.Item>
          <Form.Item
            name="mediaUrls"
            label={`File Băng (Định dang .mp3. Dung lượng tối đa ${AUDIO_UPLOAD_SIZE_LIMIT} MB)`}
            valuePropName="file"
          >
            <MediaUploadDraft />
          </Form.Item>

          <Form.Item
            label={`Poster ( .jpeg, ,jpg, .png. Dung lượng tối đa ${IMG_UPLOAD_SIZE_LIMIT} MB )`}
            name="image"
            valuePropName="file"
            // rules={[{ required: true, message: 'Tải poster!' }]}
          >
            <AvatarUpload
              showName
              checkSquare
              avatarShape="square"
              size={128}
              fontSize={64}
              fullName={radio?.title}
              uploadParams={{ wRatio: 5, hRatio: 3, maxDimension: 2000 }}
              ratio={5 / 3}
              typeName="thumbRadio"
            />
          </Form.Item>

          <Form.Item
            name="audioUrl"
            label={`Audio (Định dang .mp3. Dung lượng tối đa ${AUDIO_UPLOAD_SIZE_LIMIT} MB)`}
            valuePropName="file"
            rules={[
              { required: !isDraftOrWaiting, message: 'Tải file audio!' }
            ]}
          >
            <AudioUpload
              currentTimeCallback={currentTimeAudioVar}
              durationTimeCallback={durationTimeAudioVar}
              disabled={shouldDisableAudio}
              fallbackValue={radio?.audioUrl && encodeUrlFile(radio?.audioUrl)}
              form={form}
              fileName={generateFileName(radio || {})}
              showModal={!isBasicCategory}
            />
          </Form.Item>

          <TimeLine
            disabled={shouldDisableAudio}
            required={!isDraftOrWaiting}
            audioUrl={radio?.audioUrl}
          />

          {radio.statusName === RADIO_STATUS_TYPE_KEY.CHANGE_REQUEST &&
          hasDraft ? (
            <Row gutter={8}>
              <Col span={12}>
                <EditDraftRadioModal />
              </Col>

              <Col span={12}>
                <MergeModal />
              </Col>
            </Row>
          ) : (
            <RadioSaveButton
              isValidUser={isValidUser}
              isValidOwner={isValidOwner}
              timeLine={timeLine}
              radio={radio}
            />
          )}
        </Form>
      </Card>

      <RadioReplaySchedules />
    </>
  );
};

export default RadioDetailLeft;
