import { Button, Col, Row, Spin } from 'antd';
import { OUTSIDE_VOH_API, RADIO_STATUS_TYPE_KEY } from 'appConstants';
import moment from 'moment';
import axios from 'axios';
import {
  useDraftingRadio,
  useEditRadio
} from 'operations/mutations/radio/mutateRadio';

import React, { useMemo, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { sendMessage } from 'utils/other';
import { RadioDetailContext } from '../RadioDetail';

let btnProps = {
  type: 'primary',
  block: true
};

const colProps = {
  style: { flexGrow: 1, flexBasis: 0 }
};

const extract = ({
  audioUrl: _audioUrl,
  image: _image,
  scenarioFile: _scenarioFile
}) => {
  const image = _image?.file?.response?.Data || _image;
  const audioUrl = _audioUrl?.file?.response?.Data || _audioUrl;
  const scenarioFile = _scenarioFile?.file?.response?.Data || _scenarioFile;

  return { image, audioUrl, scenarioFile };
};

const RadioSaveButton = ({ timeLine, isValidUser, isValidOwner }) => {
  const { radio, form, getLoading } = useContext(RadioDetailContext);
  const prevStatus = useMemo(() => radio?.prevStatus, [radio?.prevStatus]);
  const nextStatus = useMemo(() => radio?.nextStatus, [radio?.nextStatus]);
  const statusName = useMemo(() => radio?.statusName, [radio?.statusName]);
  const { mutate: editRadio, loading } = useEditRadio();

  const { mutate: draftingRadio } = useDraftingRadio();
  const { id } = useParams();

  const handleSave = useCallback(async () => {
    try {
      const { mcIds, editorIds, guestIds, tagIds, ...params } =
        await form.validateFields();
      const timeLineSummary = timeLine?.filter(
        x => x.content && x.content.length > 0
      );
      params.summary = timeLineSummary?.map(item => ({
        time: moment(item.time).format('HH:mm:ss'),
        content: item.content
      }));

      let _guestIds = guestIds?.filter(x => !x.includes('isnew_')) ?? [];

      let _tagIds =
        tagIds?.filter(
          x =>
            typeof x === 'number' ||
            (typeof x === 'string' && !x.includes('isnew_'))
        ) ?? [];

      let isHaveGuest = params.checkBoxGuest ? 2 : 1

      delete params.radioReplaySchedules;
      delete params.broadcastTimeFrom;
      delete params.duration;
      delete params.broadcastTime;
      delete params.timeTo;
      delete params.checkBoxGuest;

      const { image, audioUrl, scenarioFile } = extract(params);

      const { data } = await editRadio({
        variables: {
          input: {
            ...params,
            id,
            image,
            scenarioFile,
            audioUrl,
            mcIds,
            editorIds,
            isHaveGuest,
            guestIds: _guestIds
          }
        }
      });

      saveTags(_tagIds);

      sendMessage({ command: 'EDIT_RADIO', data: data?.fullyEditRadio });
    } catch (e) { }
  }, [editRadio, form, id, timeLine]);

  const onCancel = useCallback(async () => {
    try {
      const { data } = await draftingRadio({
        variables: {
          input: {
            id: radio?.id
          }
        }
      });

      sendMessage({ command: 'EDIT_RADIO', data: data?.draftingRadio });
    } catch { }
  }, [draftingRadio, radio?.id]);

  const saveTags = useCallback(
    async tagIds => {
      const response = await axios.post(
        `${OUTSIDE_VOH_API}/api/Others/RadioTagsCreate`,
        {
          radioId: radio?.id,
          tagIds: tagIds
        }
      );
    },
    [radio?.id]
  );

  const renderSaveBtn = useCallback(
    nextStatus => {
      btnProps = { ...btnProps, onClick: handleSave };

      return (
        <Col {...colProps}>
          {(() => {
            switch (nextStatus?.value) {
              case RADIO_STATUS_TYPE_KEY.WAITING:
                return <Button {...btnProps}>Gửi duyệt</Button>;

              case RADIO_STATUS_TYPE_KEY.APPROVED:
                return !isValidUser ? (
                  <Button {...btnProps}>Lưu</Button>
                ) : (
                  <Button {...btnProps}>Duyệt</Button>
                );

              case RADIO_STATUS_TYPE_KEY.READY:
                return <Button {...btnProps}>Hoàn thành</Button>;

              default:
                return (
                  <Button {...btnProps}>Lưu và đợi thay đổi được duyệt</Button>
                );
            }
          })()}
        </Col>
      );
    },
    [handleSave, isValidUser]
  );

  const renderCancelBtn = useCallback(
    prevStatus => {
      switch (prevStatus?.value) {
        case RADIO_STATUS_TYPE_KEY.DRAFT:
          if (
            radio?.statusName === RADIO_STATUS_TYPE_KEY.APPROVED &&
            !isValidUser
          ) {
            return <></>;
          }
          return (
            <Col {...colProps}>
              <Button {...btnProps} onClick={onCancel} danger>
                Gỡ duyệt
              </Button>
            </Col>
          );

        default:
          return <></>;
      }
    },
    [isValidUser, onCancel, radio?.statusName]
  );

  return (
    <Spin spinning={loading || getLoading}>
      {isValidUser || isValidOwner ? (
        <Row gutter={8}>
          {renderCancelBtn(prevStatus)}
          {renderSaveBtn(nextStatus, statusName)}
        </Row>
      ) : (
        <></>
      )}
    </Spin>
  );
};

export default RadioSaveButton;
