import React from 'react';
import { Select } from 'antd';
import { USER_TYPE_DICTIONARY, USER_TYPE_STR_DICTIONARY } from 'appConstants';

const UserTypeSelect = ({ value, disableSelected, integerValue, ...props }) => {
  return (
    <Select
      style={{ width: 200, fontWeight: 'normal' }}
      placeholder="Chọn loại user"
      value={value}
      allowClear
      {...props}
    >
      {Object.keys(
        integerValue ? USER_TYPE_DICTIONARY : USER_TYPE_STR_DICTIONARY
      ).map((key, index) => (
        <Select.Option
          key={key}
          value={integerValue ? index + 1 + '' : key}
          disabled={disableSelected ? value === key : false}
        >
          {integerValue
            ? USER_TYPE_DICTIONARY[key]
            : USER_TYPE_STR_DICTIONARY[key]}
        </Select.Option>
      ))}
    </Select>
  );
};

export default UserTypeSelect;
