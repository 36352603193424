import viLocale from '@fullcalendar/core/locales/vi';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

import timeGridPlugin from '@fullcalendar/timegrid';
import { Spin } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import {
  CATEGORY_STATUS_KEY_INT,
  CHANNEL_BROADCAST_KEY,
  RADIO_STATUS_TYPE_COLOR
} from 'appConstants';
import moment from 'moment';
import queryString from 'query-string';
import React, { useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getCookie } from 'utils/authority';
import ChannelBox from './components/ChannelBox';
import EventContent from './components/EventContent';

import useCalendar from './useCalendar';
import { apolloClient } from 'api';
import { GET_ALL_RADIOS_CALENDAR } from 'operations/queries/queryRadio';

export const generateColor = status => {
  return RADIO_STATUS_TYPE_COLOR[status];
};

const FullScheduleCalendar = React.forwardRef((props, ref) => {
  const calendarRef = useRef();

  let history = useHistory();

  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const { events, categoryLoading, radioLoading, radioReplayScheduleLoading } =
    useCalendar(calendarRef);

  const handleEventClick = arg => {
    const { title, id, extendedProps, start, end } = arg.event;
    const record = { title, id, start, end, ...extendedProps };

    visibleDrawerVar({
      visible: true,
      record: { ...record, forCategory: { start, end } }
    });
  };

  const handleChangeDateInfo = dateInfo => {
    const typeView = dateInfo?.view?.type;
    const start = dateInfo?.start;
    const end = dateInfo?.end;

    const startDate = moment(start).format('YYYY-MM-DD');
    const endDate = moment(end).format('YYYY-MM-DD');
    history.push({
      pathname: '/lich-phat-song',
      search: queryString.stringify({
        ...searchParams,
        typeView,
        startDate,
        endDate,
        channel:
          searchParams?.channel ||
          (getCookie('BROADCAST_SCHEDULE_CHANNEL_DEFAULT')
            ? getCookie('BROADCAST_SCHEDULE_CHANNEL_DEFAULT')
            : CHANNEL_BROADCAST_KEY.FM999MHZ)
      })
    });
  };

  useEffect(() => {
    const receiveMessage = e => {
      if (e.key !== 'message') return;
      var message = JSON.parse(e.newValue);
      if (!message) return;

      if (message.command === 'EDIT_RADIO') {
        visibleDrawerVar({ visible: true, record: message.data });
        const variables = {
          where: {
            broadcastTime: {
              gte:
                moment(searchParams?.startDate).format('YYYY-MM-DD') + '+00:00',
              lte: moment(searchParams?.endDate).format('YYYY-MM-DD') + '+00:00'
            },
            category: {
              channelId: {
                eq: searchParams?.channel
              },
              status: {
                eq: CATEGORY_STATUS_KEY_INT.ACTIVE
              }
            }
          }
        };

        const res = apolloClient.readQuery({
          query: GET_ALL_RADIOS_CALENDAR,
          variables: variables
        });

        apolloClient.writeQuery({
          query: GET_ALL_RADIOS_CALENDAR,
          variables,
          data: {
            allRadios: res.allRadios.map(ele =>
              ele?.id === message?.data?.id ? message.data : ele
            )
          }
        });
      }
    };

    window.addEventListener('storage', receiveMessage);

    return () => {
      window.removeEventListener('storage', receiveMessage);
    };
  }, [searchParams?.channel, searchParams?.endDate, searchParams?.startDate]);

  return (
    <div className="schedule-section">
      {
        <Spin
          spinning={
            categoryLoading || radioLoading || radioReplayScheduleLoading
          }
          tip={`Đợi tí nha...`}
        >
          <ChannelBox />
          <FullCalendar
            timeZone="local"
            ref={calendarRef}
            locale={viLocale}
            plugins={[
              dayGridPlugin,
              interactionPlugin,
              timeGridPlugin,
              listPlugin
            ]}
            eventClick={handleEventClick}
            eventContent={EventContent}
            expandRows={true}
            selectAllow={true}
            navLinks={true}
            nowIndicator={true}
            dayMaxEvents={true}
            weekNumbers={true}
            height="auto"
            weekNumberFormat={{ week: 'long' }}
            initialView={searchParams.typeView || 'timeGridWeek'}
            titleFormat={{
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }}
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short'
            }}
            allDaySlot={false}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'timeGridWeek,timeGridDay,listWeek'
            }}
            slotDuration="00:05:00"
            events={events}
            datesSet={handleChangeDateInfo}
            slotEventOverlap={false}
            initialDate={searchParams.startDate || null}
          />
        </Spin>
      }
    </div>
  );
});

export default FullScheduleCalendar;
