import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select
} from 'antd';

import { GET_CATEGORY_BY_ID } from 'operations/queries/queryCategory';
import {
  useEditCategory,
  useAddCategory
} from 'operations/mutations/category/mutateCategory';

import TopicSelect from 'pages/Category/components/TopicSelect';
import ChannelSelect from 'components/ChannelSelect';

import CategoryStatusSelect from 'components/CategoryStatusSelect';
import { CATEGORY_STATUS_KEY, DATE_FORMAT } from 'appConstants';
import moment from 'moment';
import AvatarUpload from 'components/AvatarUpload';
import { get } from 'utils/lodash';
import CategoryTypeSelect from 'components/CategoryTypeSelect';

import CategoryAutoComplete from 'pages/BroadcastSchedule/components/CategoryAutoComplete';
import AutoPublishCateSelect from 'components/AutoPublishCateSelect';

const CategoryInfo = ({ isCreating }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();
  const { data } = useQuery(GET_CATEGORY_BY_ID, {
    variables: { id },
    skip: isCreating
  });
  const { mutate: handleEditCategory } = useEditCategory();
  const { mutate: handleAddCategory } = useAddCategory();

  const category = useMemo(() => data?.category, [data?.category]);

  useEffect(() => {
    if (category) {
      form.setFieldsValue({
        title: category.title,
        introduction: category.introduction,
        topicId: category.topicId,
        channel: category.channel,
        duration: category.duration,
        status: category.statusName.toUpperCase(),
        onAirFrom: moment(category.onAirFrom),
        onAirTo: moment(category.onAirTo),
        image: category.image,
        type: category.typeName,
        emails: category.emails || [],
        rootCategoryId: category.rootCategoryId,
        isAutoPublishName: category.isAutoPublishName,
        emailViews: category.emailView || [],
        phoneNumber: category.phoneNumber,
        facebook: category.facebook,
        instagram: category.instagram,
        tiktok: category.tiktok,
        youtube: category.youtube,
        twitter: category.twitter,
        spotify: category.spotify
      });
    }
  }, [category, form]);

  const onSave = useCallback(async () => {
    try {
      const {
        topicId,
        title,
        channel,
        duration,
        introduction,
        status,
        image: _image,
        onAirFrom,
        onAirTo,
        type,
        emails,
        rootCategoryId,
        isAutoPublishName,
        emailViews,
        phoneNumber,
        facebook,
        instagram,
        tiktok,
        youtube,
        twitter,
        spotify
      } = await form.validateFields();

      const image = get(_image, '[0].response.Data', _image);

      if (isCreating) {
        await handleAddCategory({
          variables: {
            input: {
              title,
              topicId,
              channel,
              duration,
              introduction,
              onAirFrom: moment(onAirFrom),
              onAirTo: onAirTo && moment(onAirTo),
              image,
              type,
              rootCategoryId:
                rootCategoryId === undefined ? '' : rootCategoryId,
              emails: emails || [],
              isAutoPublish: isAutoPublishName,

              emailViews: emailViews || [],
              phoneNumber,
              facebook,
              instagram,
              tiktok,
              youtube,
              twitter,
              spotify
            }
          },
          onCompleted: ({ addCategory }) => {
            history.push('/chi-tiet-chuong-trinh/' + addCategory?.id);
          }
        });

        return;
      }

      await handleEditCategory({
        variables: {
          input: {
            id,
            title,
            topicId,
            channel,
            duration,
            introduction,
            status,
            onAirFrom: moment(onAirFrom),
            onAirTo: onAirTo && moment(onAirTo),
            image,
            type,
            emails: emails || [],
            rootCategoryId: rootCategoryId === undefined ? '' : rootCategoryId,
            isAutoPublish: isAutoPublishName,

            emailViews: emailViews || [],
            phoneNumber,
            facebook,
            instagram,
            tiktok,
            youtube,
            twitter,
            spotify
          }
        }
      });
    } catch (e) {
      // throw e;
    }
  }, [form, handleAddCategory, handleEditCategory, history, id, isCreating]);

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  async function formValidator({ onAirFrom, onAirTo }) {
    if (
      onAirTo &&
      onAirFrom &&
      moment(onAirFrom).isSameOrAfter(moment(onAirTo), 'day')
    ) {
      return Promise.reject(['Ngày không hợp lệ!']);
    }

    return Promise.resolve();
  }

  const isRootSelectDisabled = useMemo(() => {
    return category?.childCategories?.length;
  }, [category?.childCategories?.length]);
  return (
    <Card title="Thông tin chương trình" style={{ marginBottom: 8 }}>
      <Form form={form} layout="vertical">
        <Form.Item
          name="title"
          label="Tên chương trình"
          rules={[{ required: true, message: 'Nhập tên!' }]}
        >
          <Input placeholder="Nhập tên" />
        </Form.Item>

        <Row gutter={8}>
          <Col span={8}>
            <TopicSelect />
          </Col>

          <Col span={8}>
            <Form.Item
              name="channel"
              label="Kênh phát sóng"
              rules={[{ required: true, message: 'Chọn kênh phát sóng!' }]}
            >
              <ChannelSelect disabled={!isCreating} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="duration"
              label="Thời lượng (phút)"
              rules={[{ required: true, message: 'Nhập thời lượng!' }]}
            >
              <InputNumber
                disabled={
                  category?.statusName === CATEGORY_STATUS_KEY.ACTIVE ||
                  category?.radioStatistics?.total > 0
                }
                placeholder="Nhập phút"
                style={{ width: '100%' }}
                min={1}
                max={12 * 60}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={8}>
            <Form.Item
              name="onAirFrom"
              label="Phát sóng từ"
              rules={[{ required: true, message: 'Chọn thời gian phát sóng!' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={DATE_FORMAT}
                placeholder="Chọn ngày"
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="onAirTo"
              label="Phát sóng đến"
              rules={[
                ({ getFieldsValue }) => ({
                  validator: () => {
                    return formValidator(
                      getFieldsValue(['onAirFrom', 'onAirTo'])
                    );
                  }
                })
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                format={DATE_FORMAT}
                placeholder="Chọn ngày"
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="type"
              label="Loại chương trình"
              rules={[{ required: true, message: 'Chọn loại chương trình!' }]}
            >
              <CategoryTypeSelect />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={8}>
            <Form.Item
              rules={[{ required: true, message: 'Chọn tự động công khai!' }]}
              name="isAutoPublishName"
              label="Tự động công khai"
            >
              <AutoPublishCateSelect />
            </Form.Item>
          </Col>

          <Col span={16}>
            <Form.Item name="rootCategoryId" label="Chương trình gốc">
              <CategoryAutoComplete
                disabled={isRootSelectDisabled}
                allowClear
                category={category?.rootCategory}
                extraParams={{
                  rootCategoryId: {
                    eq: null
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="introduction" label="Giới thiệu">
          <Input.TextArea rows={7} placeholder="Nhập giới thiệu" />
        </Form.Item>
        <Row gutter={8}>
          <Col span={16}>
            <Form.Item
              name="emails"
              label="Nhóm email phụ trách duyệt chương trình (nhập email rồi enter)"
            >
              <Select
                // dropdownRender={() => null}
                // dropdownStyle={{ display: 'none' }}
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Nhập email"
              />
            </Form.Item>
          </Col>

          {!isCreating && (
            <Col span={8}>
              <Form.Item label="Trạng thái" name="status">
                <CategoryStatusSelect
                  disabled={category?.statusName.toUpperCase()}
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Form.Item
          name="image"
          label="Poster"
          valuePropName="file"
          getValueFromEvent={normFile}
        >
          <AvatarUpload
            avatarShape="square"
            size={128}
            fontSize={64}
            fullName={category?.title}
          />
        </Form.Item>

        <Row gutter={8}>
          <Col span={16}>
            <Form.Item
              name="emailViews"
              label="Nhóm email nhận thông báo (nhập email rồi enter)"
            >
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Nhập email"
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Số điện thoại chương trình" name="phoneNumber">
              <Input placeholder="Nhập số điện thoại" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={8}>
            <Form.Item name="facebook" label="Facebook">
              <Input placeholder="Nhập link Facebook" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="instagram" label="Instagram">
              <Input placeholder="Nhập link Instagram" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="youtube" label="Youtube">
              <Input placeholder="Nhập link Youtube" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item name="twiter" label="Twiter">
              <Input placeholder="Nhập link Twiter" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="spotify" label="Spotify">
              <Input placeholder="Nhập link Spotify" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="tiktok" label="Tiktok">
              <Input placeholder="Nhập link Tiktok" />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row justify="end">
        <Col>
          <Button onClick={onSave} type="primary">
            Lưu
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default CategoryInfo;
