import {
  FileImageOutlined,
  UploadOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { Button, notification, Popover, Space, Typography } from 'antd';
import { SCRIPT_UPLOAD_SIZE_LIMIT } from 'appConstants';
import Upload from 'components/Upload';
import React, { useCallback, useEffect, useState } from 'react';
import { encodeUrlFile } from 'utils/other';

const DocumentUpload = ({
  size,
  onDone = f => f,
  disabled,
  message = 'Đã cập nhật file kịch bản',
  disableLink,
  downloadBtn,
  onDownloadCallback = f => f,
  ...props
}) => {
  const [fileUrl, setFileUrl] = useState(null);

  const [popoverVisible, setPopoverVisible] = useState(false);

  const handlechangeFile = fileUrl => {
    setFileUrl(fileUrl);

    notification.success({
      message
    });
  };

  useEffect(() => {
    if (props.file) {
      if (typeof props.file === 'string') {
        //NOTE: form item default value

        setFileUrl(props.file);

        return;
      } else {
        //NOTE: form item after upload
        if (props.file.status === 'done') {
          setFileUrl(props.file.response.Data);
          onDone(props.file.response.Data);
          return;
        }
      }
    } else {
      //NOTE: normal default value
      setFileUrl(null);
    }
  }, [onDone, props.file]);

  const onChange = args => {
    if (props.onChange) {
      props.onChange(args);
    }

    if (args.file.status === 'done') {
      handlechangeFile(args.file.response.Data);
    }
  };

  const beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < SCRIPT_UPLOAD_SIZE_LIMIT;
    if (!isLt2M) {
      notification.error({
        message: 'Up file dưới ' + SCRIPT_UPLOAD_SIZE_LIMIT + 'mb thôi nha 😡'
      });
    }
    return isLt2M;
  };

  const onDownLoadClick = useCallback(() => {
    window.open(encodeUrlFile(fileUrl), '_blank');
    onDownloadCallback();
  }, [fileUrl, onDownloadCallback]);

  const content = (
    <div>
      <Space direction="vertical">
        <Button
          disabled={!fileUrl}
          type="link"
          onClick={() => {
            window.open(
              `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                encodeUrlFile(fileUrl)
              )}`,
              '_blank'
            );
          }}
          icon={<FileImageOutlined />}
        >
          Xem kịch bản
        </Button>

        <Button
          disabled={!fileUrl}
          type="link"
          onClick={onDownLoadClick}
          icon={<DownloadOutlined />}
        >
          Tải về
        </Button>
      </Space>
    </div>
  );

  const renderLink = useCallback(() => {
    const Typo = disableLink ? Typography.Text : Typography.Link;
    return (
      <Typo ellipsis style={{ width: '100%', paddingRight: 8 }} target="_blank">
        {fileUrl?.split('/')?.pop() || fileUrl}
      </Typo>
    );
  }, [disableLink, fileUrl]);

  return (
    <>
      <Upload
        accept="application/msword, .doc, .docx"
        maxCount={1}
        showUploadList={false}
        onChange={onChange}
        beforeUpload={beforeUpload}
      >
        <Button disabled={disabled} type="default" icon={<UploadOutlined />}>
          Tải lên
        </Button>
      </Upload>

      {fileUrl && (
        <Popover
          placement="left"
          title={null}
          content={content}
          trigger="click"
          onVisibleChange={visible => {
            if (disableLink) return;
            return setPopoverVisible(visible);
          }}
          visible={popoverVisible}
        >
          <div style={{ marginTop: 8 }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {renderLink()}
            </span>
          </div>
        </Popover>
      )}

      {downloadBtn && fileUrl && (
        <div style={{ marginTop: 8 }}>
          <Button
            icon={<DownloadOutlined />}
            disabled={!fileUrl}
            onClick={onDownLoadClick}
            type="primary"
          >
            Tải xuống
          </Button>
        </div>
      )}
    </>
  );
};

export default DocumentUpload;
