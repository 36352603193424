import { Button, Form, Input, Typography } from 'antd';
import { useLogin } from 'operations/mutations/auth/mutateAuth';
import Sakura from 'other/Sakura';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const Login = () => {
  const firstFocusRef = useRef(null);
  const { mutate: login, loading: isLoggingIn } = useLogin();

  const onFinish = async values => {
    try {
      await login({ variables: { input: { ...values } } });
    } catch (error) {}
  };

  useEffect(() => {
    if (firstFocusRef.current) firstFocusRef.current.focus();
  }, []);

  useEffect(() => {
    var sakura = new Sakura('body', {
      maxSize: 14,
      minSize: 10
    });

    return () => {
      sakura.stop();
    };
  }, []);

  return (
    <div className="unauthorized-container-background">
      {/* <Switch
        style={{ margin: 20 }}
        checked={value}
        onChange={value => setValue(value)}
      /> */}

      <div className="unauthorized-container">
        <Typography.Title style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          Đăng nhập
        </Typography.Title>

        <div className="unauthorized-container__form-wrapper">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Nhập email!' }]}
            >
              <Input ref={firstFocusRef} />
            </Form.Item>

            <Form.Item
              label="Mật khẩu"
              name="password"
              rules={[{ required: true, message: 'Nhập mật khẩu!' }]}
            >
              <Input.Password />
            </Form.Item>

            {/* <div style={{ textAlign: 'end', marginBottom: '0.5em' }}>
              <Link to="/quen-mat-khau">Quên mật khẩu?</Link>
            </div> */}

            <Form.Item>
              <Button
                loading={isLoggingIn}
                style={{ width: '100%' }}
                type="primary"
                htmlType="submit"
              >
                Đăng nhập
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
