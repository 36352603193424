import { useReactiveVar } from '@apollo/client';
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Row,
  Table,
  TimePicker,
  Tooltip,
  Space,
  Typography
} from 'antd';
import {
  currentTimeAudioVar,
  durationTimeAudioVar,
  timeLineVar
} from 'api/cache';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import {
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';

export const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  form,
  ...restProps
}) => {
  const inputRef = useRef(null);
  const inputTimeRef = useRef(null);
  const handleInputChange = e => {
    const { id, value } = e.target;
    const normalizedValue = value.replace(/[\u{1D400}-\u{1D7FF}]/gu, '');
    form.setFieldsValue({ [id]: normalizedValue });
  };
  const inputNode =
    inputType === 'time' ? (
      <TimePicker
        onSelect={time => {
          form.setFieldsValue({ time });
        }}
        ref={inputTimeRef}
      />
    ) : (
      <Input.TextArea
        ref={inputRef}
        value={form.getFieldValue('content')}
        onChange={handleInputChange}
      />
    );
  useEffect(() => {
    if (editing) {
      inputTimeRef.current && inputTimeRef.current.focus();
    }
  }, [editing, form]);
  let rules = [
    {
      required: true,
      message: `Nhập ${title}!`
    }
  ];
  if (inputType != 'time') {
    rules.push({ min: 10, whitespace: true, message: `Nhập thiểu 10 ký tự` });
    rules.push({
      max: 100,
      whitespace: true,
      message: `Nhập tối đa 100 ký tự`
    });
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={rules}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const TimeLine = ({ disabled, required, content, ...props }) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const currentTimeAudio = useReactiveVar(currentTimeAudioVar);
  const durationTimeAudio = useReactiveVar(durationTimeAudioVar);
  const timeLine = useReactiveVar(timeLineVar);

  const isEditing = record => record.key === editingKey;

  const edit = record => {
    form.setFieldsValue({
      ...record
    });
    setEditingKey(record.key);
  };

  const cancel = _key => {
    timeLineVar(timeLine.filter(({ key }) => key !== _key));
    setEditingKey('');
  };

  const remove = _key => {
    timeLineVar(timeLine.filter(({ key }) => key !== _key));
  };
  useEffect(() => {
    console.log('durationTimeAudio', durationTimeAudio);
  }, [durationTimeAudio]);
  useEffect(() => {
    console.log('currentTimeAudio', currentTimeAudio);
  }, [currentTimeAudio]);

  const save = async key => {
    try {
      const row = await form.validateFields();
      const checkTimeExists =
        timeLine && timeLine.length > 0
          ? timeLine.filter(item => {
              return (
                key != item.key &&
                moment(row.time).format('HH:mm:ss') ==
                  moment(item.time).format('HH:mm:ss')
              );
            })
          : undefined;
      let timeItem = -1;
      try {
        const timeRows = moment(row.time).format('HH:mm:ss').split(':');
        timeItem =
          Number(timeRows[0]) * 3600 +
          Number(timeRows[1]) * 60 +
          Number(timeRows[2]);
      } catch (e) {}
      if (timeItem > -1 && timeItem > durationTimeAudio) {
        alert('Mốc thời gian không được quá thời gian của audio');
      } else if (checkTimeExists && checkTimeExists.length > 0) {
        alert('Mốc thời gian không được trùng nhau');
      } else {
        const newData = [...timeLine];
        const index = newData.findIndex(item => key === item.key);

        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
          timeLineVar(newData);
          setEditingKey('');
        } else {
          newData.push(row);
          timeLineVar(newData);
          setEditingKey('');
        }
      }
    } catch (errInfo) {}
  };

  const columns = [
    {
      width: 150,
      title: 'Thời gian',
      dataIndex: 'time',
      editable: true,
      render: time => {
        return (
          <Typography.Text id={moment(time).format('HH_mm_ss')}>
            {moment(time).format('HH:mm:ss')}
          </Typography.Text>
        );
      }
    },
    {
      title: 'Nội dung',
      dataIndex: 'content',
      editable: true
    },

    {
      width: 150,
      align: 'center',
      title: 'Thao tác',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Space>
              <Tooltip title="Lưu">
                <Button
                  size="small"
                  icon={<CheckOutlined />}
                  onClick={() => save(record.key)}
                />
              </Tooltip>

              <Popconfirm
                title="Bạn muốn huỷ bỏ?"
                onConfirm={() => cancel(record?.key)}
                okText="Đồng ý"
                cancelText="Huỷ"
              >
                <Tooltip title="Hủy">
                  <Button size="small" icon={<CloseOutlined />} />
                </Tooltip>
              </Popconfirm>
            </Space>
          </span>
        ) : (
          <Space>
            <Tooltip title="Chỉnh sửa">
              <Button
                size="small"
                icon={<EditOutlined />}
                disabled={editingKey !== ''}
                onClick={() => edit(record)}
                id={record.time}
              />
            </Tooltip>

            <Popconfirm
              title="Bạn muốn xóa?"
              onConfirm={() => remove(record?.key)}
              okText="Đồng ý"
              cancelText="Huỷ"
            >
              <Tooltip title="Xóa">
                <Button
                  size="small"
                  icon={<DeleteOutlined />}
                  disabled={editingKey !== ''}
                />
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      }
    }
  ];
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'time' ? 'time' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });
  const handleAddContent = () => {
    timeLineVar([
      ...timeLine,
      {
        key: timeLine.length,
        content: ``
        //content: `Nội dung ${timeLine.length}`,
        //time: moment('00:00:00', 'HH:mm:ss')
      }
    ]);

    form.setFieldsValue({
      key: timeLine.length,
      content: ``
      //content: `Nội dung ${timeLine.length}`,
      //time: moment('00:00:00', 'HH:mm:ss')
    });
    setEditingKey(timeLine.length);
  };
  const handleBlur = () => {
    updateInputContentDisabled();
  };
  const updateInputContentDisabled = useCallback(() => {
    const inputElementContent = document.getElementById('content');
    if (inputElementContent) {
      var vlTime = form.getFieldValue('time');
      inputElementContent.disabled = vlTime ? false : true;
    }
  }, [editingKey]);
  useEffect(() => {
    const inputElement = document.getElementById('time');
    updateInputContentDisabled();
    if (inputElement) {
      inputElement.addEventListener('blur', handleBlur);
      inputElement.addEventListener('change', handleBlur);
      return () => {
        inputElement.removeEventListener('blur', handleBlur);
        inputElement.removeEventListener('change', handleBlur);
      };
    }
  }, [editingKey]);

  useEffect(() => {
    console.log('timeline currentTimeAudio', currentTimeAudio, timeLine, form);
    if (currentTimeAudio) {
      timeLineVar([
        ...timeLine,
        {
          key: timeLine.length,
          content: ``,
          //content: `Nội dung ${timeLine.length}`,
          time: moment(currentTimeAudio, 'HH:mm:ss')
        }
      ]);

      form.setFieldsValue({
        key: timeLine.length,
        content: ``,
        //content: `Nội dung ${timeLine.length}`,
        time: moment(currentTimeAudio, 'HH:mm:ss')
      });
      setEditingKey(timeLine.length);
    }

    return () => {
      currentTimeAudioVar(null);
    };
  }, [currentTimeAudio, timeLine, form]);

  return (
    <>
      <Form form={form} component={false}>
        <Row align="middle" justify="space-between">
          <Typography.Text className={cn({ 'fake-required-label': required })}>
            Thời gian - chủ đề module
          </Typography.Text>
          <Button disabled={disabled} type="link" onClick={handleAddContent}>
            Tạo mới nội dung
          </Button>
        </Row>
        <Table
          components={{
            body: {
              cell: props => <EditableCell form={form} {...props} />
            }
          }}
          id={'spotlight_form'}
          bordered
          dataSource={timeLine}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
        />
      </Form>

      <div style={{ marginBottom: 16 }} />
    </>
  );
};

export default TimeLine;
