export const DEFAULT_ERROR_MESSAGE = 'Oops đã có lỗi xảy ra!';

export const PAGE_SIZE = 10;

export const PAGE_SIZE_MAX = 50;

export const BULK_DATA_KEY = {
  STATUS: 'status',
  ORDINARILY: 'ordinarily',
  IS_DELETED: 'isDeleted',
  IS_HOT: 'isHot'
};

export const ORDER_BY_DICTIONARY = {
  LATEST: {
    value: { createdDate: 'ASC' },
    displayName: 'Cũ nhất'
  },
  EARLIEST: {
    value: { createdDate: 'DESC' },
    displayName: 'Mới nhất'
  },
  RECENTLY_CHANGE: {
    value: { updatedDate: 'DESC' },
    displayName: 'Vừa thay đổi'
  }
};

export const ORDER_BY_DICTIONARY_TRENDTOPIC = {
  LATEST: {
    value: { crDateTime: 'ASC' },
    displayName: 'Cũ nhất'
  },
  EARLIEST: {
    value: { crDateTime: 'DESC' },
    displayName: 'Mới nhất'
  },
  RECENTLY_CHANGE: {
    value: { updDateTime: 'DESC' },
    displayName: 'Vừa thay đổi'
  }
};

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_REQ = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';

export const BE_FILTER_DATE_FORMAT = 'YYYY-MM-DD';

export const DEBOUNCE_VALUE = 300;

//* *************** */
//* NOTE: HARD CODE */
//* *************** */

export const GENDER_DICTIONARY = {
  male: 'Nam',
  female: 'Nữ'
};

export const IMG_FILE_TYPES = ['.bmp', '.jpg', '.jpeg', '.gif', '.png'];
export const AUDIO_FILE_TYPES = ['.mp3', '.wav'];
export const VIDEO_FILE_TYPES = ['.mp4', '.mov', '.wmv'];
export const DOC_FILE_TYPES = ['.docx'];

export const AUTO_PUBLISH_CATE_VALUE = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED'
};

export const AUTO_PUBLISH_CATE_DICTIONARY = {
  [AUTO_PUBLISH_CATE_VALUE.ACTIVE]: 'Tự động',
  [AUTO_PUBLISH_CATE_VALUE.DISABLED]: 'Tắt tự động'
};

export const STATUS_VALUE = {
  DRAFT: 1,
  APPROVED: 2,
  PUBLISHED: 3
};

export const STATUS_VALUE_STRING = {
  DRAFT: 'DRAFT',
  APPROVED: 'APPROVED',
  PUBLISHED: 'PUBLISHED'
};

export const STATUS_DICTIONARY = {
  [STATUS_VALUE.DRAFT]: 'Nháp',
  [STATUS_VALUE.APPROVED]: 'Đã duyệt',
  [STATUS_VALUE.PUBLISHED]: 'Đã phát hành'
};

export const FILTER_PARAMS_PREFIX = 'filter__';
export const FILTER_PARAMS_PREFIX_DATE = 'filter_date__';

export const FILTER_PARAMS_OPERATOR_SUFFIX = {
  EQ: '__eq',
  CONTAINS: '__contains',
  LTE: '__lte',
  GTE: '__gte',
  BTW: '__btw',
  NEQ: '__neq'
};
export const FILTER_PARAMS_DATA_TYPE = {
  INT: 'int',
  STRING: 'string',
  DATE: 'date',
  DATERANGE: 'daterange',
  ARR: 'arr'
};
export const FILTER_PARAMS_DATA_TYPE_SUFFIX = {
  INT: `__${FILTER_PARAMS_DATA_TYPE.INT}`,
  STRING: `__${FILTER_PARAMS_DATA_TYPE.STRING}`,
  DATE: `__${FILTER_PARAMS_DATA_TYPE.DATE}`,
  DATERANGE: `__${FILTER_PARAMS_DATA_TYPE.DATERANGE}`,
  ARR: `__${FILTER_PARAMS_DATA_TYPE.ARR}`
};

export const TABLE_PROPS = {
  scroll: {
    x: 1450
  }
};

//don vi tinh la mb nha cac ban
export const IMG_UPLOAD_SIZE_LIMIT = 2;
export const IMG_UPLOAD_WIDTH_LIMIT = 2000;
export const SCRIPT_UPLOAD_SIZE_LIMIT = 5;
export const AUDIO_UPLOAD_SIZE_LIMIT = 720;

export const STATUS_ACTION_TIMEOUT = 200;

export const AUTO_SAVE_INTERVAL = 30000;

export const CHANNEL_BROADCAST_KEY = {
  AM610KHZ: 'AM610KHZ',
  FM999MHZ: 'FM999MHZ',
  FM956MHZ: 'FM956MHZ',
  FM877MHZ: 'FM877MHZ'
};

export const CHANNEL_BROADCAST_DICTIONARY_DOWNLOAD = {
  [CHANNEL_BROADCAST_KEY.FM999MHZ]: 'FM999',
  [CHANNEL_BROADCAST_KEY.FM956MHZ]: 'FM956',
  [CHANNEL_BROADCAST_KEY.FM877MHZ]: 'FM877',
  [CHANNEL_BROADCAST_KEY.AM610KHZ]: 'AM610'
};

export const CHANNEL_BROADCAST_DICTIONARY = {
  [CHANNEL_BROADCAST_KEY.FM999MHZ]: 'FM 99.9 Mhz',
  [CHANNEL_BROADCAST_KEY.FM956MHZ]: 'FM 95.6 Mhz',
  [CHANNEL_BROADCAST_KEY.FM877MHZ]: 'FM 87.7 Mhz',
  [CHANNEL_BROADCAST_KEY.AM610KHZ]: 'AM 610 Khz'
};

export const CHANNEL_BROADCAST_DICTIONARY_IMG = [
  {
    value: CHANNEL_BROADCAST_KEY.AM610KHZ,
    label: CHANNEL_BROADCAST_DICTIONARY.AM610KHZ,
    img: 'imgs/610.png',
    color: 'red'
  },
  {
    value: CHANNEL_BROADCAST_KEY.FM999MHZ,
    label: [CHANNEL_BROADCAST_DICTIONARY.FM999MHZ],
    img: 'imgs/999.png',
    color: 'blue'
  },
  {
    value: CHANNEL_BROADCAST_KEY.FM956MHZ,
    label: CHANNEL_BROADCAST_DICTIONARY.FM956MHZ,
    img: 'imgs/856.png',
    color: 'gold'
  },
  {
    value: CHANNEL_BROADCAST_KEY.FM877MHZ,
    label: CHANNEL_BROADCAST_DICTIONARY.FM877MHZ,
    img: 'imgs/877.png',
    color: 'green'
  }
];

export const NO_RADIO_COLOR = '#929292';

export const colProps = {
  xl: { span: 8 },
  span: 12
};

export const DATE_OF_WEEK_DICTIONARY = {
  0: 'Chủ nhật',
  1: 'Thứ 2',
  2: 'Thứ 3',
  3: 'Thứ 4',
  4: 'Thứ 5',
  5: 'Thứ 6',
  6: 'Thứ 7'
};

export const CATEGORY_STATUS_KEY = {
  DRAFT: 'DRAFT',
  IN_ACTIVE: 'IN_ACTIVE',
  ACTIVE: 'ACTIVE'
};

export const CATEGORY_STATUS_KEY_INT = {
  [CATEGORY_STATUS_KEY.DRAFT]: 0,
  [CATEGORY_STATUS_KEY.IN_ACTIVE]: 1,
  [CATEGORY_STATUS_KEY.ACTIVE]: 2
};

export const CATEGORY_STATUS_DICTIONARY = {
  [CATEGORY_STATUS_KEY.DRAFT]: 'Nháp',
  [CATEGORY_STATUS_KEY.IN_ACTIVE]: 'Chưa hoạt động',
  [CATEGORY_STATUS_KEY.ACTIVE]: 'Đã hoạt động'
};

export const CATEGORY_STATUS_INT_DICTIONARY = {
  [CATEGORY_STATUS_KEY_INT.DRAFT]: 'Nháp',
  [CATEGORY_STATUS_KEY_INT.IN_ACTIVE]: 'Chưa hoạt động',
  [CATEGORY_STATUS_KEY_INT.ACTIVE]: 'Đã hoạt động'
};

export const CATEGORY_TYPE_KEY = {
  BASIC: 'BASIC',
  DETAILED: 'DETAILED'
};

export const CATEGORY_TYPE_DICTIONARY = {
  [CATEGORY_TYPE_KEY.BASIC]: 'Cơ bản',
  [CATEGORY_TYPE_KEY.DETAILED]: 'Chi tiết'
};

export const CATEGORY_TYPE_KEY_INT = {
  [CATEGORY_TYPE_KEY.BASIC]: 1,
  [CATEGORY_TYPE_KEY.DETAILED]: 2
};

export const CATEGORY_TYPE_INT_DICTIONARY = {
  [CATEGORY_TYPE_KEY_INT.BASIC]: 'Cơ bản',
  [CATEGORY_TYPE_KEY_INT.DETAILED]: 'Chi tiết'
};

// Radio status
export const RADIO_STATUS_TYPE_KEY = {
  DRAFT: 'DRAFT',
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  READY: 'READY',
  CHANGE_REQUEST: 'CHANGE_REQUEST'
};

export const RADIO_STATUS_TYPE_KEY_INT = {
  [RADIO_STATUS_TYPE_KEY.DRAFT]: 1,
  [RADIO_STATUS_TYPE_KEY.WAITING]: 2,
  [RADIO_STATUS_TYPE_KEY.APPROVED]: 3,
  [RADIO_STATUS_TYPE_KEY.READY]: 4,
  [RADIO_STATUS_TYPE_KEY.CHANGE_REQUEST]: 5
};

export const RADIO_STATUS_TYPE_COLOR = {
  [RADIO_STATUS_TYPE_KEY.DRAFT]: '#929292',
  [RADIO_STATUS_TYPE_KEY.WAITING]: '#fa6042',
  [RADIO_STATUS_TYPE_KEY.APPROVED]: '#eee557',
  [RADIO_STATUS_TYPE_KEY.READY]: '#35c990',
  [RADIO_STATUS_TYPE_KEY.CHANGE_REQUEST]: '#a0ff0b'
};

export const RADIO_STATUS_TYPE_DICTIONARY = {
  [RADIO_STATUS_TYPE_KEY.DRAFT]: 'Chưa khai báo',
  [RADIO_STATUS_TYPE_KEY.WAITING]: 'Đã khai báo, chưa được duyệt',
  [RADIO_STATUS_TYPE_KEY.APPROVED]: 'Đã duyệt, chưa có file audio phát sóng',
  [RADIO_STATUS_TYPE_KEY.READY]: 'Đầy đủ, sẵn sàng phát sóng',
  [RADIO_STATUS_TYPE_KEY.CHANGE_REQUEST]: 'Có thay đổi cần được duyệt'
};

export const RADIO_STATUS_TYPE_DICTIONARY_SHORT = {
  [RADIO_STATUS_TYPE_KEY.DRAFT]: 'Nháp',
  [RADIO_STATUS_TYPE_KEY.WAITING]: 'Đợi duyệt',
  [RADIO_STATUS_TYPE_KEY.APPROVED]: 'Đã duyệt',
  [RADIO_STATUS_TYPE_KEY.READY]: 'Hoàn thành',
  [RADIO_STATUS_TYPE_KEY.CHANGE_REQUEST]: 'Có thay đổi cần duyệt'
};

export const RADIO_STATUS_TYPE_DICTIONARY_INT_SHORT = {
  // [RADIO_STATUS_TYPE_KEY.INITIAL]: 'Khởi tạo',
  [RADIO_STATUS_TYPE_KEY_INT.DRAFT]: 'Nháp',
  [RADIO_STATUS_TYPE_KEY_INT.WAITING]: 'Đợi duyệt',
  [RADIO_STATUS_TYPE_KEY_INT.APPROVED]: 'Đã duyệt',
  [RADIO_STATUS_TYPE_KEY_INT.CHANGE_REQUEST]: 'Có thay đổi cần duyệt',
  [RADIO_STATUS_TYPE_KEY_INT.READY]: 'Hoàn thành'
};

export const RADIO_TYPE_KEY = {
  OFF_LINE: 'OFF_LINE',
  ON_LINE: 'ON_LINE'
};

export const RADIO_TYPE_DICTIONARY = {
  [RADIO_TYPE_KEY.OFF_LINE]: 'Thông thường',
  [RADIO_TYPE_KEY.ON_LINE]: 'Trực tiếp'
};

export const USER_STATUS_KEY = {
  WAITING: 'WAITING',
  BANNED: 'BANNED',
  ACTIVATED: 'ACTIVATED'
};

export const USER_STATUS_VALUE = {
  [USER_STATUS_KEY.WAITING]: 1,
  [USER_STATUS_KEY.BANNED]: 2,
  [USER_STATUS_KEY.ACTIVATED]: 3
};

export const USER_STATUS_DICTIONARY = {
  [USER_STATUS_VALUE.WAITING]: 'Đang chờ',
  [USER_STATUS_VALUE.ACTIVATED]: 'Đã kích hoạt',
  [USER_STATUS_VALUE.BANNED]: 'Bị tạm khóa'
};
export const USER_TYPE_KEY = {
  STANDARD: 'STANDARD',
  MANAGER: 'MANAGER',
  ADMIN: 'ADMIN'
};

export const USER_TYPE_VALUE = {
  [USER_TYPE_KEY.STANDARD]: 3,
  [USER_TYPE_KEY.MANAGER]: 1,
  [USER_TYPE_KEY.ADMIN]: 2
};

export const USER_TYPE_STR_DICTIONARY = {
  [USER_TYPE_KEY.STANDARD]: 'Phổ thông',
  [USER_TYPE_KEY.MANAGER]: 'Quản lý',
  [USER_TYPE_KEY.ADMIN]: 'Admin'
};

export const USER_TYPE_DICTIONARY = {
  [USER_TYPE_VALUE.STANDARD]: 'Phổ thông',
  [USER_TYPE_VALUE.MANAGER]: 'Quản lý',
  [USER_TYPE_VALUE.ADMIN]: 'Admin'
};

export const TRENDTOPIC_STATUS_COLOR = {
  1: 'blue',
  2: 'red'
};
export const TRENDTOPIC_STATUS = {
  1: 'Đã khai thác',
  2: 'Không khai thác'
};

export const SCREEN_PERMISSIONS = {
  [USER_TYPE_VALUE.STANDARD]: [
    'guest',
    'radio',
    'radiodetail',
    'broadcastschedule'
  ],
  [USER_TYPE_VALUE.MANAGER]: [
    'guest',
    'radio',
    'radiodetail',
    'host',
    'topic',
    'category',
    'categoryDetail',
    'broadcastschedule'
  ],
  [USER_TYPE_VALUE.ADMIN]: [
    'guest',
    'radio',
    'radiodetail',
    'host',
    'topic',
    'category',
    'categoryDetail',
    'user',
    'broadcastschedule'
  ]
};

// News status
export const NEWS_STATUS_KEY = {
  DRAFT: 'DRAFT',
  APPROVED: 'APPROVED',
  WAITING: 'WAITING',
  USED: 'USED'
};

export const NEWS_STATUS_KEY_INT = {
  [NEWS_STATUS_KEY.DRAFT]: 0,
  [NEWS_STATUS_KEY.WAITING]: 1,
  [NEWS_STATUS_KEY.APPROVED]: 2,
  [NEWS_STATUS_KEY.USED]: 3
};

export const NEWS_STATUS_DICTIONARY = {
  [NEWS_STATUS_KEY.DRAFT]: 'Nháp',
  [NEWS_STATUS_KEY.WAITING]: 'Chờ duyệt',
  [NEWS_STATUS_KEY.APPROVED]: 'Đã duyệt',
  [NEWS_STATUS_KEY.USED]: 'Đã khai thác'
};

export const NEWS_STATUS_DICTIONARY_INT = {
  [NEWS_STATUS_KEY_INT.DRAFT]: 'Nháp',
  [NEWS_STATUS_KEY_INT.WAITING]: 'Chờ duyệt',
  [NEWS_STATUS_KEY_INT.APPROVED]: 'Đã duyệt',
  [NEWS_STATUS_KEY_INT.USED]: 'Đã khai thác'
};

// News type
export const NEWS_TYPE_KEY = {
  TEXT: 'TEXT',
  AUDIO: 'AUDIO',
  PODCAST: 'PODCAST'
};

export const NEWS_TYPE_KEY_INT = {
  [NEWS_TYPE_KEY.TEXT]: 1,
  [NEWS_TYPE_KEY.AUDIO]: 2,
  [NEWS_TYPE_KEY.PODCAST]: 3,
  [NEWS_TYPE_KEY.KIENTHUC]: 4,
  [NEWS_TYPE_KEY.TUVAN]: 5

};

export const NEWS_TYPE_DICTIONARY = {
  [NEWS_TYPE_KEY.TEXT]: 'Tin',
  [NEWS_TYPE_KEY.AUDIO]: 'Bài',
  [NEWS_TYPE_KEY.PODCAST]: 'Podcast'
};

export const NEWS_TYPE_DICTIONARY_INT = {
  [NEWS_TYPE_KEY_INT.TEXT]: 'Tin',
  [NEWS_TYPE_KEY_INT.AUDIO]: 'Bài',
  [NEWS_TYPE_KEY_INT.PODCAST]: 'Podcast',
  [NEWS_TYPE_KEY_INT.KIENTHUC]: 'Kiến thức'
  , [NEWS_TYPE_KEY_INT.TUVAN]: 'Tư vấn'

};

// News type
export const RADIO_GUETS_KEY = {
  ALL: 'ALL',
  EXIST: 'EXIST',
  EMPTY: 'EMPTY'
};
export const RADIO_GUETS_TYPE_KEY_INT = {
  [RADIO_GUETS_KEY.ALL]: 1,
  [RADIO_GUETS_KEY.EXIST]: 2,
  [RADIO_GUETS_KEY.EMPTY]: 3
};
//Filter khach moi

export const RADIO_GUETS_TYPE_DICTIONARY_INT = {
  [RADIO_GUETS_KEY.ALL]: 'Tất cả',
  [RADIO_GUETS_KEY.EXIST]: 'Có khách mời',
  [RADIO_GUETS_KEY.EMPTY]: 'Không khách mời'
};

//export const OUTSIDE_VOH_API = 'http://localhost:5079';
//export const OUTSIDE_VOH_API = 'https://apidev5.voh.com.vn';
export const OUTSIDE_VOH_API = 'https://apibeta5.voh.com.vn';
