import { useQuery } from '@apollo/client';
import { Space } from 'antd';
import { ORDER_BY_DICTIONARY, PAGE_SIZE, TABLE_PROPS } from 'appConstants';
import ListCard from 'components/ListCard';
import SortSelect from 'components/SortSelect';
import Table from 'components/Table';
import { GET_TOPICS } from 'operations/queries/queryTopic';
import queryString from 'query-string';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { processQueryFilerParams } from 'utils/filter';
import { columns } from '../columns';

const TopicList = () => {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );
  const filterQuery = useMemo(
    () => processQueryFilerParams(searchParams),
    [searchParams]
  );

  const {
    loading,
    error,
    data: topicConnect
  } = useQuery(GET_TOPICS, {
    variables: {
      take: PAGE_SIZE,
      skip: PAGE_SIZE * page - PAGE_SIZE,
      order: [ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    }
  });

  const onPaginate = page => {
    history.push({
      pathname: '/danh-sach-nhom-chuong-trinh',
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/danh-sach-nhom-chuong-trinh',
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  if (error) return <div>Oops, đã có vấn đề xảy ra 😅😅😅</div>;
  // if (!hostsConnect) return <div>None</div>;
  const topics = topicConnect?.topics?.items?.map(item => ({
    ...item,
    key: item.id
  }));

  const total = topicConnect?.topics?.totalCount;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          <SortSelect value={sort} onChange={setOrderBy} />
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        columns={columns}
        dataSource={topics}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page * 1,
          quantity: topics?.length
        }}
        loading={loading}
      />
    </ListCard>
  );
};

export default TopicList;
