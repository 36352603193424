import React from 'react';
import NewsWebFilter from './components/NewsWebFilter';
import NewsWebList from './components/NewsWebList';
import NewsProvider from './NewsWebProvider';

const NewsPodcast = () => {
  return (
    <NewsProvider>
          <div className="spacing-container">
        <NewsWebFilter />
        <NewsWebList />
        </div>
  
    </NewsProvider>
  );
};

export default NewsPodcast;
