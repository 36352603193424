import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const EDIT_GUEST = gql`
  mutation EditGuest($input: EditGuestInput!) {
    editGuest(input: $input) {
      id
      name
      image
      currentWorkingPlace
      education
      introduction
      career
      position
    }
  }
`;

export const ADD_GUEST = gql`
  mutation AddNewGuest($addGuestInput: AddGuestInput!) {
    addGuest(input: $addGuestInput) {
      id
      name
      image
      currentWorkingPlace
      experiences
      education
      introduction
      career
      position
    }
  }
`;

export const REMOVE_GUEST = gql`
  mutation RemoveGuest($id: String!) {
    removeGuest(id: $id) {
      id
      name
    }
  }
`;

export const BULK_ACTION_GUESTS = gql`
  mutation BulkActionGuests($input: [BulkActionGuestInput]) {
    bulkActionGuests(input: $input) {
      id
      name
      status
      isDeleted
    }
  }
`;

export const useAddGuest = () => {
  const [mutate, { data, error }] = useMutation(ADD_GUEST, {
    refetchQueries: ['GetGuests'],
    onCompleted: ({ addGuest }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Khách mời <Typography.Text strong>{addGuest.name}</Typography.Text>{' '}
            đã được tạo 👌
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useEditGuest = () => {
  const [mutate, { data, error }] = useMutation(EDIT_GUEST, {
    refetchQueries: ['GetGuests'],
    onCompleted: ({ editGuest }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Khách mời <Typography.Text strong>{editGuest.name}</Typography.Text>{' '}
            đã được cập nhật 👌
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useRemoveGuest = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_GUEST, {
    refetchQueries: ['GetGuests'],
    onCompleted: ({ removeGuest }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            Khách mời{' '}
            <Typography.Text strong>{removeGuest.name}</Typography.Text> đã được
            xoá 👌
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useBulkGuests = actionField => {
  const [mutate, { data, error }] = useMutation(BULK_ACTION_GUESTS, {
    refetchQueries: ['GetGuests'],
    onCompleted: ({ bulkActionGuests }) => {
      getBulkMessage('khách mời', bulkActionGuests, actionField);
    }
  });

  return { mutate, data, error };
};
