import { Col, Divider, Form, Input, InputNumber, Row } from 'antd';
import { draftCurrentTimeAudioVar, draftTimeLineVar } from 'api/cache';
import {
  AUDIO_UPLOAD_SIZE_LIMIT,
  CATEGORY_TYPE_KEY,
  IMG_UPLOAD_SIZE_LIMIT
} from 'appConstants';
import AudioUpload from 'components/AudioUpload';
import AvatarUpload from 'components/AvatarUpload';
import moment from 'moment';

import { RadioDetailContext } from 'pages/RadioDetail/RadioDetail';
import React, { useContext, useEffect, useMemo } from 'react';
import { encodeUrlFile, generateFileName } from 'utils/other';
import TimeLine from '../TimeLine';
import DraftTimeLine from './DraftTimeLine';

const EditDraftRadioModalContent = ({ form }) => {
  const { radio } = useContext(RadioDetailContext);

  const isBasicCategory = useMemo(
    () => radio?.category?.typeName === CATEGORY_TYPE_KEY.BASIC,
    [radio?.category?.typeName]
  );

  useEffect(() => {
    if (radio && radio?.radioDraft) {
      const { title, radioNumber, introduction, summary, audioUrl, image } =
        radio?.radioDraft;

      form.setFieldsValue({
        title,
        radioNumber,
        introduction,
        image,
        audioUrl
      });

      draftTimeLineVar(
        summary
          ? summary?.map(({ time, content }, index) => ({
              key: index,
              time: moment(time, 'HH:mm:ss'),
              content
            }))
          : []
      );
    }
  }, [radio, form]);

  return (
    <Form form={form} layout="vertical">
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            name="radioNumber"
            label="Số radio"
            rules={[{ required: true, message: 'Nhập số radio!' }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="title"
            label="Tiêu đề (tối đa 14 từ)"
            rules={[
              { required: true, message: 'Nhập Tiêu đề!' },
              () => ({
                validator(_, value) {
                  if (value.trim().split(' ').length > 14) {
                    return Promise.reject('Tiêu đề không quá 14 ký tự!');
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        rules={[
          { required: true, message: 'Nhập đi mà!' },
          () => ({
            validator(_, value) {
              if (value.trim().split(' ').length > 49) {
                return Promise.reject('Giới thiệu không quá 49 từ!');
              }
              return Promise.resolve();
            }
          })
        ]}
        name="introduction"
        label="Giới thiệu ngắn (Sapo)"
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        label={`Poster ( .jpeg, ,jpg, .png. Dung lượng tối đa ${IMG_UPLOAD_SIZE_LIMIT} MB )`}
        name="image"
        valuePropName="file"
        // rules={[{ required: true, message: 'Tải poster!' }]}
      >
        <AvatarUpload
          showName
          checkSquare
          avatarShape="square"
          size={128}
          fontSize={64}
          fullName={radio?.title}
          uploadParams={{ wRatio: 5, hRatio: 3, maxDimension: 2000 }}
          ratio={5 / 3}
          typeName
        />
      </Form.Item>

      <Form.Item
        name="audioUrl"
        label={`Audio (Định dang .mp3. Dung lượng tối đa ${AUDIO_UPLOAD_SIZE_LIMIT} MB)`}
        valuePropName="file"
      >
        <AudioUpload
          currentTimeCallback={draftCurrentTimeAudioVar}
          fallbackValue={radio?.audioUrl && encodeUrlFile(radio?.audioUrl)}
          form={form}
          fileName={generateFileName(radio || {})}
          showModal={!isBasicCategory}
        />
      </Form.Item>

      <DraftTimeLine
      //   disabled={shouldDisableAudio} required={!isDraftOrWaiting}
      />
    </Form>
  );
};

export default EditDraftRadioModalContent;
