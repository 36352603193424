import { Card, Form, Radio } from 'antd';
import { RADIO_STATUS_TYPE_KEY } from 'appConstants';

import { RadioDetailContext } from 'pages/RadioDetail/RadioDetail';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import GuestAutoComplete from '../GuestAutoComplete';
import HostAutoComplete from '../HostAutoComplete';
import TagsAutoComplete from 'pages/RadioDetail/components/TagsAutoComplete';

const RadioHosts = () => {
  const { mcHosts, editorHosts, tags, radio, form } =
    useContext(RadioDetailContext);

  const disabled = useMemo(
    () => radio?.statusName === RADIO_STATUS_TYPE_KEY.READY,
    [radio?.statusName]
  );

  const [hasGuest, setHasGuest] = useState(false);
  useEffect(() => {
    if (radio.isHaveGuest && radio.isHaveGuest > 1) {
      setHasGuest(true)
    }
  }, [radio.isHaveGuest])
  const onChange = e => {
    setHasGuest(e.target.value);
    if (!e.target.value) {
      form.setFieldsValue({ guestIds: [] });
    }
  };

  return (
    <Card title="Phụ trách radio">
      <Form.Item label="MC" name="mcIds">
        <HostAutoComplete disabled={disabled} host={mcHosts} mode="multiple" />
      </Form.Item>

      <Form.Item label="Biên tập" name="editorIds">
        <HostAutoComplete
          disabled={disabled}
          host={editorHosts}
          mode="multiple"
        />
      </Form.Item>

      <Form.Item label="Tags" name="tagIds">
        <TagsAutoComplete disabled={disabled} tags={tags} mode="multiple" />
      </Form.Item>

      <Form.Item
        style={{ marginBottom: 0 }}
        name="checkBoxGuest"
        label="Khách mời"
        rules={[{ required: true, message: 'Vui lòng chọn!' }]}
      >
        <Radio.Group onChange={onChange} disabled={disabled} value={hasGuest}>
          <Radio value={true}>Có</Radio>
          <Radio value={false}>Không</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="guestIds">
        <GuestAutoComplete
          disabled={disabled}
          guest={radio?.guests}
          mode="multiple"
          hasGuest={hasGuest}
        />
      </Form.Item>
    </Card>
  );
};

export default RadioHosts;
