import {
  Button,
  Col,
  Modal,
  notification,
  Row,
  Tooltip,
  Typography
} from 'antd';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  ColumnWidthOutlined,
  DownloadOutlined,
  UploadOutlined
} from '@ant-design/icons';
import Upload from 'components/Upload';
import { AUDIO_UPLOAD_SIZE_LIMIT } from 'appConstants';
import { downloadResource, encodeUrlFile } from 'utils/other';

export const AudioDownloadButton = ({ url, fileName }) => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  console.log(url);
  const onDownload = useCallback(async () => {
    setDownloadLoading(true);
    await downloadResource(url, fileName);
    setDownloadLoading(false);
  }, [fileName, url]);

  return (
    <Tooltip
      visible={downloadLoading === true ? downloadLoading : undefined}
      placement="bottom"
      trigger="hover"
      title={
        downloadLoading
          ? 'Hệ thống đang xử lý file audio 🍳'
          : `Tên file audio khi tải xuống: ${fileName}`
      }
    >
      <Button
        loading={downloadLoading}
        onClick={onDownload}
        icon={<DownloadOutlined />}
      >
        Tải xuống
      </Button>
    </Tooltip>
  );
};

const AudioUpload = ({
  onDone = f => f,
  fallbackValue,
  form,
  fileName,
  showModal,
  disabled,
  currentTimeCallback = f => f,
  durationTimeCallback = f => f,
  ...props
}) => {
  const [fileUrl, setFileUrl] = useState(null);

  const ref = useRef();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handlechangeAudio = audio => {
    if (!audio) return (ref.current.src = null);
    const audioUrl = encodeUrlFile(audio);
    setFileUrl(audioUrl);
    const currentFile = ref.current.src;
    if (currentFile !== audioUrl) ref.current.src = audioUrl;
  };

  const getCurrentTime = useCallback(() => {
    const seconds = ref.current.currentTime;
    var currTime = moment('00:00', 'mm:ss')
      .add(seconds, 'seconds')
      .format('HH:mm:ss');
    currentTimeCallback(currTime);
  }, [currentTimeCallback]);

  const onChange = args => {
    if (props.onChange) {
      props.onChange(args);
    }

    if (args.file.status === 'done') {
      handlechangeAudio(args.file.response.Data);
      if (showModal) {
        setIsModalVisible(true);
      }
    }
  };

  const beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < AUDIO_UPLOAD_SIZE_LIMIT;
    if (!isLt2M) {
      notification.error({
        message: 'Up file dưới ' + AUDIO_UPLOAD_SIZE_LIMIT + 'mb thôi nha 😡'
      });
    }
    return isLt2M;
  };

  const handleOk = useCallback(() => {
    handlechangeAudio(fallbackValue);
    form?.setFieldsValue({ audioUrl: fallbackValue });
    setIsModalVisible(false);
  }, [fallbackValue, form]);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);
  useEffect(() => {
    if (props.file) {
      if (typeof props.file === 'string') {
        //NOTE: form item default value

        handlechangeAudio(props.file);

        return;
      } else {
        //NOTE: form item after upload
        if (props.file.status === 'done') {
          handlechangeAudio(props.file.response.Data);
          onDone(props.file.response.Data);
          return;
        }
      }
    } else {
      //NOTE: normal default value
      setFileUrl(null);
      handlechangeAudio(null);
    }
  }, [onDone, props.file]);
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('loadedmetadata', () => {
        durationTimeCallback(ref.current.duration);
      });
    }
  }, [durationTimeCallback, fileUrl]);

  return (
    <>
      <Row gutter={8} align="middle">
        <Col>
          <Upload
            maxCount={1}
            showUploadList={false}
            onChange={onChange}
            beforeUpload={beforeUpload}
            accept=".wav, .mp3"
          >
            <Button disabled={disabled} icon={<UploadOutlined />}>
              Tải lên
            </Button>
          </Upload>
        </Col>

        <Col style={{ flexGrow: 1 }}>
          <Row gutter={8} align="middle">
            {fileUrl && (
              <Col>
                <AudioDownloadButton url={fileUrl} fileName={fileName} />
              </Col>
            )}
            <Col style={{ flexGrow: 1 }}>
              <audio
                src={fileUrl}
                ref={ref}
                controls
                style={{ width: '100%' }}
              ></audio>
            </Col>

            <Col>
              <Tooltip title="Thêm mốc thời gian">
                <Button
                  disabled={!fileUrl}
                  type="link"
                  onClick={getCurrentTime}
                  icon={<ColumnWidthOutlined />}
                ></Button>
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        title="🍌🍌🍌"
        visible={isModalVisible}
        onOk={handleCancel}
        onCancel={handleOk}
        cancelText="Hủy"
      >
        <p>Bạn có chắc đã sử dụng nhạc lồng có bản quyền?</p>
      </Modal>

      <Typography.Text ellipsis>
        <a
          style={{ whiteSpace: 'nowrap', display: 'inline-block' }}
          href={fileUrl}
        >
          {fileUrl?.split('/')?.pop() || fileUrl}
        </a>
      </Typography.Text>
    </>
  );
};

export default AudioUpload;
