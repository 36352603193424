import { useQuery } from '@apollo/client';
import { Button, Col, Form, Result, Row, Tabs, Typography } from 'antd';
import { timeLineVar } from 'api/cache';

import moment from 'moment';
import axios from 'axios';

import { GET_RADIO_BY_ID } from 'operations/queries/queryRadio';

import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import EditDraftRadioModal from './components/EditDraftRadioModal';
import MergeModal from './components/MergeModal';
import RadioDetailLeft from './RadioDetailLeft';

import RadioDetailRight from './RadioDetailRight';
import { OUTSIDE_VOH_API } from 'appConstants';

export const RadioDetailContext = React.createContext();

const RadioDetail = () => {
  const [form] = Form.useForm();
  const { id, t } = useParams();
  const { loading, data } = useQuery(GET_RADIO_BY_ID, {
    variables: { id }
  });

  const [tags, setTags] = useState([]);
  const [timeFocused, setTimeFocused] = useState(null);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const t = urlParams.get('t');
    if (t) {
      setTimeFocused(t);
    }
    console.log('time:', t);
  }, []);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        console.log('process.env', process.env);
        const response = await axios.get(
          `${OUTSIDE_VOH_API}/api/Others/GetRadioTags?radioId=${id}`
        );

        const results = response.data;

        if (!results || !results.data) {
          return;
        }

        setTags(response.data.data);
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };

    fetchTags();
  }, [data, id]);
  useEffect(() => {
    if (timeFocused && !loading && data) {
      let intervalCheckSpotlight = setInterval(() => {
        const spotlight_form = document.getElementById('spotlight_form');
        if (spotlight_form) {
          clearInterval(intervalCheckSpotlight);

          const element = document.getElementById(timeFocused);

          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, 1000);
    }
  }, [timeFocused, data, loading]);

  const radio = useMemo(() => {
    return {
      ...data?.radio,
      tags: tags // Thêm dữ liệu tags vào radio object
    };
  }, [data?.radio, tags]);

  //const radio = useMemo(() => data?.radio, [data?.radio]);

  const hosts = useMemo(() => {
    if (radio?.hosts) {
      return radio.hosts.filter(
        (v, i, a) => a.findIndex(v2 => v2.id === v.id) === i
      );
    }

    return [];
  }, [radio?.hosts]);

  const mcHosts = useMemo(() => {
    return hosts.filter(({ radioHosts }) => {
      return radioHosts.some(({ type }) => type === 1);
    });
  }, [hosts]);

  const editorHosts = useMemo(() => {
    return hosts.filter(({ radioHosts }) => {
      return radioHosts.some(({ type }) => type === 2);
    });
  }, [hosts]);

  useEffect(() => {
    if (radio) {
      const {
        title,
        radioNumber,
        duration,
        broadcastTime,
        introduction,
        timeTo,
        radioReplaySchedules,
        summary,
        scenarioFile,
        audioUrl,
        statusName,
        image
      } = radio;

      const broadcastTimeFrom = moment(broadcastTime).format('HH:mm');
      let editorIds = editorHosts.map(({ id }) => id);
      let mcIds = mcHosts.map(({ id }) => id);
      let _title = title;
      let _introduction = introduction;
      if (radio.createdDate == radio.updatedDate) {
        _title = '';
        _introduction = '';
      }
      form.setFieldsValue({
        title: _title,
        checkBoxGuest: radio.isHaveGuest
          ? radio.isHaveGuest > 1
            ? true
            : false
          : null,
        radioNumber,
        duration,
        broadcastTime: broadcastTime && moment(broadcastTime),
        timeTo: timeTo && moment(timeTo),
        introduction: _introduction,
        broadcastTimeFrom,
        scenarioFile,
        status: statusName,
        image,
        audioUrl,
        radioReplaySchedules:
          radioReplaySchedules &&
          radioReplaySchedules.map(schedule => ({
            timeFrom: schedule?.timeFrom && moment(schedule?.timeFrom),
            timeTo: schedule?.timeTo && moment(schedule?.timeTo)
          })),
        mcIds,
        editorIds,
        tagIds: radio?.tags?.map(({ id }) => id),
        guestIds: radio?.guests?.map(({ id }) => id)
      });
      timeLineVar(
        summary
          ? summary?.map(({ time, content }, index) => ({
              key: index,
              time: moment(time, 'HH:mm:ss'),
              content
            }))
          : []
      );
    }
  }, [radio, form, mcHosts, editorHosts]);

  useEffect(() => {
    return () => {
      timeLineVar([]);
    };
  }, []);

  if (loading) return 'loading...';

  if (!loading && !radio)
    return (
      <div className="spacing-container">
        {/* <Typography.Title>Chi tiết radio</Typography.Title> */}
        <Result
          status="404"
          title="Không tìm thấy thông tin radio."
          extra={
            <Button>
              <Link to="/lich-phat-song">Lịch phát sóng</Link>
            </Button>
          }
        />
      </div>
    );

  return (
    <div className="spacing-container">
      <Typography.Title>Chi tiết radio</Typography.Title>

      <RadioDetailContext.Provider
        value={{ form, radio, mcHosts, editorHosts, tags, getLoading: loading }}
      >
        <Form form={form} layout="vertical">
          <Row gutter={8} style={{ flexWrap: 'nowrap' }}>
            <Col
              style={{
                flexGrow: 1,
                flexBasis: 'calc(100% - 350px)',
                maxWidth: 'calc(100% - 350px)'
              }}
            >
              <RadioDetailLeft radio={radio} />
            </Col>

            <Col style={{ flexBasis: 350, minWidth: 350 }}>
              <RadioDetailRight />
            </Col>
          </Row>
        </Form>
      </RadioDetailContext.Provider>
    </div>
  );
};

export default RadioDetail;
