import { useReactiveVar } from '@apollo/client';
import { Button, Form, Input } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import ChannelSelect from 'components/ChannelSelect';

import UserTypeSelect from 'components/UserTypeSelect/UserTypeSelect';
import moment from 'moment';
import { useEffect } from 'react';
import HostAutoComplete from '../components/HostAutoComplete';

const UserFormContent = ({ form, onSubmit }) => {
  const visibleDrawer = useReactiveVar(visibleDrawerVar);
  const user = visibleDrawer.record;

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        name: user?.name,
        email: user?.email,
        roleId: user?.roleId,
        phoneNumber: user?.phoneNumber,
        gender: user?.gender,
        dateOfBirth: user?.dateOfBirth && moment(user?.dateOfBirth),
        decription: user?.decription,
        userType: user?.userTypeName,
        // image: user?.image,
        hostId: user?.host?.id,
        channels: user?.channels
      });
    }
    return () => form.resetFields();
  }, [user, form]);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      {/* <Row justify="center" align="middle">
        <Col>
          <Form.Item name="image" valuePropName="file">
            <AvatarUpload
              uploadType="user"
              size={128}
              fontSize={64}
              fullName={user?.name}
            />
          </Form.Item>
        </Col>
      </Row> */}

      <Form.Item
        name="name"
        label="Họ tên"
        rules={[{ required: true, message: 'Nhập tên!' }]}
      >
        <Input placeholder="Nhập họ tên" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={
          !!user
            ? []
            : [{ required: true, type: 'email', message: 'Nhập email!' }]
        }
      >
        <Input placeholder="Nhập email" disabled={!!user} />
      </Form.Item>
      {!user && (
        <Form.Item
          name="password"
          label="Mật khẩu"
          rules={[{ required: true, message: 'Nhập mật khẩu!' }]}
        >
          <Input.Password
            placeholder="Nhập mật khẩu"
            autoComplete="new-password"
          />
        </Form.Item>
      )}

      <Form.Item
        name="userType"
        label="Loại user"
        rules={[{ required: true, message: 'Chọn loại user!' }]}
      >
        <UserTypeSelect style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        name="channels"
        label="Kênh"
        rules={[{ required: true, message: 'Chọn kênh!' }]}
      >
        <ChannelSelect channels={user?.channels} mode="multiple" />
      </Form.Item>
      <Form.Item name="hostId" label="Host">
        <HostAutoComplete allowClear host={user?.host} mode="single" />
      </Form.Item>

      <Form.Item style={{ display: 'none' }}>
        <Button htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  );
};

export default UserFormContent;
