import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Spin,
  Tag
} from 'antd';
import { USER_TYPE_DICTIONARY } from 'appConstants';
import MetaCard from 'components/MetaCard';
import moment from 'moment';
import {
  useAttachHost,
  useUpdateProfile
} from 'operations/mutations/user/mutateUser';
import { useCurrentUser } from 'operations/queries/queryCurrentUser';
import { useEffect, useMemo } from 'react';
import HostAutoComplete from './HostAutoComplete';

const PersonalInfo = () => {
  const [form] = Form.useForm();
  const { mutate: updateProfile, loading: isUpdateLoading } =
    useUpdateProfile();
  const { mutate: attachHost } = useAttachHost();
  const { data, loading: isGetUserLoading } = useCurrentUser();
  const currentUser = data?.detail;
  const isEmptyHost = useMemo(() => !currentUser?.host, [currentUser?.host]);

  const onSave = async () => {
    try {
      const formParams = await form.validateFields();

      if (isEmptyHost) {
        const { hostId } = formParams;
        await attachHost({ variables: { input: { hostId } } });
      } else {
        const {
          name,
          dateOfBirth,
          introduction,
          education,
          experience,
          currentWorkingPlace
        } = formParams;
        const params = {
          name,
          dateOfBirth,
          introduction,
          education,
          experience,
          currentWorkingPlace
        };

        await updateProfile({ variables: { input: params } });
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (currentUser) {
      const { email, userType, host } = currentUser;
      form.setFieldsValue({
        email,
        name: host?.name,
        userType: USER_TYPE_DICTIONARY[userType],
        dateOfBirth: host?.dateOfBirth && moment(host?.dateOfBirth),
        introduction: host?.introduction,
        education: host?.education,
        experience: host?.experience,
        currentWorkingPlace: host?.currentWorkingPlace
      });
    }
  }, [form, currentUser]);

  return (
    <MetaCard title="Thông tin cá nhân">
      <Spin spinning={isGetUserLoading}>
        <Form layout="vertical" form={form}>
          <Row gutter={32}>
            <Col span={8}>
              <Form.Item label="Email:" name="email">
                <Input disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Vai trò:" name="userType">
                <Input disabled />
              </Form.Item>
            </Col>

            {isEmptyHost ? (
              <Col span={8}>
                <Form.Item
                  label="Liên kết host"
                  name="hostId"
                  rules={[{ required: true, message: 'Vui lòng chọn host!' }]}
                >
                  <HostAutoComplete />
                </Form.Item>
              </Col>
            ) : (
              <Col span={24}>
                <Divider
                  orientation="left"
                  orientationMargin={8}
                  style={{ fontSize: 14 }}
                >
                  Thông tin host
                </Divider>
                <Row gutter={32}>
                  <Col span={8}>
                    <Form.Item
                      label="Họ tên"
                      name="name"
                      rules={[{ required: true, message: 'Nhập họ tên!' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Sinh nhật:" name="dateOfBirth">
                      <DatePicker
                        style={{ width: '100%' }}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="currentWorkingPlace"
                      label="Cơ quan công tác"
                    >
                      <Input placeholder="Cơ quan công tác" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="introduction" label="Giới thiệu">
                      <Input.TextArea placeholder="Nhập giới thiệu" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="experience" label="Quá trình công tác">
                      <Input.TextArea placeholder="Nhập quá trình công tác" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="education" label="Quá trình học tập">
                      <Input.TextArea placeholder="Nhập quá trình học tập" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>

          <Row justify="end" align="middle">
            <Tag color="processing" icon={<InfoCircleOutlined />}>
              Chỉ có thể thay đổi những thông tin được thao tác.
            </Tag>

            <Button onClick={onSave} type="primary" loading={isUpdateLoading}>
              Lưu
            </Button>
          </Row>
        </Form>
      </Spin>
    </MetaCard>
  );
};

export default PersonalInfo;
