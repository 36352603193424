import { Space, Typography } from 'antd';
import { visibleDrawerVar } from 'api/cache';

import RelatedUser from 'components/RelatedUser';
import RemoveButton from 'components/RemoveButton';
import StatusButton from 'components/StatusButton';
import TableRecordName from 'components/TableRecordName';
import TimeLine from 'components/TimeLine';
import UserAvatar from 'components/UserAvatar';

import 'moment/locale/vi';
import {
  useRemoveGuest,
  useEditGuest
} from 'operations/mutations/guest/mutateGuest';
import React from 'react';

const RemoveGuest = ({ record }) => {
  const { mutate } = useRemoveGuest();
  return <RemoveButton record={record} actionRemove={mutate} />;
};

const Status = ({ record }) => {
  const { mutate } = useEditGuest();

  const onClick = status => {
    mutate({
      variables: {
        input: {
          id: record.id,
          name: record.name,
          status
        }
      }
    });
  };

  return <StatusButton record={record} onClick={onClick} />;
};

const Name = ({ record }) => {
  const { name } = record;
  return (
    <Space direction="vertical">
      <TableRecordName
        name={name}
        setDrawerVisible={() =>
          visibleDrawerVar({ visible: true, record: record })
        }
      />
    </Space>
  );
};

export const columns = [
  {
    title: 'Khách mời',
    dataIndex: 'image',
    key: 'image',
    render: (image, record) => (
      <Space size={10}>
        <UserAvatar fullName={record.name} avatar={image} />
        <Space direction="vertical" align="start">
          <Name record={record} />
          <Typography.Text style={{ color: 'gray' }}>
            {record?.currentWorkingPlace}
          </Typography.Text>
        </Space>
      </Space>
    ),
    fixed: 'left',
    width: '300px'
  },
  {
    title: 'Nghề nghiệp',
    dataIndex: 'job',
    render: (_, { career, position }) => (
      <Space direction="vertical">
        <Typography.Text strong>{career}</Typography.Text>
        <Typography.Text style={{ color: 'gray' }}>{position}</Typography.Text>
      </Space>
    ),
    align: 'left',
    width: '200px'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'name',
    render: (_, record) => <Status record={record} />,
    align: 'left',
    width: '200px'
    // fixed: 'left'
  },
  {
    title: 'Giới thiệu',
    dataIndex: 'introduction',
    render: introduction => (
      <Typography.Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: 0 }}>
        {introduction}
      </Typography.Paragraph>
    ),
    width: '200px'
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '220px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'createdDate',
    width: '180px',
    render: (_, { creator, updater }) => (
      <RelatedUser creator={creator} updater={updater} />
    )
  },
  {
    title: 'Thao tác',
    width: '100px',
    render: (_, record) => <RemoveGuest record={record} />,
    toggleEdit: true,
    align: 'center',
    fixed: 'right'
  }
];
