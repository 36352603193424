import { useQuery } from '@apollo/client';
import moment from 'moment';
import { GET_ALL_CATEGORIES_CALENDAR } from 'operations/queries/queryCategory';
import {
  GET_ALL_RADIO_REPLAY_SCHEDULES_CALENDAR,
  GET_ALL_RADIOS_CALENDAR
} from 'operations/queries/queryRadio';

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { generateColor } from './FullScheduleCalendar';
import { CATEGORY_STATUS_KEY_INT, NO_RADIO_COLOR } from 'appConstants';
import { applyTimeToDate } from 'pages/RadioDetail/components/RadioReplaySchedules/RadioReplayFormInline';

const useCalendar = calendarRef => {
  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const { data: categoryConnect, loading: categoryLoading } = useQuery(
    GET_ALL_CATEGORIES_CALENDAR,
    {
      variables: {
        where: {
          channelId: {
            eq: searchParams?.channel
          },
          status: {
            eq: CATEGORY_STATUS_KEY_INT.ACTIVE
          },
          onAirTo: {
            nlt: moment(searchParams?.startDate).format('YYYY-MM-DD') + '+00:00'
          },
          onAirFrom: {
            ngt: moment(searchParams?.endDate).format('YYYY-MM-DD') + '+00:00'
          }
        }
      }
    }
  );

  const { data: radiosConnect, loading: radioLoading } = useQuery(
    GET_ALL_RADIOS_CALENDAR,
    {
      variables: {
        where: {
          broadcastTime: {
            gte:
              moment(searchParams?.startDate).format('YYYY-MM-DD') + '+00:00',
            lte: moment(searchParams?.endDate).format('YYYY-MM-DD') + '+00:00'
          },
          category: {
            channelId: {
              eq: searchParams?.channel
            },
            status: {
              eq: CATEGORY_STATUS_KEY_INT.ACTIVE
            }
          }
        }
      }
    }
  );

  const {
    data: radioReplaySchedulesConnect,
    loading: radioReplayScheduleLoading
  } = useQuery(GET_ALL_RADIO_REPLAY_SCHEDULES_CALENDAR, {
    variables: {
      where: {
        timeFrom: {
          gte: moment(searchParams?.startDate).format('YYYY-MM-DD') + '+00:00'
        },
        timeTo: {
          lte: moment(searchParams?.endDate).format('YYYY-MM-DD') + '+00:00'
        },
        radio: {
          category: {
            channelId: { eq: searchParams?.channel }
          }
        }
      }
    }
  });

  const radios = useMemo(
    () => radiosConnect?.allRadios,
    [radiosConnect?.allRadios]
  );

  const categories = useMemo(
    () => categoryConnect?.allCategories,
    [categoryConnect?.allCategories]
  );

  const radioReplays = useMemo(
    () => radioReplaySchedulesConnect?.allRadioReplaySchedules,
    [radioReplaySchedulesConnect?.allRadioReplaySchedules]
  );

  const events = useMemo(() => {
    if (!radios || !categories || !radioReplays || !calendarRef.current) {
      return [];
    }

    const categorySchedules = [];
    const calendarApi = calendarRef.current.getApi();
    const currDate = moment(calendarApi.getDate().toISOString());
    const dayOfWeek = currDate.day();

    // category schedule

    categories.forEach(category => {
      category.schedules.forEach(schedule => {
        const _dayOfWeek = schedule.dayOfWeek === 0 ? 7 : schedule.dayOfWeek;

        const start = applyTimeToDate({
          scheduleDate: currDate.clone().add(_dayOfWeek - dayOfWeek, 'days'),
          hour: moment(schedule.timeFrom).hours(),
          minute: moment(schedule.timeFrom).minutes()
        });

        const duration = moment(schedule.timeTo).diff(
          schedule.timeFrom,
          'minutes'
        );

        const end = start.clone().add(duration, 'minutes');

        const thisSchedule = {
          start: start.toISOString(),
          end: end.toISOString(),
          backgroundColor: NO_RADIO_COLOR,
          ...category
        };

        categorySchedules.push(thisSchedule);
      });
    });

    // radios

    const replayRadioSchedules = radioReplays.map(
      ({ id, timeFrom, timeTo, __typename, radio }) => ({
        ...radio,
        id,
        broadcastTime: moment(timeFrom)
          .set({
            seconds: 0,
            milliseconds: 0
          })
          .toISOString(true),
        timeTo: moment(timeTo)
          .set({
            seconds: 0,
            milliseconds: 0
          })
          .toISOString(true),
        __typename
      })
    );

    const radiosSchedules = [...radios, ...replayRadioSchedules].map(item => {
      const endTime = item.timeTo
        ? item.timeTo
        : moment(item.broadcastTime).add(item.duration, 'minutes').format();

      return {
        ...item,
        start: item.broadcastTime,
        end: endTime,
        backgroundColor: generateColor(item?.statusName)
      };
    });

    const parsedCategorySchedules = [...categorySchedules].filter(event => {
      const rangeSchedule = moment.range(
        event.start,
        moment(event.end).isSameOrBefore(event.start)
          ? moment(event.end).add(1, 'days')
          : event.end
      );

      //Check valid date
      const isValid =
        moment(event.onAirFrom).isSameOrBefore(event.start, 'day') &&
        moment(event.end).isSameOrBefore(event.onAirTo, 'day');

      // Check contain
      const isContain = radiosSchedules.some(originData => {
        return rangeSchedule.contains(moment(originData.start), {
          excludeEnd: true
        });
      });

      // Check overlapse
      const isOverlap = radiosSchedules.some(originData => {
        const rangeRadio = moment.range(originData.start, originData.end);

        return rangeRadio.overlaps(rangeSchedule);
      });

      return isValid && !isContain && !isOverlap;
    });

    return [...radiosSchedules, ...parsedCategorySchedules];
  }, [calendarRef.current, categories, radioReplays, radios]);

  return {
    radiosConnect,
    radioLoading,
    categoryConnect,
    categoryLoading,
    radioReplayScheduleLoading,
    radioReplaySchedulesConnect,
    events
  };
};

export default useCalendar;
