import {
  DeleteOutlined,
  UploadOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { Button, Image, notification, Space } from 'antd';
import { IMG_UPLOAD_SIZE_LIMIT } from 'appConstants';
import Upload from 'components/Upload';
import { useCallback, useEffect, useState } from 'react';
import { downloadResource, encodeUrlFile } from 'utils/other';

const ImageListUpload = ({
  size,
  onDone = f => f,
  disabled,
  downloadBtn,
  onDownloadCallback = f => f,
  ...props
}) => {
  const [fileUrls, setFileUrls] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.files?.length) {
      setFileUrls(props.files);
    }
  }, [onDone, props.files]);

  const onChange = args => {
    if (args.file.status === 'done') {
      setFileUrls(prev => {
        const dayLaLuc = [...prev, args.file.response.Data];
        props.onChange(dayLaLuc);
        return dayLaLuc;
      });
    }
  };

  const beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < IMG_UPLOAD_SIZE_LIMIT;
    if (!isLt2M) {
      notification.error({
        message: 'Up file dưới ' + IMG_UPLOAD_SIZE_LIMIT + 'mb thôi nha 😡'
      });
    }
    return isLt2M;
  };

  const onRemove = useCallback(
    item => {
      setFileUrls(prevLinks => {
        const dayLaLuc = prevLinks.filter(prevLinks => prevLinks !== item);
        props.onChange(dayLaLuc);

        return dayLaLuc;
      });
    },
    [props]
  );
  const onClick = useCallback(async () => {
    setLoading(true);
    await Promise.all(
      fileUrls.map(fileUrl =>
        downloadResource(encodeUrlFile(fileUrl), fileUrl?.split('/')?.pop())
      )
    );
    setLoading(false);
    onDownloadCallback();
  }, [fileUrls, onDownloadCallback]);

  return (
    <>
      <Upload
        accept=".png, .jpeg, .jpg"
        showUploadList={false}
        onChange={onChange}
        beforeUpload={beforeUpload}
        multiple
      >
        <Button disabled={disabled} type="default" icon={<UploadOutlined />}>
          Tải lên
        </Button>
      </Upload>

      <div style={{ marginTop: 16 }}>
        <Space wrap>
          {fileUrls.map(fileUrl => (
            <div
              key={fileUrl}
              style={{ marginBottom: 8, position: 'relative' }}
            >
              <Image width={200} src={encodeUrlFile(fileUrl)} />
              <Button
                style={{ position: 'absolute', bottom: '0', right: '0' }}
                size="small"
                onClick={() => onRemove(fileUrl)}
                danger
                icon={<DeleteOutlined style={{ cursor: 'pointer' }} />}
              ></Button>
            </div>
          ))}
        </Space>

        {downloadBtn && fileUrls.length !== 0 && (
          <div>
            <Button
              icon={<DownloadOutlined />}
              disabled={fileUrls.length === 0}
              onClick={onClick}
              loading={loading}
              type="primary"
            >
              Tải xuống
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default ImageListUpload;
