import { useQuery } from '@apollo/client';
import { Spin, Typography } from 'antd';
import { GET_ALL_RADIO_REPLAY_SCHEDULE_SUGGESTIONS } from 'operations/queries/queryRadio';
import React, { useMemo } from 'react';
import RadioReplayFormInline from './RadioReplayFormInline';

const ReplaySchduleSuggestions = ({ radio }) => {
  const { data, loading, refetch } = useQuery(
    GET_ALL_RADIO_REPLAY_SCHEDULE_SUGGESTIONS,
    {
      variables: {
        id: radio.id
      }
    }
  );
  const suggestions = useMemo(
    () => data?.radioReplayScheduleSuggestions || [],
    [data?.radioReplayScheduleSuggestions]
  );

  return suggestions.length ? (
    <>
      <Typography.Paragraph strong>Gợi ý lịch phát lại</Typography.Paragraph>

      <Spin spinning={loading}>
        {suggestions.map(schedule => (
          <RadioReplayFormInline
            suggestion
            onDoneAdd={refetch}
            key={schedule?.id}
            schedule={schedule}
            radio={radio}
          />
        ))}
      </Spin>
    </>
  ) : (
    <></>
  );
};

export default ReplaySchduleSuggestions;
