import { Tag } from 'antd';
import { USER_STATUS_DICTIONARY, USER_STATUS_VALUE } from 'appConstants';
import React from 'react';

const generateColor = status => {
  switch (status) {
    case USER_STATUS_VALUE.WAITING:
      return 'orange';

    case USER_STATUS_VALUE.ACTIVATED:
      return 'green';

    case USER_STATUS_VALUE.BANNED:
      return 'red';

    default:
      break;
  }
};

const UserStatusTag = ({ status, ...props }) => {
  return (
    <Tag color={generateColor(status)} {...props}>
      {USER_STATUS_DICTIONARY[status]}
    </Tag>
  );
};

export default UserStatusTag;
