import { useLazyQuery } from '@apollo/client';
import { Form } from 'antd';
import {
  FILTER_PARAMS_DATA_TYPE_SUFFIX,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX
} from 'appConstants';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { processFormItemName } from 'utils/filter';
import GuestAutoComplete from '../components/GuestAutoComplete';
import { GET_GUESTS } from 'operations/queries/queryGuest';
import { useLocation } from 'react-router-dom';

const GuestAutoCompleteFilter = () => {
  const [searchGuest, { loading }] = useLazyQuery(GET_GUESTS);
  const [guests, setGuests] = useState([]);
  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const guestFilterName = useMemo(() => {
    return processFormItemName({
      prefix: FILTER_PARAMS_PREFIX,
      name: 'radioGuests',
      operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
      dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.ARR
    });
  }, []);

  const value = useMemo(() => {
    if (Array.isArray(searchParams[guestFilterName])) {
      return searchParams[guestFilterName];
    }

    return [searchParams[guestFilterName]];
  }, [guestFilterName, searchParams]);

  const getGuests = useCallback(async () => {
    try {
      const {
        data: { guests }
      } = await searchGuest({
        variables: {
          where: {
            id: {
              or: value.map(eq => ({ eq }))
            }
          }
        }
      });
      setGuests(guests?.items);
    } catch (e) {
      throw e;
    }
  }, [searchGuest, value]);

  useEffect(() => {
    if (searchParams[guestFilterName]) getGuests();
  }, [getGuests, guestFilterName, searchParams]);

  return (
    <>
      <Form.Item name={guestFilterName} label="Khách mời">
        <GuestAutoComplete
          allowClear
          mode="multiple"
          guest={guests}
          loading={loading}
        />
      </Form.Item>
    </>
  );
};

export default GuestAutoCompleteFilter;
