import { Space, Typography } from 'antd';

import Listen from 'components/Icons/Listen';
import Radio from 'components/Icons/Radio';
import RelatedUser from 'components/RelatedUser';
import RemoveButton from 'components/RemoveButton';

import TimeLine from 'components/TimeLine';
import UserAvatar from 'components/UserAvatar';
import CategoryTag from 'components/CategoryTag';

import 'moment/locale/vi';
import {
  useEditCategory,
  useRemoveCategory
} from 'operations/mutations/category/mutateCategory';
import React from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
  CATEGORY_STATUS_KEY,
  DATE_FORMAT,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX
} from 'appConstants';
import CategoryStatusButton from 'components/CategoryStatusButton';
import { processFormItemName } from 'utils/filter';
import { Channel } from 'pages/Radio/columns';
import ColName from 'components/ColName';
import moment from 'moment';

const RemoveCategory = ({ record }) => {
  const { mutate } = useRemoveCategory();
  return (
    <RemoveButton record={record} actionRemove={mutate} titleKey="title" />
  );
};

const Status = ({ record }) => {
  const { mutate } = useEditCategory();
  const onClick = async value => {
    try {
      await mutate({
        variables: {
          input: {
            id: record.id,
            title: record.title,
            topicId: record.topicId,
            onAirFrom: record.onAirFrom,
            status: value
              ? CATEGORY_STATUS_KEY.ACTIVE
              : CATEGORY_STATUS_KEY.IN_ACTIVE
          }
        }
      });
    } catch (error) {}
  };

  return <CategoryStatusButton record={record} onClick={onClick} />;
};

export const columns = [
  {
    dataIndex: 'image',
    key: 'image',
    render: (image, record) => (
      <UserAvatar fullName={record.title} avatar={image} shape="square" />
    ),
    fixed: 'left',
    width: '100px',
    align: 'center'
  },
  {
    title: 'Chủ đề',
    dataIndex: 'title',
    key: 'image',
    render: (_, record) => (
      <Space direction="vertical">
        <ColName
          primary={
            <Link
              style={{ color: 'inherit' }}
              to={`/chi-tiet-chuong-trinh/${record?.id}`}
            >
              {record?.title} {<EditOutlined />}
            </Link>
          }
          secondary={record?.topic?.title}
        />
        <Space>
          <Channel style={{ marginRight: 0 }} channelId={record?.channel} />
          <CategoryTag style={{ marginRight: 0 }} type={record?.typeName} />
        </Space>
      </Space>
    ),
    fixed: 'left'
    // width: '250px'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'name',
    render: (_, record) => <Status record={record} />,
    align: 'left',
    width: '180px'
  },
  // {
  //   title: 'Kênh phát sóng',
  //   dataIndex: 'channel',
  //   render: (_, record) => <Channel channelId={record?.category?.channel} />,
  //   align: 'left',
  //   width: '200px'
  // },
  {
    title: 'Phát sóng',
    key: 'onAirRange',

    render: (_, record) => (
      <Space direction="vertical">
        <span>
          <Typography.Text strong>Từ</Typography.Text>{' '}
          {moment(record?.onAirFrom).format(DATE_FORMAT)}
        </span>
        <span>
          <Typography.Text strong>đến</Typography.Text>{' '}
          {moment(record?.onAirTo).format(DATE_FORMAT)}
        </span>
      </Space>
    ),
    align: 'left',
    width: '150px'
  },
  {
    title: 'Host',
    dataIndex: 'hosts',
    render: hosts => (
      <Space direction="vertical">
        {hosts.map(host => (
          <Typography.Text key={host?.id}>{host?.name}</Typography.Text>
        ))}
      </Space>
    ),
    align: 'left',
    width: '200px'
  },
  {
    title: 'Radio',
    dataIndex: 'radio',
    key: 'radio',
    render: (_, record) => (
      <Space direction="vertical">
        <Space>
          <Listen />
          <Typography.Text> {0} lượt nghe</Typography.Text>
        </Space>

        <Link
          to={
            '/danh-sach-radio/?' +
            processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'categoryId',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ
            }) +
            '=' +
            record?.id +
            '&' +
            processFormItemName({
              name: 'categoryName'
            }) +
            '=' +
            record?.title
          }
        >
          <Space>
            <Radio />
            {`${record?.radioStatistics?.total} radio`}
          </Space>
        </Link>
      </Space>
    ),
    width: '150px'
  },

  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '220px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'related',
    width: '180px',
    render: (_, { creator, updater }) => (
      <RelatedUser creator={creator} updater={updater} />
    )
  },
  {
    title: 'Thao tác',
    width: '100px',
    render: (_, record) => <RemoveCategory record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
