import { useReactiveVar } from '@apollo/client';
import { Form } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import UpdateCreateModal from 'containers/UpdateCreateModal';

import { useAddNews, useEditNews } from 'operations/mutations/news/mutateNews';

import React, { useCallback, useMemo } from 'react';

import NewsFormContent from './NewsFormContent';

const extract = ({ audioFiles: _audioFiles, docFile: _docFile }) => {
  const docFile = _docFile?.file?.response?.Data || _docFile;

  const audioFiles =
    _audioFiles?.fileList?.map(file => file?.response?.Data) || _audioFiles;

  return { docFile, audioFiles };
};

const NewsForm = () => {
  const visibleDrawer = useReactiveVar(visibleDrawerVar);
  const [form] = Form.useForm();

  const { mutate: mutateAdd, loading: addLoading } = useAddNews();
  const { mutate: mutateEdit, loading: editLoading } = useEditNews();

  const isCreating = useMemo(
    () => !visibleDrawer?.record,
    [visibleDrawer?.record]
  );

  const onClose = useCallback(() => {
    form.resetFields();
    visibleDrawerVar(false);
  }, [form]);

  const onOk = useCallback(async () => {
    try {
      let params = await form.validateFields();

      const { docFile, audioFiles } = extract(params);
      params = { ...params, docFile, audioFiles };
      if (isCreating) {
        await mutateAdd({ variables: { input: params } });

        return onClose();
      }

      await mutateEdit({
        variables: {
          input: { ...params, id: visibleDrawer?.record?.id }
        }
      });

      return onClose();
    } catch (e) {}
  }, [
    form,
    isCreating,
    mutateAdd,
    mutateEdit,
    onClose,
    visibleDrawer?.record?.id
  ]);

  return (
    <UpdateCreateModal
      title={!isCreating ? 'Chỉnh sửa tin bài' : 'Tạo tin bài'}
      onOk={onOk}
      onClose={onClose}
      visible={visibleDrawer.visible}
      isUpdate={!isCreating}
      destroyOnClose
      loading={editLoading || addLoading}
    >
      <NewsFormContent form={form} onSubmit={onOk} isCreating={isCreating} />
    </UpdateCreateModal>
  );
};

export default NewsForm;
