import React, { useMemo } from 'react';
import TagsAutoCompleteCustom from 'pages/RadioDetail/components/TagsAutoCompleteCustom';

const TagsAutoComplete = ({ tags, ...props }) => {
  const defaultOption = useMemo(
    () =>
      tags
        ? Array.isArray(tags)
          ? tags.map(({ id, text }) => ({ value: id, label: text }))
          : { value: tags.id, label: tags.text }
        : null,
    [tags]
  );

  return (
    <TagsAutoCompleteCustom
      defaultOption={defaultOption}
      labelKey="name"
      valueKey="id"
      dataKey="tags"
      placeholder="Tag"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default TagsAutoComplete;
