import { useEffect, useContext } from 'react';

import { Button, Col, Form, Input, Row, DatePicker, Select } from 'antd';

import { colProps } from 'appConstants';

import FormBase from 'containers/FormBase';
import { SpotlightBankContext } from '../../SpotlightBankProvider';
import moment from 'moment';
import CategoryAutoCompleteFilter from 'pages/Spotlights/components/CategoryAutoCompleteFilter';
import UserAutoCompleteFilter from 'pages/Spotlights/components/UserAutoCompleteFilter';

const SpotlightBankFilter = () => {
  const [form] = Form.useForm();
  const { paramFilter, setParamFilter } = useContext(SpotlightBankContext);

  useEffect(() => {
    form.setFieldsValue({ searchByDate: 1, sortOrderType: 1 });
  }, []);

  const onFinish = () => {
    var dataForm = form.getFieldValue();

    let dateRange = ['', ''];

    if (dataForm.dateRange && dataForm.dateRange.length > 1) {
      dateRange = dataForm.dateRange.map(x => moment(x).format('DD-MM-YYYY'));
    }
    let body = {
      ...paramFilter,
      categoryId: dataForm.categoryId,
      keywords: dataForm.keywords,
      createdBy: dataForm.createdBy,
      sortOrderType: dataForm.sortOrderType,
      searchByDate: dataForm.searchByDate,
      startTime: dateRange[0],
      endTime: dateRange[1],
      page: 0
    };
    debugger;
    setParamFilter(body);
  };

  return (
    <FormBase form={form} onFinish={onFinish}>
      <Row gutter={50}>
        <Col {...colProps}>
          <CategoryAutoCompleteFilter form={form} />
        </Col>
        <Col {...colProps}>
          <UserAutoCompleteFilter form={form} />
        </Col>
        <Col {...colProps}>
          <Form.Item name="sortOrderType" label="Sắp xếp">
            <Select
              options={[
                { value: 1, label: 'Mới nhất' },
                { value: 2, label: 'Vừa cập nhật' },
                { value: 3, label: 'Cũ nhất' }
              ]}
              style={{ width: '100%' }}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item name="keywords" label="Từ khóa">
            <Input placeholder="Nhập từ khóa" />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item name="dateRange" label="Khoảng thời gian">
            <DatePicker.RangePicker
              showTime={false}
              style={{ width: '100%' }}
              allowClear
              format="DD/MM/YYYY"
              placeholder={['Chọn ngày bắt đầu', 'Chọn ngày kết thúc']}
            />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item name="searchByDate" label="Khoảng thời gian theo">
            <Select
              options={[
                { value: 1, label: 'Khởi tạo' },
                { value: 2, label: 'Xuất bản' }
              ]}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8} justify="end">
        <Col>
          <Button htmlType="submit">Lọc</Button>
        </Col>
      </Row>
    </FormBase>
  );
};

export default SpotlightBankFilter;
