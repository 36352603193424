import { Empty, Timeline } from 'antd';
import React from 'react';

const NewsAuditLogList = ({ logs }) => {
  if (!logs || logs.length === 0) return <Empty />;

  return (
    <Timeline className="audit-log-timeline">
      {(logs || []).map(({ note, id }) => (
        <Timeline.Item key={id}>{note}</Timeline.Item>
      ))}
    </Timeline>
  );
};

export default NewsAuditLogList;
