import { Space, Typography } from 'antd';
import { DATE_FORMAT, TIME_FORMAT } from 'appConstants';
import moment from 'moment';

const ProcessCol = ({ title, userName, date }) => {
  return (
    <Space size={4}>
      <Typography.Text style={{ width: '100%' }} strong>
        {title}:
      </Typography.Text>
      {!userName || !date ? (
        <Typography.Text type="danger">Chưa có thông tin</Typography.Text>
      ) : (
        <Typography.Text>
          {userName} -{' '}
          {date && moment(date).format(TIME_FORMAT + ' ' + DATE_FORMAT)}
        </Typography.Text>
      )}
    </Space>
  );
};

export default ProcessCol;
