import { Button, Card, Col, Form, Row } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import HostAutoComplete from 'pages/Category/components/HostAutoComplete';
import { useAttachHostIntoCategory } from 'operations/mutations/category/mutateCategory';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_CATEGORY_BY_ID } from 'operations/queries/queryCategory';

const CategoryHosts = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { mutate: handleAttachHostIntoCategory } = useAttachHostIntoCategory();
  const { data } = useQuery(GET_CATEGORY_BY_ID, {
    variables: { id }
  });

  const category = useMemo(() => {
    return data?.category;
  }, [data?.category]);

  const hosts = useMemo(() => {
    return (category?.hosts || []).filter(
      (v, i, a) => a.findIndex(v2 => v2.id === v.id) === i
    );
  }, [category?.hosts]);

  const mcHosts = useMemo(() => {
    return hosts.filter(({ cateHosts }) => {
      return cateHosts.some(({ type }) => type === 1);
    });
  }, [hosts]);

  const editorHosts = useMemo(() => {
    return hosts.filter(({ cateHosts }) => {
      return cateHosts.some(({ type }) => type === 2);
    });
  }, [hosts]);

  const onSave = useCallback(async () => {
    try {
      const { firstHosts, secondHosts } = await form.validateFields();
      const hostIds = [
        ...firstHosts.map(id => ({ hostId: id, type: 1 })),
        ...secondHosts.map(id => ({ hostId: id, type: 2 }))
      ];

      handleAttachHostIntoCategory({
        variables: {
          categoryId: id,
          hostIds
        }
      });
    } catch (e) {
      throw e;
    }
  }, [form, handleAttachHostIntoCategory, id]);

  useEffect(() => {
    form.setFieldsValue({
      firstHosts: mcHosts.map(({ id }) => id),
      secondHosts: editorHosts.map(({ id }) => id)
    });
  }, [editorHosts, form, mcHosts]);

  return (
    <Card title="Phụ trách">
      <Form form={form} layout="vertical">
        <Form.Item label="MC" name="firstHosts">
          <HostAutoComplete host={mcHosts} mode="multiple" />
        </Form.Item>

        <Form.Item label="Biên tập" name="secondHosts">
          <HostAutoComplete host={editorHosts} mode="multiple" />
        </Form.Item>
      </Form>

      <Row justify="end">
        <Col>
          <Button onClick={onSave} type="primary">
            Lưu
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default CategoryHosts;
