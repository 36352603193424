import { Tag } from 'antd';
import { NEWS_STATUS_DICTIONARY_INT, NEWS_STATUS_KEY_INT } from 'appConstants';

const NewsStatusButton = ({ record }) => {
  const { status } = record;
  const generateColor = status => {
    switch (status) {
      case NEWS_STATUS_KEY_INT.WAITING:
        return 'orange';

      case NEWS_STATUS_KEY_INT.APPROVED:
        return 'blue';

      case NEWS_STATUS_KEY_INT.USED:
        return 'green';

      default:
        break;
    }
  };

  return (
    <Tag color={generateColor(status)} style={{ marginRight: 0 }}>
      {NEWS_STATUS_DICTIONARY_INT[status]}
    </Tag>
  );
};

export default NewsStatusButton;
