import { useReactiveVar } from '@apollo/client';
import { Result, Typography } from 'antd';
import { currentPermissionsVar } from 'api/cache';
import { useCurrentUser } from 'operations/queries/queryCurrentUser';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router';

const PrivateRoute = ({ children, isLoggedIn, path, keyCode, ...props }) => {
  const currentPermissions = useReactiveVar(currentPermissionsVar);
  const { loading, error } = useCurrentUser();
  if (loading) return <Typography.Text>Loading...</Typography.Text>;
  if (error)
    return (
      <Result
        status="500"
        title="500"
        subTitle="Hệ thống đang bảo trì, vui lòng quay lại sau."
        // extra={
        //   <Link to="/">
        //     <Button type="primary">Trang chủ</Button>
        //   </Link>
        // }
      />
    );
  // const { permissions, roleType } = detailConnect.detail.role;

  // const userType = detailConnect?.detail?.userType;

  // const currentPermissions = SCREEN_PERMISSIONS[userType];

  const render = () => {
    if (currentPermissions)
      switch (true) {
        case currentPermissions.includes(keyCode):
        case path === '/404':
        case path === '/unauthorized':
        case path === '/':
        case path === '/danh-sach-bai-tin':
        case path === '/chi-tiet-tin-bai/:id?':
        case path === '/ca-nhan/:id':
        case path === '/ca-nhan':
          return (
            <Route path={path} {...props}>
              {isLoggedIn ? children : <Redirect exact to="/dang-nhap" />}
            </Route>
          );

        default:
          return <Redirect to="/unauthorized" />;
      }
    else {
      return children;
    }
  };

  return render();
};

PrivateRoute.propTypes = {
  children: PropTypes.any,
  isLoggedIn: PropTypes.bool
};

export default PrivateRoute;
