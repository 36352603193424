import 'moment/locale/vi';
import { Space, Typography } from 'antd';
import ColName from 'components/ColName';

export const columns = [
  {
    title: 'STT',
    dataIndex: 'stt',
    render: (stt, record) => (
      <Space direction="vertical" >
        <Typography.Text strong>
          {stt}
        </Typography.Text>
      </Space>
    ),
    align: 'center',
    width: '40px'
  },
  {
    title: 'Từ khóa',
    key: 'keyWord',
    dataIndex: 'keyWord',
    render: (keyWord, record) => {
      return (
        <ColName
          primary={
            <div>{record?.keyWord}</div>
          }
        />
      )
    },
  },
  {
    title: 'Số lượt tìm kiếm',
    dataIndex: 'searchCount',
    render: (searchCount, record) => {
      return (
        <ColName
          primary={
            <div>{record?.searchCount}+</div>
          }
        />
      )
    },
    align: 'left',
    width: '180px'
  },
  {
    title: 'Ngày hoạt động',
    dataIndex: 'activeDay',
    render: (activeDay, record) => {
      return (
        <ColName
          primary={
            <div>{new Date(Date.parse(record?.activeDay)).getDate().toString().padStart(2, '0') + '/' + (parseInt(new Date(Date.parse(record?.activeDay)).getMonth() + 1).toString().padStart(2, '0')) + '/' + new Date(Date.parse(record?.activeDay)).getFullYear() + ' ' + new Date(Date.parse(record?.activeDay)).getHours().toString().padStart(2, '0') + ':' + new Date(Date.parse(record?.activeDay)).getMinutes().toString().padStart(2, '0')}</div>
          }
        />
      )
    },
    align: 'left',
    width: '180px'
  },
  {
    title: 'Top 3 trang web',
    dataIndex: 'topWeb',
    render: (_, record) => (
      <Space direction="vertical">
        {record?.topWeb?.split(',').map((item, index) => (
          <a target='_blank' href={item}>{item}</a>
        ))}
      </Space>
    ),
    align: 'left',
  },
];
