import { Card } from 'antd';

import { RadioDetailContext } from 'pages/RadioDetail/RadioDetail';
import React, { useContext } from 'react';
import NotesContent from './NotesContent';
import RadioNotesForm from './RadioNotesForm';

const RadioNotes = () => {
  const { radio } = useContext(RadioDetailContext);

  return (
    <Card title="Ghi chú">
      <NotesContent radio={radio} />
      <RadioNotesForm radio={radio} />
    </Card>
  );
};

export default RadioNotes;
