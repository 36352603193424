import { Button, Dropdown, Menu, Popover } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { MoreOutlined, DeleteOutlined } from '@ant-design/icons';
import { useCurrentUser } from 'operations/queries/queryCurrentUser';
import { useRemoveRadioNotes } from 'operations/mutations/radio/mutateRadio';
import { apolloClient } from 'api';
import { GET_RADIO_BY_ID } from 'operations/queries/queryRadio';

const NoteMenu = ({ radio, radioNote }) => {
  const { data } = useCurrentUser();
  const currentUser = useMemo(() => data?.detail, [data?.detail]);
  const { mutate } = useRemoveRadioNotes();

  const onDelete = useCallback(async () => {
    try {
      const { data } = await mutate({
        variables: {
          removeRadioNoteId: radioNote.id
        }
      });

      const variables = {
        id: radio.id
      };

      apolloClient.writeQuery({
        query: GET_RADIO_BY_ID,
        variables,
        data: {
          radio: {
            ...radio,
            radioNotes: radio.radioNotes.filter(
              _radioNote => _radioNote.id !== data.removeRadioNote.id
            )
          }
        }
      });
    } catch (e) {}
  }, [mutate, radio, radioNote.id]);

  const menu = useMemo(
    () => (
      <Menu
        items={[
          {
            key: '1',
            label: <span>Xóa ghi chú</span>,
            icon: <DeleteOutlined />,
            onClick: onDelete
          }
        ]}
      />
    ),
    [onDelete]
  );

  return (
    <Dropdown
      disabled={currentUser.id !== radioNote?.creator.id}
      trigger={'click'}
      overlay={menu}
    >
      <Button
        shape="circle"
        size="small"
        type="default"
        icon={<MoreOutlined />}
      />
    </Dropdown>
  );
};

export default NoteMenu;
