// src/Tiptap.jsx
import Link from '@tiptap/extension-link';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useEffect } from 'react';
import MenuBar from './MenuBar';

const Tiptap = ({ value, onChange }) => {
  // const triggerChange = changedValue => {
  //   onChange?.({
  //     ...value,
  //     ...changedValue
  //   });
  // };

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false
      })
    ],
    content: null,
    onUpdate({ editor }) {
      // The content has changed.
      const content = editor?.getHTML();
      onChange(content);
    }
  });

  useEffect(() => {
    const html = editor?.getText();
    if (!html && value) editor?.commands?.insertContent(value);
      if (!editor || !editor.view || !editor.view.dom) return;
  
      const handleLinkClick = (event) => {
        const anchor = event.target.closest('a');
        if (anchor && anchor.href) {
          event.preventDefault();
          window.open(anchor.href, "_blank");
        }
      };
  
      const { dom } = editor.view;
      dom.addEventListener('click', handleLinkClick);
  
      return () => {
        dom.removeEventListener('click', handleLinkClick);
      };
    
  }, [editor, value]);

  return (
    <div className="editor">
      <div className="editor__header">
        <MenuBar editor={editor} />
      </div>
      <EditorContent editor={editor} className="editor__content" />
    </div>
  );
};

export default Tiptap;
