import React, { useCallback } from 'react';
import { Avatar, Spin } from 'antd';
import { encodeUrlFile } from 'utils/other';

const UserAvatar = ({
  fullName,
  defaultAvatar = <></>,
  avatar,
  loading,
  typeName,
  ...props
}) => {
  const generateAvatarContent = useCallback((fullName, defaultAvatar) => {
    try {
      if (!fullName || typeof fullName !== 'string') {
        return defaultAvatar;
      }

      const arr = fullName.trim().split(' ');

      if (arr.length === 1) {
        return arr.shift()[0].toUpperCase();
      }

      return (arr.shift()[0] + arr.pop()[0]).toUpperCase();
    } catch (e) {
      return fullName;
    }
  }, []);
  const getAvatarRadio = useCallback((avatar) => {
    try {
      if (typeName && typeName == "thumbRadio") {
        const fileName = avatar?.split('/')?.pop();
        const path = avatar?.replace(fileName, '');
        const url = avatar?.split('/').shift()
          ? avatar
          : "https://cdn.voh.com.vn" + path + encodeURIComponent(fileName)
        return url;
      } else {
        return encodeUrlFile(avatar);
      }
    } catch (e) {
      return fullName;
    }
  }, []);
  return (
    <Spin spinning={!!loading}>
      {avatar ? (
        <Avatar
          style={{ userSelect: 'none' }}
          size={64}
          {...props}
          src={getAvatarRadio(avatar)}
        />
      ) : (
        <Avatar style={{ userSelect: 'none' }} size={64} {...props}>
          {generateAvatarContent(fullName, defaultAvatar)}
        </Avatar>
      )}
    </Spin>
  );
};

export default UserAvatar;
