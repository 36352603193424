import { Space, Typography, Button } from 'antd';
import { EditOutlined, HistoryOutlined } from '@ant-design/icons';

import { visibleDrawerVar } from 'api/cache';
import RelatedUser from 'components/RelatedUser';
import RemoveButton from 'components/RemoveButton';
import StatusButton from './components/StatusButton';
import TableRecordName from 'components/TableRecordName';
import TimeLine from 'components/TimeLine';
import {
  TRENDTOPIC_STATUS_COLOR,
  TRENDTOPIC_STATUS
} from 'appConstants';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from 'appConstants';
import 'moment/locale/vi';
import { Link } from 'react-router-dom';
import {
  useRemoveTrendTopic,
  useEditTrendTopicStatus
} from 'operations/mutations/TrendTopic/mutateTrendTopic';
import React, { useContext } from 'react';
import { TrendTopicContext } from './TrendTopicProvider';
import ColName from 'components/ColName';

const Btns = ({ record }) => {
  const { setAuditLogTarget } = useContext(TrendTopicContext);
  const { mutate } = useRemoveTrendTopic();
  record.id = record.trendTopicId

  return (

    <Space>
      <Button
        onClick={() => setAuditLogTarget(String(record.trendTopicId))}
        type="default"
        size="small"
        icon={<HistoryOutlined />}
      />
      <RemoveButton record={record} actionRemove={mutate} keyB='trendTopicId' />
    </Space>
  );
};
const isWithin24Hours = (dateString) => {
  const createdDate = new Date(dateString);
  const currentDate = new Date();
  const differenceInHours = (currentDate - createdDate) / (1000 * 60 * 60);
  return differenceInHours < 24;
};
const Status = ({ record }) => {
  const { mutate } = useEditTrendTopicStatus();

  const onClick = useStatus => {
    mutate({
      variables: {
        input: {
          trendTopicId: record.trendTopicId,
          title: record.title,
          useStatus
        }
      }
    });
  };
  return <StatusButton record={record} onClick={onClick} />;
};



// const Name = ({ record }) => {
//   const { title } = record;
//   return (
//     <Space direction="vertical">
//       <TableRecordName
//         name={title}
//         setDrawerVisible={() =>
//           visibleDrawerVar({ visible: true, record: record })
//         }
//       />
//     </Space>
//   );
// };

export const columns = [
  // {
  //   title: 'Trạng thái',
  //   dataIndex: 'usedStatus',
  //   key: 'usedStatus',
  //   render: (x, record) => (
  //     <Status record={record} />
  //   ),
  //   fixed: 'left',
  //   width: '100px'
  // },
  {
    title: 'Chủ đề',
    key: 'title',
    dataIndex: 'title',
    render: (_, record) => (
      <ColName
        primary={
          <Link
            style={{ color: 'inherit' }}
            to={`/chi-tiet-chu-de-trend/${parseInt(record?.trendTopicId)}`}
          >
            {isWithin24Hours(record?.createdDate) ? (
              <div className="ant-tag ant-tag-processing" style={{ margin: '0 6px' }}>New</div>
            ) : (
              null
            )}
            {record?.title} {<EditOutlined />}
            
          </Link>

        }

      />

    ),
    align: 'left',
    width: '300px'
  },
  {
    title: 'Ghi chú',
    dataIndex: 'note',
    key: 'note',
    render: (_, record) => (
      <Typography.Paragraph  >
        Ghi chú: {record?.note?.trim()
          ? <Typography.Text type="danger">Có</Typography.Text>
          : <Typography.Text >Không</Typography.Text>
        }
      </Typography.Paragraph>
    ),
    align: 'left',
    width: '130px'
  },
  // {
  //   title: 'Nền tảng đã khai thác',
  //   dataIndex: 'contentText',
  //   key: 'usePlatforms',
  //   render: (_, record) => (
  //     record?.usePlatforms && record?.usePlatforms.length > 0 ? (
  //       record.usePlatforms.map((item) => (
  //         <Typography.Paragraph key={item.platformName}>
  //           {item.platformName}
  //         </Typography.Paragraph>
  //       ))
  //     ) : (
  //       <Typography.Paragraph type="danger">Chưa có thông tin</Typography.Paragraph>
  //     )
  //   ),
  //   width: '200px'
  // },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '200px',
    render: (_, { createdDate, updatedDate, useBy }) => (
      <Space direction="vertical">
        <Typography.Text>
          <Typography.Text strong>Thời gian: </Typography.Text>
          {createdDate &&
            moment(createdDate).format(DATE_FORMAT + ' ' + TIME_FORMAT)}
        </Typography.Text>
        <Typography.Text>
          <Typography.Text strong>Cập nhật: </Typography.Text>
          {updatedDate &&
            moment(updatedDate).format(DATE_FORMAT + ' ' + TIME_FORMAT)}
        </Typography.Text>
        {/* <Typography.Text>
          <Typography.Text strong>Khởi tạo: </Typography.Text>
          {
            useBy?.name
              ? useBy?.name
              : <Typography.Text type="danger">Chưa có thông tin</Typography.Text>
          }

        </Typography.Text> */}

        {/* <Typography.Text>{moment(createdDate).fromNow()}</Typography.Text> */}
      </Space>
    )
  },
  // {
  //   title: 'Lịch sử ghi nhận',
  //   dataIndex: 'createdDate',
  //   width: '180px',
  //   render: (_, { creator, updater }) => (
  //     <RelatedUser creator={creator} updater={updater} />
  //   )
  // },
  {
    title: 'Thao tác',
    width: '100px',
    render: (_, record) =>
      <Btns record={record} />
    ,
    toggleEdit: true,
    align: 'center',
    fixed: 'right'
  }
];
