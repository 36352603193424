import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const EDIT_HOST = gql`
  mutation EditHost($input: EditHostInput!) {
    editHost(input: $input) {
      id
      name
      image
      currentWorkingPlace
      experience
      education
      introduction
    }
  }
`;

export const ADD_HOST = gql`
  mutation AddNewHost($addHostInput: AddHostInput!) {
    addHost(input: $addHostInput) {
      id
      name
      image
      currentWorkingPlace
      experience
      education
      introduction
    }
  }
`;

export const REMOVE_HOST = gql`
  mutation RemoveHost($id: String!) {
    removeHost(id: $id) {
      id
      name
    }
  }
`;

export const BULK_ACTION_HOSTS = gql`
  mutation BulkActionHosts($input: [BulkActionHostInput]) {
    bulkActionHosts(input: $input) {
      id
      name
      status
      isDeleted
    }
  }
`;

export const useAddHost = () => {
  const [mutate, { data, error }] = useMutation(ADD_HOST, {
    refetchQueries: ['GetHosts'],
    onCompleted: ({ addHost }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo host <Typography.Text strong>{addHost.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useEditHost = () => {
  const [mutate, { data, error }] = useMutation(EDIT_HOST, {
    refetchQueries: ['GetHosts'],
    onCompleted: ({ editHost }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa host{' '}
            <Typography.Text strong>{editHost.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useRemoveHost = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_HOST, {
    refetchQueries: ['GetHosts'],
    onCompleted: ({ removeHost }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá host{' '}
            <Typography.Text strong>{removeHost.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useBulkHosts = actionField => {
  const [mutate, { data, error }] = useMutation(BULK_ACTION_HOSTS, {
    refetchQueries: ['GetHosts'],
    onCompleted: ({ bulkActionHosts }) => {
      getBulkMessage('host', bulkActionHosts, actionField);
    }
  });

  return { mutate, data, error };
};
