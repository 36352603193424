import React from 'react';
import { Pagination, Row, Table as AntTable } from 'antd';
import QuantityTableFooter from 'components/QuantityTableFooter';

const Table = ({ columns, dataSource, pagination, ...rest }) => {
  return (
    <div className="navi-table">
      <AntTable
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        {...rest}
      />

      {pagination && (
        <Row
          align="middle"
          justify="space-between"
          style={{ margin: '16px 0px' }}
        >
          <QuantityTableFooter
          pageSize={pagination.pageSize}
            page={pagination.current}
            quantity={pagination.quantity}
            total={pagination.total}
          />

          <Pagination {...pagination} />
        </Row>
      )}
    </div>
  );
};

export default Table;
