import { Select } from 'antd';
import { CHANNEL_BROADCAST_DICTIONARY } from 'appConstants';
import React from 'react';
const { Option } = Select;

const ChannelSelect = props => {
  return (
    <Select
      allowClear
      placeholder="Chọn kênh"
      style={{ width: '100%' }}
      {...props}
    >
      {Object.entries(CHANNEL_BROADCAST_DICTIONARY).map(([key, value]) => (
        <Option key={key} value={key}>
          {value}
        </Option>
      ))}
    </Select>
  );
};

export default ChannelSelect;
