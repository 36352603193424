import { Select } from 'antd';
import { DEBOUNCE_VALUE } from 'appConstants';
import React, { useEffect, useState } from 'react';
import { debounce } from 'utils/lodash';

const AutoComplete = ({
  defaultOption,
  labelKey,
  labelKey2,
  valueKey,
  dataKey,
  handleSearch,
  disableValue,
  prefixDataKey,
  prefixDataKey2,
  prefixDataUnit2,
  extraParams,
  getLabel,
  ...props
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (Array.isArray(defaultOption)) {
      defaultOption && setOptions(defaultOption);
    } else {
      defaultOption && setOptions([defaultOption]);
    }
  }, [defaultOption]);

  const onSearch = async searchText => {
    if (!searchText) {
      return;
    }

    let whereCondition = {
      variables: {
        where: { [labelKey]: { contains: searchText }, ...extraParams }
      }
    };

    if (labelKey2) {
      whereCondition = {
        variables: {
          where: {
            or: [
              { [labelKey]: { contains: searchText } },
              { [labelKey2]: { contains: searchText } }
            ]
          },
          ...extraParams
        }
      };
    }

    const results = await handleSearch(whereCondition);

    if (!results) {
      return;
    }

    return setOptions(
      results.data[dataKey]?.items?.map(result => ({
        label:
          (getLabel
            ? getLabel(result)
            : (prefixDataKey ? result[prefixDataKey] + ' - ' : '') +
              result[labelKey]) +
          (prefixDataKey2
            ? ' - ' +
              result[prefixDataKey2] +
              (prefixDataUnit2 ? prefixDataUnit2 : '')
            : ''),
        value: result[valueKey]
      }))
    );
  };

  const debouncedOnSearch = debounce(value => onSearch(value), DEBOUNCE_VALUE);

  const renderOptions = () => {
    return options.map(({ value, label }) => (
      <Select.Option
        value={value}
        key={value}
        disabled={disableValue === value}
      >
        {label}
      </Select.Option>
    ));
  };

  return (
    <Select
      filterOption={false}
      notFoundContent={null}
      showSearch
      onSearch={debouncedOnSearch}
      {...props}
    >
      {renderOptions()}
    </Select>
  );
};

export default AutoComplete;
