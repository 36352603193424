import { Spin, Typography } from 'antd';

import React from 'react';

import RadioNotification from './components/RadioNotification';
import Statistic from './components/Statistic';
import Radios from './components/Radios';
import useDashboard from './useDashboard';

const DashboardContext = React.createContext();

const Dashboard = () => {
  const { data, loading } = useDashboard();

  return (
    <DashboardContext.Provider value={data}>
      <Spin spinning={loading}>
        <Typography.Title>Bảng điều khiển</Typography.Title>

        <RadioNotification />

        <Statistic />

        <div style={{ marginBottom: 8 }} />

        <Radios />
      </Spin>
    </DashboardContext.Provider>
  );
};
export { DashboardContext };
export default Dashboard;
