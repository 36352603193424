import { useReactiveVar } from '@apollo/client';
import { Button, Col, Form, Input, Row } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import AvatarUpload from 'components/AvatarUpload';
import React, { useEffect } from 'react';

const HostFormContent = ({ form, onSubmit }) => {
  const visibleDrawer = useReactiveVar(visibleDrawerVar);
  const host = visibleDrawer.record;

  useEffect(() => {
    if (host) {
      form.setFieldsValue({
        name: host?.name,
        introduction: host?.introduction,
        currentWorkingPlace: host?.currentWorkingPlace,
        experience: host?.experience,
        education: host?.education,
        image: host?.image
      });
    }
    return () => form.resetFields();
  }, [host, form]);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Row justify="center" align="middle">
        <Col>
          <Form.Item name="image" valuePropName="file">
            <AvatarUpload size={128} fontSize={64} fullName={host?.name} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="name"
        label="Tên host"
        rules={[{ required: true, message: 'Nhập tên!' }]}
      >
        <Input placeholder="Nhập họ tên" />
      </Form.Item>
      <Form.Item name="introduction" label="Giới thiệu">
        <Input.TextArea placeholder="Nhập giới thiệu" />
      </Form.Item>
      <Form.Item name="currentWorkingPlace" label="Cơ quan công tác">
        <Input placeholder="Cơ quan công tác" />
      </Form.Item>
      <Form.Item name="experience" label="Quá trình công tác">
        <Input.TextArea placeholder="Nhập quá trình công tác" />
      </Form.Item>
      <Form.Item name="education" label="Quá trình học tập">
        <Input.TextArea placeholder="Nhập quá trình học tập" />
      </Form.Item>
      <Form.Item style={{ display: 'none' }}>
        <Button htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  );
};

export default HostFormContent;
