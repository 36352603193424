import { Typography } from 'antd';

import React from 'react';

import RadioList from './components/RadioList';
import RadioFilter from './RadioFilter';
import RadioProvider from './RadioProvider';
import RadioAuditLog from './RadioAuditLog';

const Radio = () => {
  return (
    <RadioProvider>
      <div className="spacing-container">
        <Typography.Title>Quản lý radio</Typography.Title>

        <RadioFilter />

        <RadioList />

        <RadioAuditLog />
      </div>
    </RadioProvider>
  );
};

export default Radio;
