import { Space, Tag, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import ColName from 'components/ColName';
import RadioStatusTag from 'components/RadioStatusTag';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { CHANNEL_BROADCAST_DICTIONARY_IMG } from 'appConstants';
import { RadioImage } from 'pages/Radio/columns';

const Channel = ({ channelId }) => {
  const channel = CHANNEL_BROADCAST_DICTIONARY_IMG.find(
    ({ value }) => value === channelId
  );

  if (channel) {
    return <Tag color={channel.color}>{channel.label}</Tag>;
  }

  return <></>;
};

export const columns = [
  {
    dataIndex: 'image',
    key: 'image',
    render: RadioImage,
    width: 100
  },
  {
    title: 'Chủ đề',
    dataIndex: 'title',
    key: 'image',
    render: (_, record) => (
      <ColName
        popover={record?.title}
        primary={
          <Link to={`/chi-tiet-radio/${record?.id}`}>
            {record?.title} {<EditOutlined />}
          </Link>
        }
        secondary={record?.category?.title}
      />
    )
  },
  {
    title: 'Kênh phát sóng',
    key: 'channel',
    dataIndex: 'channel',
    render: channel => <Channel channelId={channel.toUpperCase()} />,
    width: 130
  },
  {
    title: 'Trạng thái',
    key: 'status',
    render: (_, record) => (
      <RadioStatusTag status={record?.statusName?.toUpperCase()} />
    ),
    width: 130
  },
  {
    title: 'Phát sóng',
    dataIndex: 'broadcastTime',
    render: broadcastTime => (
      <Space direction="vertical">
        <Typography.Text>
          {broadcastTime && moment(broadcastTime).format('DD/MM/YYYY HH:mm')}
        </Typography.Text>

        <Typography.Text>
          {broadcastTime && moment(broadcastTime).fromNow()}
        </Typography.Text>
      </Space>
    ),

    width: 200
  }
];
