import { gql } from '@apollo/client';

export const GET_TRENDTOPICS = gql`
  query GetTrendTopics(
    $skip: Int
    $take: Int
    $order: [TrendTopicSortInput!]
    $where: TrendTopicFilterInput
  ) {
    trendTopics(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        trendTopicId
        note
        title
        contentText
        sourceUrl
        usedStatus
        updatedDate
        createdDate
        sourceMedia
        useDateTime
        creator {
          id
          name
        }
        updater {
          id
          name
        }
        useBy{
          id
          name
        }
        usePlatforms{
          platformName
          usePlatformId
        }
      }
    }
  }
`;

export const GET_TRENDTOPIC_BY_ID = gql`
query Query($id: Int!) {
  trendTopic(id: $id) {
    trendTopicId
    note
    title
    contentText
    sourceUrl
    usedStatus
    useDateTime
    sourceMedia
    creator {
      id
      name
    }
    updater {
      id
      name
    }

  }
}
`;

export const GET_PLATFORMS = gql`
query GetUsePlatforms( $where: UsePlatformsFilterInput) {
  usePlatforms (  where: $where) {
    totalCount
    items  {
    usePlatformId
  platformName
  
  }
    __typename
  }
}
`;

export const GET_All_PLATFORMS = gql`
query GetAllUsePlatforms {
  allUsePlatforms {
        usePlatformId
platformName
  }
}
`;

