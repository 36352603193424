import { useLazyQuery } from '@apollo/client';
import { Form } from 'antd';
import {
  FILTER_PARAMS_DATA_TYPE_SUFFIX,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX
} from 'appConstants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { processFormItemName } from 'utils/filter';
import { GET_PLATFORMS ,GET_All_PLATFORMS} from 'operations/queries/queryTrendTopic';
import AutoComplete from 'components/AutoComplete';

const PlatFormAutoCompleteFilter = ({ name }) => {

  const createdByFilterName = useMemo(() => {
    return processFormItemName({
      prefix: FILTER_PARAMS_PREFIX,
      name: 'platFormId',
      operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
      dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.ARR
    });
  }, []);
  const [search] = useLazyQuery(GET_All_PLATFORMS);

  const [searchUser, { loading }] = useLazyQuery(GET_PLATFORMS);
  const [options, setOptions] = useState([]);

  useEffect(async () => {
    const results = await search();
    setOptions(
      results.data.allUsePlatforms?.map(result => ({
        label: result?.platformName,
        value: result?.usePlatformId
      }))
    );
  }, []);

  return (
    <>
      <Form.Item name={name ? name : createdByFilterName} label="Nền tảng">
        <AutoComplete
          handleSearch={searchUser}
          mode="multiple"
          labelKey="platformName"
          valueKey="usePlatformId"
          dataKey="usePlatforms"
          placeholder="Nền tảng"
          style={{ width: '100%' }}
          defaultOption={options}
        />
      </Form.Item>
    </>
  );
};

export default PlatFormAutoCompleteFilter;
