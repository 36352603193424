import React, { useEffect, useMemo, useState } from 'react';

import { Button, Col, Form, Input, DatePicker, Row, Select } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import {
  colProps,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX,
  FILTER_PARAMS_DATA_TYPE_SUFFIX,
  TRENDTOPIC_STATUS
} from 'appConstants';
import queryString from 'query-string';
import { visibleDrawerVar } from 'api/cache';
import FormBase from 'containers/FormBase';
import moment from 'moment';
import { processFormItemName } from 'utils/filter';
import UserAutoCompleteFilter from './UserAutoCompleteFilter';
import PlatFormAutoCompleteFilter from './PlatFormAutoCompleteFilter';

const TrendTopicFilter = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [isShow, setIsShow] = useState(false);
  const broadcastTimeFormName = useMemo(
    () =>
      processFormItemName({
        prefix: FILTER_PARAMS_PREFIX,
        name: 'createdDate',
        operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.BTW,
        dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.DATE
      }),
    []
  );
  const onShowFilter = async () => {
   setIsShow(!isShow);
  };
  useEffect(() => {
    const initValue = queryString.parse(history.location.search);
    if (initValue.filter__platFormId__eq__arr) {
      if (Array.isArray(initValue.filter__platFormId__eq__arr))
        initValue.filter__platFormId__eq__arr = initValue.filter__platFormId__eq__arr.map(x => Number(x))
      else initValue.filter__platFormId__eq__arr = Number(initValue.filter__platFormId__eq__arr)
    }
    const broadcastTimes = initValue[broadcastTimeFormName];
    form.setFieldsValue({
      ...initValue,
      ...(broadcastTimes
        ? {
          [broadcastTimeFormName]: broadcastTimes.map(broadcastTime =>
            moment(broadcastTime)
          )
        }
        : {})
    });
  }, [broadcastTimeFormName, form, history.location.search]);

  return (
    <FormBase form={form}>
      {isShow&&
      <Row gutter={8}>
        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'title',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.CONTAINS
            })}
            label="Từ khóa"
          >
            <Input placeholder="Nhập từ khóa" />
          </Form.Item>
        </Col>
        {/* <Col {...colProps}>
          <PlatFormAutoCompleteFilter />
        </Col> */}
        {/* <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'note',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
              dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.STRING
            })}
            label="Ghi chú"
          >
            <Select
              options={[{ value: "0", label: "Không ghi chú" }, { value: "1", label: "Có ghi chú" }]}
              style={{ width: '100%' }}
              placeholder="Chọn trạng thái ghi chú"
              allowClear
            />
          </Form.Item>
        </Col> */}

        <Col {...colProps}>
          <Form.Item name={broadcastTimeFormName} label="Thời gian tạo">
            <DatePicker.RangePicker
              showTime={false}
              style={{ width: '100%' }}
              allowClear
              format="DD/MM/YYYY"
              placeholder={['Chọn ngày bắt đầu', 'Chọn ngày kết thúc']}
            />
          </Form.Item>
        </Col>
        {/* <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'usedStatus',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
              dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.INT
            })}
            label="Trạng thái"
          >
            <Select
              options={Object.entries(
                TRENDTOPIC_STATUS
              ).map(([key, value]) => ({
                value: key,
                label: value,
              }))}
              style={{ width: '100%' }}
              placeholder="Chọn trạng thái"
              allowClear
            />
          </Form.Item>
        </Col> */}
        <Col {...colProps}>
          <UserAutoCompleteFilter form={form} />
        </Col>
      </Row>
    }
      <Row gutter={8} justify="end">
        <Col>
          <Button htmlType="text" onClick={onShowFilter}>{!isShow?"Bộ Lọc":"Bỏ lọc"}</Button>
        </Col>
        {isShow&&
        <Col>
          <Button htmlType="submit">Lọc</Button>
        </Col>}
        <Col>
          <Link to={'/chi-tiet-chu-de-trend'}>
            <Button type="primary">Thêm</Button>
          </Link>
        </Col>
      </Row>
    </FormBase>
  );
};

export default TrendTopicFilter;
